import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Injector,
  Input, OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from '@angular/forms';
import { DocumentTag, FileWithTags, UploadFileDialogData, UploadFileValue } from '@portal-workspace/grow-shared-library';
import {ApplicationDialogService, fieldErrorMessages} from '@portal-workspace/grow-ui-library';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatCardModule } from '@angular/material/card';
import { ExtendedModule } from '@angular/flex-layout/extended';
import {JsonPipe, NgClass } from '@angular/common';
import mime from 'mime';
import _ from 'lodash';
// var match = require('mime-match');
import {mimeMatch as match} from '@portal-workspace/grow-shared-library';
import { MatChipsModule } from '@angular/material/chips';
import {tap} from 'rxjs/operators';
import { MatTooltipModule } from '@angular/material/tooltip';



// NOTE: value object for this component: UploadFileValue
@Component({
    selector: 'upload-file',
    templateUrl: './upload-file.component.html',
    styleUrls: ['./upload-file.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => UploadFileComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => UploadFileComponent) },
    ],
    standalone: true,
    imports: [JsonPipe, NgClass, ExtendedModule, MatCardModule, FlexModule, MatFormFieldModule, MatChipsModule,MatTooltipModule]
})



export class UploadFileComponent implements ControlValueAccessor, OnInit, Mark {

  // subscriptions: Subscription[] = [];

  onTouchFn?: ()=>void;
  onChangeFn?: (v?: UploadFileValue)=>void;
  disabled = false;
  isDirty = false;
  hasErrors: boolean = false;
  errorMessages: string[] = [];

  @ViewChild('fileInputRef') fileInputRef!: ElementRef;
  @Output() events: EventEmitter<File[]> = new EventEmitter<File[]>();
  @Output() selectedFileTags = new EventEmitter<FileWithTags[]>();

  @Input({required: false}) title: string = 'Driver licence';
  @Input({required: false}) message: string = 'Drop files here or click to upload';
  @Input({required: false}) subMessage: string | null = null;
  @Input({required: false}) mandatory = false;
  @Input({required: false}) isShowFlieList:boolean = true;
  @Input({required: false}) isShowEditButton:boolean = false;
  @Input({required: false}) allowMultiple:boolean = false;
  @Input({required: false}) maxFilesAllowed = 1;
  @Input({required: false}) allowDuplicateFiles: boolean = true;
  @Input({required: false}) tags1: DocumentTag[] = [];
  @Input({required: false}) selectableTags: DocumentTag[] = [];
  @Input({required: false}) allTags: DocumentTag[] = [];
  @Input({required: false}) showMaxTagErrorMessage = false;
  @Input({required: false}) dialogData!: UploadFileDialogData;
  @Input({required: false}) acceptFileTypes: string[] = [
    '.doc',
    '.docm',
    '.docx',
    '.gif',
    '.htm',
    '.html',
    '.zip', // 'application/zip'
    'application/x-zip-compressed',
    'application/zip-compressed',
    'application/x-zip',
    '.jpg',
    '.jpeg',
    '.xls',
    '.xlsx',
    '.csv',
    '.pdf',
    '.png',
    '.ppt',
    '.pptx',
    '.rar',
    '.tif',
    '.tiff',
    '.txt',
    'image/*'
  ];

  // private errorStatus: boolean = false;
  // private errorMessageList: string[] = [];
  mimeTypes: string[] = [];  // computed mime types
  inDropableState = false;
  files: File[] = [];
  tags: DocumentTag[] = [];
  filesWithTags: FileWithTags[] = [];
  MAX_TAG_ALLOWED = 10;

  duplicateFileNames: string[] = [];

  constructor(private cdr: ChangeDetectorRef,
              private applicationDialogService: ApplicationDialogService,
              private injector: Injector) {
  }

  ngOnInit() {
    this.mimeTypes = this.acceptFileTypes
      .map(t => {
        if (t.indexOf('/')>0) {
          return t;
        }
        const v = mime.getType(t) ?? '';
        return v;
      }).filter(t => t);
      // this.updateValidationStatus()
      this.checkErrors(this.files);

  }

  // updateValidationStatus () {
  //   this.errorStatus = this.checkForErrors()
  //   this.errorMessageList = this.fileErrorMessages()
  // }

  // checkForErrors(): boolean {
  //   const hasErrors = this.isDirty &&
  //     ((this.mandatory && this.files.length < 1) ||
  //     (this.files.length > this.maxFilesAllowed) ||
  //     (this.duplicateFileNames.length > 0));

  //     return hasErrors;
  // }

  private checkErrors(files: File[]) {
    const _hasErrors = this.isDirty &&
      (
        (this.mandatory && files.length < 1) ||
        (files.length > this.maxFilesAllowed) ||
        (this.duplicateFileNames.length > 0)
      )
    ;
    this.hasErrors = _hasErrors;
    const _errorMessages: string[] = [];
    if (_hasErrors) {
      if (this.mandatory && this.files.length < 1) {
        _errorMessages.push(fieldErrorMessages.required);
      }
      if (this.files.length > this.maxFilesAllowed) {
        _errorMessages.push(fieldErrorMessages.uploadFile({maxFiles: this.maxFilesAllowed, total: this.files.length}));
      }
      if (this.duplicateFileNames.length) {
        _errorMessages.push(fieldErrorMessages.uploadDuplicateFile({filename: this.duplicateFileNames.join(',')}));
        this.duplicateFileNames = [];
      }
    }
    this.errorMessages = _errorMessages;
  }

  // errorMessages(): string[] {
  //   const errors: string[] = [];
  //   if (this.mandatory && this.files.length < 1) {
  //     errors.push(fieldErrorMessages.required);
  //   }
  //   if (this.files.length > this.maxFilesAllowed) {
  //     errors.push(fieldErrorMessages.uploadFile({maxFiles: this.maxFilesAllowed, total: this.files.length}));
  //   }
  //   if (this.duplicateFileNames.length) {
  //     for (const duplicateFileName of [...this.duplicateFileNames]) {
  //       errors.push(fieldErrorMessages.uploadDuplicateFile({filename: duplicateFileName}));
  //     }
  //     this.duplicateFileNames = [];
  //   }
  //   return errors;
  // }


  fileInputChange($event: Event) {
    const inputElement = $event.target as HTMLInputElement;
    const files = ($event.target as HTMLInputElement).files;
    this.addFiles(files);
    inputElement.value = ''; // clear filelist
    // if (files && files.length) {
    //   for (const f of Array.from(files)) {
    //     this.files.push(f);
    //   }
    // }
    // inputElement.value = ''; // clear filelist
    // this.events.emit(this.files);
    // console.log('**** files (input) ', this.files);
    // this.propagateChange(this.files);
  }

  onClick($event: Event) {
    this.markAsDirty();
    (this.fileInputRef.nativeElement as HTMLInputElement).click();
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.inDropableState = true;
    this.markAsDirty();
    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = "copy";
    }
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    this.markAsDirty();
    this.inDropableState = false;
    const files = event.dataTransfer?.files;
    this.addFiles(files);
    // if (files && files.length > 0) {
    //   for (const f of Array.from(files)) {
    //     this.files.push(f);
    //   }
    //   this.events.emit(this.files);
    //   console.log('**** files (on drop) ', this.files);
    //   this.propagateChange(this.files);
    // }
  }

  onDragLeave($event: DragEvent) {
    this.markAsDirty();
    this.inDropableState = false;
  }

  onDragEnter($event: DragEvent) {
    this.markAsDirty();
    this.inDropableState = true;
  }

  deleteFile($event: MouseEvent, file: File) {
    this.markAsDirty();
    this.files = this.files.filter(f => {
      return f !== file;
    });

    this.events.emit(this.files);
    if (this.files.length) {
      this.propagateChange(this.files);
    } else {
      this.propagateChange(null);
    }
  }


  // NOTE: not used
  // remove(tag: DocumentTag): void {
  //   const index = this.tags.indexOf(tag);
  //   if (index >= 0) {
  //     this.tags.splice(index, 1);
  //     // remove max tag error message
  //     if (this.tags.length < this.MAX_TAG_ALLOWED) {
  //       this.showMaxTagErrorMessage = false;
  //     }
  //   }
  //   this.selectableTags = this.allTags.filter(t => !this.tags.map(tag => tag.value).includes(t.value));
  // }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj?: UploadFileValue): void {
    if (obj == null) {
      this.markAsUntouched();
    }
    this.propagateChange(obj);
  }

  propagateChange(v?: UploadFileValue) {
    this.files = v ? v : [];
    this.onTouchFn && this.onTouchFn();
    this.checkErrors(this.files);
     //this.onChangeFn && this.onChangeFn(this.hasErrors ? null : this.files.length ? this.files : null);
     this.onChangeFn && this.onChangeFn(this.files.length ? this.files : null);
  }

  markAsDirty() {
    this.isDirty = true;
  }

  mark(): void {
    this.markAsDirty();
    this.cdr.markForCheck();
  }

  markAsUntouched() {
    this.isDirty = false;
    const ngControl = this.injector.get(NgControl);
    if (ngControl && ngControl.control) {
      ngControl.control.markAsUntouched();
    }
  }

  private addFiles(files?: FileList | null) {
    if (files && files.length) {

      /// ========================
      /// === VALIDATION stage ===
      /// ========================
      // handle file rejection (invalid mime types format)

      const rejectedFile: File[] = [];
      let _files: File[] = [...(this.files ?? [])]; // effective files after passing all filtering
      for (const _file of Array.from(files)) {
        if (this.matchMimeTypes(_file)) {
          _files.push(_file);
        } else {
          rejectedFile.push(_file);
        }
      }
      if (rejectedFile.length) {
        this.applicationDialogService.openAlertDialog({
          message: `Invalid file types`,
          subMessage: `Files (${rejectedFile.map(f => f.name).join(',')}) has invalid types`,
        });
        return;
      }
 
      // handle duplicate file names
      if (this.allowDuplicateFiles) {
        this.duplicateFileNames = Object.entries(
          _.countBy(_files.map(file => file.name))) // array group by filename and it's count eg. [{'file1': 2, 'file2': 1, 'file3': 1}]
          .filter(entry => entry[1] > 1)      // filter those have count > 1
          .map(entry => entry[0])             // get the filename
      }

      // check and make sure there aren't any errors
    

      // filter out duplicate files from _files
      // _files = _files.filter(file => this.duplicateFileNames.includes(file.name));


      /// ===================
      /// === READY stage ===
      /// ===================
      // copy over _files to instance's file when we are ready

      // this.files = _files;
      // const m = this.files.concat(_files)
      const m = _files;
      this.files = m
      for (let i = 0; i < files.length; i++) {
        const f = files.item(i)
        if(this.tags1 && files && f){
            this.addFileWithTags(f,this.tags1)
        }
      }
      this.events.emit(this.files);
      if (this.files.length) {
        this.propagateChange(this.files);
      } else {
        this.propagateChange(null);
      }

      

    }
  }

  private getFileExtension(filename: string): string {
    return "." + filename.split('.').pop() || '';
  }

  private matchMimeTypes(file: File) {
    if (this.mimeTypes.length == 0) { // no mime types, assume match
      return true;
    }
    
    for (const mimeType of this.mimeTypes) {
      const matched = match(file.type, mimeType);
      if (matched) {
        return true;
      }else if(file.type === ""){
        const ext = this.getFileExtension(file.name);
        const type = mime.getType(ext)
        if(type){
          const matched = match(type, mimeType);
          if (matched) {
            return true;
          }
        }
        
      }

    }
    return false;
  }

  editFile($event: MouseEvent, file: File) {
    this.applicationDialogService.openSelectableTagsDialog({
      selectableTags: this.selectableTags,
      tagsSel: this.getTagsByFileName(file),
      allTags: this.allTags,
      showMaxTagErrorMessage: this.showMaxTagErrorMessage,
    }).afterClosed().pipe(
      tap((r) => {
        if (r && r.valid) {
          this.tags = r.selectedTags
          this.addFileWithTags(file,r.selectedTags)

        }
      })
    ).subscribe()
    // this.propagateChange(this.files);
  }


  addFileWithTags(fileName: File, fileTags: DocumentTag[]): void {
    const fileIndex = this.getFileIndexByFilename(fileName)
    const existingFile = this.filesWithTags[fileIndex];
    if (existingFile) {
      // Override the tags for the existing file
       existingFile.fileTags = fileTags;
    } else {
      // Add a new file with tags
      const newFileWithTags: FileWithTags = {  fileName:fileName.name ,fileTags };
      this.filesWithTags[fileIndex] = newFileWithTags
    }

    this.selectedFileTags.emit(this.filesWithTags)
 }

 removeSingleTagFromFile(file: File, tagValue: DocumentTag): void {
    const existingFileIndex = this.getFileIndexByFilename(file)
    //this.filesWithTags[existingFileIndex].fileTags.filter(tag => tag.value !== tagValue.value);
    const fileData = this.filesWithTags[existingFileIndex];
    if (fileData) {
        // remove max tag error message
        if (fileData.fileTags.length < this.MAX_TAG_ALLOWED) {
          this.showMaxTagErrorMessage = false;
        }

      fileData.fileTags = fileData.fileTags.filter(tag => tag.value !== tagValue.value);
    }

    this.selectedFileTags.emit(this.filesWithTags)
  }

  getFileIndexByFilename(file: File){
    return  this.files.indexOf(file);
  }

  getTagsByFileName(file: File): DocumentTag[] {
    const index = this.getFileIndexByFilename(file)
    const tagList = this.filesWithTags[index];
    return tagList ? tagList.fileTags : [];
  }
}
