// self-employed, full-time, part-time, contract, casual
export type EmploymentStatusType = 'self-employed' | 'full-time' | 'part-time' | 'contract' | 'casual';

export const EmploymentStatusSelectionValueOptions: Exclude<EmploymentStatusSelectionValue, null>[] = [
  { type: 'self-employed', name: 'Self Employed'},
  { type: 'full-time', name: 'Full Time'},
  { type: 'part-time', name: 'Part Time'},
  { type: 'contract', name: 'Contract'},
  { type: 'casual', name: 'Casual'},
];

export type EmploymentStatusSelectionValue = {
  type: EmploymentStatusType,
  name: string,
} | null;

