import {Component, Input, OnInit, OnChanges, SimpleChanges, EventEmitter, Output} from '@angular/core';
import {AccountFilter, AccountSummary, BankStatementsAnalysisData, DebitCreditSummary, GetBsaCalculatorFn, SaveBsaCalculatorFn} from '@portal-workspace/grow-shared-library';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { DayEndBalanceChartComponent } from './day-end-balance-chart.component';
import { DecisionMetricsComponent } from './decision-metrics.component';
import { TransactionSummaryComponent } from './transaction-summary.component';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from "@angular/flex-layout";
import { FullTransactionListComponent } from './full-transaction-list.component';
import { CategoriesTableComponent } from './categories-table.component';
import { MonthlyDebitsCreditsChartComponent } from './monthly-debits-credits-chart.component';
import { MonthlyDebitsCreditsTableComponent } from './monthly-debits-credits-table.component';
import { NonPreferredLenderSummaryComponent } from './non-preferred-lender-summary.component';
import { OverdrawnTableComponent } from './overdrawn-table.component';
import { TransactionTableComponent } from './transaction-table.component';
import { DishonoursTableComponent } from './dishonours-table.component';
import { MonthlyRevenueChartComponent } from './monthly-revenue-chart.component';
import { MonthlyRevenueTableComponent } from './monthly-revenue-table.component';

@Component({
    selector: 'bank-statement-overview',
    templateUrl: './bank-statement-overview.component.html',
    styleUrls: ['./bank-statement-overview.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    standalone: true,
    imports: [MatTableModule, NgClass, ExtendedModule, CategoriesTableComponent, MonthlyRevenueTableComponent, MonthlyRevenueChartComponent, DishonoursTableComponent, TransactionTableComponent, OverdrawnTableComponent, NonPreferredLenderSummaryComponent, MonthlyDebitsCreditsChartComponent, MonthlyDebitsCreditsTableComponent, FullTransactionListComponent, MatButtonModule, FormsModule, FlexModule, ReactiveFormsModule, MatCheckboxModule, TransactionSummaryComponent, DecisionMetricsComponent, DayEndBalanceChartComponent, LooseCurrencyPipe]
})
export class BankStatementOverviewComponent implements OnInit, OnChanges {
  @Input({required: true}) data!: BankStatementsAnalysisData;
  @Input({required: false}) selectedAccounts: number[] =[];
  @Input({required: true}) saveBsaCalculatorFn!: SaveBsaCalculatorFn;
  @Input({required: true}) getBsaCalculatorFn!: GetBsaCalculatorFn;
  @Output() accountSelector = new EventEmitter();

  dataSource: AccountSummary[] = [];
  columnsToDisplay = ['icon', 'BankName', 'BSBAccNumber', 'AccHolder', 'AccName', 'currentBalance', 'TotalCredit', 'LoanCreditFlag', 'selector'];
  accountFilter: AccountFilter = {};
  expandedElement!: AccountSummary | null;
  transactionListSelectedAccounts: number[] = [];

  constructor( private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
   this.init();
  }

  init() {
    this.dataSource = this.data?.AccountSummary;
    this.initAccountFilter();
    this.data.AccountSummary.forEach((account: AccountSummary) => {
      this.accountFilter[account.BankID].setValue(this.selectedAccounts.includes(account.BankID));
    })
    this.transactionListSelectedAccounts = this.selectedAccounts;
  }

  initAccountFilter() {
    this.data.AccountSummary.forEach((account: AccountSummary) => {
      this.accountFilter[account.BankID] = new FormControl<boolean>(false);
    })
  }

  isEmpty(obj: DebitCreditSummary, table: 'overdrawn' | 'non-preferred-lender' | 'dishonours'): boolean {
    switch (table) {
      case 'non-preferred-lender':
        return !obj.details?.length && !obj.numcredit && !obj.numdebit;
      case 'overdrawn':
        return !obj.numdebit;
      case 'dishonours':
        return !obj.numdebit;
      default:
        return !obj.details?.length && !obj.numcredit && !obj.numdebit;
    }
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'BankName': return 'Bank Name';
      case 'BSBAccNumber': return 'BSB / Acc. Number';
      case 'AccHolder': return 'Acc. Holder';
      case 'AccName': return 'Acc. Name';
      case 'currentBalance': return 'Current Balance';
      case 'TotalCredit': return '$ Total Credit';
      case 'LoanCreditFlag': return 'Credit Product';
      case 'icon': return '';
      case 'selector': return '';
      default: return column;
    }
  }

  needCurrencyPipe(column: string) {
    return ['currentBalance', 'availableBalance', 'TotalCredit'].includes(column);
  }

  needAlignRight(column: string) {
    return ['currentBalance', 'TotalCredit', 'LoanCreditFlag'].includes(column);
  }

  onClickRow(element: AccountSummary) {
    this.expandedElement = this.expandedElement === element ? null : element;
  }

  onConfirmAccountSelection() {
    this.selectedAccounts = this.data.AccountSummary.filter((account: AccountSummary) => 
      this.accountFilter[account.BankID].value
    ).map((account: AccountSummary) =>  account.BankID);
    this.transactionListSelectedAccounts = this.selectedAccounts;
    this.accountSelector.emit(this.selectedAccounts);
    console.log(this.selectedAccounts)
  }

  viewAccountTransactions(element: AccountSummary) {
    console.log(element);
    this.transactionListSelectedAccounts = [element.BankID];
    const el = document.getElementById("transaction-list");
    if (el) {
      el.scrollIntoView();
    }
  }
}
