import {Component, Input, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import {AccountSummary, BankStatementsAnalysisData} from '@portal-workspace/grow-shared-library';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { DayEndBalanceChartComponent } from './day-end-balance-chart.component';
import { DecisionMetricsComponent } from './decision-metrics.component';
import { TransactionSummaryComponent } from './transaction-summary.component';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';
import { MatTableModule } from '@angular/material/table';

@Component({
    selector: 'account-summary',
    templateUrl: './account-summary.component.html',
    styleUrls: ['./account-summary.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    standalone: true,
    imports: [MatTableModule, NgClass, ExtendedModule, TransactionSummaryComponent, DecisionMetricsComponent, DayEndBalanceChartComponent, LooseCurrencyPipe]
})
export class AccountSummaryComponent implements OnInit, OnChanges {
  @Input({required: true}) data!: BankStatementsAnalysisData;
  dataSource: AccountSummary[] = [];
  columnsToDisplay = ['icon', 'BankName', 'BSBAccNumber', 'AccHolder', 'AccName', 'currentBalance', 'TotalCredit', 'LoanCreditFlag'];
  expandedElement!: AccountSummary | null;

  constructor() {}

  ngOnInit(): void {
    this.dataSource = this.data?.AccountSummary;
    console.log('data source: ', this.dataSource);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dataSource = this.data?.AccountSummary;
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'BankName': return 'Bank Name';
      case 'BSBAccNumber': return 'BSB / Acc. Number';
      case 'AccHolder': return 'Acc. Holder';
      case 'AccName': return 'Acc. Name';
      case 'currentBalance': return 'Current Balance';
      case 'TotalCredit': return '$ Total Credit';
      case 'LoanCreditFlag': return 'Credit Product';
      default: return column;
    }
  }

  needCurrencyPipe(column: string) {
    return ['currentBalance', 'availableBalance', 'TotalCredit'].includes(column);
  }

  needAlignRight(column: string) {
    return ['currentBalance', 'TotalCredit', 'LoanCreditFlag'].includes(column);
  }
}
