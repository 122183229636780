import {Component, forwardRef, inject, Input, OnInit} from "@angular/core";
import {MatFormFieldModule} from "@angular/material/form-field";
import {FlexLayoutModule} from "@angular/flex-layout";
import {
  FormBuilder,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import {AsyncPipe, JsonPipe} from "@angular/common";
import {MatInputModule} from "@angular/material/input";
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {AbstractControlValueAccessor} from "../abstract-control-value-accessor";
import {HexColorValue} from "@portal-workspace/grow-shared-library";
import {Subscription} from "rxjs";
import {UntilDestroy} from "@ngneat/until-destroy";
import {
  createHexColorInputMask,
  formControlErrorKeys, formControlErrorMessage,
  setupUntilDestroy,
  validHexColorValidator
} from "@portal-workspace/grow-ui-library";
import {delay, filter, tap} from "rxjs/operators";
import {InputMaskModule} from '@ngneat/input-mask';
import {DisableControlDirective} from '../../directives/disable-control.directive';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  selector: 'hex-color',
  templateUrl: './hex-color.component.html',
  styleUrls: ['./hex-color.component.scss'],
  standalone: true,
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(()=>HexColorComponent), multi: true},
    {provide: MARK, useExisting: forwardRef(() => HexColorComponent)},
  ],
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    InputMaskModule,
    JsonPipe,
    AsyncPipe,
    DisableControlDirective,
  ],
})
export class HexColorComponent extends AbstractControlValueAccessor<HexColorValue> implements OnInit, Mark {

  @Input({required: false}) title: string = 'Color (in Hex)'

  bgColor: string = '';

  createHexColorInputMask = createHexColorInputMask();
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  subscriptions: Subscription[] = [];

  formBuilder = inject(FormBuilder);
  formControl: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required, validHexColorValidator()]);
  formGroup = this.formBuilder.group({
    color: this.formControl,
  });

  ngOnInit() {
    setupUntilDestroy(this);
    this.subscriptions.push(this.formControl.valueChanges.pipe(
      delay(0),
      tap(v => {
        if (this.formControl.valid) {
          this.bgColor = v ?? '';
          this.propagateChange(v);
        } else {
          this.bgColor = '';
          this.propagateChange(null);
        }
      }),
    ).subscribe());
  }

  mark(): void {
    this.formGroup.markAllAsTouched();
  }

  override doWriteValue(v?: HexColorValue | undefined): void | HexColorValue {
    this.formControl.setValue(v ?? null);
  }

}
