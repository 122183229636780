<div class="solar-page mt-4">
  <ng-container *ngTemplateOutlet="currentTemplate"></ng-container>
</div>

<ng-template #indexStep>
  <div class="indexStep">
    <div class="container">
      <div class="row pb-5 col-reverse mobile-padding">
        <div class="col-md-5 mat-headline-3 ">
          <p>Solar & Energy Finance. Get approved today. </p>
          <div class="row col pr-0">
            <button mat-flat-button class="commercial-button" (click)="onGetStarted('Commercial')"><span>I'm a
            Commercial Customer</span>
            <span class="mdi mdi-arrow-right"></span></button>
          </div>
          <div class="row col pr-0">
            <button mat-stroked-button class="customer-button" (click)="onGetStarted('Consumer')"><span>I'm a Consumer
              </span> <span class="mdi mdi-arrow-right icon-hover"></span></button>
            </div>
          </div>
          <div class="col-md-1"></div>
          <div class="col-md-6">
            <img src="/assets/images/lg-solar-img.png" class="solar-image">
          </div>
        </div>
      </div>

      <div class="mt-4" class="content-background">
        <div class="container ">
          <div class="row ">
            <div class="col-md-5">
              <div class="content-left mat-headline-4">
                <p>High quality and long lasting solar panels with a 25 year
                  Product Warranty
                </p>
              </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-5">
              <div class="content-right mat-headline-4">
                <p class="mb-3">2-7 year finance terms</p>
                <p class="mb-3"> Flexible weekly, fortnightly or monthly repayments</p>
                <p class="mb-3"> Finance LG Solar panels from 4.99% p.a. for homes
                  and 5.99% for commercial
                </p>
              </div>
            </div>
            <div class="col-md-1"></div>
          </div>
        </div>
      </div>
      <div class="contactus-background">
        <div class="container">
          <div class="row">
            <div class="col-md-5">
              <div class="content-left mat-headline-4" style="color: white;">
                <p class="">Still have questions? Contact Sales, we're here to help!</p>
              </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-6">
              <div class="button-container content-right mat-headline-4 mt-2" >
                <button mat-stroked-button class="contact-button"><span
                  class="mdi mdi-arrow-top-right alternate-hover"></span><span>Contact Us</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-md-5">
            <div class="content-left mat-headline-4">
              <p>Discover other Dynamoney’s products and offers</p>
            </div>
          </div>
          <div class="col-md-1"></div>
          <div class="col-md-6">
            <div class="content-right mat-headline-4">
              <p class="mb-3"> Medical Equipment Finance</p>
              <p class="mb-3"> Office Equipment Finance</p>
              <p class="mb-3"> Industrial Equipment Finance</p>
              <p >Vehicle Finance</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #eligibility>
    <div class="eligibilityStep">
      <div>
        <eligibility (onGotIt)="onGotIt()" (onGoBack)="onGoBack()"></eligibility>
      </div>
    </div>
  </ng-template>
  <ng-template #steps>
    <div class="container">
      @if (consumerType==='Consumer') {
        <application-stepper2 [linear]="false" #applicationStepper="applicationStepper2">
          <cdk-step [stepControl]="consumerFormGroupStep1">
            <ng-content *ngTemplateOutlet="consumerstep1; context: {applicationStepper: applicationStepper}"></ng-content>
          </cdk-step>
          <cdk-step [stepControl]="formGroupStep2">
            <ng-content *ngTemplateOutlet="consumerstep2; context: {applicationStepper: applicationStepper}"></ng-content>
          </cdk-step>
          <cdk-step [stepControl]="consumerFormGroupStep3">
            <ng-content *ngTemplateOutlet="consumerstep3; context: {applicationStepper: applicationStepper}"></ng-content>
          </cdk-step>
          @if (step3OnWardsHasSpouse()) {
            <cdk-step [stepControl]="consumerFormGroupStep4">
              <ng-content *ngTemplateOutlet="consumerstep4; context: {applicationStepper: applicationStepper}"></ng-content>
            </cdk-step>
          }
          <cdk-step [stepControl]="consumerFormGroupStep5">
            <ng-content *ngTemplateOutlet="consumerstep5; context: {applicationStepper: applicationStepper}"></ng-content>
          </cdk-step>
          <cdk-step [stepControl]="consumerFormGroupStep6">
            <ng-content *ngTemplateOutlet="consumerstep6; context: {applicationStepper: applicationStepper}"></ng-content>
          </cdk-step>
          <cdk-step [stepControl]="consumerFormGroupStep7">
            <ng-content *ngTemplateOutlet="consumerstep7; context: {applicationStepper: applicationStepper}"></ng-content>
          </cdk-step>
          <cdk-step [stepControl]="consumerFormGroupStep8">
            <ng-content *ngTemplateOutlet="consumerstep8; context: {applicationStepper: applicationStepper}" ></ng-content>
          </cdk-step>
        </application-stepper2>
      }
      @if (consumerType==='Commercial') {
        <application-stepper2 [linear]="false" #applicationStepper="applicationStepper2">
          <cdk-step [stepControl]="commercialFormGroupStep1">
            <ng-content *ngTemplateOutlet="commercialstep1; context: {applicationStepper: applicationStepper}"></ng-content>
          </cdk-step>
          <cdk-step [stepControl]="formGroupStep2">
            <ng-content *ngTemplateOutlet="commercialstep2; context: {applicationStepper: applicationStepper}"></ng-content>
          </cdk-step>
          <cdk-step [stepControl]="commercialFormGroupStep3">
            <ng-content *ngTemplateOutlet="commercialstep3; context: {applicationStepper: applicationStepper}"></ng-content>
          </cdk-step>
          @if (step3OnwardsOrganisationType === 'trust') {
            <cdk-step [stepControl]="commercialFormGroupStep4">
              <ng-content *ngTemplateOutlet="commercialstep4_1; context: {applicationStepper: applicationStepper}"></ng-content>
            </cdk-step>
          }
          @if (step3OnwardsOrganisationType === 'sole-trader') {
            <cdk-step [stepControl]="commercialFormGroupStep4">
              <ng-content *ngTemplateOutlet="commercialstep4_2; context: {applicationStepper: applicationStepper}"></ng-content>
            </cdk-step>
          }
          @if (step3OnwardsOrganisationType === 'partnership') {
            <cdk-step [stepControl]="commercialFormGroupStep4">
              <ng-content *ngTemplateOutlet="commercialstep4_3; context: {applicationStepper: applicationStepper}"></ng-content>
            </cdk-step>
          }
          @if (step3OnwardsOrganisationType === 'company') {
            <cdk-step [stepControl]="commercialFormGroupStep4">
              <ng-content *ngTemplateOutlet="commercialstep4_4; context: {applicationStepper: applicationStepper}"></ng-content>
            </cdk-step>
          }
          @if (step3OnwardsOrganisationType === 'other') {
            <cdk-step [stepControl]="commercialFormGroupStep4">
              <ng-content *ngTemplateOutlet="commercialstep4_5; context: {applicationStepper: applicationStepper}"></ng-content>
            </cdk-step>
          }
          <cdk-step [stepControl]="commercialFormGroupStep5">
            <ng-content *ngTemplateOutlet="commercialstep5; context: {applicationStepper: applicationStepper}"></ng-content>
          </cdk-step>
          <cdk-step [stepControl]="commercialFormGroupStep6">
            <ng-content *ngTemplateOutlet="commercialstep6; context: {applicationStepper: applicationStepper}"></ng-content>
          </cdk-step>
          <cdk-step [stepControl]="commercialFormGroupStep7">
            <ng-content *ngTemplateOutlet="commercialstep7; context: {applicationStepper: applicationStepper}"></ng-content>
          </cdk-step>
          <cdk-step [stepControl]="commercialFormGroupStep8">
            <ng-content *ngTemplateOutlet="commercialstep8; context: {applicationStepper: applicationStepper}"></ng-content>
          </cdk-step>
          <cdk-step [stepControl]="commercialFormGroupStep9">
            <ng-content *ngTemplateOutlet="commercialstep9; context: {applicationStepper: applicationStepper}"></ng-content>
          </cdk-step>
        </application-stepper2>
      }
    </div>
  </ng-template>

  <ng-template #consumerstep1 let-applicationStepper="applicationStepper">
    <div class="step1">
      <form [formGroup]="consumerFormGroupStep1">
        <div class="row">
          <div class="col">
            <div class="mat-h2">Your name</div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <name title="First Name" [formControl]="formControlStep1FirstName"></name>
          </div>
          <div class="col-3">
            <name title="Last Name" [formControl]="formControlStep1FirstName"></name>
          </div>
        </div>
      </form>
    </div>
  </ng-template>

  <ng-template #consumerstep2 let-applicationStepper="applicationStepper">
    <div class="step2">
      <form [formGroup]="formGroupStep2" >
        <div class="row">
          <div class="col-6">
            <aggregator-search [searchFn]="step2BrokerSearchFn"  title="Broker / Vendor"
            [formControl]="formControlStep2Broker" [mark]="applicationStepper.markTriggerSubject(formGroupStep2)" ></aggregator-search>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="mat-h2">How much would you like to borrow?</div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <currency-input title="Invoice amount"
            [formControl]="formControlStep2InvoiceAmount" [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"></currency-input>
          </div>

          <div class="col-3">
            <currency-input title="Deposit amount"
            [formControl]="formControlStep2DepositAmount" [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"></currency-input>
          </div>
          <div class="col-3">
            <currency-input title="Loan amount"
            [formControl]="formControlStep2LoanAmount"></currency-input>
          </div>
        </div>

        <div class="row separator-bottom">
          <div class="col">
            <div class="mat-h2">Asset details</div>
            <div class="mat-body">Please provide details on the asset you would like to finance</div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <asset-type  [categoryTypes]="step2AssetTypes" [formControl]="formControlStep2AssetType" [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
            </asset-type>
          </div>
        </div>
        <div class="row separator-bottom">
          <div class="col-6">
            <mat-form-field>
              <mat-label>Asset description</mat-label>
              <textarea matInput [formControl]="formControlStep2AssetDescription" ></textarea>
              <mat-hint>Please include the size of the system, number and type of panels, number and type of inverters and
              the panel mounting type.</mat-hint>
            </mat-form-field>
          </div>
        </div>


        <div class="row separator">
          <div class="col">
            <div class="mat-h2">Finance details</div>
            <div class="mat-body">Let us know your preferred repayment terms</div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <commercial-loan-terms-selection  [formControl]="formControlStep2LoanTerms" [mark]="applicationStepper.markTriggerSubject(formGroupStep2)" >
            </commercial-loan-terms-selection>
          </div>
          <div class="col-3">
            <payment-frequency-selection  [formControl]="formControlStep2PaymentFrequency" [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
            </payment-frequency-selection>
          </div>
        </div>

        <div class="row separator-bottom">
          <div class="col">
            <div class="mat-h2">Company details</div>
            <div class="mat-body">Please provide us with further details so we can provide you with a more accurate quote
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <yes-no title="Property owner"  [formControl]="formControlStep2PropertyOwner" ></yes-no>
          </div>
        </div>

      </form>
    </div>
  </ng-template>

  <ng-template #consumerstep3 let-applicationStepper="applicationStepper">
    <div class="step3">
      <form [formGroup]="consumerFormGroupStep3">
        <div class="row separator-bottom">
          <div class="col">
            <application-header-segment [applicationId]="applicationId" [titleValue]="organisationName">
            </application-header-segment>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="mat-h2">Applicant details</div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <title-selection  [formControl]="formControlStep3Title"></title-selection>
          </div>
          <div class="col-3">
            <name title="First Name" [formControl]="formControlStep3FirstName"></name>

          </div>
          <div class="col-3">
            <name title="Last Name" [formControl]="formControlStep3LastName"></name>

          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <datepicker  title="Date of birth" [allowOnly18YrsPlus]="true" [formControl]="formControlStep3Dob" [mark]="applicationStepper.markTriggerSubject(consumerFormGroupStep3)"></datepicker>
          </div>
          <div class="col-3">
            <gender  [formControl]="formControlStep3Gender" [mark]="applicationStepper.markTriggerSubject(consumerFormGroupStep3)"></gender>
          </div>
        </div>


        <div class="row">
          <div class="col-6">
            <custom-address  title="Residential address" [formControl]="formControlStep3ResidentialAddress" [mark]="applicationStepper.markTriggerSubject(consumerFormGroupStep3)"></custom-address>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <time-in-address  [formControl]="formControlStep3TimeInAddress" [mark]="applicationStepper.markTriggerSubject(consumerFormGroupStep3)"></time-in-address>
          </div>
        </div>


        <div class="row">
          <div class="col-3">
            <yes-no  title="Privacy consent obtained" [formControl]="formControlStep3PrivacyConsent">
            </yes-no>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <property-owner-with-address

              [formControl]="formControlStep3PropertyOwnerWithAddress"
              [copyAddressFn]="getAddress2ComponentValueFormControlValueFn(formControlStep3ResidentialAddress)">
            </property-owner-with-address>
          </div>
        </div>


        <div class="row">
          <div class="col-6">
            <email [formControl]="formControlStep3Email" [mark]="applicationStepper.markTriggerSubject(consumerFormGroupStep3)"></email>
          </div>
          <div class="col-3">
            <mobile [formControl]="formControlStep3Mobile"
              [mark]="applicationStepper.markTriggerSubject(consumerFormGroupStep3)"
            ></mobile>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <maritial-status-selection  [formControl]="formControlStep3MaritalStatus" [mark]="applicationStepper.markTriggerSubject(consumerFormGroupStep3)">
            </maritial-status-selection>
          </div>
          <div class="col-3">
            <mat-form-field>
              <mat-label>Number of dependants</mat-label>
              <input type="number" min="0" matInput [formControl]="formControlStep3NumberOfDependants" style="margin-right:50%">
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <employment-status-selection  [formControl]="formControlStep3EmploymentStatus" [mark]="applicationStepper.markTriggerSubject(consumerFormGroupStep3)" >
            </employment-status-selection>
          </div>
          <div class="col-6">
            <income  [formControl]="formControlStep3RegularIncome" [mark]="applicationStepper.markTriggerSubject(consumerFormGroupStep3)"></income>
          </div>
        </div>


        <div class="row">
          <div class="col">
            <employer  [formControl]="formControlStep3Employer" [mark]="applicationStepper.markTriggerSubject(consumerFormGroupStep3)"></employer>
          </div>
        </div>


      </form>
    </div>
  </ng-template>

  <ng-template #consumerstep4 let-applicationStepper="applicationStepper">
    <div class="step4">
      <form [formGroup]="consumerFormGroupStep4">

        <div class="row separator-bottom">
          <div class="col">
            <application-header-segment [applicationId]="applicationId" [titleValue]="organisationName">
            </application-header-segment>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="mat-h2">Spouse details</div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <title-selection  [formControl]="formControlStep4Title"
              [mark]="applicationStepper.markTriggerSubject(consumerFormGroupStep4)"
            ></title-selection>
          </div>
          <div class="col-3">
            <name title="First Name" [formControl]="formControlStep4FirstName"
              [mark]="applicationStepper.markTriggerSubject(consumerFormGroupStep4)"
            ></name>

          </div>
          <div class="col-3">
            <name title="Last Name" [formControl]="formControlStep4LastName"
              [mark]="applicationStepper.markTriggerSubject(consumerFormGroupStep4)"
            ></name>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <datepicker [allowOnly18YrsPlus]="true"  title="Date of birth"
              [formControl]="formControlStep4Dob"
            [mark]="applicationStepper.markTriggerSubject(consumerFormGroupStep4)"></datepicker>
          </div>
          <div class="col-3">
            <gender  [formControl]="formControlStep4Gender" [mark]="applicationStepper.markTriggerSubject(consumerFormGroupStep4)"></gender>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <email [formControl]="formControlStep4Email"
              [mark]="applicationStepper.markTriggerSubject(consumerFormGroupStep4)"
            ></email>
          </div>
          <div class="col-3">
            <mobile title="Mobile number" [formControl]="formControlStep4Mobile"
              [mark]="applicationStepper.markTriggerSubject(consumerFormGroupStep4)"
            ></mobile>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <employment-status-selection  [formControl]="formControlStep4EmploymentStatus" [mark]="applicationStepper.markTriggerSubject(consumerFormGroupStep4)">
            </employment-status-selection>
          </div>
          <div class="col-6">
            <income  [formControl]="formControlStep4RegularIncome"
              [mark]="applicationStepper.markTriggerSubject(consumerFormGroupStep4)"
            ></income>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <mat-form-field>
              <mat-label>Employer</mat-label>
              <input matInput type="text" [formControl]="formControlStep4EmployerName" >
            </mat-form-field>
          </div>
          <div class="col-3">
            <mobile title="Employer contact"
              [formControl]="formControlStep4EmployerContact"
              [mark]="applicationStepper.markTriggerSubject(consumerFormGroupStep4)"
            ></mobile>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="mat-h2">Is the spouse willing to act as a guarantor?</div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <yes-no title="Guarantor" [formControl]="formControlStep4Guarantor"></yes-no>
          </div>
          @if (step4ShowPrivacyConsent) {
            <div class="col-3">
              <yes-no title="Privacy consent obtained" [formControl]="formControlStep4PrivacyConsent"></yes-no>
            </div>
          }
        </div>

      </form>
    </div>

  </ng-template>

  <ng-template #consumerstep5 let-applicationStepper="applicationStepper">
    <div class="step5">
      <form [formGroup]="consumerFormGroupStep5">

        <div class="row separator-bottom">
          <div class="col">
            <application-header-segment [applicationId]="applicationId" [titleValue]="organisationName">
            </application-header-segment>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="mat-h2">Monthly living expenses</div>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <currency-input title="Home loans"
            [formControl]="formControlStep5HomeLoans" [mark]="applicationStepper.markTriggerSubject(consumerFormGroupStep5)"></currency-input>
          </div>
          <div class="col-3">
            <currency-input title="Electricity"
            [formControl]="formControlStep5Electricity" [mark]="applicationStepper.markTriggerSubject(consumerFormGroupStep5)" ></currency-input>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <currency-input title="Car loans"
            [formControl]="formControlStep5CarLoans" [mark]="applicationStepper.markTriggerSubject(consumerFormGroupStep5)"></currency-input>
          </div>
          <div class="col-3">
            <currency-input title="Other utilities"
            [formControl]="formControlStep5OtherUtilities" [mark]="applicationStepper.markTriggerSubject(consumerFormGroupStep5)"></currency-input>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <currency-input title="Personal loans"
            [formControl]="formControlStep5PersonalLoans" [mark]="applicationStepper.markTriggerSubject(consumerFormGroupStep5)"></currency-input>
          </div>
          <div class="col-3">
            <currency-input title="Education"
            [formControl]="formControlStep5Education" [mark]="applicationStepper.markTriggerSubject(consumerFormGroupStep5)"></currency-input>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <currency-input title="Total creadit card"
            [formControl]="formControlStep5TotalCreditCardLimits" [mark]="applicationStepper.markTriggerSubject(consumerFormGroupStep5)"></currency-input>
          </div>
          <div class="col-3">
            <currency-input title="Groceries"
            [formControl]="formControlStep5Groceries" [mark]="applicationStepper.markTriggerSubject(consumerFormGroupStep5)"></currency-input>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <currency-input title="Other loans"
            [formControl]="formControlStep5OtherLoans" [mark]="applicationStepper.markTriggerSubject(consumerFormGroupStep5)"></currency-input>
          </div>
          <div class="col-3">
            <currency-input title="Insurance"
            [formControl]="formControlStep5Insurance" [mark]="applicationStepper.markTriggerSubject(consumerFormGroupStep5)"></currency-input>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="mat-h2">Your share of monthly living expenses</div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <mat-form-field>
              <mat-label>Share</mat-label>
              <input matInput type="number" min="0" [formControl]="formControlStep5ShareOfMonthlyLivingExpanses">
              <span class="mdi mdi-percent" matSuffix></span>
            </mat-form-field>
          </div>
        </div>

      </form>
    </div>
  </ng-template>

  <ng-template #consumerstep6 let-applicationStepper="applicationStepper">
    <div class="step6">
      <form [formGroup]="consumerFormGroupStep6">

        <div class="row separator-bottom">
          <div class="col">
            <application-header-segment [applicationId]="applicationId" [titleValue]="organisationName">
            </application-header-segment>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <reference description="Reference is optional"
              [formControl]="formControlStep6References" [mark]="applicationStepper.markTriggerSubject(consumerFormGroupStep6)">
            </reference>
          </div>
        </div>

      </form>
    </div>

  </ng-template>

  <ng-template #consumerstep7 let-applicationStepper="applicationStepper">
    <div class="step7">
      <form [formGroup]="consumerFormGroupStep7">

        <div class="row">
          <div class="col">
            <application-header-segment [applicationId]="applicationId" [titleValue]="organisationName">
            </application-header-segment>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="mat-h2">Upload Driver licence</div>
          </div>
        </div>

        <div class="row separator-bottom">
          <div class="col-6">
            <message-box type="info" title="Before uploading">
              <ul>
                <li>Ensure both the front and back of the licence are supplied</li>
                <li>Ensure the text is clear and readable</li>
                <li>If using PDF, ensure the front of the driver licence is on the first page</li>
                <li>We don’t accept pictures of digital driver licences</li>
              </ul>
            </message-box>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="mat-h2">Upload {{formControlStep3FirstName.value}} {{formControlStep3LastName.value}} Driver
            licence</div>
          </div>
        </div>
        <div class="row separator-bottom">
          <div class="col-6">
            <upload-file
              title="{{formControlStep3FirstName.value}} {{formControlStep3LastName.value}} Driver licence"
              [formControl]="formControlStep7ApplicantDriverLicenceFront">
            </upload-file>
          </div>
        </div>

        @if (step3OnWardsHasSpouse()) {
          <div class="row separator-bottom">
            <div class="col-6">
              <upload-file
                title="{{formControlStep4FirstName.value}} {{formControlStep4LastName.value}} Driver licence"
                [formControl]="formControlStep7ApplicantSpouseDriverLicenceFront">
              </upload-file>
            </div>
          </div>
        }

        <div class="row separator-bottom">
          <div class="col">
            <div class="mat-h2">Upload other supporting documents</div>
            <div class="mat-body">Attach supporting documents, such as system quotation, signed privacy consent(s) and pay
            slips.</div>
          </div>
        </div>
        <div class="row separator-bottom">
          <div class="col-6">
            <upload-file title="Other supporting documents" [formControl]="formControlStep7OtherSupportingDocuments">
            </upload-file>
          </div>
        </div>

        <div class="row separator-bottom">
          <div class="col-6">
            <mat-form-field>
              <mat-label>Application notes  <span class="mdi mdi-information-outline"></span></mat-label>
              <textarea matInput maxlength="7000" [formControl]="formControlStep7ApplicationNotes"></textarea>
            </mat-form-field>
          </div>
        </div>



      </form>
    </div>
  </ng-template>

  <ng-template #consumerstep8 let-applicationStepper="applicationStepper">
    <div class="step8">
      <form [formGroup]="consumerFormGroupStep8">

        <div class="row">
          <div class="col">
            <application-header-segment [applicationId]="applicationId" [titleValue]="organisationName">
            </application-header-segment>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <consumer-finance-summary [summary]="step8Summary"></consumer-finance-summary>
          </div>
        </div>

      </form>
    </div>
  </ng-template>

  <ng-template #commercialstep1 let-applicationStepper="applicationStepper">
    <div class="step1">
      <form [formGroup]="commercialFormGroupStep1" >
        <div class="row">
          <div class="col-12">
            <div class="mat-h2">Applicant's business name</div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <business-search
              [formControl]="formControlStep1Business" [placeholder]="businessSearchPlaceholder"
            [searchFn]="step1BusinessSearchFn" [mark]="applicationStepper.markTriggerSubject(commercialFormGroupStep1)"></business-search>
          </div>
        </div>
      </form>
    </div>
  </ng-template>

  <ng-template #commercialstep2 let-applicationStepper="applicationStepper">
    <div class="step2">
      <form [formGroup]="formGroupStep2" >
        <div class="row">
          <div class="col-6">
            <aggregator-search [searchFn]="step2BrokerSearchFn"  title="Broker / Vendor"
            [formControl]="formControlStep2Broker" [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"></aggregator-search>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="mat-h2">How much would you like to borrow?</div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <currency-input title="Invoice amount (incl. GST)"
            [formControl]="formControlStep2InvoiceAmount" [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"></currency-input>
          </div>
          <div class="col-3">
            <currency-input title="Deposit amount"
            [formControl]="formControlStep2DepositAmount" [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"></currency-input>
          </div>
          <div class="col-3">
            <currency-input title="Loan amount"  [formControl]="formControlStep2LoanAmount" ></currency-input>
          </div>
        </div>

        <div class="row separator-bottom">
          <div class="col">
            <div class="mat-h2">Asset details</div>
            <div class="mat-body">Please provide details on the asset you would like to finance</div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <asset-type  [categoryTypes]="step2AssetTypes" [formControl]="formControlStep2AssetType" [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
            </asset-type>
          </div>
        </div>
        <div class="row separator-bottom">
          <div class="col-6">
            <mat-form-field>
              <mat-label>Asset description</mat-label>
              <textarea matInput [formControl]="formControlStep2AssetDescription" ></textarea>
              <mat-hint>Please include the size of the system, number and type of panels, number and type of inverters and
              the panel mounting type.</mat-hint>
            </mat-form-field>
          </div>
        </div>


        <div class="row separator">
          <div class="col">
            <div class="mat-h2">Finance details</div>
            <div class="mat-body">Let us know your preferred repayment terms</div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <commercial-loan-terms-selection  [formControl]="formControlStep2LoanTerms" [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
            </commercial-loan-terms-selection>
          </div>
          <div class="col-3">
            <payment-frequency-selection  [formControl]="formControlStep2PaymentFrequency" [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
            </payment-frequency-selection>
          </div>
        </div>

        <div class="row separator-bottom">
          <div class="col">
            <div class="mat-h2">Company details</div>
            <div class="mat-body">Please provide us with further details so we can provide you with a more accurate quote
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <yes-no title="Property owner"  [formControl]="formControlStep2PropertyOwner" [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"></yes-no>
          </div>
        </div>

      </form>
    </div>
  </ng-template>

  <ng-template #commercialstep3 let-applicationStepper="applicationStepper">
    <div class="step3">
      <form [formGroup]="commercialFormGroupStep3" >
        <div class="row">
          <div class="col">
            <application-header-segment [applicationId]="applicationId" [titleValue]="organisationName">
            </application-header-segment>
          </div>
        </div>

        <div class="row separator-bottom">
          <div class="col">
            <div class="mat-h2">Company details</div>
            <div class="mat-body">Please provide us with further details so we can provide you with a more accurate quote
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <entity-type title="Organisation type"  [formControl]="formControlStep3OrganisationType" [mark]="applicationStepper.markTriggerSubject(commercialFormGroupStep3)" >
            </entity-type>
          </div>
          <div class="col-3">
            <abn [formControl]="formControlStep3Abn"
              [mark]="applicationStepper.markTriggerSubject(commercialFormGroupStep3)"
            ></abn>
          </div>
          @if (step3OnwardsOrganisationType === 'company') {
            <div class="col-3">
              <acn [formControl]="formControlStep3Acn"
                [mark]="applicationStepper.markTriggerSubject(commercialFormGroupStep3)"
              ></acn>
            </div>
          }
        </div>

        <div class="row">
          <div class="col-3">
            <annual-revenue title="Annual revenue" [formControl]="formControlStep3AnnualRevenue" [mark]="applicationStepper.markTriggerSubject(commercialFormGroupStep3)" ></annual-revenue>
          </div>
          <div class="col-3">
            <yes-no  title="Operates in a commercial premise?"
            [formControl]="formControlStep3OperatesInCommercialPremises" [mark]="applicationStepper.markTriggerSubject(commercialFormGroupStep3)" ></yes-no>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <primary-industry-selection  title="Primary industry"
            [formControl]="formControlStep3PrimaryIndustry" [mark]="applicationStepper.markTriggerSubject(commercialFormGroupStep3)" ></primary-industry-selection>
          </div>
          <div class="col-6">
            <secondary-industry-selection  title="Industry sector"
            [formControl]="formControlStep3IndustrySector" [mark]="applicationStepper.markTriggerSubject(commercialFormGroupStep3)" ></secondary-industry-selection>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <custom-address title="Primary business address" [formControl]="formControlStep3PrimaryBusinessAddress" [mark]="applicationStepper.markTriggerSubject(commercialFormGroupStep3)" ></custom-address>
          </div>
          <div class="col-3">
            <mobile title="Business landline"
              [formControl]="formControlStep3BusinessLandline"
              [mark]="applicationStepper.markTriggerSubject(commercialFormGroupStep3)"
            ></mobile>
          </div>
        </div>

      </form>
    </div>
  </ng-template>

  <ng-template #commercialstep4_1 let-applicationStepper="applicationStepper">
    <!--trust -->
    <div class="step4">
      <form [formGroup]="commercialFormGroupStep4" >
        <div class="row">
          <div class="col">
            <application-header-segment [applicationId]="applicationId" [titleValue]="organisationName" >
            </application-header-segment>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <trustee  [showAssetsAndLiabilities]="false" [formControl]="formControlStep4Applicants" [businessNumberSearchFn]="step5BusinessNumberSearchFn" [mark]="applicationStepper.markTriggerSubject(commercialFormGroupStep4)">
            </trustee>
          </div>
        </div>
      </form>
    </div>
  </ng-template>
  <ng-template #commercialstep4_2 let-applicationStepper="applicationStepper">
    <!-- sole trader -->
    <div class="step4">
      <form [formGroup]="commercialFormGroupStep4" >
        <div class="row">
          <div class="col">
            <application-header-segment [applicationId]="applicationId" [titleValue]="organisationName">
            </application-header-segment>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <sole-trader  [formControl]="formControlStep4Applicants" [mark]="applicationStepper.markTriggerSubject(commercialFormGroupStep4)"></sole-trader>
          </div>
        </div>
      </form>
    </div>
  </ng-template>
  <ng-template #commercialstep4_3 let-applicationStepper="applicationStepper">
    <!-- partnership -->
    <div class="step4">
      <form [formGroup]="commercialFormGroupStep4" >
        <div class="row">
          <div class="col">
            <application-header-segment [applicationId]="applicationId" [titleValue]="organisationName">
            </application-header-segment>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <partner  [showAssetsAndLiabilities]="false" [businessNumberSearchFn]="step5BusinessNumberSearchFn" [formControl]="formControlStep4Applicants" [mark]="applicationStepper.markTriggerSubject(commercialFormGroupStep4)">
            </partner>
          </div>
        </div>
      </form>
    </div>
  </ng-template>
  <ng-template #commercialstep4_4 let-applicationStepper="applicationStepper">
    <!-- company -->
    <div class="step4">
      <form [formGroup]="commercialFormGroupStep4" >
        <div class="row">
          <div class="col">
            <application-header-segment [applicationId]="applicationId" [titleValue]="organisationName">
            </application-header-segment>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <director  [showAssetsAndLiabilities]="false" [formControl]="formControlStep4Applicants" [mark]="applicationStepper.markTriggerSubject(commercialFormGroupStep4)">
            </director>
          </div>
        </div>
      </form>
    </div>
  </ng-template>
  <ng-template #commercialstep4_5 let-applicationStepper="applicationStepper">
    <!-- other (member) -->
    <div class="step4">
      <form [formGroup]="commercialFormGroupStep4" >
        <div class="row">
          <div class="col">
            <application-header-segment [applicationId]="applicationId" [titleValue]="organisationName">
            </application-header-segment>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <member  [showAssetsAndLiabilities]="false" [businessNumberSearchFn]="step5BusinessNumberSearchFn" [formControl]="formControlStep4Applicants" [mark]="applicationStepper.markTriggerSubject(commercialFormGroupStep4)">
            </member>
          </div>
        </div>
      </form>
    </div>
  </ng-template>

  <ng-template #commercialstep5 let-applicationStepper="applicationStepper">
    <div class="step5">
      <form [formGroup]="commercialFormGroupStep5" >
        <div class="row">
          <div class="col">
            <application-header-segment [applicationId]="applicationId" [titleValue]="organisationName">
            </application-header-segment>
          </div>
        </div>
        <div class="row">
          <div class="col">
            @if (step5MandatoryGuarantors) {
              <div class="col">
                <guarantor [businessNumberSearchFn]="step5BusinessNumberSearchFn"
                  [formControl]="formControlStep5Guarantors"
                  [showAssetsAndLiabilities]="false"
                  [mandatoryGuarantors]="step5MandatoryGuarantors">
                </guarantor>
              </div>
            }
          </div>
        </div>
      </form>
    </div>
  </ng-template>

  <ng-template #commercialstep6 let-applicationStepper="applicationStepper">
    <div class="step6">
      <div class="row">
        <div class="col">
          <application-header-segment [applicationId]="applicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>
      <form [formGroup]="commercialFormGroupStep6">
        <div class="row">
          <div class="col">
            <select-contact  [predefinedContactSelection]="step6PossiblePrimaryContacts"
            [formControl]="formControlStep6PrimaryContact" [mark]="applicationStepper.markTriggerSubject(commercialFormGroupStep1)" ></select-contact>
          </div>
        </div>
      </form>
    </div>
  </ng-template>

  <ng-template #commercialstep7 let-applicationStepper="applicationStepper">
    <div class="step7">
      <form [formGroup]="commercialFormGroupStep7" >
        <div class="row">
          <div class="col">
            <application-header-segment [applicationId]="applicationId" [titleValue]="organisationName">
            </application-header-segment>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <reference description="Reference is optional"
              [mark]="applicationStepper.markTriggerSubject(commercialFormGroupStep7)" [formControl]="formControlStep7References">
            </reference>
          </div>
        </div>
      </form>
    </div>
  </ng-template>

  <ng-template #commercialstep8 let-applicationStepper="applicationStepper">
    <div class="step8">
      <form [formGroup]="commercialFormGroupStep8" >
        <div class="row">
          <div class="col">
            <application-header-segment [applicationId]="applicationId" [titleValue]="organisationName">
            </application-header-segment>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="mat-h2">Upload Driver Licence</div>
          </div>
        </div>
        <div class="row separator-bottom">
          <div class="col-6">
            <message-box type="info" title="Before uploading">
              <ul>
                <li>Ensure both the front and back of the licence are supplied</li>
                <li>Ensure the text is clear and readable</li>
                <li>If using PDF, ensure the front of the driver licence is on the first page</li>
                <li>We don’t accept pictures of digital driver licences</li>
              </ul>
            </message-box>
          </div>
        </div>

        @for (dr of step8DriverLicenses; track dr) {
          <div class="row separator-bottom">
            <div class="col-6">
              <upload-file [maxFilesAllowed]="1" [mandatory]="true" title="{{dr.name}}'s Driver Licence" [formControl]="dr.formControl">
              </upload-file>
            </div>
          </div>
        }

        <div class="row">
          <div class="col">
            <div class="mat-h2">Upload other supporting documents</div>
          </div>
        </div>
        <div class="row separator-bottom">
          <div class="col-6">
            <upload-file title="Upload documents" [formControl]="formControlStep8OtherSupportingDocuments"></upload-file>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <mat-form-field>
              <mat-label>Application notes </mat-label>
              <textarea matInput maxlength="7000" [formControl]="formControlStep8ApplicationNotes"></textarea>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
  </ng-template>

  <ng-template #commercialstep9 let-applicationStepper="applicationStepper">
    <div class="step9">
      <form [formGroup]="commercialFormGroupStep9" >
        <div class="row">
          <div class="col">
            <application-header-segment [applicationId]="applicationId" [titleValue]="organisationName">
            </application-header-segment>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <commercial-finance-summary [summary]="step9Summary"></commercial-finance-summary>
          </div>
        </div>
      </form>
    </div>
  </ng-template>
