<div class="fa-activated-dialog" >
  <mat-dialog-content>
    <div class="card mb-4" style="padding: 16px 32px;">

      <div class="header">

        <div class="row mb-1" style="justify-content: center;">
          <img src="assets/images/success icon.svg" class="mr-2" style="width: 40px;height: 40px;">
        </div>

        <div class="row" style="justify-content: center;">
          <h3>2FA is activated</h3>
          <div><p style="font-weight: 500;  text-align: center;
      font-size: 16px;">2FA is a good way to add an extra layer of security to your Dynamoney account to make sure that only you have the ability to log in</p></div>
        </div>
      </div>


      <div class="row" style="justify-content: center;">
        <ul style="list-style-type: none;">
          <li>
            <span class="mdi mdi-two-factor-authentication mr-2"></span> <span style="font-size: 14px;">Use the 2FA to login to your account</span>
          </li>
          <li>
            <span class="mdi mdi-email-outline mr-2"></span> <span style="font-size: 14px;">Login with your Email still available</span>
          </li>
          <li>
            <span class="mdi mdi-cellphone mr-2"></span> <span style="font-size: 14px;">Login with your phone number still available</span>
          </li>
        </ul>
      </div>
    </div>
    <form [formGroup]="formGroup">
      <div class="footer">
        <!-- <button color="primary" fxFlexAlign="start" mat-stroked-button (click)="onCancel()">Cancel</button> -->
        <button color="primary" fxFlexAlign="end" mat-stroked-button type="submit" (click)="onSubmit()">Got it, proceed!</button>
      </div>
    </form>
  </mat-dialog-content>
</div>
