<div class="edit-lender-dialog">
  <mat-dialog-content>
    <div class="row">
      <div class="col-12">
        <div class="mat-subtitle-1 header">
          {{type}} {{title}}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-form-field>
          <mat-label>Lender</mat-label>
          <input type="text" matInput [formControl]="formControlLender" placeholder="Enter lender...">
          @for (errorKey of errorKeys(formControlLender); track errorKey) {
            <mat-error>
              @if (formControlLender.touched && formControlLender.hasError(errorKey)) {
                {{errorMessage(formControlLender, errorKey)}}
              }
            </mat-error>
          }
        </mat-form-field>
      </div>
    </div>
    <br>
      <div class="row">
        <div class="col" fxLayout="row" fxLayoutAlign="space-between center">
          <button mat-flat-button color="accent" class="ml-3" (click)="cancel()">Cancel</button>
          <button mat-flat-button color="primary" class="mr-3" [disabled]="formGroup.invalid" (click)="submit()">Save</button>
        </div>
      </div>
    </mat-dialog-content>
  </div>
