<div class="business-finance-application-details">
  <div fxLayout="column" fxLayoutGap="5px">
    <div>
      <breadcrumb (events)="onBreadcurmbEvents($event)" [trails]="breadcrumbTrails"></breadcrumb>
    </div>
    <div class="separator-bottom row">
      <div class="col-lg-8 col-md-12 mb-1" fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-flat-button class="mat-flat-button-custom w-lt-md-100 btn-black-border">
          <application-type-icon applicationType="BusinessLoans"/>
          Business Term Loan
          @if (application.Source == 'external') {
            <span class="ml-2 mdi mdi-alpha-e-circle" matTooltip="External source application"></span>
          }
          @if (application.Source == 'direct') {
            <span class="ml-2 mdi mdi-alpha-d-circle" matTooltip="Direct source application"></span>
          }
        </button>
      </div>
      <div class="col-lg-4 col-md-12 mb-1">
        <application-stage-icon [stage]="getApplicationStage(application)"></application-stage-icon>
      </div>
    </div>
  </div>

  <mat-tab-group
    [mat-stretch-tabs]="false"
    mat-align-tabs="start"
    [(selectedIndex)]="currentSectionIndex"
    (selectedTabChange)="onSelectionTabChange($event)">
    @for (currentTab of tabs; track currentTab) {
      <mat-tab>
        <ng-template mat-tab-label>
          <span class="mdi {{currentTab.iconClass}}"></span>
          {{currentTab.name}}
        </ng-template>
      </mat-tab>
    }
  </mat-tab-group>

  @if (currentSection === 'asset') {
    <div class="row separator-bottom">
      <div class="col-12 btn-space header"
        fxLayoutGap="10px"
        fxLayout="row"
        fxLayoutAlign="end center"
        fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="center start"
        >
        @if (allowContractGeneration) {
          <button mat-flat-button class="w-lt-md-100" [disabled]="!applicationStageAllowGenerateContract(application)" (click)="openGenerateContractDialog()">
            <span class="mdi mdi-file-sign pr-2" matPrefix></span>
            <span class="label">Generate Contract</span>
          </button>
        }
        @if (activeContract && allowContractGeneration) {
          <button mat-flat-button class="w-lt-md-100" (click)="openContractStatusDialog()">
            <span class="mdi mdi-account-supervisor-outline pr-2" matPrefix></span>
            <span class="label">Signer Status</span>
          </button>
        }
        <button mat-flat-button [disabled]="!applicationStageAllowSettleLoan(application)" class="w-lt-md-100" (click)="onSettleLoan()">
          <span class="mdi mdi-cash-multiple pr-2" matPrefix></span>
          <span class="label">Request Settlement</span>
        </button>
      </div>
    </div>
  }

  <div>
    @if (currentSection === 'app') {
      <business-app
        [getRatecardDetailsFn]="getRatecardDetailsFn"
        [getUsersInCompanyFn]="getUsersInCompanyFn"
        (events)="events.emit($event)"
        [application]="application"
        [amortizationScheduleData]="amortizationScheduleData"
        [repaymentEstimationData]="repaymentEstimationData"
        [paymentChartData]="paymentChartData"
        [copyApplicationFn]="copyApplicationFn"
        [updateApplicationFn]="updateApplicationFn"
        [ip]="ip"
        [getGeoLocationFn]="getGeoLocationFn"
        [amortizationChartData]="amortizationChartData"
        [searchGrantorsFn]="searchGrantorsFn"
        [getPpsrDetailsFn]="getPpsrDetailsFn"
        (navigateToApplications)="navigateToApplications.emit()">
      </business-app>
    }
    @if (currentSection === 'bank-statement') {
      <business-bank-statement
        [getBankStatementsAnalysisFn]="getBankStatementsAnalysisFn"
        [refreshBankStatementFn]="refreshBankStatementFn"
        [apiUrl]="apiUrl"
        [bankStatementsUrl]="bankStatementsUrl"
        [application]="application"
        [getDscrCalculatorValueFn]="getDscrCalculatorValueFn"
        [getBsaLenderListFn]="getBsaLenderListFn"
        [getBsaExcludedLenderListFn]="getBsaExcludedLenderListFn"
        [saveBsaCalculatorFn]="saveBsaCalculatorFn"
        [getBsaCalculatorFn]="getBsaCalculatorFn"
        [getNotesByApplicationIdFn]="getNotesByApplicationIdFn"
        [createApplicationNoteFn]="createApplicationNoteFn"
        [removeApplicationNoteByNoteIdFn]="removeApplicationNoteByNoteIdFn"
        [getDscrCalculatorHistoryFn]="getDscrCalculatorHistoryFn"
      [updateDscrCalculatorValueFn]="updateDscrCalculatorValueFn"></business-bank-statement>
    }
    @if (currentSection === 'asset') {
      <business-settlement
        [application]="application"
        [paymentChartData]="paymentChartData"
        [createNewDisbursementFn]="createNewDisbursementFn"
        [updateDisbursementFn]="updateDisbursementFn"
        [getDisbursementByApplicationIdFn]="getDisbursementByApplicationIdFn"
        [syncDisbursementToSfFn]="syncDisbursementToSfFn"
        [businessNumberSearchFn]="businessNumberSearchFn"
        [searchSupplierFn]="searchSupplierFn"
        [getBankDetailsFromOpportunitySfFn]="getBankDetailsFromOpportunitySfFn"
        [syncBankDetailsToSfFn]="syncBankDetailsToSfFn"
        [getApplicationAuditLogsFn]="getApplicationAuditLogsFn"
        [getNotesByApplicationIdFn]="getNotesByApplicationIdFn"
        [getBillerNameFn]="getBillerNameFn"
        [getInstitutionNameFn]="getInstitutionNameFn"
        [validateBpayFn]="validateBpayFn"
        [removeApplicationNoteByNoteIdFn]="removeApplicationNoteByNoteIdFn"
        [addAuditLogFn]="addAuditLogFn"
        [createApplicationNoteFn]="createApplicationNoteFn">
      </business-settlement>
    }
    @if (currentSection === 'kyc-aml') {
      <kyc-verification
        [getClientFn]="getClientFn"
        [authenticateFn]="authenticateFn"
        [getOriginatorByIdFn]="getOriginatorByIdFn"
        [addIndividualFn]="addIndividualFn"
        [businessSearchFn]="businessSearchFn"
        [verifyApplicationIndividualsFn]="verifyApplicationIndividualsFn"
        [verifyOneApplicationIndividualFn]="verifyOneApplicationIndividualFn"
        [sendAskForVerificationInfoEmailFn]="sendAskForVerificationInfoEmailFn"
        [getApplicationIndividualsFn]="getApplicationIndividualsFn"
        [updateIndividualFn]="updateIndividualFn"
        [updateApplicationIndividualDigitalIdMappingFn]="updateApplicationIndividualDigitalIdMappingFn"
        [updateApplicationIndividualInfoFn]="updateApplicationIndividualInfoFn"
        [printDigitalIdResultFn]="printDigitalIdResultFn"
        [setupApplicationIndividualMappingFn]="setupApplicationIndividualMappingFn"
        [businessNumberSearchFn]="businessNumberSearchFn"
        [downloadDocumentFromAzureFn]="downloadDocumentFromAzureFn"
        [idVerifyUrl]="idVerifyUrl"
        [sendIdVerifyLinkFn]="sendIdVerifyLinkFn"
        [bypassFaceCompareFn]="bypassFaceCompareFn"
        [deleteIdentityVerificationFn]="deleteIdentityVerificationFn"
        [sendPrivacyConsentEmailFn]="sendPrivacyConsentEmailFn"
        [application]="application">
      </kyc-verification>
    }
    @if (currentSection === 'documents') {
      <business-documents
        [apiUrl]="apiUrl"
        [getApplicationByIdFn]="getApplicationByIdFn"
        [uploadApplicationDocumentFn]="uploadApplicationDocumentFn"
        [listApplicationDocumentFn]="listApplicationDocumentFn"
        [downloadApplicationDocumentUrlFn]="downloadApplicationDocumentUrlFn"
        [approveApplicationDocumentFn]="approveApplicationDocumentFn"
        [declineApplicationDocumentFn]="declineApplicationDocumentFn"
        [deleteApplicationDocumentFn]="deleteApplicationDocumentFn"
        [downloadAllApplicationDocumentUrlFn]="downloadAllApplicationDocumentUrlFn"
        [updateApplicationDocumentTagsFn]="updateApplicationDocumentTagsFn"
        [updateApplicationRequiredDocumentsFn]="updateApplicationRequiredDocumentsFn"
        [sendApplicationDocumentNotificationFn]="sendApplicationDocumentNotificationFn"
        [createDocumentWorklistFn]="createDocumentWorklistFn"
        [completeDocumentWorklistFn]="completeDocumentWorklistFn"
        [getNotesByApplicationIdFn]="getNotesByApplicationIdFn"
        [createApplicationNoteFn]="createApplicationNoteFn"
        [generateApprovalNoticeFn]="generateApprovalNoticeFn"
        [sendApprovalNoticeEmailFn]="sendApprovalNoticeEmailFn"
        [deleteApprovalNoticeFileFn]="deleteApprovalNoticeFileFn"
        [getApplicationOwnerFn]="getApplicationOwnerFn"
        [undoApplicationDocumentFn]="undoApplicationDocumentFn"
        [getUserFn]="getUserFn"
        [application]="application"
        [removeApplicationNoteByNoteIdFn]="removeApplicationNoteByNoteIdFn"
        [getDisbursementByApplicationIdFn]="getDisbursementByApplicationIdFn"
        [settleLoanFn]="settleLoanFn"
        [updateApplicationStageFn]="updateApplicationStageFn"
        [getApplicationAssetFn]="getApplicationAssetFn"
      ></business-documents>
    }
    @if (currentSection === 'credit') {
      <business-credit
      [application]="application"
      [getDscrCalculatorValueFn]="getDscrCalculatorValueFn"
      [updateDscrCalculatorValueFn]="updateDscrCalculatorValueFn"
      [getLvrCalculatorValueFn] = "getLvrCalculatorValueFn"
      [updateLvrCalculatorValueFn]="updateLvrCalculatorValueFn"
      />
    }
    @if (currentSection === 'pricing') {
      <business-pricing
        [application]="application"
        [currentRstValue]="rst"
        [getContractStatusFn]="getContractStatusFn"
        [updateApplicationFn]="updateApplicationFn"
        [updateApplicationSfFn]="updateApplicationSfFn"
        [getRateCardDetailsFn]="getRatecardDetailsFn"
        (events)="onBusinessPricingEvents($event)">
      </business-pricing>
    }
  </div>
</div>
