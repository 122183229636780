<div class="sidemenu-component" fxLayout="column">
  <div class="menuset">
    <div class="title mat-caption">ACCOUNT</div>
    <div fxLayout="column" fxLayoutAlign="center start" [fxLayoutGap]="pxToRem(20)">
      @if (user) {
        <div [ngClass]="{activated: menuItemId === 'profile'}" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px"><span class="mdi mdi-account-circle-outline"></span><span class="cursor-pointer" (click)="onMenuClick($event, 'profile')">{{user.GivenName}} {{user.FamilyName}}</span></div>
      }
      <div [ngClass]="{activated: menuItemId === 'notifications'}" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px"><span class="mdi mdi-bell-outline"></span><span class="cursor-pointer" (click)="onMenuClick($event, 'notifications')">Notifications</span><span [matBadge]="unreadNotificationsCount">&nbsp;</span></div>
    </div>
  </div>

  <div class="menuset">
    <div class="title mat-caption">PRODUCTS</div>
    <mat-tree [dataSource]="treeDataSource" [treeControl]="treeControl">
      <!-- Non expandable tree node  -->
      <ng-container *matTreeNodeDef="let flatTreeNode; when: flatTreeNodeHasNoChild">
        <mat-tree-node fxLayout="row" matTreeNodePadding [ngClass]="{'hidden': !hasAccessLevels(flatTreeNode.treeNode.accessLevels)}">
          @if (hasAccessLevels(flatTreeNode.treeNode.accessLevels)) {
            <div [ngClass]="{activated: menuItemId === flatTreeNode.treeNode.type}">
              @if (flatTreeNode.treeNode.icon) {
                <span class="mdi {{flatTreeNode.treeNode.icon}}"></span>
              }
              <span fxFlex="grow" class="cursor-pointer" (click)="onTreeMenuClick($event, flatTreeNode)">{{flatTreeNode.treeNode.name}}</span>
            </div>
          }
        </mat-tree-node>
      </ng-container>
      <!-- Expandable tree node -->
      <ng-container *matTreeNodeDef="let flatTreeNode; when: flatTreeNodeHasChild">
        <mat-tree-node fxLayout="row" matTreeNodePadding [ngClass]="{'hidden': !hasAccessLevels(flatTreeNode.treeNode.accessLevels)}">
          @if (hasAccessLevels(flatTreeNode.treeNode.accessLevels)) {
            <div [ngClass]="{activated: isExpandableNodeActivated(flatTreeNode.treeNode.type)}" fxLayout="row" class="width-100">
              @if (flatTreeNode.treeNode.icon) {
                <span matTreeNodeToggle class="mdi {{flatTreeNode.treeNode.icon}}"></span>
              }
              <span fxLayout="row" fxFlex="grow" class="cursor-pointer" (click)="onTreeMenuClick($event, flatTreeNode)" matTreeNodeToggle>{{flatTreeNode.treeNode.name}}</span>
              @if (treeControl.isExpandable(flatTreeNode)) {
                <div matTreeNodeToggle>
                  @if (treeControl.isExpanded(flatTreeNode)) {
                    <span class="mdi mdi-chevron-up cursor-pointer"></span>
                  }
                  @if (!treeControl.isExpanded(flatTreeNode)) {
                    <span class="mdi mdi-chevron-down cursor-pointer"></span>
                  }
                </div>
              }
            </div>
          }
        </mat-tree-node>
      </ng-container>
    </mat-tree>
  </div>

  <div class="menuset">
    <div class="title mat-caption">DYNAMONEY</div>
      <div fxLayout="column" fxLayoutAlign="center start" [fxLayoutGap]="pxToRem(10)">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px"><span class="mdi mdi-information-outline"></span><span class="cursor-pointer" (click)="onMenuClick($event, 'product-guide')">Product Guide</span></div>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px"><span class="mdi mdi-lifebuoy"></span><span class="cursor-pointer" (click)="onMenuClick($event, 'faq')">FAQ</span></div>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px"><span class="mdi mdi-bullhorn-outline"></span><span class="cursor-pointer" (click)="onMenuClick($event, 'contact-us')">Contact Us</span></div>
        @if (isInternalUser(user)) {
          <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" [ngClass]="{activated: this.menuItemId == 'accreditation-listing'}" ><span class="mdi mdi-clipboard-list-outline"></span><span class="cursor-pointer" (click)="onMenuClick($event, 'accreditation-listing')">Accreditation Listing</span></div>
        }
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" [ngClass]="{activated: this.menuItemId == 'send-privacy-consent'}" ><span class="mdi mdi-file-document-outline"></span><span class="cursor-pointer" (click)="onMenuClick($event, 'send-privacy-consent')">Privacy Consent</span></div>
      </div>
    </div>

    @if (hasAccessLevels(ADMIN_TAB_ACCESS_LEVELS)) {
      <div class="menuset">
        <div class="title mat-caption">YOUR ORGANIZATION</div>
        <mat-tree [dataSource]="treeDataSource2" [treeControl]="treeControl2">
          <!-- Non expandable tree node  -->
          <mat-tree-node fxLayout="row" *matTreeNodeDef="let flatTreeNode; when: flatTreeNodeHasNoChild" matTreeNodePadding>
            @if (hasAccessLevels(flatTreeNode.treeNode.accessLevels)) {
              <div [ngClass]="{activated: menuItemId === flatTreeNode.treeNode.type}">
                @if (flatTreeNode.treeNode.icon) {
                  <span class="mdi {{flatTreeNode.treeNode.icon}}"></span>
                }
                <span fxFlex="grow" class="cursor-pointer" (click)="onTreeMenuClick($event, flatTreeNode)">{{flatTreeNode.treeNode.name}}</span>
              </div>
            }
          </mat-tree-node>
          <!-- Expandable tree node -->
          <mat-tree-node fxLayout="row" *matTreeNodeDef="let flatTreeNode; when: flatTreeNodeHasChild" matTreeNodePadding>
            @if (hasAccessLevels(flatTreeNode.treeNode.accessLevels)) {
              <div [ngClass]="{activated: isExpandableNodeActivated(flatTreeNode.treeNode.type)}" fxLayout="row" class="width-100">
                @if (flatTreeNode.treeNode.icon) {
                  <span matTreeNodeToggle class="mdi {{flatTreeNode.treeNode.icon}}"></span>
                }
                <span fxLayout="row" fxFlex="grow" class="cursor-pointer" (click)="onTreeMenuClick($event, flatTreeNode)" matTreeNodeToggle>{{flatTreeNode.treeNode.name}}</span>
                @if (treeControl2.isExpandable(flatTreeNode)) {
                  <div matTreeNodeToggle>
                    @if (treeControl2.isExpanded(flatTreeNode)) {
                      <span class="mdi mdi-chevron-up cursor-pointer"></span>
                    }
                    @if (!treeControl2.isExpanded(flatTreeNode)) {
                      <span class="mdi mdi-chevron-down cursor-pointer"></span>
                    }
                  </div>
                }
              </div>
            }
          </mat-tree-node>
        </mat-tree>
      </div>
    }
  </div>
