import {
  enableProdMode,
  ErrorHandler,
  APP_INITIALIZER,
  Injector,
  importProvidersFrom,
  isDevMode,
  provideZoneChangeDetection
} from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { QRCodeModule } from 'angularx-qrcode';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { AccreditationResolver } from './app/service/accreditation.resolver';
import { OriginatorBusinessResolver } from './app/service/originator-business.resolver';
import { AggregatorResolver } from './app/service/aggregator.resolver';
import { RatecardsResolver } from './app/service/ratecards-resolver.service';
import { CustomerUserResolver } from './app/service/customer-user.resolver';
import { UserResolver } from './app/service/user.resolver';
import { TradeFinanceRatecardResolver } from './app/service/trade-finance-ratecard.resolver';
import { ConsumerRatecardResolver } from './app/service/consumer-ratecard.resolver';
import { InsurancePremiumRatecardResolver } from './app/service/insurance-premium-ratecard.resolver';
import { BusinessOverdraftRatecardResolver } from './app/service/business-overdraft-ratecard.resolver';
import { BusinessLoansRatecardResolver } from './app/service/business-loans-ratecard.resolver';
import { AssetFinanceRatecardResolver } from './app/service/asset-finance-ratecard.resolver';
import { ApplicationResolver } from './app/service/application.resolver';
import { OfflineDetectionService } from './app/service/offline-detection.service';
import { DocusignService } from './app/service/docusign.service';
import { AppInsightService } from './app/service/app-insight.service';
import { LocalApplicationsDbService } from './app/service/local-applications-db.service';
import { ApplicationAuditService } from './app/service/applicationAudit.service';
import { AuditService } from './app/service/audit.service';
import { RegistrationService } from './app/service/registration.service';
import { AccreditationService } from './app/service/accreditation.service';
import { PpsrService } from './app/service/ppsr.service';
import { ConfigurationService } from './app/service/configuration.service';
import { NotificationService } from './app/service/notification.service';
import { AdminService } from './app/service/admin.service';
import { BankingService } from './app/service/banking.service';
import { TradeAdminService } from './app/service/trade-admin.service';
import { DigitalIdService } from './app/service/digital-id.service';
import { ApplicationService } from './app/service/application.service';
import { RedbookService } from './app/service/redbook.service';
import {AutoRefreshTokenInterceptorFn} from './app/service/auto-refresh-token.interceptor';
import {HttpErrorInterceptorFn} from './app/service/error-handler.interceptor';
import {provideHttpClient, withInterceptors} from '@angular/common/http';
import { GlobalErrorHandler } from './app/service/global-error-handler';
import { PortalHotToastService, GrowUiLibraryModule } from '@portal-workspace/grow-ui-library';
import { TwoFactorAuthService } from './app/service/2fa.service';
import { AuthService } from './app/service/auth.service';
import { StorageService } from './app/service/storage.service';
import { OverdraftCustomerService } from './app/service/overdraft-customer.service';
// import { provideServiceWorker } from '@angular/service-worker';
import { UpdateService } from './app/service/update.service';
import {BankfeedsService} from "./app/service/bankfeeds.service";
import {setupComponentUtils} from '@portal-workspace/grow-ui-library';
import { DirectSaleApplicationResolver } from './app/service/direct-sale-application.resolver';
import { PrivacyConsentResolver } from './app/service/privacy-consent.resolver';
import {CorporateLoansRatecardResolver} from "./app/service/corporate-loan-ratecard.resolver";
import { BankStatementsUploadResolver } from './app/service/bank-statements-upload.resolver';
import {provideHotToastConfig} from "@ngneat/hot-toast";
// import {
//   provideRouter,
//   withComponentInputBinding,
//   withRouterConfig,
//   withViewTransitions
// } from "@angular/router";
import {AppRoutingModule, routes} from './app/app-routes';
import {provideAnimations} from "@angular/platform-browser/animations";
import {provideAnimationsAsync} from "@angular/platform-browser/animations/async";
import {BrokerflowService} from "./app/service/brokerflow.service";
import {WhitelabelService} from "./app/service/whitelabel.service";

const appInitializerFn = (injector: Injector, updateService: UpdateService, whitelabelService: WhitelabelService) => {
  return () => new Promise((res, rej) => {
    // todo: init tasks
    // if (environment.enableServiceWorker) {
    //   updateService.init();
    // }
    setupComponentUtils({
      encryptionKey: environment.localStorage.encryptionKey,
    });
    whitelabelService.loadFromLocalStorageAndTriggerWhitelabelEvent();
    res(true);
  });
}



if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
    importProvidersFrom(BrowserModule, GrowUiLibraryModule, QRCodeModule, AppRoutingModule),
    // provideRouter(routes,
    //   withViewTransitions(),
    //   withComponentInputBinding(),
    //   withRouterConfig({onSameUrlNavigation: 'reload'}),
    // ),
    provideAnimations(),
    provideAnimationsAsync('animations'),
    provideZoneChangeDetection(),
    provideHttpClient(withInterceptors([
      HttpErrorInterceptorFn(),
      AutoRefreshTokenInterceptorFn(),
    ])),
    { provide: StorageService, useClass: StorageService },
    { provide: AuthService, useClass: AuthService },
    { provide: TwoFactorAuthService, useClass: TwoFactorAuthService },
    { provide: PortalHotToastService, useClass: PortalHotToastService },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: RedbookService, useClass: RedbookService },
    { provide: ApplicationService, useClass: ApplicationService },
    { provide: DigitalIdService, useClass: DigitalIdService },
    { provide: TradeAdminService, useClass: TradeAdminService },
    { provide: AdminService, useClass: AdminService },
    { provide: BankingService, useClass: BankingService },
    { provide: NotificationService, useClass: NotificationService },
    { provide: ConfigurationService, useClass: ConfigurationService },
    { provide: PpsrService, useClass: PpsrService },
    { provide: AccreditationService, useClass: AccreditationService },
    { provide: RegistrationService, useClass: RegistrationService },
    { provide: AuditService, useClass: AuditService },
    { provide: ApplicationAuditService, useClass: ApplicationAuditService },
    { provide: LocalApplicationsDbService, useClass: LocalApplicationsDbService },
    { provide: AppInsightService, useClass: AppInsightService },
    { provide: DocusignService, useClass: DocusignService },
    { provide: OfflineDetectionService, useClass: OfflineDetectionService },
    { provide: OverdraftCustomerService, useClass: OverdraftCustomerService },
    { provide: UpdateService, useClass: UpdateService },
    { provide: WhitelabelService, useClass: WhitelabelService },
    { provide: BankfeedsService, useClass: BankfeedsService },
    { provide: BrokerflowService, useClass: BrokerflowService },
    { provide: 'ApplicationResolver', useClass: ApplicationResolver },
    { provide: 'AssetFinanceRatecardResolver', useClass: AssetFinanceRatecardResolver },
    { provide: 'BusinessLoansRatecardResolver', useClass: BusinessLoansRatecardResolver },
    { provide: 'BusinessOverdraftRatecardResolver', useClass: BusinessOverdraftRatecardResolver },
    { provide: 'CorporateLoansRatecardResolver', useClass: CorporateLoansRatecardResolver },
    { provide: 'InsurancePremiumRatecardResolver', useClass: InsurancePremiumRatecardResolver },
    { provide: 'ConsumerRatecardResolver', useClass: ConsumerRatecardResolver },
    { provide: 'TradeFinanceRatecardResolver', useClass: TradeFinanceRatecardResolver },
    { provide: 'UserResolver', useClass: UserResolver },
    { provide: 'CustomerUserResolver', useClass: CustomerUserResolver },
    { provide: 'RatecardsResolver', useClass: RatecardsResolver },
    { provide: 'AggregatorResolver', useClass: AggregatorResolver },
    { provide: 'OriginatorBusinessResolver', useClass: OriginatorBusinessResolver },
    { provide: 'AccreditationResolver', useClass: AccreditationResolver },
    { provide: 'DirectSaleApplicationResolver', useClass: DirectSaleApplicationResolver },
    { provide: 'PrivacyConsentResolver', useClass:PrivacyConsentResolver },
    { provide: 'BankStatementsUploadResolver', useClass: BankStatementsUploadResolver },
    { provide: APP_INITIALIZER, useFactory: appInitializerFn, deps: [Injector, UpdateService, WhitelabelService], multi: true },
    // provideServiceWorker('ngsw-worker.js', {
    //     enabled: environment.enableServiceWorker,
    //     registrationStrategy: 'registerWhenStable:30000'
    // }),
    provideHotToastConfig(),
]
})
  .catch(err => console.error(err));
