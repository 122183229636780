<div class="corporate-loans-bank-statement-component">
  <mat-card appearance="outlined">
    <div class="row">
      <div class="col-12">
        <div class="mat-h3">Bank Statements</div>
      </div>
    </div>

    @if (bankStatementMessage) {
      <div class="row separator">
        <div class="col-lg-9 col-md-12">
          <message-box type="warn">
            {{bankStatementMessage}}
          </message-box>
        </div>
      </div>
    }

    @if (!salesforceId) {
      <div class="row separator">
        <div class="col-lg-6 col-md-12">
          <message-box type="warn">
            Bankstatement upload link cannot be generated because there is no salesforce id.
          </message-box>
        </div>
      </div>
    }

    @if (!!salesforceId && !hasBankStatement) {
      <div class="row">
        <div class="col-lg-9 col-md-12">
          <mat-form-field>
            <mat-label>Copy BankStatements link</mat-label>
            <input matInput type="text" readonly [formControl]="formControlBankStatementLink">
            <span class="mdi mdi-content-copy cursor-pointer" matSuffix [cdkCopyToClipboard]="formControlBankStatementLink.value ?? ''"></span>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-9 col-md-12">
          <p class="mat-body">
            If you need customers to send us their bankstatements, send them this link.
          </p>
          <p class="mat-body">
            When customers use this link instead of the generic link, the bankstatement files will be sent directly to the documents tab.
          </p>
          <p class="mat-body">
            Customers are always presented with this link after they submit their application, however, for various reasons (security concerns etc) they may close the window and lose the link without clicking on it.
          </p>
          <p class="mat-body">
            You can use this button to generate a link to send to customers if that happens.
          </p>
        </div>
      </div>
    }

    @if (hasBankStatement) {
      <div class="row">
        <div class="col-12">
          <div fxLayout="row"
            fxLayoutAlign="end center"
            fxLayoutGap="10px"
            fxLayout.lt-md="column"
            fxLayoutAlign.lt-md="center start">
            @if (isInternalUser(loggedInUser)) {
              <button mat-flat-button
                class="w-lt-md-100"
                (click)="refreshBankStatement()">
                <span class="mdi mdi-refresh" matPrefix></span>
                <span class="label"> Refresh </span>
              </button>
            }
            <button mat-flat-button color="primary"
              class="w-lt-md-100"
              [cdkCopyToClipboard]="formControlBankStatementLink.value ?? ''">
              <span class="mdi mdi-content-copy cursor-pointer"></span>
              Copy Link for New Bank Statement
            </button>
          </div>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-12">
          <bank-statement-decisioning 
            [data]="bankStatementAnalysisData"
            [getBsaLenderListFn]="getBsaLenderListFn"
            [getBsaExcludedLenderListFn]="getBsaExcludedLenderListFn"
            [application]="application"
            [getDscrCalculatorValueFn]="getDscrCalculatorValueFn"
            [updateDscrCalculatorValueFn]="updateDscrCalculatorValueFn"
            [saveBsaCalculatorFn]="saveBsaCalculatorFn"
            [getBsaCalculatorFn]="getBsaCalculatorFn"
            [getNotesByApplicationIdFn]="getNotesByApplicationIdFn"
            [createApplicationNoteFn]="createApplicationNoteFn"
            [removeApplicationNoteByNoteIdFn]="removeApplicationNoteByNoteIdFn"
            [getDscrCalculatorHistoryFn]="getDscrCalculatorHistoryFn"
          ></bank-statement-decisioning>
        </div>
      </div>
      }
    </mat-card>
  </div>
