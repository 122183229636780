import {Component, Input, OnInit, OnChanges} from '@angular/core';
import {Moment} from 'moment';
import {AssetFinanceSummary, BusinessSearchValue} from '@portal-workspace/grow-shared-library';
import {AggregatorSearchComponentValue} from '@portal-workspace/grow-shared-library';
import {AssetSelectionComponentValue} from '@portal-workspace/grow-shared-library';
import {AssetConditionValue} from '@portal-workspace/grow-shared-library';
import {YesNoValue} from '@portal-workspace/grow-shared-library';
import {LoanTermValue} from '@portal-workspace/grow-shared-library';
import {PaymentFrequencyValue} from '@portal-workspace/grow-shared-library';
import {FinanceTypeValue} from '@portal-workspace/grow-shared-library';
import {BalloonPaymentValue} from '@portal-workspace/grow-shared-library';
import {BrokerageSelectionValue} from '@portal-workspace/grow-shared-library';
import {EntityTypeValue} from '@portal-workspace/grow-shared-library';
import {PrimaryIndustrySelectionValue} from '@portal-workspace/grow-shared-library';
import {SecondaryIndustry} from '@portal-workspace/grow-shared-library';
import {SecondaryIndustrySelectionValue} from '@portal-workspace/grow-shared-library';
// import {AddressComponentValue} from '../address-component/google-address.component';
import {TrusteeValue} from '@portal-workspace/grow-shared-library';
import {MemberValue} from '@portal-workspace/grow-shared-library';
import {PartnerValue} from '@portal-workspace/grow-shared-library';
import {DirectorValue} from '@portal-workspace/grow-shared-library';
import {SoleTraderValue} from '@portal-workspace/grow-shared-library';
import {GuarantorValue} from '@portal-workspace/grow-shared-library';
import {ContactValue} from '@portal-workspace/grow-shared-library';
import {ReferenceValue} from '@portal-workspace/grow-shared-library';
import {booleanToYesNo, displaybleDate, notNullUndefined} from '@portal-workspace/grow-shared-library';
import { categoriesApplicants, } from '@portal-workspace/grow-ui-library';
import {Address2ComponentValue} from '@portal-workspace/grow-shared-library';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { ReferencesSummaryComponent } from './references-summary.component';
import { PrimaryContactSummaryComponent } from './primary-contact-summary.component';
import { GuarantorsSummaryComponent } from './guarantors-summary.component';
import { SoletradersSummaryComponent } from './soletraders-summary.component';
import { MembersSummaryComponent } from './members-summary.component';
import { PartnersSummaryComponent } from './partners-summary.component';
import { TrusteesSummaryComponent } from './trustees-summary.component';
import { DirectorsSummaryComponent } from './directors-summary.component';
import { MatDividerModule } from '@angular/material/divider';
import { CellComponent } from './cell.component';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import {EllipsisPipe} from "../../pipes/ellipsis.pipe";

@Component({
    selector: 'asset-finance-summary',
    templateUrl: './asset-finance-summary.component.html',
    styleUrls: ['./asset-finance-summary.component.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, FormsModule, CellComponent, MatDividerModule, DirectorsSummaryComponent, TrusteesSummaryComponent, PartnersSummaryComponent, MembersSummaryComponent, SoletradersSummaryComponent, GuarantorsSummaryComponent, PrimaryContactSummaryComponent, ReferencesSummaryComponent, LooseCurrencyPipe, EllipsisPipe]
})
export class AssetFinanceSummaryComponent implements OnInit,OnChanges {

  booleanToYesNo = booleanToYesNo;
  displayableDate = displaybleDate;
  notNullUndefined = notNullUndefined;

  _trustee?: Exclude<TrusteeValue, null>;
  _members?: Exclude<MemberValue, null>;
  _soleTraders?: (Exclude<SoleTraderValue, null>)[];
  _directors?: Exclude<DirectorValue, null>;
  _partners?: Exclude<PartnerValue, null>;

  @Input({required: true}) summary!: AssetFinanceSummary;

  ngOnInit(): void {
  }
  ngOnChanges(){
    if (this.summary && this.summary.applicants) {
      const r = categoriesApplicants(this.summary.applicants);
      this._directors = r.directors.length ? r.directors : undefined;
      this._trustee = r.trustees.length ? r.trustees : undefined;
      this._members = r.members.length ? r.members : undefined;
      this._partners = r.partners.length ? r.partners : undefined;
      this._soleTraders = r.soletraders.length ? r.soletraders : undefined;
    }
  }

}
