import { Component, Inject, OnInit } from '@angular/core';
import { AsyncValidatorFn, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccessLevelValue, getUser } from '@portal-workspace/grow-ui-library';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { tap} from 'rxjs/operators';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {
  AggregatorAndOriginatorListValue,
  AggregatorAndOriginatorListValueGetFn,
  ConfirmPasswordAdminValue,
  EmailComponentValue,
  GetUserByEmailFn,
  MobileValue,
  NameComponentValue,
  User,
  allowEmailAccessLevel
} from '@portal-workspace/grow-shared-library';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { validEmailValidator,ApplicationDialogService} from '@portal-workspace/grow-ui-library';
import {
  AddBrokerUserFormDialogDialogData,
  AddBrokerUserFormDialogResult
} from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { ConfirmPasswordAdminComponent } from '../confirm-password-admin-component/confirm-password-admin.component';
import { EmailComponent } from '../common fields/email.component';
import { MobileComponent } from '../mobile-component/mobile.component';
import { AccessLevelComponent } from '../access-level-component/access-level.component';
import { AggregatorAndOriginatorListComponent } from '../aggregator-search-component/aggregator-and-originator-list.component';
import { NameComponent } from '../name-component/name.component';
import { NgIf,NgFor } from '@angular/common';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    templateUrl: './add-broker-user-form.dialog.html',
    styleUrls: ['./add-broker-user-form.dialog.scss'],
    standalone: true,
    imports: [FormsModule,ReactiveFormsModule,NameComponent, AggregatorAndOriginatorListComponent, AccessLevelComponent, MobileComponent, EmailComponent, ConfirmPasswordAdminComponent, FlexModule, MatButtonModule, MatDialogModule]
})
export class AddBrokerUserFormDialog implements OnInit {

  emailValidators: ValidatorFn[];
  // emailAsyncValidators: AsyncValidatorFn[];
  loggedInUser = getUser();
  existingUser = false; // if this user already exists (identified by email)

  subscriptions: Subscription[] = [];
  getFn: AggregatorAndOriginatorListValueGetFn;
  getUserByEmailFn: GetUserByEmailFn;


  user !: User
  formGroup1: FormGroup<{
    firstName : FormControl<NameComponentValue>;
    lastName : FormControl<NameComponentValue>;
    middleName: FormControl<NameComponentValue>;
    aggregatorOrOriginator : FormControl<AggregatorAndOriginatorListValue>;
    mobileNumber : FormControl<MobileValue>;
    email : FormControl<EmailComponentValue>;
    accessLevel : FormControl<AccessLevelValue>;
    password : FormControl<ConfirmPasswordAdminValue>;
  }>;
  formControlFirstName: FormControl<NameComponentValue>;
  formControlLastName: FormControl<NameComponentValue>;
  formControlMiddleName: FormControl<NameComponentValue>;
  formControlAggregatorOrOriginator: FormControl<AggregatorAndOriginatorListValue>;
  formControlMobileNumber: FormControl<MobileValue>;
  formControlEmail: FormControl<EmailComponentValue>;
  formControlAccessLevel: FormControl<AccessLevelValue>;
  formControlPassword: FormControl<ConfirmPasswordAdminValue>;


  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: AddBrokerUserFormDialogDialogData,
    private matRef: MatDialogRef<AddBrokerUserFormDialog, AddBrokerUserFormDialogResult>,
    private toastService: PortalHotToastService,
    private applicationDialogService: ApplicationDialogService
  ) {
    this.emailValidators = [Validators.required, Validators.email];
    // this.emailAsyncValidators = [validEmailValidator(data.validEmailCheckFn)];

    this.formControlFirstName = formBuilder.control(null, [Validators.required]);
    this.formControlLastName = formBuilder.control(null, [Validators.required]);
    this.formControlMiddleName = formBuilder.control(null);
    this.formControlAggregatorOrOriginator = formBuilder.control(null, [Validators.required]);
    this.formControlMobileNumber = formBuilder.control(null, [Validators.required]);
    this.formControlEmail = formBuilder.control(null, this.emailValidators);
    this.formControlPassword = formBuilder.control(null, [Validators.required])
    this.formControlAccessLevel = formBuilder.control(null, [Validators.required]);


    this.formGroup1 = this.formBuilder.group({
      firstName: this.formControlFirstName,
      lastName: this.formControlLastName,
      middleName: this.formControlMiddleName,
      aggregatorOrOriginator: this.formControlAggregatorOrOriginator,
      mobileNumber: this.formControlMobileNumber,
      email: this.formControlEmail,
      accessLevel: this.formControlAccessLevel,
      password: this.formControlPassword,
    });



    this.subscriptions.push(this.formControlEmail.valueChanges.pipe(
      tap(emailComponentValue => {
        if (emailComponentValue) {
          this.subscriptions.push(this.getUserByEmailFn(emailComponentValue).pipe(
            tap(user => {
              this.user = user;
              if (user) {
                this.formControlFirstName.setValue(user.GivenName ?? null);
                this.formControlLastName.setValue(user.FamilyName ?? null);
                this.formControlMiddleName.setValue(user.MiddleName ?? null);
                this.formControlAccessLevel.setValue(user.AccessLevel ?? null);
                this.formControlMobileNumber.setValue(user.MobileNumber ?? null);
                const v: AggregatorAndOriginatorListValue =  user.OriginatorBusinessId ? {
                  kind: user.priviledges.includes('aggregator') ? 'Aggregator' : 'Originator',
                  OriginatorBusinessId: user.OriginatorBusinessId,
                  EntityName: '',
                  SalesforceId: '',
                } : null;
                this.formControlAggregatorOrOriginator.setValue(v);
                this.existingUser = true;
                this.updatePasswordValidation(false);
              } else {
                this.formControlFirstName.setValue(null);
                this.formControlLastName.setValue(null);
                this.formControlMiddleName.setValue(null);
                this.formControlAccessLevel.setValue(null);
                this.formControlMobileNumber.setValue(null);
                this.formControlAggregatorOrOriginator.setValue(null);
                this.existingUser = false;
                this.updatePasswordValidation(true);
              }
            })
          ).subscribe());
        }
      })
    ).subscribe());

    this.getFn = this.data.getFn;
    this.getUserByEmailFn = this.data.getUserByEmailFn;
  }

  ngOnInit() {
  }

  updatePasswordValidation(required: boolean) {
    const passwordControl = this.formControlPassword;
    if (required) {
      passwordControl.setValidators([Validators.required]);
    } else {
      passwordControl.clearValidators();
    }
    passwordControl.updateValueAndValidity();
  }

  cancel($event: Event) {
    this.matRef.close({
      valid: false,
    });
  }

  submit($event: Event) {
    const res = allowEmailAccessLevel(this.formControlEmail.value!,this.formControlAccessLevel.value!)
    if (!res) {
      this.applicationDialogService.openAlertDialog({
        message: `Access Level`,
        subMessage: `Email ${this.formControlEmail.value} is not allow access level ${this.formControlAccessLevel.value!}`,
      });
      return;
    }
      this.doSubmit($event);

    // NOTE: doing another check here becasuse async validators in email component doesn't seems
    //       to trigger a form valid when it is valid
    // NOTE: formGroup and formControls should be valid already upon reaching here
    // this.data.validEmailCheckFn(this.formControlEmail.value!).pipe(
    //   tap(valid => {
    //     if (valid) {
    //       this.doSubmit($event);
    //     } else {
    //       alert(`Email ${this.formControlEmail.value} already exists`);
    //     }
    //   })
    // ).subscribe();


  }

  doSubmit($event: Event) {
    // NOTE: formGroup and formControls should be valid already upon reaching here
    const data = {
      GivenName: this.formControlFirstName.value!,
      FamilyName: this.formControlLastName.value!,
      MiddleName: this.formControlMiddleName.value!,
      AccessLevel: this.formControlAccessLevel.value!,
      Email: this.formControlEmail.value!,
      // AggregatorId: 0,
      OriginatorBusinessId: 0,
      isLock:false,
      // Name: this.formControlFirstName.value + ' ' + this.formControlLastName.value,
      MobileNumber: this.formControlMobileNumber.value!,
      // NOTE: if existing user we don't update the password
      Password: this.existingUser ? undefined : this.formControlPassword.value ?? undefined,
    };
    const o: AggregatorAndOriginatorListValue = this.formControlAggregatorOrOriginator.value;
    // if (o && o.kind === 'Aggregator') {
    //   data.AggregatorId = o.AggregatorId;
    // } else if (o && o.kind === 'Originator') {
    //   data.OriginatorBusinessId = o.OriginatorBusinessId;
    // }
    if (o) {
      data.OriginatorBusinessId = o.OriginatorBusinessId;
    }

    if(!this.existingUser){
    this.data.createUserFn(data).
    pipe(this.toastService.spinnerObservable(),
      this.toastService.retryableMessage({
        successMessage: 'User created',
        errorMessage: 'Failed to create the User',
        retryFn: ()=> {
          this.submit($event);
        }
      }),).
    subscribe((res) => {
      if(res){
        this.matRef.close({
          valid: true,
        });
      }
    })
  } else {
    this.data.updateUserFn(this.user.UserId,data).
    pipe(this.toastService.spinnerObservable(),
      this.toastService.retryableMessage({
        successMessage: 'User updated',
        errorMessage: 'Failed to update the User',
        retryFn: ()=> {
          this.submit($event);
        }
      }),).
    subscribe((res) => {
      if(res){
        this.matRef.close({
          valid: true,
        });
      }
    })
  }
  }
}
