<div class="bank-statements-page container-fluid">
  <div class="mat-h2 header">
    <div>Bank Statements Analysis</div>
    <!-- <button class="arrow-left" (click)="onClickBack(applicationStepper)">
    <span class="mdi mdi-arrow-left"></span>
  </button> -->
</div>
<application-stepper2 [linear]="false" #applicationStepper="applicationStepper2">
  <cdk-step [stepControl]="formGroupStep1" ><ng-content *ngTemplateOutlet="step1" ></ng-content></cdk-step>
  <cdk-step [stepControl]="formGroupStep2" ><ng-content *ngTemplateOutlet="step2" ></ng-content></cdk-step>
</application-stepper2>
</div>

<!--
##########################################
#  STEP 1: business search
##########################################
-->
<ng-template #step1>
  <div class="step1">
    <form [formGroup]="formGroupStep1" >
      <div class="row">
        <div class="col-12">
          <div class="mat-subtitle-1">Applicant's business name</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <business-search [allowFreeText]="false" [placeholder]="businessSearchPlaceholder" [mark]="applicationStepper.markTriggerSubject(formGroupStep1)" [formControl]="formControlStep1Business" [searchFn]="step1SearchFn"></business-search>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 2: analysis details
##########################################
-->
<ng-template #step2>
  <div class="step2">
    <div class="row">
      <div class="col-12">
        <img class="logo" [src]="logo">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-lg-10 col-md-12">
        <div class="mat-h3">
          Statement Analysis for {{businessName}} ({{abn}})
        </div>
      </div>
      <div class="col-lg-2 col-md-12">
        @if (isInternalUser(loggedInUser)) {
          <button mat-flat-button
            class="w-lt-md-100"
            (click)="refreshBankStatement()">
            <span class="mdi mdi-refresh" matPrefix></span>
            <span class="label"> Refresh </span>
          </button>
        }
      </div>
    </div>


    <!--    account summary-->
    <div class="row">
      <div class="col-12">
        <div class="mat-h3">Account Summary</div>
        <account-summary [data]="data"></account-summary>
      </div>
    </div>

    <!--    customer analysis-->
    <div class="row mt-4">
      <div class="col-12">
        <div class="mat-h3 customer-analysis-title">Customer Analysis</div>
        <span class="notes">The below analysis combines all the bank accounts submitted under the customer’s reference ID.</span>
        <customer-analysis [data]="data"></customer-analysis>
      </div>
    </div>
  </div>
</ng-template>
