<div class="income-component">
  <div class="row">
    <div class="col-6">
      <currency-input title="Regular income (after tax)"  [formControl]="formControlIncome"></currency-input>
    </div>
    <div class="col-6">
      <mat-form-field>
        <mat-label>Per</mat-label>
        <mat-select [formControl]="formControlPeriod">
          @for (incomePeriodOption of incomePeriodOptions; track incomePeriodOption) {
            <mat-option  [value]="incomePeriodOption">
              {{incomePeriodOption.name}}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
