import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'abbreviateNumber',
  standalone: true,
})
export class AbbreviateNumberPipe implements PipeTransform {
  transform(value: number): string {
    if (isNaN(value) || value === null) {
      return '';
    }

    // Threshold for using K or M
    const thousand = 1000;
    const million = 1000000;

    if (value >= million) {
      const stringValue = (value / million).toFixed(1);
      if (stringValue.endsWith('.0')) {
        // Remove decimal point and trailing zero
        return stringValue.slice(0, -2) + 'mln';
      } else {
        return stringValue + 'mln';
      }
    } else if (value >= thousand) {
      const stringValue = (value / thousand).toFixed(1);
      if (stringValue.endsWith('.0')) {
        // Remove decimal point and trailing zero
        return stringValue.slice(0, -2) + 'K';
      } else {
        return stringValue + 'K';
      }
    } else {
      return value.toString();
    }
  }
}
