import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {
  AccountFilter,
  AccountSummary,
  BankStatementAnalysisCategoryByBankId,
  BankStatementsAnalysisCategory,
  BankStatementsAnalysisTransactionDetails,
  CategoryTransactionCheckbox,
  DayEndBalanceSummary,
  DebitCreditSummary,
} from '@portal-workspace/grow-shared-library';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable} from 'rxjs';
import {ApplicationDialogService, PortalHotToastService} from '@portal-workspace/grow-ui-library';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { MonthlyDebitsCreditsChartComponent } from './monthly-debits-credits-chart.component';
import { CategoryDetailsTableComponent } from './category-details-table.component';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatTableModule } from '@angular/material/table';
import { DayEndBalanceChartComponent } from './day-end-balance-chart.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FlexModule } from '@angular/flex-layout/flex';
import { NgClass, AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { loadingFor } from '@ngneat/loadoff';
 

class InternalDataSource extends DataSource<BankStatementsAnalysisCategory> {

  subject = new BehaviorSubject<BankStatementsAnalysisCategory[]>([]);

  connect(): Observable<BankStatementsAnalysisCategory[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(data: BankStatementsAnalysisCategory[]) {
    this.subject.next(data);
  }
}

@Component({
    selector: 'categories-table',
    templateUrl: './categories-table.component.html',
    styleUrls: ['./categories-table.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    standalone: true,
    imports: [FlexModule, MatCheckboxModule, AsyncPipe, FormsModule, MatButtonModule, ReactiveFormsModule, DayEndBalanceChartComponent, MatTableModule, NgClass, ExtendedModule, CategoryDetailsTableComponent, MonthlyDebitsCreditsChartComponent, LooseCurrencyPipe]
})
export class CategoriesTableComponent implements OnInit, OnChanges {
  @Input({required: false}) data: BankStatementsAnalysisCategory[] = [];
  @Input({required: false}) accountSummary: AccountSummary[] = [];
  @Input({required: true}) debitCreditSummary!: DebitCreditSummary;
  @Input({required: false}) selectedAccounts: number[] =[];
  @Input({required: false}) categoryByBankId: BankStatementAnalysisCategoryByBankId = {};
  dataSource = new InternalDataSource();
  columnsToDisplay: string[] = ['icon', 'category', 'amountcredit', 'numcredit', 'amountdebit', 'numdebit'];
  expandedElement!: BankStatementsAnalysisCategory | null;
  accountFilter: AccountFilter = {};
  dayEndBalanceChartData: DayEndBalanceSummary[] = [];
  categoryTransactionCheckbox: CategoryTransactionCheckbox = {};
  isData!: boolean;
  selectedDebitCredit!: DebitCreditSummary;
  tableData: BankStatementsAnalysisCategory[] = [];
  accountSelectionChanges: boolean = true;
  firstTimeLoadData: boolean = true;
  loader = loadingFor('tableLoading');
  singleAccount = false;

  constructor(
    private applicationDialogService: ApplicationDialogService,
    ) {}

  ngOnInit(): void {

    this.singleAccount = this.accountSummary.length === 1;
    this.dataSource.update([]);
    this.initAccountFilter();
    if (this.singleAccount) {
      this.dataSource.update(this.data);
      this.tableData = this.data;
      this.getDayEndBalance(this.accountSummary.map(account => account.BankID));
      this.initCategoryTransactionCheckbox();
      this.updateSelectedDebitCredit();
    }

    if (this.selectedAccounts.length) {
      this.reloadChartAndTable();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initAccountFilter();
    if (this.singleAccount) {
      this.dataSource.update([]);
      this.dataSource.update(this.data);
      this.tableData = this.data;
      this.initCategoryTransactionCheckbox();
      this.updateSelectedDebitCredit();
    }

    if (changes['selectedAccounts']) {
      this.reloadChartAndTable();
    }
  }

  initAccountFilter() {
    this.accountSummary.forEach((account: AccountSummary) => {
      this.accountFilter[account.BankID] = new FormControl<boolean>(this.singleAccount);
    })

    if (this.selectedAccounts.length) {
      this.accountSummary.forEach((account: AccountSummary) => {
        this.accountFilter[account.BankID].setValue(this.selectedAccounts.includes(account.BankID));
      })
    }
  }

  initCategoryTransactionCheckbox() {
    for (let i = 0; i < this.data.length; i++) {
      this.categoryTransactionCheckbox[i] = {};
      for (const d of this.tableData[i].details) {
        this.categoryTransactionCheckbox[i][d.id] = true;
      }
    }
  }

  getDayEndBalance(bankIds: number[]) {
    this.dayEndBalanceChartData = []
    if (this.accountSummary.length) {
      for (let i = 0; i < this.accountSummary[0].dayEndBalances.length; i++) {
        let sum = 0;
        for (const accSummary of this.accountSummary) {
          if (bankIds.includes(accSummary.BankID)) {
            sum += Number(accSummary.dayEndBalances[i]?.balance ?? 0);
          }
        }
        this.dayEndBalanceChartData.push({
          date: this.accountSummary[0].dayEndBalances[i].date,
          balance: String(sum)
        })
      }
    }
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'category': return 'Category';
      case 'numcredit': return '# Credits';
      case 'amountcredit': return '$ Credits';
      case 'numdebit': return '# Debits';
      case 'amountdebit': return '$ Debits';
      case 'icon': return '';
      default: return column;
    }
  }

  needCurrencyPipe(column: string) {
    return ['amountdebit', 'amountcredit'].includes(column);
  }

  needAlignRight(column: string) {
    return ['numcredit', 'amountcredit', 'numdebit', 'amountdebit'].includes(column);
  }

  filterChangeHandler() {
    this.accountSelectionChanges = true;
    this.firstTimeLoadData = false;
  }

  reloadChartAndTable() {
    const ref = this.applicationDialogService.openProgressSpinnerDialog();
    setTimeout(() => {
      ref.close();
    }, 3000);
    const accountIds = Object.keys(this.accountFilter);
    const filteredBankIds: number[] = [];
    for (const accountId of accountIds) {
      const checked = this.accountFilter[accountId].value;
      if (checked) {
        filteredBankIds.push(Number(accountId))
      }
    }

    const filteredSummary = filteredBankIds.map(bankId => this.categoryByBankId[bankId]);
    const filteredData: BankStatementsAnalysisCategory[] = [];

    for (let i = 0; i < this.data.length; i++) {
      const amountcredit = filteredSummary.reduce((partial: number, summary: BankStatementsAnalysisCategory[]) => partial + summary[i].amountcredit ,0);
      const amountdebit = filteredSummary.reduce((partial: number, summary: BankStatementsAnalysisCategory[]) => partial + summary[i].amountdebit ,0);
      const numcredit = filteredSummary.reduce((partial: number, summary: BankStatementsAnalysisCategory[]) => partial + summary[i].numcredit ,0);
      const numdebit = filteredSummary.reduce((partial: number, summary: BankStatementsAnalysisCategory[]) => partial + summary[i].numdebit ,0);
      const details = filteredSummary.reduce(
        (partial: BankStatementsAnalysisTransactionDetails[], summary: BankStatementsAnalysisCategory[]) =>
          [...partial, ...summary[i].details], []
      );
      const avgcredit = numcredit ? Number((amountcredit / numcredit).toFixed(2)) : 0;
      const avgdebit = numdebit ? Number((amountdebit / numdebit).toFixed(2)) : 0;
      const category = filteredSummary?.length ? filteredSummary[0][i].category : '';
      filteredData.push({
        amountcredit,
        amountdebit,
        avgcredit,
        avgdebit,
        category,
        details,
        numcredit,
        numdebit,
      })
    }

    this.dataSource.update(filteredData);
    this.tableData = filteredData;
    if (this.dataSource.subject.value.length == 0) {
      this.isData = true
    }else{
      this.isData = false
    }

    this.getDayEndBalance(filteredBankIds);
    this.initCategoryTransactionCheckbox();
    this.updateSelectedDebitCredit();

    this.accountSelectionChanges = false;
  }

  showFilter() {
    return this.accountSummary && this.accountSummary.length && this.accountSummary.length > 1 &&
      (this.accountSummary[0]?.BankID !== undefined || this.accountSummary[0]?.BankID !== null);

// this code works good in production but not on test.
// If anything goes wrong we can uncomment the below code which works good on test as well as production.

    // return this.accountSummary && this.accountSummary.length && this.accountSummary.length > 1 &&
    // (this.accountSummary[0].hasOwnProperty('BankID'));
  }

  handleCheckboxChange(checkbox: { [id: number]: boolean }, index: number) {
    this.categoryTransactionCheckbox[index] = checkbox;
    this.updateSelectedDebitCredit();
  }

  updateSelectedDebitCredit() {
    this.selectedDebitCredit = {
      ...this.debitCreditSummary
    }
    const monthlyCredit: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const monthlyDebit: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const startDate = this.debitCreditSummary.startdate;
    const startYear = Number(startDate.slice(0, 4));
    const startMonth = Number(startDate.slice(5, 7));
    const endDate = this.debitCreditSummary.enddate;
    const endYear = Number(endDate.slice(0, 4));
    const endMonth = Number(endDate.slice(5, 7));

    for (let i = 0; i < this.tableData.length; i++) {
      for (let j = 0; j < this.tableData[i].details.length; j++) {
        if (this.categoryTransactionCheckbox[i][this.tableData[i].details[j].id]) {
          const details = this.tableData[i].details[j];
          const year = Number(details.date.slice(0, 4));
          const month = Number(details.date.slice(5, 7));
          let index = 0;
          if (startYear !== endYear) {
            if (year === startYear) {
              index = 12 - (month - startMonth);
            } else {
              index = endMonth - month;
            }
          } else {
            index = endMonth - month;
          }

          const creditDebitTagIndex = details.tags.map(obj => Object.keys(obj)).findIndex(keys => keys.includes('creditDebit'));
          if (creditDebitTagIndex >= 0 && details.tags[creditDebitTagIndex]['creditDebit'] === 'credit') {
            monthlyCredit[index] += details.amount;
            // console.log(details, index, 'add to credit', details.amount, monthlyCredit)
          } else if (creditDebitTagIndex >= 0 && details.tags[creditDebitTagIndex]['creditDebit'] === 'debit') {
            monthlyDebit[index] += details.amount;
            // console.log(details, index, 'add to dedit', details.amount, monthlyDebit)
          }
        }
      }
    }

    this.selectedDebitCredit = {
      ...this.debitCreditSummary,
      monthlycredit: monthlyCredit,
      monthlydebit: monthlyDebit,
    }
  }
}
