import {Component, Input, OnInit} from '@angular/core';
import {BankStatementsAnalysisData, DebitCreditSummary} from '@portal-workspace/grow-shared-library';
import { FullTransactionListComponent } from './full-transaction-list.component';
import { CategoriesTableComponent } from './categories-table.component';
import { MonthlyDebitsCreditsChartComponent } from './monthly-debits-credits-chart.component';
import { MonthlyDebitsCreditsTableComponent } from './monthly-debits-credits-table.component';
import { NonPreferredLenderSummaryComponent } from './non-preferred-lender-summary.component';
import { OverdrawnTableComponent } from './overdrawn-table.component';
import { TransactionTableComponent } from './transaction-table.component';
import { DishonoursTableComponent } from './dishonours-table.component';
import { MonthlyRevenueChartComponent } from './monthly-revenue-chart.component';
import { MonthlyRevenueTableComponent } from './monthly-revenue-table.component';


@Component({
    selector: 'customer-analysis',
    templateUrl: './customer-analysis.component.html',
    styleUrls: ['./customer-analysis.component.scss'],
    standalone: true,
    imports: [MonthlyRevenueTableComponent, MonthlyRevenueChartComponent, DishonoursTableComponent, TransactionTableComponent, OverdrawnTableComponent, NonPreferredLenderSummaryComponent, MonthlyDebitsCreditsTableComponent, MonthlyDebitsCreditsChartComponent, CategoriesTableComponent, FullTransactionListComponent]
})
export class CustomerAnalysisComponent implements OnInit {
  @Input({required: true}) data!: BankStatementsAnalysisData;

  constructor() {}

  ngOnInit(): void {}

  isEmpty(obj: DebitCreditSummary, table: 'overdrawn' | 'non-preferred-lender' | 'dishonours'): boolean {
    switch (table) {
      case 'non-preferred-lender':
        return !obj.details?.length && !obj.numcredit && !obj.numdebit;
      case 'overdrawn':
        return !obj.numdebit;
      case 'dishonours':
        return !obj.numdebit;
      default:
        return !obj.details?.length && !obj.numcredit && !obj.numdebit;
    }
  }
}
