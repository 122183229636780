import {Component, Input, OnInit, Output} from '@angular/core';
import {Observable, Observer, Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {tap} from 'rxjs/operators';
import {MinimalLayoutService} from './minimal-layout.service';
import {environment} from '../../../environments/environment';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';

import {MatTooltipModule} from "@angular/material/tooltip";
import {setupUntilDestroy} from "@portal-workspace/grow-ui-library";
import {WhitelabelServiceEvent} from "@portal-workspace/grow-shared-library";
export interface MinimalLayoutHeaderInput {
  showAccreditationInContact: boolean,
  showContact: boolean,
  showCross: boolean,
  showPrev: boolean,
  crossTooltips?: string,
}

export interface MinimalLayoutHeaderOutput {
  type: 'accreditation-clicked' | 'cross-clicked' | 'prev-clicked',
}


const DEFAULT_LOGO = 'https://growpublicaccess.blob.core.windows.net/logos/SVG/DYNA_Logo_103023_Master-Pos.svg';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'minimal-layout-header',
    templateUrl: './minimal-layout-header.component.html',
    styleUrls: ['./minimal-layout-header.component.scss'],
    standalone: true,
    imports: [FlexModule, MatButtonModule, MatTooltipModule],
})
export class MinimalLayoutHeaderComponent implements OnInit {


  @Input({required: false}) whitelabelServiceEventObservable?: Observable<WhitelabelServiceEvent>;

  logo = DEFAULT_LOGO;

  subscriptions: Subscription[] = [];

  showAccreditationInContact: boolean = false;
  showContact: boolean = false;
  showCross: boolean = true;
  showPrev: boolean = true;
  crossTooltip = '';

  constructor(private minimalLayoutService: MinimalLayoutService) {
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
    this.subscriptions.push(this.minimalLayoutService.settingChanges().pipe(
      tap(r => {
        if (r) {
          this.showAccreditationInContact = r.showAccreditationInContact;
          this.showContact = r.showContact;
          this.showCross = r.showCross;
          this.showPrev = r.showPrev;
          this.crossTooltip = r.crossTooltips ?? '';
        }
      })
    ).subscribe());
    if (this.whitelabelServiceEventObservable) {
      this.subscriptions.push(this.whitelabelServiceEventObservable.pipe(
        tap(r => {
          if (r && r.payload.logoLink) {
            this.logo = r.payload.logoLink;
          } else {
            this.logo = DEFAULT_LOGO;
          }
        })
      ).subscribe());
    }
  }

  onAccreditationClicked($event: Event) {
    this.minimalLayoutService.fireHeaderEventChanges({
      type: 'accreditation-clicked'
    });
  }

  onCrossClicked($event: Event) {
    this.minimalLayoutService.fireHeaderEventChanges({
      type: 'cross-clicked'
    });
  }

  onPrevClicked($event: Event) {
    this.minimalLayoutService.fireHeaderEventChanges({
      type: 'prev-clicked'
    });
  }

  onHelpClicked($event: MouseEvent) {
    window.open(environment.helpCenterUrl, '_blank');
  }
}
