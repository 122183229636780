import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  ApiResponse, applicantIndividual, Application, ApplicationAssetData, AssetComponentEvent,
  AssetFinanceApplication,
  AssetSelectionComponentValue,
  AssetSettlementGetApplicationAssetFn,
  AssetSupplier, AzureStorageDocument,
  Base64File, CommercialEntity,
  constants,
  ConsumerFinanceApplication,
  ContractStatusResponse,
  CreateAssetInspectionFn,
  ExtractTaxInvoiceFn,
  getAssetCategory,
  getAssetCategoryType,
  GetContractStatusFn,
  getFinanceType,
  getInspectionTypeGuid,
  GetInstitutionNameFn,
  getVerimotoAssetGuid,
  Individual, isInternalUser, Metadata,
  NotNullable, parseJSON,
  PayloadApiResponse,
  PpsrAsset,
  PpsrAssetValue,
  PrivateSeller,
  PrivateSellerValue,
  SaveAssetEvent,
  TaxInvoiceDetailsDialogResult,
  TaxInvoiceDetailsError,
  TaxInvoiceDetailsSuccess,
  User,
  VerimotoAssetInspectionBuyer,
  VerimotoAssetInspectionData,
  VerimotoCreateInspectionResponse,
  VerimotoInspectionTableDataWithInspectionDetails,
  VerimotoLenderType
} from '@portal-workspace/grow-shared-library';
import { Observable, Subject, Subscription, tap } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AddAssetDetailsComponent} from './add-asset-details.component';
import { AbstractControl, NG_VALUE_ACCESSOR, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ApplicationDialogService,
  AssetSettlementGetApplicationPpsrInfoFn, AssetSettlementGetPpsrDetailsFn,
  AssetSettlementSubmitApplicationAssetsFn,
  AssetSettlementUpdateApplicationAssetsFn,
  BusinessSearchFn,
  doMarkAll,
  duplicateFileNameValidator,
  formControlErrorKeys,
  formControlErrorMessage,
  getUser,
  markTriggerSubject,
  maxFileUploadValidator,
  noSpaceValidator,
  PortalHotToastService,
  SearchSupplierFn,
  Supplier2ComponentEvent,
  UpdateApplicationDocumentFn,
  UploadedFilesListComponentEvent
} from '@portal-workspace/grow-ui-library';
import { PrivateSellerComponent } from './private-seller.component';
import { PpsrService } from 'apps/portal2/src/app/service/ppsr.service';
import { ActivatedRoute } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { uniq, isEqual } from 'lodash';
import { combineLatest } from 'rxjs';
import { loadingFor } from '@ngneat/loadoff';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { DisableControlDirective } from '../../directives/disable-control.directive';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { UploadFileComponent } from '../upload-file-component/upload-file.component';
import { UploadedFilesListComponent } from '../upload-file-component/uploaded-files-list.component';
import { MatInputModule } from '@angular/material/input';
import { CellComponent } from '../application-summary-component/cell.component';
import { MarkDirective } from '../../directives/mark-as-dirty.directive';
import { Supplier2Component } from './supplier2.component';
import { MatTabsModule } from '@angular/material/tabs';
import { AsyncPipe, JsonPipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatCardModule } from "@angular/material/card";
const ELEMENT_DATA: any = [];

export type UpdateDocumentMetadataFn = (path: string, fileName: string, metadata: Metadata) => Observable<ApiResponse>;

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    selector: 'add-asset',
    templateUrl: './add-asset.component.html',
    styleUrls: ['./add-asset.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AddAssetComponent), multi: true },
        // {provide: MARK, useExisting: forwardRef(()=>AddAssetComponent)}
    ],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    standalone: true,
    imports: [MatCardModule, FlexModule, MatButtonModule, MatFormFieldModule, MatTabsModule, Supplier2Component, FormsModule, ReactiveFormsModule, MarkDirective, PrivateSellerComponent, CellComponent, MatInputModule, UploadedFilesListComponent, UploadFileComponent, MatTableModule, MatSortModule, AddAssetDetailsComponent, DisableControlDirective, AsyncPipe, LooseCurrencyPipe, JsonPipe]
})
export class AddAssetComponent implements OnInit {
  @Input({required: true}) businessSearchFn!: BusinessSearchFn;
  @Input({required: true}) submitApplicationAssetsFn!: AssetSettlementSubmitApplicationAssetsFn;
  @Input({required: true}) updateApplicationAssetsFn!: AssetSettlementUpdateApplicationAssetsFn;
  @Input({required: false}) getApplicationPpsrInfoFn!: AssetSettlementGetApplicationPpsrInfoFn;
  @Input({required: true}) uploadApplicationDocumentFn!: UpdateApplicationDocumentFn;
  @Input({required: true}) editAssetValue!: PpsrAssetValue;
  @Input({required: true}) searchSupplierFn!: SearchSupplierFn;
  @Input({required: true}) application!: Application;
  @Input({required: true}) getPpsrDetailsFn!: AssetSettlementGetPpsrDetailsFn;
  @Input({required: true}) updateDocumentMetadataFn!: UpdateDocumentMetadataFn;
  @Input({required: true}) getInstitutionNameFn!: GetInstitutionNameFn;
  @Input({required: true}) getApplicationAssetFn!: AssetSettlementGetApplicationAssetFn;
  @Input({required: true}) extractTaxInvoiceFn!: ExtractTaxInvoiceFn;
  @Input({required: true}) createAssetInspectionFn!: CreateAssetInspectionFn;
  @Input({required: true}) getContractStatusFn!: GetContractStatusFn;
  @Input({required: true}) verimotoLender!: VerimotoLenderType;
  @Input({required: false}) assetInspectionTableData: VerimotoInspectionTableDataWithInspectionDetails[] = [];
  @Output() onBackClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onAssetSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() onAssetInspectionCreated: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  @ViewChild("getData") getData!: PrivateSellerComponent
  @ViewChild("formArray") assetForm!:AddAssetDetailsComponent
  loggedinUser: User | null = getUser()
  displayedColumns: string[] = ['asset', 'VINCodeOrChassisNumber', 'invoiceAmount', 'actions'];
  dataSource = ELEMENT_DATA;
  markTriggerSubject = markTriggerSubject;
  editAssetData!: PpsrAssetValue;
  updateAssetData!: PpsrAssetValue;
  editAssetIndex!: number | null;
  expandedElement !: any | null;
  disableButton : boolean = false;
  disableRow:boolean = false;
  firstAssetDataValue!: AssetFinanceApplication | ConsumerFinanceApplication | null;
  isFirstAsset = true;
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  markObservable: Subject<boolean> = new Subject<boolean>();
  formArrayAssets: UntypedFormArray; // array of formcontrol
  ready = false;
  index = 1;
  subscriptions: Subscription[] = [];
  loader = loadingFor('logon');
  formGroup: UntypedFormGroup;
  formControlInvoiceNumber: UntypedFormControl;
  formControlSupplier: UntypedFormControl;
  formControlTaxInvoice: UntypedFormControl;
  supplier!: AssetSupplier;
  privateSupplier!: PrivateSeller;
  assetSubmitted: boolean = false;
  applicationAssets!: ApplicationAssetData[];
  asset: AssetSelectionComponentValue = null;
  tabIndex: number = 0;  // NOTE: default to zero, will be changed if in edit mode during onInit
  uploadedDocs: AzureStorageDocument[] = [];
  deletedDocs: AzureStorageDocument[] = [];
  isRentalApplication = false;
  disableSaveButton = false;
  contractSigned = false;

  constructor(private formBuilder: UntypedFormBuilder,
    private ppsrService: PpsrService,
    private route: ActivatedRoute,
    private dialogService: ApplicationDialogService,
    private toastService: PortalHotToastService) {
    this.formArrayAssets = formBuilder.array([], Validators.required);
    this.formControlSupplier = formBuilder.control(null, [Validators.required]);
    this.formControlInvoiceNumber = formBuilder.control(null, [Validators.required,noSpaceValidator()]);
    this.formControlTaxInvoice = formBuilder.control(null, [Validators.required,duplicateFileNameValidator(),maxFileUploadValidator(5)]);
    this.formGroup = formBuilder.group({
      // supplier: this.formControlSupplier,
      assets: this.formArrayAssets,
      invoiceNumber: this.formControlInvoiceNumber,
      taxInvoice: this.formControlTaxInvoice
    });
  }

  // getAssetData(event: any) {
  //   if (this.editAssetIndex != undefined) {
  //     this.dataSource.splice(this.editAssetIndex, 1, event.asset)
  //     this.dataSource = [...this.dataSource];
  //   }
  //   else {
  //     this.dataSource.push(event.asset)
  //     this.dataSource = [...this.dataSource];
  //     this.formArrayAssets.controls.pop();
  //   }
  //   this.editAssetIndex = null
  // }

  getSavedAssetData(event: SaveAssetEvent) {
    if (this.editAssetIndex != undefined) {
      this.dataSource.splice(this.editAssetIndex, 1, event.asset)
      this.dataSource = [...this.dataSource];
    }
    else {
      this.dataSource.push(event.asset);
      this.dataSource = [...this.dataSource];
    }
    this.editAssetIndex = null
  }

  deleteAsset(element: any) {
    let index = this.dataSource.findIndex((e: any) => e === element)
    this.dataSource.splice(index, 1)
    this.dataSource = [...this.dataSource];
    if(this.dataSource.length === 0){
      this.addAsset();
    }
  }

  getSupplierData(event: Supplier2ComponentEvent) {
    console.log("Supplier:::", event);
    this.formControlSupplier.setValue(event.supplier);
    console.log('=========this.formControlSupplier: ', this.formControlSupplier)
  }
  getColumnTitles(column: string): string {
    switch (column) {
      case 'asset': return 'Asset';
      case 'VINCodeOrChassisNumber': return 'VIN or Chassis number';
      case 'invoiceAmount': return 'Invoice Amount';
      case 'actions': return '';
      default: return column;
    }
  }
  ngOnInit(): void {
    this.dataSource = [];
    this.formArrayAssets.clear();
    this.getApplicationAssetFn(this.application.ApplicationId).pipe(
      tap(r => {
        console.log("get appliction asset from add asset", r)
        if (r && r.length) {
          this.isFirstAsset = false;
        } else {
          this.isFirstAsset = true;
        }
        this.addAsset();
      })
    ).subscribe()
    this.disableRow = false
    this.ready = true;
    this.isRentalApplication = getFinanceType(this.application) == 'Rental'
    this.populate();
    this.formControlSupplier.valueChanges.subscribe(value => {
      console.log('this.formControlSupplier value chaanges', value)
    })

    // get contract status
    // this.subscriptions.push(
    //   this.getContractStatusFn(this.application.ApplicationId).subscribe(
    //     (response: ContractStatusResponse) => {
    //       if (response?.signers && response?.signers?.length) {
    //         this.contractSigned = response.signers.reduce((a, b) => a && b?.status === 'completed', true);
    //       }
    //       console.log('===contract signed: ', this.contractSigned);
    //     }
    //   )
    // )
  }

  populate() {
    if (this.editAssetValue) {
      this.disableSaveButton = true;
      if (this.editAssetValue?.SettlementAssetDetails?.supplier) {
        this.supplier = this.editAssetValue?.SettlementAssetDetails?.supplier;
        this.formControlSupplier.setValue(this.supplier)
        const invoice = this.editAssetValue.invoiceNumber;
        this.formControlInvoiceNumber.setValue(invoice);
        this.editAssetData = this.editAssetValue;
        this.tabIndex = 0;
      } else if (this.editAssetValue?.PrivateSellerDetails) {
        this.formControlSupplier.clearValidators();
        this.formControlSupplier.updateValueAndValidity();
        this.privateSupplier = this.editAssetValue?.PrivateSellerDetails;
        console.log("PRivate seller from edit value", this.privateSupplier)
        const invoice = this.editAssetValue.invoiceNumber
        this.formControlInvoiceNumber.setValue(invoice);
        this.editAssetData = this.editAssetValue;
        this.tabIndex = 1
      }
      this.uploadedDocs = this.editAssetValue?.taxInvoices ?? [];
    }
  }

  addAsset(isappAsset = true) {
    this.expandedElement = null;
    this.editAssetIndex = null;
    this.disableRow = true;
    this.firstAssetDataValue=null;

    if (isappAsset && this.isFirstAsset) {
      this.firstAssetDataValue = this.application as AssetFinanceApplication | ConsumerFinanceApplication
    }
    const as: PpsrAssetValue = {
      assetCategory: null,
      assetType: null,
      description: '',
      serialNumberType: '',
      serialNumber: '',
      rego: '',
      invoicePrice: 0,
      gst: 0,
      year: 0,
      supplier: null as any,
      AssetId: null,
      salesforceId: null,
      assetNumber: null,
      vehicleDetails: null,
      depositAmount: 0,
      depositAmountDynamoney: 0,
      depositAmountSupplier: 0,
      amountFinanced: 0,
      residual: 0,
      autoGenerateInspection: false,
    };
    const fc = this.formBuilder.control(as, [Validators.required]);
    this.formArrayAssets.push(fc);

    this.disableButton = false;
  }
  removeAsset(control: AbstractControl) {
    const fc = control as UntypedFormControl;
    const index = this.formArrayAssets.controls.findIndex(c => c === fc);
    this.formArrayAssets.removeAt(index);
  }

  ondisableAddButton($event: boolean){
    this.disableButton = $event;
  }

  onDisableRow($event: boolean){
    this.disableRow = $event;
  }
  editAsset(control: any) {
    this.disableRow = false;
    this.editAssetIndex = this.dataSource.findIndex((e: any) => e === control);
    this.updateAssetData = control;
  }
  editDisable(event:any){
    event.stopPropagation();
  }

  onAssetEvent($event: AssetComponentEvent, control: AbstractControl) {
    switch ($event.type) {
      // never gets this anymore for some reason (guessing due to UI Changes???)
      // case 'Add': {
      //   this.addAsset();
      //   break;
      // }
      case 'Remove': {
        this.removeAsset(control);
        break;
      }
    }
  }

  setCurrentSelection(tabIndex: number){
    this.populate();
    this.tabIndex = tabIndex
  }

  get formGroupValid() {
    if(this.editAssetData) {
      return this.formControlInvoiceNumber.valid && this.assetForm.formGroup.valid &&
      (this.formControlTaxInvoice.valid || this.uploadedDocs?.length) &&
      (this.tabIndex == 0 ? (this.formControlSupplier.value ? this.formControlSupplier.valid : false) // throw error when formControlSupplier is null
                          : this.getData.formGroupPrivateSeller.valid)
    } else {
      return this.formControlInvoiceNumber.valid &&
      (this.formControlTaxInvoice.valid || this.uploadedDocs?.length) &&
      (this.tabIndex == 0 ? (this.formControlSupplier.value ? this.formControlSupplier.valid : false) // throw error when formControlSupplier is null
                          : this.getData.formGroupPrivateSeller.valid)
    }
  }

  async updateApplicationAssetsData(key: 'Submit' | 'Save') {
    this.disableSaveButton = true;
    if (this.editAssetData) {   // EDIT : UPDATE
      if (!this.formGroupValid) {
        this.getData.formGroupPrivateSeller.markAllAsTouched();
        this.formGroup.markAllAsTouched();
        this.assetForm.formGroup.markAllAsTouched();
        markTriggerSubject(this.assetForm.formGroup).next(true);
        markTriggerSubject(this.formGroup).next(true);
        if (this.assetForm?.formGroup) {
          this.assetForm.formGroup.markAllAsTouched();
          markTriggerSubject(this.assetForm.formGroup).next(true);
          doMarkAll(this.assetForm.formGroup);
        }
        doMarkAll(this.formGroup);
        if(!this.formGroupValid) {
          this.dialogService.openAlertDialog({
            message: 'Missing Information',
            subMessage: 'Make sure all fields are filled in.',
          });
        }
        this.disableSaveButton = false;
      } else {
        this.dataSource.push((this.formGroup.value).assets[0])
        this.dataSource.map((asset:any)=>{
          asset.AssetId = this.editAssetValue?.AssetId;
        })
        let isinvalidCheckdigit: any
        if (this.formGroup.touched || (key === 'Submit')) {
          if (isinvalidCheckdigit) {
            this.dialogService.openAlertDialog({ message: 'Alert', subMessage: 'Please Check VIN' })
            this.disableSaveButton = false;
          } else {
            await this.saveAssets(key);
          }
        } else {
          this.assetSubmitted = true;
          this.disableSaveButton = false;
        }
      }
    } else {   // SAVE : NEW
      console.log(this.formGroup, 'forgroup')
      console.log(this.formGroupValid)
      console.log('supplier', this.formControlSupplier)
      console.log('private seller', this.getData.formGroupPrivateSeller)
      if (!this.formGroupValid) {
        this.getData.formGroupPrivateSeller.markAllAsTouched();
        this.formGroup.markAllAsTouched();
        markTriggerSubject(this.formGroup).next(true);
        if (this.assetForm?.formGroup) {
          this.assetForm.formGroup.markAllAsTouched();
          markTriggerSubject(this.assetForm.formGroup).next(true);
          doMarkAll(this.assetForm.formGroup);
        }
        doMarkAll(this.formGroup);
        if(!this.formGroupValid) {
          this.dialogService.openAlertDialog({
            message: 'Missing Information',
            subMessage: 'Make sure all fields are filled in.',
          });
        }
        this.disableSaveButton = false;
      }
      else if(this.dataSource.length <= 0) {
        this.dialogService.openAlertDialog({
          message: 'Asset not saved',
          subMessage: 'Please save asset before final submission.',
        });
        this.disableSaveButton = false;
      } else {
        let isinvalidCheckdigit: any
        if(this.formGroup.valid){
          console.log('form valid')
          this.dataSource.push((this.formGroup.value).assets[0]);
          this.disableSaveButton = false;
        }
        if (this.formGroup.touched || (key === 'Submit')) {
          if (isinvalidCheckdigit) {
            this.dialogService.openAlertDialog({ message: 'Alert', subMessage: 'Please Check VIN' });
            this.disableSaveButton = false;
          } else {
            await this.saveAssets(key);
          }
        } else {
          this.assetSubmitted = true;
          this.disableSaveButton = false;
        }
      }
    }
  }

  async saveAssets(key: 'Submit' | 'Save') {
    if (this.dataSource.length>0) {
      let updateData: any[] = []
      let ppsrData: any[] = []
      const data = this.dataSource;
      data.forEach(async (element: NotNullable<PpsrAssetValue>) => {
        let asset: any = {}
        if (this.editAssetValue) {
          console.log(this.editAssetData?.PrivateSellerDetails, 'ele')
          asset.ApplicationId = this.application.ApplicationId
          asset.AssetId = this.editAssetValue.AssetId;
          asset.SalesForceId = this.tabIndex == 1 ? this.editAssetData?.PrivateSellerDetails?.SalesForceId : '';
          asset.invoiceNumber = this.formControlInvoiceNumber.value;
          asset.SettlementAssetDetails = ({
            assetCategory: element.assetCategory?.index ?? '',
            assetType: element.assetType?.index ?? '',
            description: element.description,
            serialNumberType: element.serialNumberType,
            serialNumber: element.serialNumber,
            rego: element.rego,
            InvoicePrice: element.invoicePrice,
            GST: element.gst,
            year: element.year,
            depositAmount: element.depositAmount,
            depositAmountDynamoney: element.depositAmountDynamoney,
            depositAmountSupplier: element.depositAmountSupplier,
            amountFinanced: element.amountFinanced,
            residual: element.residual,
            supplierPPSRStatus: this.editAssetValue.SettlementAssetDetails?.supplierPPSRStatus ?? 'unchecked',
            supplier: this.tabIndex == 0 ? this.formControlSupplier.value ?? '' : '',
            VehicleDetails: this.editAssetValue.SettlementAssetDetails?.VehicleDetails ? this.editAssetValue.SettlementAssetDetails.VehicleDetails : '',
            autoGenerateInspection: element.autoGenerateInspection,
          })
         if(!this.formGroupValid){
          this.getData.formGroupPrivateSeller.markAllAsTouched();
          this.getData.formControlMobile.setValue(null);
          this.getData.formControlEmail.setValue(null);
          if(this.getData.formControlYesNo.value === true) {
            this.getData.formControlAddress.setValue(null)
            this.getData.formControlFirstName.setValue(null)
            this.getData.formControlLastName.setValue(null)
            this.getData.formControlMiddleName.setValue(null)
          } else if(this.getData.formControlYesNo.value === false) {
            this.getData.formControlBusiness.setValue(null)
          }
         }
          const e: PrivateSellerValue = {
            PrivateSellerDetails: (this.getData.formGroupPrivateSeller.value)
          }
          e.PrivateSellerDetails.SalesForceId = this.tabIndex == 1 ? this.editAssetData?.PrivateSellerDetails?.SalesForceId : '';
          asset.PrivateSellerDetails = this.tabIndex == 1 ? e.PrivateSellerDetails : ''
          asset.isAssetSubmitted = false
          asset.status = 'pending'
          let ppsrStatus;

          if(this.editAssetValue?.PPSRStatus === 'confirmed' && element.serialNumber === null){ ppsrStatus = 'unchecked' }

          asset.PPSRStatus = ppsrStatus ?? 'unchecked';

          updateData.push(asset);
          let ppsr: any = {}
          ppsr.ApplicationId = this.application.ApplicationId
          ppsrData.push(ppsr);
        } else {
          asset.ApplicationId = this.application.ApplicationId
          asset.AssetId = element.AssetId;
          asset.SalesForceId = this.tabIndex == 1 ? this.editAssetData?.PrivateSellerDetails?.SalesForceId : '';
          asset.invoiceNumber = this.formControlInvoiceNumber.value;
          asset.SettlementAssetDetails = ({
            assetCategory: element.assetCategory?.index ?? '',
            assetType: element.assetType?.index ?? '',
            description: element.description,
            serialNumberType: element.serialNumberType,
            serialNumber: element.serialNumber,
            rego: element.rego,
            InvoicePrice: element.invoicePrice,
            GST: element.gst,
            year: element.year,
            depositAmount: element.depositAmount,
            depositAmountDynamoney: element.depositAmountDynamoney,
            depositAmountSupplier: element.depositAmountSupplier,
            residual: element.residual,
            amountFinanced: element.amountFinanced,
            supplierPPSRStatus: 'unchecked',
            supplier: this.tabIndex == 0 ? this.formControlSupplier.value ?? '' : '',
            autoGenerateInspection: element.autoGenerateInspection,
          })
          const e: PrivateSellerValue = {
            PrivateSellerDetails: this.getData.formGroupPrivateSeller.value
          }
          e.PrivateSellerDetails.SalesForceId = this.tabIndex == 1 ?this.editAssetData?.PrivateSellerDetails?.SalesForceId : '';
          asset.PrivateSellerDetails = this.tabIndex == 1 ? e.PrivateSellerDetails : ''
          asset.isAssetSubmitted = false
          asset.status = 'pending'
          asset.PPSRStatus = 'unchecked';
          updateData.push(asset);
          let ppsr: any = {}
          ppsr.ApplicationId = this.application.ApplicationId
          ppsrData.push(ppsr);
        }
      });

      const subs = [];
      for (let i = 0; i <= updateData.length - 1; i++) {
        if (key === 'Submit') {
          updateData[i].isAssetSubmitted = true
        }
        if (updateData[i].AssetId !== null && updateData[i].AssetId !== undefined) { // UPDATE : EXISTING
          if (key === 'Submit' && (this.applicationAssets[i].submittedByBroker === null || this.applicationAssets[i].submittedByAdmin === null)) {
            // this.isAdmin() ? [updateData[i].submittedByAdmin = true, updateData[i].submittedByBroker = false] : [updateData[i].submittedByAdmin = false, updateData[i].submittedByBroker = true];
            if (this.isAdmin()) {
              updateData[i].submittedByAdmin = true;
              updateData[i].submittedByBroker = false;
            } else {
              updateData[i].submittedByAdmin = false;
              updateData[i].submittedByBroker = true;
            }
          }
          subs.push(this.updateAssetsToDatabase(updateData[i], key));
          this.saveAuditLog(this.getAuditLogData(this.editAssetData,updateData[i]));
        } else {   // SAVE : NEW
          delete updateData[i].AssetId;
          if (key === 'Submit') {
            // this.isAdmin() ? [updateData[i].submittedByAdmin = true, updateData[i].submittedByBroker = false] : [updateData[i].submittedByAdmin = false, updateData[i].submittedByBroker = true];
            if (this.isAdmin()) {
              updateData[i].submittedByAdmin = true;
              updateData[i].submittedByBroker = false;
            } else {
              updateData[i].submittedByAdmin = false;
              updateData[i].submittedByBroker = true;
            }
          }
          subs.push(this.saveAssetsToDatabase(updateData[i], key));
          this.editAssetData = null;
        }
      }
      combineLatest(subs).pipe(
        this.loader.logon.track(),
        tap((assetIds: number[]) => {
          console.log('-==========================r', assetIds)
          this.saveTaxInvoice(assetIds);
        })
      ).subscribe();

      this.deleteDocs();
      this.getPpsrDetailsFn(this.application.ApplicationId).pipe(
        this.loader.logon.track(),
        tap(async r => {
          let res = r;
          if (res === null) {
            await this.savePPSRToDatabase(ppsrData[0])
          }
          else {
            await this.updatePPSRToDatabase(ppsrData[0])
          }
        })).subscribe();
    }
    else {
      console.log("Form group::::", this.formGroup)
      this.dialogService.openAlertDialog({ message: 'Alert', subMessage: 'Please check details and try again.' })
      if (this.formGroup.invalid) {
      }
    }
  }

  saveAuditLog(auditLogData: { Field: string, OldValue: string, NewValue: string }[]) {
    const applicationId = this.route.snapshot.paramMap.get('applicationId') || ''
    // const user = parseJSON(localStorage.getItem('growUser') || '')
    for (let i = 0; i < auditLogData.length; i++) {
      const element = auditLogData[i];
      if (element !== undefined && element !== null) {
        const auditData = {
          UserId: this.loggedinUser?.UserId!,
          ApplicationId: parseInt(applicationId),
          Field: element.Field,
          OldValue: element.OldValue,
          NewValue: element.NewValue,
          // DateGenerated: new Date().toISOString(),
          AssetNumber: (this.editAssetValue as any).assetNumber as number,
          AssetId: this.editAssetValue?.AssetId as number
        }
        this.ppsrService.addAuditLog(auditData).pipe(
          this.toastService.spinnerObservable(),
          this.toastService.snackBarObservable('Audit log added'),
          tap(r => {
          })
        ).subscribe();
      }
    }
  }

  async savePPSRToDatabase(assetData: {
    ApplicationId: number,
    Grantors: string,
  }) {
    this.ppsrService.addPpsrDetail(assetData).subscribe(res => {
    })
  }

  async updatePPSRToDatabase(assetupdatedata: {
    ApplicationId: number,
    Grantors: string,
  }) {
    this.ppsrService.updatePpsrDetail(assetupdatedata).subscribe(res => {
    })
  }

  updateAssetsToDatabase(assetupdatedata: PpsrAsset, key: 'Submit' | 'Save') {
    console.log('========assetupdatedata: ', assetupdatedata);
    return this.updateApplicationAssetsFn(assetupdatedata).pipe(
      this.toastService.spinnerObservable(),
      this.toastService.snackBarObservable('Asset Updated'),
      tap(r => {
          if (key === 'Submit') {
            this.assetSubmitted = true;
            this.disableSaveButton = false;
            this.onAssetSave.next("assetSaved")
          }
        })
      );
  }

  saveAssetsToDatabase(assetData: PpsrAsset, key: 'Submit' | 'Save') {
    return this.submitApplicationAssetsFn(assetData).pipe(
      tap((r: number) => {
        // create new asset inspection for private seller
        // check if the contract is signed or not

        // temporarily remove the automation
        // if (this.getData.formGroupPrivateSeller.valid && (assetData.SettlementAssetDetails?.InvoicePrice ?? 0) <= 150000 && this.contractSigned) {
        //   const assetGuid: string | null = getVerimotoAssetGuid(assetData.SettlementAssetDetails?.assetCategory ?? '', assetData.SettlementAssetDetails?.assetType ?? '');
        //   if (!assetGuid) {
        //     return;
        //   }
        //   const stateCode = assetData.PrivateSellerDetails?.business ? assetData.PrivateSellerDetails.state : assetData.PrivateSellerDetails?.address?.State;
        //   if (!stateCode) {
        //     return;
        //   }

        //   const inspectionTypeGuid: string | null = getInspectionTypeGuid(this.application, assetGuid ?? '');
        //   const brokersOrLenders = [
        //     {
        //       email: constants.verimotoLenderEmail,
        //       isOwner: true
        //     }
        //   ];

        //   const applicant = this.application.Contacts;
        //   let buyer: VerimotoAssetInspectionBuyer = null;
        //   if (applicant) {
        //     buyer = {
        //       name: applicant.GivenName ?? '',
        //       surName: applicant.SurName ?? '',
        //       email: applicant.Email ?? '',
        //       phoneNumberWithCountryCode: applicant.Mobile ?
        //       `+61${applicant.Mobile.slice(1, applicant.Mobile.length)}` : null
        //     }
        //   }
        //   const data: VerimotoAssetInspectionData =  {
        //     seller: {
        //       name: this.getData.formGroupPrivateSeller.value.name ?? '',
        //       surName: this.getData.formGroupPrivateSeller.value.lastName ?? '',
        //       email: this.getData.formGroupPrivateSeller.value.email ?? '',
        //       phoneNumberWithCountryCode: this.getData.formGroupPrivateSeller.value.mobile ?
        //         `+61${this.getData.formGroupPrivateSeller.value.mobile.slice(1, this.getData.formGroupPrivateSeller.value.mobile.length)}` : ''
        //     },
        //     buyer: buyer,
        //     lenderGuid: this.verimotoLender.guid,
        //     lenderRef: `${this.application.AppInfoSalesforceID ?? ''}-${r}`,
        //     externalRef: `${this.application.AppInfoSalesforceID ?? ''}-${r}`,
        //     assetGuid: assetGuid ?? '',
        //     planGuid: inspectionTypeGuid,
        //     stateCode: stateCode,
        //     brokersOrLenders: brokersOrLenders
        //   }

        //   console.log('======data: ', data);

        //     this.createAssetInspectionFn(r, this.application.ApplicationId, data).pipe(
        //       this.toastService.spinnerObservable(),
        //     ).subscribe((result: PayloadApiResponse<VerimotoCreateInspectionResponse>) => {
        //       this.onAssetInspectionCreated.emit(true);
        //       if (result.status) {
        //         this.dialogService.successDialog({
        //           message: "Success",
        //           subMessage: "New Verimoto inspection is created."
        //         }).afterClosed().subscribe()
        //       } else {
        //         this.dialogService.openAlertDialog({
        //           message: "Error",
        //           subMessage: `${result.message}`
        //         })
        //       }
        //     })

        // }

        if (key === 'Submit') {
          this.assetSubmitted = true;
          this.disableSaveButton = false;
        }
      })
    );
  }

  async saveTaxInvoice(assetIds: number[]) {
    let taxInvoiceFiles: File[];
    if (this.editAssetValue) {
      taxInvoiceFiles = await this.getTaxInvoiceData([this.editAssetValue?.AssetId as number]);
    } else {
      taxInvoiceFiles = await this.getTaxInvoiceData(assetIds);
    }

    (await this.uploadApplicationDocumentFn(
      this.application.ApplicationId,
      [...taxInvoiceFiles],
      []
    )).pipe(
      this.toastService.spinnerObservable(),
      this.toastService.snackBarObservable('Asset saved')
    ).subscribe(() => {
      this.onAssetSave.next("backTriggred");
    });
  }

  isAdmin() {
    if (this.loggedinUser) {
      if (isInternalUser(this.loggedinUser)) {
        return true
      }
    }
    return false
  }

  async onClickBack() {
    this.onBackClick.next("backTriggred")
  }

  getAuditLogData = (oldvalue: any, newvalue: any) => {
    let auditLog = []
    let keys = this.getUpdatedKeys(oldvalue, newvalue)
    for (let i = 0; i < keys.length; i++) {
      if (keys[i] === 'SettlementAssetDetails') {
        let key = this.getUpdatedKeys(oldvalue['SettlementAssetDetails'], newvalue['SettlementAssetDetails'])
        key.forEach(element => {
          let auditSettlementAssetData: any = {}
          if (oldvalue[keys[i]][element] !== undefined && oldvalue[keys[i]][element] !== null) {
            auditSettlementAssetData.Field = element
            if (element === 'assetCategory') {
              auditSettlementAssetData.OldValue = getAssetCategory(oldvalue[keys[i]][element])?.value
              auditSettlementAssetData.NewValue = getAssetCategory(newvalue[keys[i]][element])?.value
            } else if (element === 'assetType') {
              auditSettlementAssetData.OldValue = getAssetCategoryType(oldvalue[keys[i]]['assetCategory'], oldvalue[keys[i]][element])?.value
              auditSettlementAssetData.NewValue = getAssetCategoryType(newvalue[keys[i]]['assetCategory'], newvalue[keys[i]][element])?.value
            } else {
              auditSettlementAssetData.OldValue = oldvalue[keys[i]][element]
              auditSettlementAssetData.NewValue = newvalue[keys[i]][element]
            }
            auditLog.push(auditSettlementAssetData)
          }
          if (element === 'supplier') {
            let supplierKeys = this.getUpdatedKeys(oldvalue[keys[i]][element], newvalue[keys[i]][element])
            supplierKeys.forEach(e => {
              if (oldvalue[keys[i]]['supplier'][e] !== undefined && oldvalue[keys[i]]['supplier'][e] !== null) {
                let auditSupplier: any = {}
                auditSupplier.Field = "Supplier - " + e
                auditSupplier.OldValue = oldvalue[keys[i]]['supplier'][e]
                auditSupplier.NewValue = newvalue[keys[i]]['supplier'][e]
                auditLog.push(auditSupplier)
              }
            })
          }

        });
      }
      else if (keys[i] === 'PrivateSellerDetails') {
        let key = this.getUpdatedKeys(oldvalue['PrivateSellerDetails'], newvalue['PrivateSellerDetails'])
        key.forEach(element => {
          if (oldvalue[keys[i]][element] !== undefined && oldvalue[keys[i]][element] !== null) {
            let pivateSeller: any = {}
            pivateSeller.Field = element
            pivateSeller.OldValue = oldvalue[keys[i]][element]
            pivateSeller.NewValue = newvalue[keys[i]][element]
            auditLog.push(pivateSeller)
          }
        });
      } else {
        if (oldvalue[keys[i]] !== undefined && oldvalue[keys[i]] !== null) {
          let auditLogData: any = {}
          auditLogData.Field = keys[i]
          auditLogData.OldValue = oldvalue[keys[i]]
          auditLogData.NewValue = newvalue[keys[i]]
          auditLog.push(auditLogData)
        }
      }
    }
    return auditLog
  }

  getUpdatedKeys = (oldValue: any, newValue: any) => {
    const data = uniq([...Object.keys(oldValue), ...Object.keys(newValue)]);
    const keys = [];
    const requiredKeys: any = ['SettlementAssetDetails',
      'PrivateSellerDetails',
      'invoiceNumber', 'status', 'supplier',
      'assetCategory', 'assetType', 'description', 'invoicePrice', 'serialNumber', 'year', 'serialNumberType', 'GST','InvoicePrice', 'rego', 'residual',
      'accountNumber', 'address', 'bankName', 'bsb', 'financialInstitution', 'lastName', 'middleName', 'name', 'email', 'mobile',
      'AssetSupplierId', 'SupplierType', 'ABN', 'SupplierAddress', 'Contact', 'SalesForceId', 'SupplierName', 'isDeleted', 'ACN', 'isAccredited', 'isPrivateSeller']
    for (const key of data) {
      for (let i = 0; i <= requiredKeys.length; i++) {
        if (!isEqual(oldValue[key], newValue[key]) && key.includes(requiredKeys[i])) {
          keys.push(key);
        }
      }
    }
    return keys;
  }

  async getTaxInvoiceData(assetIds: number[]): Promise<File[]> {
    const formData = this.formControlTaxInvoice.value;
    if (formData) {
      const fileArr = formData.map((f: Base64File) => {
        f.tags = ['taxinvoice'];
        if (f.metadata) {
          f.metadata = {
            assetid: assetIds.join('-'),
            ...this.documentMetadata,
            ...f.metadata
          }
        } else {
          f.metadata = {
            assetid: assetIds.join('-'),
            ...this.documentMetadata,
          };
        }

        return f;
      })
      return fileArr;
    }
    return [];
  }

  get documentMetadata() {
    if (this.application.ApplicationType !== "Consumer") {
      const primaryEntity = this.application?.CommercialEntities.find((e: CommercialEntity) => e.Type === 'Primary');
      return {
        abn: primaryEntity?.ABN ?? '' as string,
        legalname: primaryEntity?.LegalName ?? '' as string
      } as Metadata;
    } else {
      const applicant = this.application?.Individuals.find((i: Individual) => i.Role === 'Applicant');
      return {
        consumername: applicant && (applicant?.GivenName || applicant?.SurName) ? applicant?.GivenName + ' ' + applicant?.SurName : '' as string,
        dob: applicant?.DoB ?? '' as string
      } as Metadata;
    }
  }

  onDeleteUploadedDoc(event: UploadedFilesListComponentEvent) {
    this.deletedDocs = event.deleted;
    this.uploadedDocs = event.existing;
  }

  deleteDocs() {
    const subs = [];
    for (const doc of this.deletedDocs) {
      const azureDoc = doc as AzureStorageDocument;
      const assetIdMetadata = (azureDoc.metadata as any).assetid;
      const assetIdArr = assetIdMetadata.split('-');
      const index = assetIdArr.indexOf(String(this.editAssetValue?.AssetId));
      if (index > -1) {
        assetIdArr.splice(index, 1);
      }
      const newAssetIdMetadata = assetIdArr.join("-");

      subs.push(
        this.updateDocumentMetadataFn(azureDoc.name, "", {
          ...azureDoc.metadata,
          assetid: newAssetIdMetadata
        })
      );
    }

    this.subscriptions.push(
      combineLatest(subs).subscribe()
    )
  }

  skipFileUploadValidation() {
    return (this.uploadedDocs && this.uploadedDocs.length > 0);
  }

  handleAssetDetailsFinishLoading() {
    this.disableSaveButton = false;
  }

  async onUploadTaxInvoice(files: any) {
    if (files.length) {
      const lastTaxInvoice = files[files.length - 1];
      this.subscriptions.push(
        (await this.extractTaxInvoiceFn(lastTaxInvoice)).pipe(this.toastService.spinnerObservable()).subscribe(result => {
          console.log(result)
          if (result && !!!(result as TaxInvoiceDetailsError).Error) {
            this.dialogService.openTaxInvoiceDetailsDialog({
              taxInvoiceDetails: result as TaxInvoiceDetailsSuccess
            }).afterClosed().subscribe((data: TaxInvoiceDetailsDialogResult | undefined) => {
              if (data && data.readyForSubmission) {
                console.log(this.formControlTaxInvoice.value);
                const taxInvoiceFiles = this.formControlTaxInvoice.value;
                if (taxInvoiceFiles.length) {
                  taxInvoiceFiles[taxInvoiceFiles.length - 1].metadata = {
                    ...data.invoiceDetails,
                    amount: data.invoiceDetails.amount?.toString()
                  };
                }
                this.formControlTaxInvoice.setValue(taxInvoiceFiles);

                // if (this.tabIndex === 1) {
                //   this.privateSupplier.bankDetails = {
                //     InstitutionName: data.invoiceDetails.financialInstitution,
                //     AccountName: data.invoiceDetails.accountName,
                //     BSB: data.invoiceDetails.bsb,
                //     AccountNumber: data.invoiceDetails.accountNumber,
                //   }
                // }
              }
            })
          }
        })
      )
    }
  }
}

