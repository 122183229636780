<div class="minimal-layout hide-scrollbar"
     fxLayout="column"
     fxLayoutAlign="start center">
  <div class="header-2 width-100">
    <minimal-layout-header
      [whitelabelServiceEventObservable]="whitelabelServiceEventObserver"
    ></minimal-layout-header>
  </div>
  <div class="body">
    <error-notification2></error-notification2>
    <router-outlet></router-outlet>
  </div>
</div>
