<div class="consumer-finance-summary-component">
  @if (summary) {
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="mat-h2">Summary</div>
        </div>
      </div>
      @if (summary.repayment || summary.estimatedDrawDownDate) {
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <mat-form-field>
              <mat-label>{{summary.repaymentFrequency}} repayments</mat-label>
              <input matInput class="ml-4" type="input" readonly name="monthlyRepayment" [ngModel]="summary.repayment">
              <span class="mdi mdi-currency-usd" matPrefix></span>
            </mat-form-field>
          </div>
          <div class="col-lg-3 col-md-12">
            <mat-form-field>
              <mat-label>Estimated draw down date</mat-label>
              <input matInput type="input" readonly name="estimatedDrawDownDate" [ngModel]="displayableDate(summary.estimatedDrawDownDate)">
            </mat-form-field>
          </div>
        </div>
      }
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Application ID</div>
            <div body>{{summary.applicationId}}</div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Applicant</div>
            <div body>{{summary.firstName}} {{summary.lastName}}</div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Broker Contact</div>
            <div body>{{summary.brokerContact?.FirstName}} {{summary.brokerContact?.LastName}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row separator-top">
        <div class="col-12">
          <div class="mat-h3">Applicant details</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Title</div>
            <div body>{{summary.title?.name}}</div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>First name</div>
            <div body><p [innerHTML]="summary.firstName | ellipsis:10"></p></div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Last name</div>
            <div body><p [innerHTML]="summary.lastName | ellipsis:10"></p></div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Date of birth</div>
            <div body>{{displayableDate(summary.dob)}}</div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Gender</div>
            <div body>{{summary.gender}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Property Owner</div>
            <div body>{{booleanToYesNo(summary.propertyOwnerWithAddress?.propertyOwner ?? false)}}</div>
          </cell>
        </div>
        @if (summary.propertyOwnerWithAddress?.propertyOwner && summary.propertyOwnerWithAddress?.address) {
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Property address</div>
              <div body>{{summary.propertyOwnerWithAddress?.address?.address}}</div>
            </cell>
          </div>
        }
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        @if (summary.propertyOwnerWithAddress?.propertyOwner && summary.propertyOwnerWithAddress?.propertyValue) {
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Property value</div>
              <div body>{{summary.propertyOwnerWithAddress?.propertyValue}}</div>
            </cell>
          </div>
        }
        @if (summary.propertyOwnerWithAddress?.propertyValue && summary.propertyOwnerWithAddress?.mortgageValue) {
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Mortgage balance</div>
              <div body>{{summary.propertyOwnerWithAddress?.mortgageValue}}</div>
            </cell>
          </div>
        }
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <cell>
            <div label>Email address</div>
            <div body>{{summary.email}}</div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Business landline</div>
            <div body>{{summary.mobileNumber}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Marital status</div>
            <div body>{{summary.maritalStatus?.name}}</div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Number of dependants</div>
            <div body>{{summary.numberOfDependants}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Employment status</div>
            <div body>{{summary.employmentStatus?.name}}</div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Regular income (after tax)</div>
            <div body><span class="mdi mdi-currency-usd"></span>{{summary.regularIncome?.income}} {{summary.regularIncome?.period?.name}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Employer</div>
            <div body>{{summary.employer?.currentEmployer}}</div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Employer contact</div>
            <div body>{{summary.employer?.currentEmployerContact}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Time at current employer</div>
            <div body>{{summary.employer?.yearsAtCurrentEmployer}} years {{summary.employer?.monthsAtCurrentEmployer}} months</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      @if (summary.employer?.previousEmployer || summary.employer?.previousEmployerContact) {
        <div class="row">
          @if (summary.employer?.previousEmployer) {
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>Employer</div>
                <div body>{{summary.employer?.previousEmployer}}</div>
              </cell>
            </div>
          }
          @if (summary.employer?.previousEmployerContact) {
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>Employer contact</div>
                <div body>{{summary.employer?.previousEmployerContact}}</div>
              </cell>
            </div>
          }
        </div>
      }
      <mat-divider [inset]="true"></mat-divider>
      @if (summary.spouse) {
        <div class="row">
          <div class="col-12">
            <div class="mat-h3">Spouse details</div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Title</div>
              <div body>{{summary.spouse.title?.name}}</div>
            </cell>
          </div>
          <div class="col-lg-3 col-md-12">
            <div label>First name</div>
            <div body><p [innerHTML]="summary.spouse.firstName | ellipsis:10"></p></div>
          </div>
          <div class="col-lg-3 col-md-12">
            <div label>Last name</div>
            <div body><p [innerHTML]="summary.spouse.lastName | ellipsis:10"></p></div>
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Date of birth</div>
              <div body>{{displayableDate(summary.spouse.dob)}}</div>
            </cell>
          </div>
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Gender</div>
              <div body>{{summary.spouse.gender}}</div>
            </cell>
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <cell>
              <div label>Email address</div>
              <div body>{{summary.spouse.email}}</div>
            </cell>
          </div>
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Business landline</div>
              <div body>{{summary.spouse.mobileNumber}}</div>
            </cell>
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Additional guarantor</div>
              <div body>{{summary.spouse.guarantor}}</div>
            </cell>
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Employment status</div>
              <div body>{{summary.spouse.employmentStatus?.name}}</div>
            </cell>
          </div>
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Regular income (after tax)</div>
              <div body>{{summary.spouse.regularIncome?.income}} {{summary.spouse.regularIncome?.period?.name}}</div>
            </cell>
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <cell>
              <div label>Employer</div>
              <div body>{{summary.spouse.employer}}</div>
            </cell>
          </div>
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Employer contact</div>
              <div body>{{summary.spouse.employerContact}}</div>
            </cell>
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
      }
      <div class="row separator-top">
        <div class="col-12">
          <div class="mat-h3">Monthly living expenses</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Home loans</div>
            <div body><span class="mdi mdi-currency-usd"></span>{{summary.expanses.homeLoans}}</div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Electricity</div>
            <div body><span class="mdi mdi-currency-usd"></span>{{summary.expanses.electricity}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Car loans</div>
            <div body><span class="mdi mdi-currency-usd"></span>{{summary.expanses.carLoans}}</div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Other utilities</div>
            <div body><span class="mdi mdi-currency-usd"></span>{{summary.expanses.otherUtilities}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Personal loans</div>
            <div body><span class="mdi mdi-currency-usd"></span>{{summary.expanses.personalLoans}}</div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Education</div>
            <div body><span class="mdi mdi-currency-usd"></span>{{summary.expanses.education}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Total credit card limits</div>
            <div body><span class="mdi mdi-currency-usd"></span>{{summary.expanses.totalCreditCardLimits}}</div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Groceries</div>
            <div body><span class="mdi mdi-currency-usd"></span>{{summary.expanses.groceries}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Other loans</div>
            <div body><span class="mdi mdi-currency-usd"></span>{{summary.expanses.otherLoans}}</div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Insurance</div>
            <div body><span class="mdi mdi-currency-usd"></span>{{summary.expanses.insurance}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row separator-top">
        <div class="col-12">
          <div class="mat-h3">Your share of monthly living expenses</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Share</div>
            <div body>{{summary.expanses.shareMonthlyLivingExpanses}}<span class="mdi mdi-percent"></span></div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-12 mt-3">
          <references-summary [references]=" summary.references"></references-summary>
        </div>
      </div>
      @if (summary.applicationNotes) {
        <div class="row">
          <div class="col-12 mt-3">
            <div class="mat-h3">Application notes</div>
          </div>
        </div>
      }
      <div class="row">
        <div class="col-lg-6 col-md-12">
          {{summary.applicationNotes}}
        </div>
      </div>
    </div>
  }
</div>
