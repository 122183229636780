@if (!((store.value$ | async)?.error)) {
  <div class="profile-component">
    <mat-card appearance="outlined">
      <mat-card-content>
        <mat-tab-group [mat-stretch-tabs]="false" mat-align-tabs="start">
          <mat-tab label="ACCOUNT"><ng-container *ngTemplateOutlet="profileTemplate"></ng-container></mat-tab>
        </mat-tab-group>
      </mat-card-content>
    </mat-card>
  </div>
}

<ng-template #profileTemplate>
  <div class="row mb-4">
    <div class="col-lg-4 col-md-12">
      <span class="mat-body-strong">
        Personal information
      </span>
    </div>
  </div>

  <div class="row" style="margin-left: 0px;">
    <div style="border-radius: 50px; width: 100px;height: 100px;background: #ECEFF1; padding: 6px 4px; gap: 10px;">
      <div style="margin-left: 17px;margin-top: 32px;"> <span style="font-size: 45px;">{{givenName}}{{familyName}}</span></div>
    </div>
  </div>

  @if (allowProfileEdit == false) {
    <div class="row separator-bottom mt-4">
      <div class="col-6">
        <message-box>
          Please contact Dynamoney if you require changes to your profile
        </message-box>
      </div>
    </div>
  }
  <div class="row mt-4">
    <div class="col-md-12 col-lg-4">
      <name title="First Name" [formControl]=formControlFirstName></name>
    </div>

    <div class="col-md-12 col-lg-4">
      <name title="Last Name" [formControl]=formControlLastName></name>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 col-lg-4">
      <name title="Middle Name" [mandatory]="false" [formControl]="formControlMiddleName"></name>
    </div>

    <div class="col-md-12 col-lg-4">
      <mobile [mandatory]="true" [onlyMobile]="true" [formControl]="formControlMobileNumber">
      </mobile>
    </div>
  </div>

  <mat-divider [inset]="true"></mat-divider>

  <div class="row mt-4">
    <div class="col-12">
      <span class="mat-body-strong">
        Email
      </span>
      <p class="col-md-12 pl-0 md-body-1 mt-2">
        You use the email address to sign in. For security reasons, please reach out
        to the administrator of your organization to change your email address.
      </p>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-md-12 col-lg-6">
      <email [formControl]="formControlEmail"></email>
    </div>
  </div>

  <mat-divider [inset]="true"></mat-divider>

  <div class="separator-bottom mt-4">
    <div class="row mb-4 ml-0">
      <div class="col-12">
        <span class="mat-body-strong">
          Password and Authentication
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-9 col-md-12">
        <mat-card appearance="outlined" class="mb-4 custom-card">
          <mat-card-content>
            <div class="row pt-2 pb-2 pass-wrapper">
              <div class="col-1 mr-2 mt-2">
                <span class="mdi mdi-lock-outline mt-4"></span>
              </div>
              <div class="col-9">
                <span class="row list-header">Change password</span>
              </div>
              <div class="col-1 cursor-pointer" (click)="openConfirmPassword($event)">
                <span class="mdi mdi-chevron-right mt-4"></span>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-9 col-md-12">
        <mat-card appearance="outlined" class="mb-4 custom-card">
          <mat-card-content>
            <div class="row pass-wrapper">
              <div class="col-1 mr-2 pt-2">
                <span class="mdi mdi-two-factor-authentication"></span>
              </div>
              <div class="col-9">
                <span class="row list-header">Enable two-factor auth</span>
                @if (!!!formControl2FA.value) {
                  <span class="row list-description">Protect the Dynamoney account with an extra layer of security</span>
                }
                @if (!!formControl2FA.value) {
                  <span class="row list-description">2FA is a good way to add an extra layer of security to your Dynamoney account to make sure that only you have the ability to log in</span>
                }
              </div>
              <div class="col-1">
                <slide-toggle  [showCancelConfirmation]="false"
                  [formControl]="formControl2FA">
                </slide-toggle>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <mat-divider [inset]="true"></mat-divider>
  </div>

  <div class="row">
    <div class="col-12"
      fxLayoutGap="10px"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayout.lt-md="column"
      fxLayoutAlign.lt-md="center start">
      <button mat-stroked-button color="primary"
        class="w-lt-md-100"
      (click)="onCancel($event);">Cancel</button>
      <button mat-flat-button color="primary"
        class="w-lt-md-100"
        [disabled]="!allowProfileEdit || isLoadingProfile == true || formGroup1.invalid"
      (click)="onSaveProfile($event);">{{isLoadingProfile ? 'Saving Profile' : 'Save Profile'}}</button>
    </div>
  </div>
</ng-template>
