import {Component, Inject} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  DirectPayment,
  DirectPaymentTransactionDialogData, DirectPaymentTransactionDialogResult
} from '@portal-workspace/grow-shared-library';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { NgClass, AsyncPipe } from '@angular/common';

@Component({
  templateUrl: './direct-payment-transaction.dialog.html',
  styleUrls: ['./direct-payment-transaction.dialog.scss'],
  standalone: true,
    imports: [MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, FlexModule, MatButtonModule, MatOptionModule, NgClass, AsyncPipe, MatDialogModule]
})
export class DirectPaymentTransactionDialog {
  // formControlAmount!: FormControl<number | null>;
  formControlType!: FormControl<'direct-debit' | 'direct-credit' | null>;
  formControlDate!: FormControl<string | null>;
  formControlAmount!: FormControl<number | null>;
  formControlBsb!: FormControl<string | null>;
  formControlAccountNumber!: FormControl<string | null>;
  formControlAccountTitle!: FormControl<string | null>;
  formControlReference!: FormControl<string | null>;
  formGroup!: FormGroup;
  directPayment: DirectPayment | undefined;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DirectPaymentTransactionDialogData,
              private dialogRef: MatDialogRef<DirectPaymentTransactionDialog, DirectPaymentTransactionDialogResult>,
              private formBuilder: FormBuilder) {
    this.directPayment = this.data?.directPayment;
    this.formControlType = this.formBuilder.control(this.directPayment?.type ?? null, [Validators.required]);
    this.formControlDate = this.formBuilder.control(this.directPayment?.date ?? null, [Validators.required]);
    this.formControlAmount = this.formBuilder.control(this.directPayment?.amount ?? null, [Validators.required]);
    this.formControlBsb = this.formBuilder.control(this.directPayment?.bsb ?? null, [Validators.required]);
    this.formControlAccountNumber = this.formBuilder.control(this.directPayment?.accountNumber ?? null, [Validators.required]);
    this.formControlAccountTitle = this.formBuilder.control(this.directPayment?.accountTitle ?? null, [Validators.required]);
    this.formControlReference = this.formBuilder.control(this.directPayment?.reference ?? null, [Validators.required]);
    this.formGroup = this.formBuilder.group({
      type: this.formControlType,
      date: this.formControlDate,
      amount: this.formControlAmount,
      bsb: this.formControlBsb,
      accountNumber: this.formControlAccountNumber,
      accountTitle: this.formControlAccountTitle,
      reference: this.formControlReference,
    })
  }

  onSubmit(confirmation: boolean) {
    const directPaymentValue = this.directPayment ? {
        id: this.directPayment?.id ?? 0, // with id - edit; id == 0, new
        ...this.formGroup.value
      } : this.formGroup.value;
    this.dialogRef.close({
      readyForSubmission: confirmation,
      directPayment: directPaymentValue
    })
  }
}
