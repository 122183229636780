import {Component, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  AggregatorSearchComponentValue,
  AssetSelectionComponentValue,
  AzureStorageDocument,
  BusinessNumberSearchValue,
  BusinessSearchValue,
  CustomerApplicantTypeValue,
  DocumentTag,
  GetUserSameCompanyFunc,
  IndividualGuarantor, InsurancePremiumApplication,
  Metadata,
  NotNullable, OriginatorBusiness, UploadAzureFiles, UploadFileValue,
  UserSameCompanySelectionValue,
  UserSelectionValue,
  yesNoToBoolean,
  getAbn, GetSalesforceContactSameAccountFunc, SalesforceContactSameAccountSelectionValue, constants,
  isExternalUser,
} from '@portal-workspace/grow-shared-library';
import {
  BusinessNumberSearchFn,
  AnnualRevenueValue,
  applicationToAdditionalBrokerCorrespondent,
  applicationToHasAdditionalBrokerCorrespondent,
  BusinessSearchFn,
  GetUsersFunc,
  toInteflowAddress,
  AggregatorSearchFn,
  UserSameCompanySelectionComponent,
  AggregatorSearchComponent,
  BusinessNumberSearchComponent,
  applicationToBrokerSearchValue,
  applicationToApplicantType,
  formControlErrorKeys,
  formControlErrorMessage, applicationToPrivacyConfirmation,
  setStepper2StepConfig, SalesforceContactSameAccountSelectionComponent,
  applicationToBrokerContact,
  duplicateFileNameValidator,
  maxFileUploadValidator
} from '@portal-workspace/grow-ui-library';
import {
  AbnComponentValue,
  AcnComponentValue,
  isApplicationApplicantIndividual, isApplicationApplicantsSoleTrader, MobileValue,
  SelectContactValue,
  UploadedFiles,
  YesNoValue
} from '@portal-workspace/grow-shared-library';
import {Observable, of, Subscription} from 'rxjs';
import {
  applicantsThatAreGuarantor,
  applicantsThatAreIndividual,
  applicationToAbn,
  applicationToAcn,
  applicationToApplicants,
  applicationToApplicationNotes,
  applicationToAuthorisedSignatories,
  applicationToBusinessLandline,
  applicationToBusinessSearchValue,
  applicationToIndustrySector,
  applicationToLegalName,
  applicationToOperatesInCommercialPremise,
  applicationToOrganisationType,
  applicationToPolicies,
  applicationToPrimaryAddress,
  applicationToPrimaryContact,
  applicationToPrimaryIndustry,
  applicationToRevenue,
  createAbnInputMask,
  createAcnInputMask,
  createCurrencyInputMask,
  createEmailInputMask,
  createPhoneNumberInputMask,
  fromApplicantToInteflowCommercialEntities,
  fromApplicantToInteflowIndividualsData, fromContactToInteflowContacts,
  getUser,
  toInteflowEntityTypes,
  toInteflowLegalName,
  applicantsToPrimaryContact,
  setupUntilDestroy,
} from '@portal-workspace/grow-ui-library';
import {
  booleanToYesNo,
  filesToBase64Files, filterOutUniquePrimaryContacts,
  isAddressEquals,
  patchAcn
} from '@portal-workspace/grow-shared-library';
import {delay, map, skip, tap} from 'rxjs/operators';
import {EntityTypeCompanyOption, EntityTypeOtherOption, EntityTypePartnershipOption, EntityTypes, EntityTypeSoleTraderOption, EntityTypeTrustOption, EntityTypeValue} from '@portal-workspace/grow-shared-library';
import { PolicyComponentCalcFn as CalcFn, } from '@portal-workspace/grow-ui-library';
import {PolicyValue} from '@portal-workspace/grow-shared-library';
import { ContactValue } from '@portal-workspace/grow-shared-library';

import {AuthorisedSignatoryValue} from '@portal-workspace/grow-shared-library';
import _ from 'lodash';
import {InsurancePremiumSummary, defaultDocuments} from '@portal-workspace/grow-shared-library';
import {PrimaryIndustrySelectionValue} from '@portal-workspace/grow-shared-library';
import {SecondaryIndustrySelectionValue} from '@portal-workspace/grow-shared-library';
import { CdkStepper, CdkStepperModule } from '@angular/cdk/stepper';
import {ApplicationDialogService} from '@portal-workspace/grow-ui-library';
import {
  ApplicationService,
} from '../../service/application.service';
import {
  Application,
  BrokerOfUser,
  GeoLocation,
  RatecardDetails,
  UpdateApplicationData
} from '@portal-workspace/grow-shared-library';
import {ActivatedRoute, Router} from '@angular/router';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ApplicationApplicant, Base64File, User} from '@portal-workspace/grow-shared-library';
import {PortalHotToastService} from '@portal-workspace/grow-ui-library';
import {AppCalculator, TermRate} from '@portal-workspace/grow-shared-library';
import {UploadAzureFilesValue} from '@portal-workspace/grow-shared-library';
import {UploadedFilesListComponentEvent} from '@portal-workspace/grow-ui-library';
import {Address2ComponentValue} from '@portal-workspace/grow-shared-library';
import {navigationUrlForApplications, navigationUrlForNewApplication} from '../../service/navigation-urls';
import { AuthService } from '../../service/auth.service';
import {DriverLicenceUpload} from '../asset-finance/asset-finance.page';
import { InsurancePremiumSummaryComponent } from '@portal-workspace/grow-ui-library';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { UploadFilesMultiTagDialogComponent } from '@portal-workspace/grow-ui-library';
import { UploadFileComponent } from '@portal-workspace/grow-ui-library';
import { UploadedFilesListComponent } from '@portal-workspace/grow-ui-library';
import { MessageBoxComponent } from '@portal-workspace/grow-ui-library';
import { SelectContactComponent } from '@portal-workspace/grow-ui-library';
import { AuthorisedSignatoryComponent } from '@portal-workspace/grow-ui-library';
import { ApplicationHeaderSegmentComponent } from '@portal-workspace/grow-ui-library';
import { MemberComponent } from '@portal-workspace/grow-ui-library';
import { DirectorComponent } from '@portal-workspace/grow-ui-library';
import { PartnerComponent } from '@portal-workspace/grow-ui-library';
import { SoleTraderComponent } from '@portal-workspace/grow-ui-library';
import { TrusteeComponent } from '@portal-workspace/grow-ui-library';
import { MobileComponent } from '@portal-workspace/grow-ui-library';
import { CustomAddressComponent } from '@portal-workspace/grow-ui-library';
import { SecondaryIndustrySelectionComponent } from '@portal-workspace/grow-ui-library';
import { PrimaryIndustrySelectionComponent } from '@portal-workspace/grow-ui-library';
import { YesNoComponent } from '@portal-workspace/grow-ui-library';
import { AnnualRevenueComponent } from '@portal-workspace/grow-ui-library';
import { AcnComponent } from '@portal-workspace/grow-ui-library';
import { AbnComponent } from '@portal-workspace/grow-ui-library';
import { EntityTypeComponent } from '@portal-workspace/grow-ui-library';
import { PolicyComponent } from '@portal-workspace/grow-ui-library';
import { UserSelectionComponent } from '@portal-workspace/grow-ui-library';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MarkDirective } from '@portal-workspace/grow-ui-library';
import { BusinessSearchComponent } from '@portal-workspace/grow-ui-library';
import { NgTemplateOutlet } from '@angular/common';
import { ApplicationStepper2Component } from '@portal-workspace/grow-ui-library';
import { CustomerApplicantComponent } from '@portal-workspace/grow-ui-library';
import {environment} from '../../../environments/environment';

// export type FrontBackDriverLicenceUpload = {id: string, name: string, formControlFront: FormControl, formControlBack: FormControl};
export type FrontBackDriverLicenceUpload = {id: string, name: string, formControl: FormControl<UploadAzureFilesValue>};


// NOTE: Page to land when you are in DRAFT (new unsubmitted) insurance premium application
@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './insurance-premium.page.html',
    styleUrls: ['./insurance-premium.page.scss'],
    standalone: true,
    imports: [
      ApplicationStepper2Component,
      BusinessNumberSearchComponent,
      CdkStepperModule,
      NgTemplateOutlet,
      FormsModule,
      ReactiveFormsModule,
      BusinessSearchComponent,
      MarkDirective,
      MatCheckboxModule,
      MatTooltipModule,
      UserSelectionComponent,
      PolicyComponent,
      EntityTypeComponent,
      AbnComponent,
      AcnComponent,
      AnnualRevenueComponent,
      YesNoComponent,
      PrimaryIndustrySelectionComponent,
      SecondaryIndustrySelectionComponent,
      CustomAddressComponent,
      MobileComponent,
      TrusteeComponent,
      SoleTraderComponent,
      PartnerComponent,
      DirectorComponent,
      MemberComponent,
      ApplicationHeaderSegmentComponent,
      AuthorisedSignatoryComponent,
      SelectContactComponent,
      MessageBoxComponent,
      UploadedFilesListComponent,
      UploadFileComponent,
      UploadFilesMultiTagDialogComponent,
      MatFormFieldModule,
      MatInputModule,
      InsurancePremiumSummaryComponent,
      UserSameCompanySelectionComponent,
      AggregatorSearchComponent,
      CustomerApplicantComponent,
      SalesforceContactSameAccountSelectionComponent,
    ]
})
export class InsurancePremiumPage implements OnInit {

  @ViewChild('uploadFilesMultiTagDialogComponent') uploadFilesMultiTagDialogComponent!: UploadFilesMultiTagDialogComponent;
  
  businessSearchPlaceholder:string="Enter name or ABN…"

  existingApplication: InsurancePremiumApplication | null = null; // will exists if there is a draft
  rateCard: RatecardDetails | null = null;

  subscriptions: Subscription[] = [];


  defaultDocuments = defaultDocuments;

  errorKeys = formControlErrorKeys;
  errorMessages = formControlErrorMessage;

  // global:
  ip: string = '';
  geoLocation: GeoLocation = {};
  // user!: User;
  user: User = getUser()!;
  isExternalUser : boolean = isExternalUser(this.user);
  otherUsersObservable?: Observable<NotNullable<UserSelectionValue>[]>;
  brokerOfUser: BrokerOfUser | null = null;
  brokerApplicationId: string | null = null;
  applicationId: number | null = null;
  acceptedQuotation = false;

  // global: step 5 onwards
  organisationName: string = ''
  terms: TermRate | null = null;          // available on / after step2
  calculator: AppCalculator | null = null;   // available on / after step2

  // after popup confirmation
  businessUseOnly = false;
  selfServiceability = false;
  // privacyConfirmation = false;


  // STEP 1:
  step1SearchFn!: BusinessSearchFn;
  // step1GetUsersFn!: GetUsersFunc;
  // noOtherUsersInCompany = true;
  step1GetUserSameCompanyFn!: GetUserSameCompanyFunc;
  step1GetSalesforceContactSameAccountFn!: GetSalesforceContactSameAccountFunc;
  step1BrokerSearchFn!: AggregatorSearchFn;
  step1OrgSalesforceId: string | null = null;
  step1NoOtherUsersInCompany = true;
  formControlStep1Broker!: FormControl<AggregatorSearchComponentValue>;

  formGroupStep1!: FormGroup<{
    broker: FormControl<AggregatorSearchComponentValue>,
    business: FormControl<BusinessSearchValue>,
    showCorrespondent: FormControl<boolean | null>,
    correspondent: FormControl<UserSelectionValue>,
    brokerContact:FormControl<SalesforceContactSameAccountSelectionValue>
  }>;
  formControlStep1Business!: FormControl<BusinessSearchValue>;
  formControlStep1ShowCorrespondent!: FormControl;
  formControlStep1Correspondent!: FormControl<UserSameCompanySelectionValue>;
  formControlStep1BrokerContact!: FormControl<SalesforceContactSameAccountSelectionValue>;
  // formControlStep1PreviousBusiness!: FormControl<BusinessNumberSearchValue>;

  // STEP 1_b:
  formGroupStep1_b!: FormGroup<{
    customerApplicantType: FormControl<CustomerApplicantTypeValue>;
    privacyStatementCheck: FormControl<boolean | null>;
  }>;
  formControlStep1_bCustomerApplicantType!: FormControl<CustomerApplicantTypeValue>;
  formControlStep1_bPrivacyStatementCheck!: FormControl<boolean | null>;

  // STEP 2:
  step2CalcFn!: CalcFn;
  step2MonthlyInstallment: number | null = null;
  step2BrokerageFee: number | null = null;
  step2DocFee: number | null = null;  // application fee
  formControlStep2Policies!: FormControl<PolicyValue>;
  formGroupStep2!: FormGroup<{
    policies: FormControl<PolicyValue>
  }>;

  // STEP 3:
  step3OnwardsOrganisationType: EntityTypes | null = null;
  formControlStep3OrganisationType!: FormControl<EntityTypeValue>;
  formControlStep3Abn!: FormControl<AbnComponentValue>;
  formControlStep3Acn!: FormControl<AcnComponentValue>;
  formControlStep3Revenue!: FormControl<AnnualRevenueValue>;
  formControlStep3OperateInCommercialPremises!: FormControl<YesNoValue>;
  formControlStep3PrimaryIndustry!: FormControl<PrimaryIndustrySelectionValue>;
  formControlStep3IndustrySector!: FormControl<SecondaryIndustrySelectionValue>;
  formControlStep3PrimaryBusinessAddress!: FormControl<Address2ComponentValue>;
  formControlStep3BusinessLandline!: FormControl<MobileValue>;
  formGroupStep3!: FormGroup<{
    organisationType: FormControl<EntityTypeValue>,
    abn: FormControl<AbnComponentValue>,
    acn: FormControl<AcnComponentValue>,
    revenue: FormControl<AnnualRevenueValue>,
    operateInCommercialPremises: FormControl<YesNoValue>,
    primaryIndustry: FormControl<PrimaryIndustrySelectionValue>,
    industrySector: FormControl<SecondaryIndustrySelectionValue>,
    primaryBusinessAddress: FormControl<Address2ComponentValue>,
    businessLandline: FormControl<MobileValue>,
  }>;

  // STEP 4:
  step4BusinessSearchFn!: BusinessSearchFn;
  step4BusinessNumberSearchFn!: BusinessNumberSearchFn;
  formControlStep4Applicants!: FormControl<ApplicationApplicant>;
  formGroupStep4!: FormGroup<{
    applicants: FormControl<ApplicationApplicant>,
  }>;

  // STEP 6:
  step6TotalNumberOfIndividuals:number = 0;
  formControlStep6AuthorisedSignatories!: FormControl<AuthorisedSignatoryValue>;
  formGroupStep6!: FormGroup<{
    authorisedSignatories: FormControl<AuthorisedSignatoryValue>,
  }>;

  // STEP 7:
  formControlStep7PrimaryContact!: FormControl<SelectContactValue>;
  step7PredefinedContacts: ContactValue[] = []
  formGroupStep7!: FormGroup<{
    primaryContact: FormControl<SelectContactValue>,
  }>;


  // STEP 8:
  step8DriverLicencesRequired: FrontBackDriverLicenceUpload[] = [];
  step8UploadedDocs: AzureStorageDocument[] = [];
  step9UploadedDocs: AzureStorageDocument[] = [];
  step9DeletedUploadedDocs: Exclude<AzureStorageDocument[], null> = [];
  step8UploadedDrDoc: AzureStorageDocument[] = [];
  step8DeletedUploadedDocs: Exclude<AzureStorageDocument[], null> = [];
  formGroupStep8!: FormGroup<{}>;
  step8And9FilesUploadedToAzure: Base64File[] = [];

  // STEP 9:
  formControlStep9OtherSupportingDocuments!: FormControl<UploadAzureFilesValue>;
  formGroupStep9!: FormGroup<{
    otherSupportingDocuments: FormControl<UploadAzureFilesValue>,
  }>;

  // STEP 10:
  formControlStep10ApplicationNotes!: FormControl<string | null>;
  formGroupStep10!: FormGroup<{
    applicationNotes: FormControl<string | null>,
  }>;

  // STEP 11:
  step11Summary!: InsurancePremiumSummary;
  formGroupStep11!: FormGroup<{}>;

  showAddressFormFlag = false
  documentTypes: DocumentTag[] = [];


  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private toastService: PortalHotToastService,
              private applicationService: ApplicationService,
              private router: Router,
              private applicationDialogService: ApplicationDialogService,
              private authService: AuthService) {
  }

  async ngOnInit() {
    setupUntilDestroy(this)
    this.existingApplication = (this.route.snapshot.data as any).application;
    this.rateCard = (this.route.snapshot.data as any).ratecard;
    console.log('Resolved [InsurancePremium] application', this.existingApplication);
    if (this.existingApplication) {
      if (this.existingApplication.ApplicationType != 'InsurancePremium') {
        this.toastService.error(`This application ${this.existingApplication.ApplicationId} is not an Insurance Premium application`, `Invalid application type`);
      }
    }

    // get user
    // this.user = getUser()!;

    this.initStep1();
    this.initStep1_b();
    this.initStep2();
    this.initStep3();
    this.initStep4();
    this.initStep6();
    this.initStep7();
    this.initStep8();
    this.initStep9();
    this.initStep10();
    this.initStep11();
    this.populate();

    // get ip
    const sub1 = this.applicationService.getIpAddress().pipe(
      map(r => {
        if (r.status) {
          this.ip = r.payload;
          console.log('**** ip', this.ip);
        }
      })
    ).subscribe();
    this.subscriptions.push(sub1);

    // get geolocation
    try {
      const geoLocation = await this.applicationService.getGeoLocation();
      if (geoLocation) {
        this.geoLocation = geoLocation;
      }
      console.log('**** geolocation', geoLocation);
    } catch(err) {
      alert(err);
    }

    // get broker of user
    const sub2 = this.applicationService.getBrokerOfUser(this.user.UserId).pipe(
      tap(r => {
        if (r.status) {
          this.brokerOfUser = r.payload;
        }
      })
    ).subscribe();
    this.subscriptions.push(sub2);
  }

  populate() {
    if (this.existingApplication) {
      // 1
      this.formControlStep1Business.setValue(applicationToBusinessSearchValue(this.existingApplication));
      this.formControlStep1ShowCorrespondent.setValue(applicationToHasAdditionalBrokerCorrespondent(this.existingApplication));
      this.formControlStep1Correspondent.setValue(applicationToAdditionalBrokerCorrespondent(this.existingApplication));
      this.brokerApplicationId = this.existingApplication.BrokerAppId;
      this.applicationId = this.existingApplication.ApplicationId;
      this.acceptedQuotation = this.existingApplication.AcceptQuotation;
      this.organisationName = applicationToLegalName(this.existingApplication) ?? '';
      const brokerSearchValue = applicationToBrokerSearchValue(this.existingApplication);
      this.formControlStep1Broker.setValue(brokerSearchValue);
      this.step1OrgSalesforceId = brokerSearchValue?.salesforceId ?? null;
      this.formControlStep1BrokerContact.setValue(applicationToBrokerContact(this.existingApplication))

      // 2
      this.formControlStep2Policies.setValue(applicationToPolicies(this.existingApplication));

      // 3
      this.formControlStep3OrganisationType.setValue(applicationToOrganisationType(this.existingApplication));
      this.formControlStep3Abn.setValue(applicationToAbn(this.existingApplication));
      this.formControlStep3Acn.setValue(applicationToAcn(this.existingApplication));
      this.formControlStep3Revenue.setValue(applicationToRevenue(this.existingApplication));
      this.formControlStep3OperateInCommercialPremises.setValue(applicationToOperatesInCommercialPremise(this.existingApplication));
      this.formControlStep3PrimaryIndustry.setValue(applicationToPrimaryIndustry(this.existingApplication));
      this.formControlStep3IndustrySector.setValue(applicationToIndustrySector(this.existingApplication));
      this.formControlStep3PrimaryBusinessAddress.setValue(applicationToPrimaryAddress(this.existingApplication));
      this.formControlStep3BusinessLandline.setValue(applicationToBusinessLandline(this.existingApplication));

      // 4
      {
        const v = applicationToApplicants(this.existingApplication);
        this.formControlStep4Applicants.setValue(v);
        if (this.formControlStep4Applicants.value !== null) {
          let propertyOwner: any
          let residentialAddress: any
          // if (this.formControlStep4Applicants.value.kind === 'SoleTrader') {
          if (isApplicationApplicantsSoleTrader(this.formControlStep4Applicants.value)) {
            propertyOwner = this.formControlStep4Applicants.value.propertyOwner;
            residentialAddress = this.formControlStep4Applicants.value.residentialAddress;
          }
          else {
            const applicant = this.formControlStep4Applicants.value;
            for (let i = 0; i <= applicant.length - 1; i++) {
              const currentApplicant = applicant[i];
              if (isApplicationApplicantIndividual(currentApplicant)) {
                propertyOwner = currentApplicant.propertyOwner;
                residentialAddress = currentApplicant.residentialAddress;
              }
            }
          }
          if (propertyOwner) {
            if (propertyOwner.propertyOwner) {
              if (isAddressEquals(residentialAddress, propertyOwner.address)) {
                this.showAddressFormFlag = false;
              }
            }
          }
        }

      }

      // 6
      {
        const v = applicationToAuthorisedSignatories(this.existingApplication);
        this.formControlStep6AuthorisedSignatories.setValue(v);
      }

      // 7
      this.formControlStep7PrimaryContact.setValue(applicationToPrimaryContact(this.existingApplication));

      // 8-10
      this.formControlStep10ApplicationNotes.setValue(applicationToApplicationNotes(this.existingApplication));
      this.listUploadedDocuments();
      this.documentTypes = this.existingApplication.DocumentTypes ?? [];

    } else {  // not prepopulating ie. creating new application

      // auto populate originator business if we have one
      const orignatorBusinessId = this.user?.OriginatorBusinessId;
      // const aggregatorId = this.user?.AggregatorId;
      if (orignatorBusinessId) {
        this.subscriptions.push(this.applicationService.getOriginatorBusinessById(orignatorBusinessId).pipe(
          tap(r => {
            if (r.payload) {
              const originatorBusiness: OriginatorBusiness = r.payload;
              const v: AggregatorSearchComponentValue = {
                abn: originatorBusiness.ABN,
                entityName: originatorBusiness.EntityName,
                salesforceId: originatorBusiness.SalesforceId,
              }
              this.formControlStep1Broker.setValue(v);
            }
          })
        ).subscribe());
      }
      this.documentTypes = this.applicationDefaultDocuments(false);
    }
  }

  initStep1() {
    this.step1BrokerSearchFn = (term)=> {
      return this.applicationService.searchBroker2(term).pipe(map(r => r.payload));
    }
    this.formControlStep1Business = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1Broker = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1ShowCorrespondent = this.formBuilder.control(false)
    this.formControlStep1Correspondent = this.formBuilder.control(null);
    this.formControlStep1BrokerContact = this.formBuilder.control(null, [Validators.required]);
    this.formGroupStep1 = this.formBuilder.group({
      broker: this.formControlStep1Broker,
      business: this.formControlStep1Business,
      showCorrespondent: this.formControlStep1ShowCorrespondent,
      brokerContact:this.formControlStep1BrokerContact,
      correspondent: this.formControlStep1Correspondent,
    });
    // this.step1SearchFn = (search: string) => {
    //   return this.applicationService.searchCompanyByName(search).pipe(map(r => r.payload));
    // }
    this.step1SearchFn = this.applicationService.businessSearchFn;
    this.step1GetUserSameCompanyFn = (orgSalesforceId: string) => {
      return this.authService.getOtherUsersInCompanyBySalesforceIdFn(orgSalesforceId).pipe(
        map(r => {
          // exclude this user
          const filteredCorrespndentUserList =  r.filter(u => u.UserId != this.user.UserId);
          this.step1NoOtherUsersInCompany = filteredCorrespndentUserList.length == 0;
          return filteredCorrespndentUserList;
        })
      );
    }
    this.step1GetSalesforceContactSameAccountFn = this.authService.getSalesforceContactSameAccountFn;
    this.subscriptions.push(this.formControlStep1Broker.valueChanges.pipe(
      tap(broker => {
        if (broker) {
          this.step1OrgSalesforceId = broker.salesforceId;
          this.step1GetUserSameCompanyFn(this.step1OrgSalesforceId).pipe().subscribe();
        }
      })
    ).subscribe());
    this.subscriptions.push(this.formControlStep1ShowCorrespondent.valueChanges.pipe(
      tap((r: Boolean)=> {
        if(!r){
          this.formControlStep1Correspondent.setValue(null)
        }
      })
    ).subscribe())
    this.subscriptions.push(this.formControlStep1Business.valueChanges.pipe(
      tap((r: BusinessSearchValue) => {
        if (r && r.type === 'search-result') { // NOTE: we can deduce only when business is an ASIC/ABN search
          if (r.result?.entityType) {
            const description = r.result.entityType.entityDescription;
            const code = r.result.entityType.entityTypeCode;

            if ('IND' == code) {
              this.formControlStep3OrganisationType.setValue(EntityTypeSoleTraderOption);
            } else if (description && description.toLowerCase().indexOf('trust') >= 0) {
              this.formControlStep3OrganisationType.setValue(EntityTypeTrustOption);
            } else if (description && description.toLowerCase().indexOf('company') >= 0) {
              this.formControlStep3OrganisationType.setValue(EntityTypeCompanyOption);
            } else if (description && description.toLowerCase().indexOf('partnership') >= 0) {
              this.formControlStep3OrganisationType.setValue(EntityTypePartnershipOption);
            } else {
              this.formControlStep3OrganisationType.setValue(EntityTypeOtherOption);
            }
          }
        }
      })
    ).subscribe());

    const sub = this.formControlStep1Business.valueChanges.pipe(
      tap((r: BusinessSearchValue) => {
        if (r) {
          if (r) {
            if (r.abn && this.formControlStep3Abn) {
              this.formControlStep3Abn.setValue(r.abn);
            }
            if (r.acn && this.formControlStep3Acn) {
              const acn = patchAcn(r.acn);
              this.formControlStep3Acn.setValue(acn ?? null);
            }

            if(r.abn && this.existingApplication){
              if(r.abn !== getAbn(this.existingApplication)){
                this.formControlStep1_bCustomerApplicantType.setValue(null);
                this.formControlStep1_bPrivacyStatementCheck.setValue(null);
              }
              else{
                const userApplicantDetails = this.existingApplication.AppInfo;
                if (userApplicantDetails) {
                  if (userApplicantDetails.MetApplicant !== undefined) {
                    this.formControlStep1_bCustomerApplicantType.setValue(applicationToApplicantType(this.existingApplication));
                  }
                  if (userApplicantDetails.PrivacyConfirmation !== undefined) {
                    this.formControlStep1_bPrivacyStatementCheck.setValue(applicationToPrivacyConfirmation(this.existingApplication));
                  }
                }
              }
            }
            this.organisationName = toInteflowLegalName(r) ?? '';
          }
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);

    setStepper2StepConfig(this.formGroupStep1, {
      nextStepClickedFn: (stepper) => {
        stepper.next();
      },
      previousStepClickedFn: async (stepper) => {
        if(this.applicationId){
          await this.router.navigate(navigationUrlForApplications({reload: true }));
        }else {
          await this.router.navigate(navigationUrlForNewApplication());
        }
      }
    });
  }

  initStep1_b(){
    this.formControlStep1_bCustomerApplicantType = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1_bPrivacyStatementCheck = this.formBuilder.control(null, [Validators.required]);
    this.formGroupStep1_b = this.formBuilder.group({
      customerApplicantType: this.formControlStep1_bCustomerApplicantType,
      privacyStatementCheck: this.formControlStep1_bPrivacyStatementCheck,
    })

    const sub = this.formControlStep1_bPrivacyStatementCheck.valueChanges.pipe(
      tap((r) =>{
          if (r === false) {
            this.formControlStep1_bPrivacyStatementCheck.setValue(null);
          }
      })
    ).subscribe()
    this.subscriptions.push(sub);
    setStepper2StepConfig(this.formGroupStep1_b, {
      nextStepButtonText:'Next',
      nextStepClickedFn: async stepper => {
         stepper.next();
      }
    });

  }

  initStep2() {
    this.step2CalcFn = (total,  paidBy) => {
      // todo: calculate monthly installment, application fee, estimated drawdown date etc. form ratecard

      const loanTerms = Number(paidBy);
      const totalAmount = total;

      const calculator = new AppCalculator();
      const terms = calculator.getTotalInterestRateForInsurancePremiumFinance(this.rateCard!, {totalPremiumAmount: totalAmount});
      this.terms = terms;
      this.calculator = calculator;

      const interestRate = terms.totalInterest / 100;
      const interestAmount = interestRate * totalAmount;

      const docFee = terms.docFee;
      const brokerageAmount = _.round((interestAmount * 20 / 100 ), 2);
      const repayment = _.round(totalAmount * (1  + interestRate) / loanTerms, 2);

      return of({
        monthlyInstallment: repayment,
        applicationFee: docFee,
        brokerage: brokerageAmount,
      }).pipe(
        tap(r => {
          // keep a copy of estimate drawdown date & monthly installment
          this.step2MonthlyInstallment = r.monthlyInstallment;
          this.step2DocFee = r.applicationFee;
          this.step2BrokerageFee = r.brokerage;
        })
      );
    };
    this.formControlStep2Policies = this.formBuilder.control(null, [Validators.required]);
    this.formGroupStep2 = this.formBuilder.group({
      policies: this.formControlStep2Policies,
    });

  }

  initStep3() {
    this.formControlStep3OrganisationType = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3Abn = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3Acn = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3Revenue = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3OperateInCommercialPremises = this.formBuilder.control(false, [Validators.required]);
    this.formControlStep3PrimaryIndustry = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3IndustrySector = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3PrimaryBusinessAddress = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3BusinessLandline = this.formBuilder.control('');
    this.formGroupStep3 = this.formBuilder.group({
      organisationType: this.formControlStep3OrganisationType,
      abn: this.formControlStep3Abn,
      acn: this.formControlStep3Acn,
      revenue: this.formControlStep3Revenue,
      operateInCommercialPremises: this.formControlStep3OperateInCommercialPremises,
      primaryIndustry: this.formControlStep3PrimaryIndustry,
      industrySector: this.formControlStep3IndustrySector,
      primaryBusinessAddress: this.formControlStep3PrimaryBusinessAddress,
      businessLandline: this.formControlStep3BusinessLandline,
    });
    const sub = this.formControlStep3OrganisationType.valueChanges.pipe(
      tap((r: EntityTypeValue) => {
        if (r) {
          // if (this.formControlStep4Applicants) {
          //   // this.formControlStep4Applicants.setValue(null);
          // }
          if(this.step3OnwardsOrganisationType && this.step3OnwardsOrganisationType !== r.type) {
            // the org type changes and is different from prev value
            this.formControlStep4Applicants.setValue(null)
          }
          this.step3OnwardsOrganisationType = r.type;
          if (r && r.type === 'company') {
            /* Not required to set it again as validation is already set */
            // this.formControlStep3Acn.setValidators([Validators.required]);
          } else {
            this.formControlStep3Acn.setValidators(null);
            this.formControlStep3Acn.updateValueAndValidity();
          }
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);
   setStepper2StepConfig(this.formGroupStep3, {
    nextStepClickedFn: async stepper => {
            if(this.formGroupStep3.value.businessLandline === null){
          this.applicationDialogService.openAlertDialog({
            message: `Error`,
            subMessage: 'Invalid bussiness landline'
          });
        }
        else {
          stepper.next();
        }
    }
  });

  }

  initStep4() {
    this.step4BusinessSearchFn = this.applicationService.businessSearchFn;
    this.step4BusinessNumberSearchFn = this.applicationService.businessNumberSearchFn;
    this.formControlStep4Applicants = this.formBuilder.control(null, [Validators.required]);
    this.formGroupStep4 = this.formBuilder.group({
      applicants: this.formControlStep4Applicants,
    });
    setStepper2StepConfig(this.formGroupStep4, {
      nextStepClickedFn: async stepper => {
        await this.saveApplication(stepper, 4);
      }
    });
  }

  initStep6() {
    this.formControlStep6AuthorisedSignatories = this.formBuilder.control(null, [Validators.required]);
    this.formGroupStep6 = this.formBuilder.group({
      authorisedSignatories: this.formControlStep6AuthorisedSignatories,
    });

    setStepper2StepConfig(this.formGroupStep6, {
      nextStepClickedFn: async stepper => {
        await this.saveApplication(stepper, 6);
        const applicants = this.formControlStep4Applicants.value;
        const individualApplicants = applicantsThatAreIndividual(applicants);
        this.step6TotalNumberOfIndividuals = individualApplicants.length
      }
    });
  }

  initStep7() {
    this.formControlStep7PrimaryContact = this.formBuilder.control(null, [Validators.required]);
    this.formGroupStep7 = this.formBuilder.group({
      primaryContact: this.formControlStep7PrimaryContact
    });

    setStepper2StepConfig(this.formGroupStep7, {
      stepSelectionEventFn: e => {
         // all individual applicants that are guarantor & authorised signatory can be primary contacts
          const possiblePrimaryContacts: ContactValue[] = [];
          const applicants: ApplicationApplicant = this.formControlStep4Applicants.value
          // individual can be primary contact
          if (applicants) {
            const c: ContactValue[] = applicantsToPrimaryContact(applicants);
            if(c) {
              possiblePrimaryContacts.push(...c);
            }
          }

          possiblePrimaryContacts.push(...applicantsThatAreGuarantor(this.formControlStep4Applicants.value).map( i => {
            const c: ContactValue = {
              isManual: false,
              email: i.email,
              mobileNumber: i.mobileNumber,
              areaCode: '',
              lastName: i.lastName,
              title: i.title,
              firstName: i.firstName,
              telephone: i.mobileNumber,
            }
            return c;
          }));
          possiblePrimaryContacts.push(...(this.formControlStep6AuthorisedSignatories.value ?? []).map((r: Exclude<AuthorisedSignatoryValue, null>[number]) => {
            const c: ContactValue = {
              isManual: false,
              email: r.email,
              mobileNumber: r.mobile,
              areaCode: '',
              lastName: r.lastName,
              title: r.title,
              firstName: r.firstName,
              telephone: r.mobile,
            }
            return c;
          }));
          this.step7PredefinedContacts = filterOutUniquePrimaryContacts(possiblePrimaryContacts);
          const filledPrimaryContact = this.formControlStep7PrimaryContact.value;
          const contactObj = this.step7PredefinedContacts.find(a =>
            a.email === filledPrimaryContact?.email && a.mobileNumber === filledPrimaryContact?.mobileNumber &&
            a.lastName === filledPrimaryContact?.lastName && a.firstName === filledPrimaryContact?.firstName &&
            a.title === filledPrimaryContact?.title);
          if (contactObj) {
            this.formControlStep7PrimaryContact.setValue(contactObj);
          }
      },
      nextStepClickedFn: async stepper => {
        await this.saveApplication(stepper, 7);
      }
    });

  }

  initStep8(){
    this.formGroupStep8 = this.formBuilder.group({});
    setStepper2StepConfig(this.formGroupStep8, {
      nextStepButtonText:'Next',
      stepSelectionEventFn: e => {
          const allThatRequireDriverLicences = this.getAllThatRequireDriverLicences();

          const driverLicences: FrontBackDriverLicenceUpload[]  = [];
          for (const requireDriverLicence of allThatRequireDriverLicences) {
            const id = `${_.lowerCase(requireDriverLicence.firstName)}}${_.lowerCase(requireDriverLicence.lastName)}`;
            const existingEntry = this.step8DriverLicencesRequired.find(e => e.id === id);
            if (existingEntry) {
              driverLicences.push(existingEntry);
            } else {
              const name = `${requireDriverLicence.firstName} ${requireDriverLicence.lastName}`;
              const skipValidation = (this.step8UploadedDocs && this.step8UploadedDocs.length);
              const formControl = this.formBuilder.control(null,
                skipValidation ? [] : [Validators.required, duplicateFileNameValidator(),maxFileUploadValidator(2)]);
              driverLicences.push({id, name, formControl});
              (this.formGroupStep8 as FormGroup).addControl(name, formControl);
            }
          }
          this.step8DriverLicencesRequired = driverLicences;
      },
      nextStepClickedFn: async stepper => {
        const checkUploadFileMoreThenTwo = this.step8DriverLicencesRequired.filter(formArg=> {
          if(formArg.formControl.value && formArg.formControl.value.length > 0){
            const existingEntry  = this.step8UploadedDrDoc.filter(arg =>  arg.metadata && arg.metadata.driverslicencename && arg.metadata.driverslicencename === formArg.name)
            if (existingEntry &&  (existingEntry.length + formArg.formControl.value.length) > 2) {
              return true
            }else {
              return false;
            } 
          }else {
            return false
          }
      })
      if(checkUploadFileMoreThenTwo.length > 0){
        const fileNamesArray = checkUploadFileMoreThenTwo.map(item => item.name).join(", ");
        const validationMessage = `You have uploaded too many Driver Licence files (${fileNamesArray}). Please upload only up to two files.`;
        this.applicationDialogService.openAlertDialog({
          message: `Validation Error`,
          subMessage: validationMessage
        });
        
      }else {
        await this.saveApplication(stepper, 8);
      }
        // await this.saveApplication(stepper, 8);
      }
    });

  }

  // other supporting doc
  initStep9() {
    this.formControlStep9OtherSupportingDocuments = this.formBuilder.control(null,[duplicateFileNameValidator(),maxFileUploadValidator(2)]);
    this.formGroupStep9 = this.formBuilder.group({
      otherSupportingDocuments: this.formControlStep9OtherSupportingDocuments,
    });


    setStepper2StepConfig(this.formGroupStep9, {
      nextStepButtonText:'Next',
      nextStepClickedFn: async stepper => {
        const isMissingTagsCheck = this.formGroupStep9.value.otherSupportingDocuments?.filter(file => file.tags && file.tags.length === 0) ;
        if(isMissingTagsCheck?.length){
          this.applicationDialogService.openAlertDialog({
            message: `Validation Error`,
            subMessage: "Please check the following validation issue: Some files are missing tags."
          });
          
        }else {
          await this.saveApplication(stepper, 9);
        }
      }
    });
  }

  // application note
  initStep10() {
    this.formControlStep10ApplicationNotes = this.formBuilder.control(null);
    this.formGroupStep10 = this.formBuilder.group({
      applicationNotes: this.formControlStep10ApplicationNotes,
    });


    setStepper2StepConfig(this.formGroupStep10, {
      nextStepButtonText:'Next to Review',
      nextStepClickedFn: async stepper => {
        await this.saveApplication(stepper, 10);
      }
    });
  }

  initStep11(){
    this.formGroupStep11 = this.formBuilder.group({ });

    setStepper2StepConfig(this.formGroupStep11, {
      nextStepButtonText:'Submit',
      stepSelectionEventFn: e => {
          this.step11Summary = {
            monthlyRepayment: String(this.step2MonthlyInstallment) ?? '',
            applicationId: String(this.applicationId),


            organisation: this.formControlStep1Business.value,
            organisationType: this.formControlStep3OrganisationType.value,
            addtionalBrokerCorrespondent: this.formControlStep1Correspondent.value,
            abn: this.formControlStep3Abn.value!,
            acn: this.formControlStep3Acn.value!,

            annualRevenue: this.formControlStep3Revenue.value!,
            operateInCommercialPremises: this.formControlStep3OperateInCommercialPremises.value,
            primaryIndustry: this.formControlStep3PrimaryIndustry.value,
            industrySector: this.formControlStep3IndustrySector.value,
            primaryBusinessAddress: this.formControlStep3PrimaryBusinessAddress.value,
            businessLandline: this.formControlStep3BusinessLandline.value!,

            policies: this.formControlStep2Policies.value,

            applicants: this.formControlStep4Applicants.value ?? [],

            authorisedSignatories: this.formControlStep6AuthorisedSignatories.value,

            primaryContact: this.formControlStep7PrimaryContact.value!,

            applicationNotes: this.formControlStep10ApplicationNotes.value!,
            brokerContact:this.formControlStep1BrokerContact.value!
          };
      },
      nextStepClickedFn: async stepper => {
        this.finalSubmission(stepper, this.step11Summary!);
      }
    });
  }


  finalSubmission(stepper: CdkStepper, summary: InsurancePremiumSummary) {
    // todo: perform submission
    this.applicationDialogService.openInsurancePremiumApplicationConfirmationDialog()
      .afterClosed().pipe(
      tap(async r => {
        if (r && r.readyForSubmission) {
          this.businessUseOnly = false;
          this.selfServiceability = true;
          // this.privacyConfirmation = true;
          await this.sendFinalSubmission(stepper);
        }
      })
    ).subscribe();
  }

  async sendFinalSubmission(stepper: CdkStepper) {
    if (this.applicationId) {
      const data = await this.mapToInteflowData(true);

      // update driver licence doc metadata - as the individualId will be changed
      if (this.applicationId) {
        this.subscriptions.push(
          this.applicationService.listApplicationDocumentFn(this.applicationId).subscribe(
            (r: AzureStorageDocument[]) => {
              const driverLicenceDocs = r.filter(obj => Object.values(obj?.tags ?? {}).includes('driverslicence'));
              for (const doc of driverLicenceDocs) {
                const individual = data.Individuals.find(obj => (doc.metadata?.driverslicencename ?? '') == `${obj.GivenName ?? ''} ${obj.SurName ?? ''}`);
                if (individual && individual.id) {
                  this.applicationService.updateDocumentMetadataFn(doc.name, "", {
                    ...doc.metadata,
                    individualid: individual.id ?? ''
                  }).subscribe()
                }
              }
            }
          )
        )
      }

      const sub = this.applicationService.submitInsurancePremium(this.applicationId, data).pipe(
        this.toastService.spinnerObservable(),
        tap(r => {
          if (r && r.status) {
            this.applicationDialogService.openApplicationSubmittedDialog().afterClosed().pipe(
              tap(async r => {
                stepper.reset();
                stepper.next();
                await this.router.navigate(navigationUrlForApplications({reload: true }), {queryParams: {reload:true}});
              })
            ).subscribe();
          } else {
            this.applicationDialogService.openAlertDialog({
              message: `Error`,
              subMessage: r.message
            });
          }
        })
      ).subscribe();
      this.subscriptions.push(sub);
    }
  }


  private async saveApplication(stepper: CdkStepper, step: number) {
    if (!this.applicationId) { // application was not saved before (new draft)
      const inteflowData = await this.mapToInteflowData();
      this.applicationService.saveApplication(inteflowData).pipe(
        // this.toastService.toastObserver('save draft'),
        this.toastService.spinnerObservable(),
        tap(r => {
          const applicationId = r.payload.ApplicationId;
          const brokerApplicationId = r.payload.BrokerApplicationId;
          this.brokerApplicationId = brokerApplicationId;
          this.applicationId = applicationId;
          const business: BusinessSearchValue = this.formControlStep1Business.value
          if (business) {
            this.organisationName = business.organisationName;
          }
          stepper.next();
        })
      ).subscribe();
    } else {  // existing draft
      const inteflowData = await this.mapToInteflowData();
      console.log('**** inteflowdata', inteflowData);
      await this.updateDraftInBg(step);
      stepper.next();
    }
  }


  private async updateDraftInBg(step: number, fn?: ()=>void) {
    if (this.applicationId) {
      const data = await this.mapToInteflowData();
      await this.uploadFileToAzure(step, data);
      const sub = this.applicationService.updateApplication(this.applicationId, data).pipe(
        fn ? this.toastService.loadingWithMessage(`Saving, please be patient`) : tap(()=>{}),
        tap(r => {
          if (fn) {
            fn();
          }
        })
      ).subscribe();
      this.subscriptions.push(sub);
    }
  }

  async uploadFileToAzure(step: number, data: UpdateApplicationData) {
    let base64Files: Base64File[] = [];
    let allToBeDeleted: string[] = [];
    let allFiles: File[] = [];
    if (step === 8) { // driver's licence
      const driverLicencesUpload: DriverLicenceUpload[] = this.step8DriverLicencesRequired;
      allToBeDeleted = [...this.step8DeletedUploadedDocs].map(file => file.name);
      if (driverLicencesUpload && driverLicencesUpload.length) {
        const fileArr = driverLicencesUpload.map(upload => {
          if(upload.formControl.value) {
            return upload.formControl.value.map((f) => {
              const individual = data.Individuals.find(obj => upload.name == `${obj.GivenName ?? ''} ${obj.SurName ?? ''}`);
              (f as any).tags = ['driverslicence'];
              (f as any).metadata =  individual?.id ? {
                ...this.step9OtherDocMetadata,
                individualid: individual.id,
                driverslicencename: `${individual?.GivenName ?? ''} ${individual?.SurName ?? ''}`,
              } : this.step9OtherDocMetadata;
              return f;
            })
          }else {
            return []
          }
        });
        allFiles = fileArr.map((f: File[]) => f && f.length ? f[0] : null).filter((f: File | null) => !!f) as File[];
      }

    } else if (step === 9) { // other supporting doc
      const otherSupportingDocs: UploadFileValue = this.formControlStep9OtherSupportingDocuments.value;
      allToBeDeleted = [...this.step9DeletedUploadedDocs].map(file => file.name);
      if (otherSupportingDocs && otherSupportingDocs.length) {
        allFiles = [...otherSupportingDocs];
      }
    } else {
      return;
    }
    base64Files = [...await filesToBase64Files(allFiles)];
    // double check if any changes with existing files
    // remove file that has been uploaded
    base64Files = base64Files.map(file => {
      file.metadata = file.tags?.includes('driverslicence') ? file.metadata : this.step9OtherDocMetadata
      return file;
    }).filter(f => {
      const fileIdentifiers = this.step8And9FilesUploadedToAzure.map(m => `${m.name}${m.tags?.join(' ')}`);
      return !fileIdentifiers.includes(`${f.name}${f.tags?.join(' ')}`);
    })

    this.step8And9FilesUploadedToAzure = [...this.step8And9FilesUploadedToAzure, ...base64Files];

    if (this.applicationId) {
      this.subscriptions.push(
        (await this.applicationService.uploadApplicationDocumentToAzureStorage(
            this.applicationId,
            [...base64Files],
            allToBeDeleted)
        ).subscribe(r =>{
          if(step === 8){
            this.listUploadedDocuments()
            this.step8DriverLicencesRequired.map(arg=>{
              arg.formControl.setValue(null)
              arg.formControl.removeValidators([Validators.required])
              arg.formControl.updateValueAndValidity(); 
            })
          }else if(step === 9){
            this.uploadFilesMultiTagDialogComponent.files = []
            this.uploadFilesMultiTagDialogComponent.filesWithTags = []
            this.uploadFilesMultiTagDialogComponent.formControl.setValue(null)
            this.listUploadedDocuments()
            this.formControlStep9OtherSupportingDocuments.setValue(null)
          }
        })
      );
    }
  }

  async mapToInteflowData(finalSubmission = false) {

    const user = getUser();
    const b: BusinessSearchValue = this.formControlStep1Business.value;
    const correspondentDetails: UserSelectionValue = this.formControlStep1Correspondent.value;
    const broker: AggregatorSearchComponentValue = this.formControlStep1Broker.value;
    const o: EntityTypeValue = this.formControlStep3OrganisationType.value;
    const applicationNotes = this.formControlStep10ApplicationNotes.value;
    const contact: SelectContactValue = this.formControlStep7PrimaryContact.value;
    const policies: PolicyValue = this.formControlStep2Policies.value;
    const abn = this.formControlStep3Abn.value;
    const acn = this.formControlStep3Acn.value;
    const applicants: ApplicationApplicant = this.formControlStep4Applicants.value;
    const userApplicant: CustomerApplicantTypeValue = this.formControlStep1_bCustomerApplicantType.value;

     // workout broker salesforce id
     let brokerSalesforceId = broker!.salesforceId;
     let brokerAbn = broker!.abn;
     let brokerEntityName = broker!.entityName;
     if (user!.BrokerSalesforceId === environment.GrowFinanceGroupSalesforceId) {
       if (broker && broker.salesforceId) {
         brokerSalesforceId = broker.salesforceId;
         brokerAbn = broker.abn;
         brokerEntityName = broker.entityName;
       } else if (this.brokerOfUser && this.brokerOfUser.SalesforceId) {
         brokerSalesforceId = this.brokerOfUser.SalesforceId;
         brokerAbn = this.brokerOfUser.ABN;
         brokerEntityName = this.brokerOfUser.EntityName;
       }
     }
     else {
       if (broker) { // broker dropdown selection is being populated
         // use the declared brokerSalesforceId, brokerAbn and brokerEntityName
       } else if (this.brokerOfUser && this.brokerOfUser.SalesforceId !== undefined) {
         // broker drop down selection is not being populated, we populate it with the current user's company details
         brokerSalesforceId = this.brokerOfUser.SalesforceId;
         brokerAbn = this.brokerOfUser.ABN;
         brokerEntityName = this.brokerOfUser.EntityName;
       }
     }

    // workout disclosure
    let disclosed = true;
    let introducerGroup = 'ECLIPXCOMMERCIAL';
    if (this.brokerOfUser) {
      if (this.brokerOfUser.Relationship !== 'disclosed') {
        disclosed = false;
        introducerGroup = 'UNDISCLOSED_ECLIPXCOMMERCIAL';
      }
    }

    // work out legalName & entityTYpe
    let legalName = toInteflowLegalName(b) ?? undefined;
    let entityType = o ? toInteflowEntityTypes(o.type) : undefined;

    // work out industries
    const primaryIndustry: PrimaryIndustrySelectionValue = this.formControlStep3PrimaryIndustry.value;
    const secondaryIndustry: SecondaryIndustrySelectionValue = this.formControlStep3IndustrySector.value;
    const revenue = this.formControlStep3Revenue.value;
    const operateOnCommercialPremises: boolean = this.formControlStep3OperateInCommercialPremises.value ?? false;
    const landline = this.formControlStep3BusinessLandline.value;
    const phoneNumber = landline ? landline.substring(2).trim() : undefined;
    const areaCode = landline ? landline.substring(0, 2).trim() : undefined;
    const address: Address2ComponentValue = this.formControlStep3PrimaryBusinessAddress.value;

    // pricing details
    const loanTerm = (policies?.paidByMonthlyInstallment != null ? Number(policies.paidByMonthlyInstallment) : undefined);


    // bureau report / company details
    const companyDetails = (b && b.type === 'search-result' && b?.result) ?
      b.result :
      this.existingApplication?.CompanyDetails ? this.existingApplication.CompanyDetails : undefined;



    // entities
    const primaryCommercialEntity = {
      LegalName: legalName ?? undefined,
      EntityType: entityType,
      ABN: abn ?? undefined,
      ACN: _.isEmpty(acn) ? '000000000' : acn ?? undefined,
      TradingName: '',
      Type: 'Primary',
      PrimaryIndustry: primaryIndustry?.division ?? undefined,
      IndustrySector: secondaryIndustry?.code ?? undefined,
      Revenue: revenue ? Number(revenue) : undefined,
      OperateatCommercialAddressFlag: operateOnCommercialPremises,
      PhoneNumber: phoneNumber,
      AreaCode: areaCode,
      BusinessCategory: 'SME ($1m+ Revenue | $0.5m to $5m Capital)',
      PrinciplePlaceofBusiness: toInteflowAddress(address),
    }

    if(entityType!=='PTY'){
      delete primaryCommercialEntity.ACN
    }

    // commercial entities
    const otherCommercialEntities = fromApplicantToInteflowCommercialEntities(applicants);
    const otherCommercialEntity = [
      ...otherCommercialEntities,
    ];


    // individuals
    const applicantIndividuals = fromApplicantToInteflowIndividualsData(applicants);
    const otherIndividuals = [
      ...applicantIndividuals,
    ];


    // file tags
    const otherSupportingDocs: UploadAzureFilesValue = this.formControlStep9OtherSupportingDocuments.value;
    const driverLicencesUpload: FrontBackDriverLicenceUpload[] = this.step8DriverLicencesRequired;
    const allToBeDeleted = [...this.step8DeletedUploadedDocs,...this.step9DeletedUploadedDocs].map(file => file.name);
    let allFiles: File[] = [];
    if (driverLicencesUpload && driverLicencesUpload.length) {
      const fileArr = driverLicencesUpload.map(upload => {
        if(upload.formControl.value){
          return upload.formControl.value.map((f) => {
            const individual = otherIndividuals.find(obj => upload.name == `${obj.GivenName ?? ''} ${obj.SurName ?? ''}`);
            (f as any).tags = ['driverslicence'];
            (f as any).metadata = individual?.id ? {
              individualid: individual?.id,
              driverslicencename: `${individual?.GivenName ?? ''} ${individual?.SurName ?? ''}`,
              ...this.step9OtherDocMetadata
            } : this.step9OtherDocMetadata;
            return f;
          })
        }else {
          return []
        }
    });
      allFiles = fileArr.map((f: File[]) => f && f.length ? f[0] : null).filter((f: File | null) => !!f) as File[];
    }
    if (otherSupportingDocs && otherSupportingDocs.length) {
      allFiles = [...allFiles, ...otherSupportingDocs];
    }
    allFiles = [...allFiles].filter(file => !allToBeDeleted.includes(file?.name));

    // get doc types
    let uploadedTags = allFiles.reduce((tags: string[], file) => [...tags, ...((file as any).tags ?? [])], []);
    uploadedTags = [...uploadedTags, ...this.step8UploadedDocs.reduce((tags: string[], file: AzureStorageDocument) => [...tags, ...Object.values(file?.tags ?? {})], [])];
    uploadedTags = [...new Set(uploadedTags)];
    console.log('=====uploadedTags: ', uploadedTags);
    for (const tag of uploadedTags) {
      if (!this.documentTypes.map(t => t.value).includes(tag)) {
        const tagObj = (Object.values(constants.documentTypes) as DocumentTag[]).find(obj => obj.value === tag);
        if (tagObj) {
          this.documentTypes.push(tagObj);
        }
      }
    }
    console.log('====updated defaultTags: ', this.documentTypes);

    const apiBodyPricingDetails = {
      LoanTerm: loanTerm,
      BrokerageAmount: policies?.brokerage ?? undefined,
      DocFee: policies?.applicationFee ?? undefined,
      TotalAmount: policies?.total ?? undefined,
      PaymentPeriod: 'Monthly',
      DocFeeFinanced: booleanToYesNo(false),
      Rate: this.terms?.totalInterest ?? undefined,
      Repayment: policies?.monthlyInstallment ?? undefined,
    }

    const apiBodyIndividuals  = [
      ...otherIndividuals
    ];

    const apiBodyCommercialEntities = [
      primaryCommercialEntity,
      ...otherCommercialEntity,
    ];

    const apiBodyPolicies =  policies?.policies.map( p => {
      return {
        typeOfCover: p.coverType?.ProductName ?? '',
        policyNumber: p.policyNumber ?? '',
        insurer: p.insurer ?? '',
        inceptionDate: p.inceptionDate?.format('DD/MM/YYYY'),
        expiryDate: p.expiryDate?.format('DD/MM/YYYY'),
        premiumAmount: p.premiumAmount ?? 0,
        invoiceNumber: p.invoiceNumber ?? '',
      };
    }) ?? undefined;

    const apiBodyContact = fromContactToInteflowContacts(contact);

    const apiBodyAppInfo = {
      BrokerAppID: this.brokerApplicationId ?? undefined,
      IntroducerGroup: introducerGroup,
      Disclosed: disclosed,
      FinanceType: 'Insurance Premium',
      UserId: user!.UserId,
      CompanyId: user!.OriginatorBusinessId,
      UserEmail: user!.Email,
      UserFirstName: user!.GivenName,
      UserLastName: user!.FamilyName,
      Notes: applicationNotes ?? undefined,
      BusinessUse: booleanToYesNo(this.businessUseOnly),
      SelfServiceability:  booleanToYesNo(this.selfServiceability),
      PrivacyConfirmation: booleanToYesNo(this.formControlStep1_bPrivacyStatementCheck.value ?? null),
      BrokerflowDocumentID: undefined,
      BrokerSalesforceID: brokerSalesforceId,
      BrokerAbn: brokerAbn,
      BrokerEntityName: brokerEntityName,
      AssetCategory: undefined,
      AssetType: undefined,
      ContactId: undefined,
      CustomerId: undefined,
      SalesforceId: undefined,
      StageName: 'Draft',
      MetApplicant: userApplicant ? userApplicant.type : undefined,
    };

    const authorisedSignatories: AuthorisedSignatoryValue = this.formControlStep6AuthorisedSignatories.value;
    const apiBodyAuthorisedSignatory = (authorisedSignatories)?.map(a => {
      return {
        Role: a?.role?.name ?? '',
        Title: a?.title?.type ?? '',
        GivenName: a.firstName ?? '',
        SurName: a.lastName ?? '',
        MiddleName: a.middleName ?? '',
        Email: a.email ?? '',
        MobileNumber: a.mobile ?? '',
      }
    }) ?? undefined;


    // broker contact
    const brokerContact: SalesforceContactSameAccountSelectionValue = this.formControlStep1BrokerContact.value;

    const apiBody: UpdateApplicationData = {
      ApplicationType: 'InsurancePremium',
      IP: this.ip,
      ApplicantLocation: this.geoLocation,
      UserId: this.applicationService.getApplicationUserId(this.existingApplication,this.user),
      CompanyId: this.applicationService.getApplicationCompanyId(this.existingApplication,this.user),
      AppInfo: apiBodyAppInfo,
      Contacts: apiBodyContact,
      Policies: apiBodyPolicies,
      CommercialEntities: apiBodyCommercialEntities,
      Individuals: apiBodyIndividuals,
      PricingDetails: apiBodyPricingDetails,
      AuthorisedSignatory: apiBodyAuthorisedSignatory,
      AcceptQuotation: this.acceptedQuotation,
      ApplicationNotes: applicationNotes ?? undefined,
      CompanyDetails: companyDetails ?? undefined,
      AdditionalBrokerCorrespondent: correspondentDetails ?? null,
      BrokerContact: brokerContact,
      //Documents: apiBodyDocuments,
      DocumentTypes: this.documentTypes,
      AssetSpec: undefined,
      Reference: undefined,
    }
    return apiBody;
  }

  onStep9DeleteUploadedDoc($event: UploadedFilesListComponentEvent) {
    this.step9DeletedUploadedDocs = [...$event.deleted];
  }

  onStep8DeleteUploadedDoc($event: UploadedFilesListComponentEvent) {
     
    this.step8UploadedDrDoc = this.step8UploadedDrDoc.filter(file => {
      // Check if the file should be kept in the array
      return !(file.metadata && 
               file.metadata.driverslicencename && 
               $event.deletedFile.metadata && 
               $event.deletedFile.metadata.driverslicencename && 
               $event.deletedFile.metadata.driverslicencename === file.metadata.driverslicencename);
    });

    this.step8DeletedUploadedDocs = [...$event.deleted];
    const allThatRequireDriverLicences = this.getAllThatRequireDriverLicences();

    for (const requireDriverLicence of allThatRequireDriverLicences) {
      const id = `${_.lowerCase(requireDriverLicence.firstName)}}${_.lowerCase(requireDriverLicence.lastName)}`;
      const existingEntry = this.step8DriverLicencesRequired.find(e => e.id === id);
      if (existingEntry && $event.deletedFile && $event.deletedFile.metadata && $event.deletedFile.metadata.driverslicencename && $event.deletedFile.metadata.driverslicencename === existingEntry.name) {
        const isDriverslicenceExist  = $event.existing.filter(arg =>  arg.metadata && arg.metadata.driverslicencename && arg.metadata.driverslicencename === existingEntry.name)
        if(isDriverslicenceExist.length === 0){
          existingEntry.formControl.addValidators([Validators.required, duplicateFileNameValidator(),maxFileUploadValidator(2)])
          existingEntry.formControl.updateValueAndValidity(); 
        }
      }
    }
  }

 /*  onStep8DeleteUploadedDoc($event: any) { // UploadedFilesListComponentEvent
    this.step8DeletedUploadedDocs = [...$event.deleted];
  } */

  get step9OtherDocMetadata() {
    const b: BusinessSearchValue = this.formControlStep1Business.value;
    const abn = this.formControlStep3Abn.value ?? '';
    const legalName = toInteflowLegalName(b) ?? '';

    return {
      abn: abn,
      legalname: legalName
    };
  }

  getAllThatRequireDriverLicences() {
    const applicants = this.formControlStep4Applicants.value;
    const individualApplicants = applicantsThatAreIndividual(applicants);
    return individualApplicants;
  }

  onStep9UploadOtherDoc(files: UploadAzureFiles) {
    console.log("files",files)
    if(files){
      this.formControlStep9OtherSupportingDocuments.setValue(files)
    }
 
  }

  onStep9DeleteSupportingDoc(index: number) {
    const supportingDocs = this.formControlStep9OtherSupportingDocuments.value;
    if (supportingDocs) {
      supportingDocs.splice(index, 1);
      this.formControlStep9OtherSupportingDocuments.setValue([...supportingDocs]);
    }
  }

  applicationDefaultDocuments(includeOptions = false): DocumentTag[] {
    const applicants = this.formControlStep4Applicants.value;
    const individualApplicants = applicantsThatAreIndividual(applicants);
    const names = individualApplicants
      .map(requireDriverLicence => `${requireDriverLicence.firstName} ${requireDriverLicence.lastName}`);
    return this.defaultDocuments(
      'InsurancePremium',
      names,
      // this.formControlStep3OrganisationType.value === EntityTypeTrustOption
      toInteflowLegalName(this.formControlStep1Business.value) ?? "",
      "",
      "",
      (this.formControlStep2Policies.value as PolicyValue)?.total,
      0,
      false,
      this.formControlStep3OrganisationType.value === EntityTypeTrustOption,
      this.formControlStep3OrganisationType.value === EntityTypePartnershipOption,
      includeOptions
    );
  }

  skipFileUploadValidation() {
    return (this.step8UploadedDocs && this.step8UploadedDocs.length > 0);
  }

  listUploadedDocuments() {
    if (this.applicationId) {
      this.subscriptions.push(
        this.applicationService.listApplicationDocumentFn(this.applicationId).subscribe(
          (r: AzureStorageDocument[]) => {
            const driverslicenceDoc  = r.filter(arg =>  arg.metadata && arg.metadata.driverslicencename)
            const supportDoc  = r.filter(arg =>  arg.metadata && !arg.metadata.driverslicencename)
            this.step8UploadedDocs = driverslicenceDoc;
            this.step9UploadedDocs = supportDoc;
            this.step8UploadedDrDoc  = driverslicenceDoc
            console.log("=====uploaded driverslicenceDoc docs: ", driverslicenceDoc);
            console.log("=====uploaded supportDoc docs: ", supportDoc);
          }
        )
      )
    }
  }
}
