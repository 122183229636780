<div class="customer-analysis">
  <div class="mt-4">
    <div class="mat-h4-custom">Monthly Revenue</div>
    @if (data && data.Revenue) {
      <monthly-revenue-table [data]="data.Revenue"></monthly-revenue-table>
      <div class="mt-4">
        <monthly-revenue-chart [data]="data.Revenue"></monthly-revenue-chart>
      </div>
    }
  </div>
  <div class="mt-4">
    <div class="mat-h4-custom">Dishonours</div>
    @if (data && data.Dishonours) {
      @if (!isEmpty(data.Dishonours, 'dishonours')) {
        <dishonours-table [data]="data.Dishonours"></dishonours-table>
        <div class="mat-h4-custom sub-title">Dishonour Transactions</div>
        <transaction-table [data]="data.Dishonours.details" [type]="'dishonour'"></transaction-table>
      }
      <!--      For testing-->
      <!--      <ng-container *ngIf="data?.Summary">-->
      <!--        <dishonours-table [data]="data.Overdrawn"></dishonours-table>-->
      <!--        <div class="mat-h4 sub-title">Dishonour Transactions</div>-->
      <!--        <transaction-table [data]="data.Summary[2].details" [type]="'dishonour'"></transaction-table>-->
    <!--      </ng-container>-->
    @if (isEmpty(data.Dishonours, 'dishonours')) {
      <span class="notes">No dishonour transactions.</span>
    }
  }
</div>
<div class="mt-4">
  <div class="mat-h4-custom">Overdrawn</div>
  @if (data && data.Overdrawn) {
    @if (!isEmpty(data.Overdrawn, 'overdrawn')) {
      <overdrawn-table [data]="data.Overdrawn"></overdrawn-table>
      <div class="mat-h4-custom sub-title">Overdrawn Transactions</div>
      <transaction-table [data]="data.Overdrawn.details" [type]="'overdrawn'"></transaction-table>
    }
    <!--      For testing-->
    <!--      <ng-container *ngIf="data?.Summary">-->
    <!--        <overdrawn-table [data]="data.Overdrawn"></overdrawn-table>-->
    <!--        <div class="mat-h4 sub-title">Overdrawn Transactions</div>-->
    <!--        <transaction-table [data]="data.Summary[2].details" [type]="'overdrawn'"></transaction-table>-->
  <!--      </ng-container>-->
  @if (isEmpty(data.Overdrawn, 'overdrawn')) {
    <span class="notes">No overdrawn transactions.</span>
  }
}
</div>
<div class="mt-4">
  <div class="mat-h4-custom">Non Preferred Lender Summary</div>
  @if (data && data.LenderList) {
    @if (!isEmpty(data.LenderList, 'non-preferred-lender')) {
      <non-preferred-lender-summary [data]="data.LenderList"></non-preferred-lender-summary>
      <div class="mat-h4-custom sub-title">Non Preferred Lender Transactions</div>
      <transaction-table [data]="data.LenderList.details" [type]="'non-preferred-lender'"></transaction-table>
    }
    <!--      For testing-->
    <!--      <ng-container *ngIf="data?.Summary">-->
    <!--        <non-preferred-lender-summary [data]="data.LenderList"></non-preferred-lender-summary>-->
    <!--        <div class="mat-h4 sub-title">Non Preferred Lender Transactions</div>-->
    <!--        <transaction-table [data]="data.Summary[2].details" [type]="'non-preferred-lender'"></transaction-table>-->
  <!--      </ng-container>-->
  @if (isEmpty(data.LenderList, 'non-preferred-lender')) {
    <span class="notes">No relationship with non-preferred lenders.</span>
  }
}
</div>
<div class="mt-4">
  <div class="mat-h4-custom">Monthly Debits / Credits</div>
  @if (data && data.debitcredit) {
    <monthly-debits-credits-table [data]="data.debitcredit"></monthly-debits-credits-table>
    <div class="mt-4">
      <monthly-debits-credits-chart [data]="data.debitcredit"></monthly-debits-credits-chart>
    </div>
  }
</div>
<div class="mt-4">
  <div class="mat-h4-custom">Categories</div>
  @if (data && data.Summary) {
    <categories-table [data]="data.Summary"
      [accountSummary]="data.AccountSummary"
      [debitCreditSummary]="data.debitcredit"
    [categoryByBankId]="data.SummaryByBankId"></categories-table>
  }
</div>
<div class="mt-4">
  <div class="mat-h4-custom">Full Transaction List</div>
  @if (data && data.Summary) {
    <full-transaction-list [data]="data.Summary" [accountSummary]="data.AccountSummary"></full-transaction-list>
  }
</div>
</div>
