<div class="corporate-loans-app-component">
  <mat-card appearance="outlined">
    <mat-tab-group [mat-stretch-tabs]="false" mat-align-tabs="start">
      <mat-tab label="APPLICANT"><div *ngTemplateOutlet="applicantTab;"></div></mat-tab>
      <!-- <mat-tab label="DIRECTORS"><div *ngTemplateOutlet="directorsTab"></div></mat-tab> -->
      <mat-tab label={{getOrganisationTypeForTitle(application)}}><div *ngTemplateOutlet="directorsTab"></div></mat-tab>
      <mat-tab label="GUARANTORS"><div *ngTemplateOutlet="guarantorTab"></div></mat-tab>
      <mat-tab label="PRIMARY CONTACT"><div *ngTemplateOutlet="primaryContactTab"></div></mat-tab>
      <mat-tab label="REFERENCE"><div *ngTemplateOutlet="referenceTab"></div></mat-tab>
      <mat-tab label="BACKGROUND"><div *ngTemplateOutlet="backgroundTab"></div></mat-tab>
    </mat-tab-group>
  </mat-card>
</div>

<ng-template #applicantTab>
  <div class="row separator header flex space-between align-center"
    fxLayout="row"
    fxLayoutGap="10px"
    fxLayoutAlign="space-between center"
    fxLayout.lt-md="column"
    fxLayoutAlign.lt-md="center start">
    @if (applicationStatus && applicationStatus==='Under Review') {
      <div class="col-md-3 under-review">
        <status-box type="under-review"></status-box>
      </div>
    } @else {
      <div></div>
    }
    <button mat-flat-button class="inactive ml-2 w-lt-md-100" (click)="onCopyApplication()">
      <span class="mdi mdi-content-copy" matPrefix></span>
      Copy This Application
    </button>
  </div>

  <div class="section">
  <div class="row">
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Application ID</div>
        <div body>{{getBrokerApplicationId(application)}}</div>
      </cell>
    </div>
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Salesforce ID</div>
        <div body>{{getAppSalesforceId(application)}}</div>
      </cell>
    </div>
    <div class="col-lg-6 col-md-12">
      <cell>
        <div label>Broker name</div>
        <div body>{{getAppInfoBrokerEntityName(application)}}</div>
      </cell>
    </div>
  </div>
  @if (isInternalUser()) {
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <cell>
          <div label>Submitter's name</div>
          <div body>
            <user-selection
              [getFunc]="getUsersInCompanyFn"
              (events)="emitChangeSubmitterEvent($event)"
              [companySalesforceId]="application.AppInfoBrokerSalesforceID"
              [userId]="application.UserId">
            </user-selection>
          </div>
        </cell>
      </div>
      @if (application.AdditionalBrokerCorrespondent) {
        <div class="col-lg-6 col-md-12">
          <cell>
            <div label>Additional Correspondent's name</div>
            <div body>
              <user-selection
                [getFunc]="getUsersInCompanyFn"
                (events)="emitChangeAdditionalCorrespondentEvent($event)"
                [companySalesforceId]="application.AppInfoBrokerSalesforceID"
                [userId]="application.AdditionalBrokerCorrespondent.UserId">
              </user-selection>
            </div>
          </cell>
        </div>
      }
    </div>
  }
  <mat-divider [inset]="true"></mat-divider>
  <div class="row">
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Facility Establishment Fee %</div>
        <div body>{{getFacilityFeePercent(application)}}<span class="mdi mdi-percent"></span></div>
      </cell>
    </div>
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Facility Establishment Fee</div>
        <div body><span class="mdi mdi-currency-usd"></span>{{getFacilityFee(application) | looseCurrency:'':''}}</div>
      </cell>
    </div>
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Facility Limit</div>
        <div body><span class="mdi mdi-currency-usd"></span>{{getLoanAmount(application) | looseCurrency:'':''}}</div>
      </cell>
    </div>
    @if (getBrokerFlowId(application)) {
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>BrokerFlow ID </div>
          <div body>{{getBrokerFlowId(application)}}</div>
        </cell>
      </div>
    }
  </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>


  <div class="section">
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Broker Contact</div>
          <div body>{{getBrokerContact(application)}}</div>
        </cell>
      </div>
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Company name</div>
          <div body>{{getCompanyName(application)}}</div>
        </cell>
      </div>
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Organisation type</div>
          <div body>{{getOrganisationType(application)}}</div>
        </cell>
      </div>
    </div>
    <mat-divider [inset]="true"></mat-divider>
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>ABN</div>
          <div body>{{getAbn(application)}}</div>
        </cell>
      </div>
      @if (getAcn(application)) {
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>ACN</div>
            <div body>{{getAcn(application)}}</div>
          </cell>
        </div>
      }
    </div>
    <mat-divider [inset]="true"></mat-divider>
    @if (getPreviousBusiness(application)) {
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <cell>
            <div label>Previous Business</div>
            <div body>{{getPreviousBusinessName(application)}}</div>
          </cell>
        </div>
        @if (getPreviousBusinessABN(application)) {
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Previous Business ABN</div>
              <div body>{{getPreviousBusinessABN(application)}}</div>
            </cell>
          </div>
        }
        @if (getPreviousBusinessACN(application)) {
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Previous Business ACN</div>
              <div body>{{getPreviousBusinessACN(application)}}</div>
            </cell>
          </div>
        }
      </div>
      <mat-divider [inset]="true"></mat-divider>
    }
  </div>


  <div class="section">
    <div class="row">
      <div class="col-12">
        <risk-analysis [application]="application"></risk-analysis>
      </div>
    </div>
  </div>


  <div class="section">
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <cell>
          <div label>Finance type</div>
          <div body>{{getFinanceType(application)}}</div>
        </cell>
      </div>
    </div>
  </div>

  <div class="section">

    <div class="row">
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Adverse on file</div>
          <div body>{{getAdverseOnFile(application)}}</div>
        </cell>
      </div>
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Property owner</div>
          <div body>{{getPropertyOwner(application)}}</div>
        </cell>
      </div>
    </div>
    <mat-divider [inset]="true"></mat-divider>
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Equifax Score</div>
          <div body>{{getEquifaxScoreAboveThreshold(application)}}</div>
        </cell>
      </div>
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Director Score</div>
          <div body>{{getDirectorScoreRate(application)}}</div>
        </cell>
      </div>
    </div>

    <mat-divider [inset]="true"></mat-divider>
    @if (getPropertyOwner(application) === 'No') {
      <div class="row" >
        <div class="col-lg-6 col-md-12">
          <cell>
            <div label>Any commercial loan in the past 3 years</div>
            <div body>{{getPreviousLoan(application)}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
    }
  </div>

  <div class="section">
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>LTV</div>
          <div body>{{getLtv(application)}}</div>
        </cell>
      </div>
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Security Type</div>
          <div body>{{getSecurityType(application)}}</div>
        </cell>
      </div>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>

  <div class="section">
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Revenue</div>
          <div body><span class="mdi mdi-currency-usd"></span>{{getRevenue(application) | looseCurrency:'':''}}</div>
        </cell>
      </div>
      <div class="col-lg-3 col-md-12">
        <!--cell>
        <div label>Limit request</div>
        <div body>{{getLimitRequest(application)}}</div>
      </cell-->
    </div>
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Commercial premise</div>
        <div body>{{getOperateInCommercialPremises(application)}}</div>
      </cell>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <cell>
        <div label>Primary industry</div>
        <div body>{{getApplicationPrimaryIndustry(application)}}</div>
      </cell>
    </div>
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Industry sector</div>
        <div body>{{getIndustrySector(application)}}</div>
      </cell>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <cell>
        <div label>Primary business address</div>
        <div body>{{getPrimaryBusinessAddress(application)}}</div>
      </cell>
    </div>
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Business landline</div>
        <div body><!--span>+61</span-->{{getBusinessLandline(application)}}</div>
      </cell>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>
</div>

@if (getApplicationNotes(application)) {
  <div class="section">
    <div class="row">
      <div class="col-12">
        <div class="mat-h3">Application notes</div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <cell>
          <div body>{{getApplicationNotes(application)}}</div>
        </cell>
      </div>
    </div>
  </div>
}
<mat-divider [inset]="true"></mat-divider>
</ng-template>

<ng-template #directorsTab>
  <individual-details type="all-non-guarantors" [application]="application"></individual-details>
</ng-template>
<ng-template #guarantorTab>
  <individual-details type="guarantors-only" [application]="application"></individual-details>
</ng-template>
<ng-template #primaryContactTab>
  <primary-contact-details [application]="application"></primary-contact-details>
</ng-template>
<ng-template #referenceTab>
  <references-details [application]="application"></references-details>
</ng-template>
<ng-template #backgroundTab>
  <corporate-loans-background-details [application]="application"></corporate-loans-background-details>
</ng-template>
