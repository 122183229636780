import {Component, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CorporateLoanRateCardValue,
  InsurancePremiumRateCardValue, MinMaxRateValue,
  RatecardDetails,
  UpdateRatecardInput,
} from '@portal-workspace/grow-shared-library';
import {
  AssetRateCardComponent,
  findInvalidControlsRecursive,
  formControlErrorKeys,
  formControlErrorMessage,
  nameIncludeDigitsWithoutBlankSpaceValidator,
} from '@portal-workspace/grow-ui-library';
import {AssetRateCardValue} from '@portal-workspace/grow-shared-library';
import {NonAssetRateCardComponent,} from '@portal-workspace/grow-ui-library';
import {NonAssetRateCardValue} from '@portal-workspace/grow-shared-library';
import { BusinessLoanRateCardComponent, } from '@portal-workspace/grow-ui-library';
import { BusinessLoanRateCardValue } from '@portal-workspace/grow-shared-library';
import { BusinessOverdraftRateCardComponent, } from '@portal-workspace/grow-ui-library';
import { BusinessOverdraftRateCardValue, ConsumerRateCardValue } from '@portal-workspace/grow-shared-library';
import { AdminService, } from '../../service/admin.service';
import { PortalHotToastService } from '@portal-workspace/grow-ui-library';
import { tap } from 'rxjs/operators';
import { navigationUrlForRateCardDetails, navigationUrlForRateCards } from '../../service/navigation-urls';
import {AssetTypeRateValue} from '@portal-workspace/grow-shared-library';
import {createAsyncStore, loadingFor} from '@ngneat/loadoff';
import {Subject, Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {ApplicationDialogService} from '@portal-workspace/grow-ui-library';
import {InsurancePremiumRateCardComponent} from '@portal-workspace/grow-ui-library';
import { Location, NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { ConsumerRateCardComponent } from '@portal-workspace/grow-ui-library';
import { InsurancePremiumRateCardComponent as InsurancePremiumRateCardComponent_1 } from '@portal-workspace/grow-ui-library';
import { BusinessOverdraftRateCardComponent as BusinessOverdraftRateCardComponent_1 } from '@portal-workspace/grow-ui-library';
import { BusinessLoanRateCardComponent as BusinessLoanRateCardComponent_1 } from '@portal-workspace/grow-ui-library';
import { MarkDirective } from '@portal-workspace/grow-ui-library/mark';
import { AssetRateCardComponent as AssetRateCardComponent_1 } from '@portal-workspace/grow-ui-library';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import {MatTabChangeEvent, MatTabsModule} from '@angular/material/tabs';
import { CorporateLoanRateCardComponent } from "@portal-workspace/grow-ui-library";

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './rate-card-detail.page.html',
    styleUrls: ['./rate-card-detail.page.scss'],
    standalone: true,
    imports: [
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    NgTemplateOutlet,
    FlexModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    AssetRateCardComponent_1,
    MarkDirective,
    BusinessLoanRateCardComponent_1,
    BusinessOverdraftRateCardComponent_1,
    InsurancePremiumRateCardComponent_1,
    ConsumerRateCardComponent,
    CorporateLoanRateCardComponent,
    AsyncPipe
]
})
export class RateCardDetailPage implements OnInit {

  triggerMark = new Subject<boolean>();

  subscriptions: Subscription[] = [];

  ratecards?: RatecardDetails[];

  errorKeys = formControlErrorKeys;
  errorMessages = formControlErrorMessage;

  loader = loadingFor('update', 'save');
  store = createAsyncStore();

  // showError = false
  errorTitle =  'Error Occurred!'
  errorMessage = 'Please try again.'

  // child components
  @ViewChild('assetRateCardComponent') assetRateCardComponent!: AssetRateCardComponent;
  @ViewChild('businessLoanRateCardComponent') businessLoanRateCardComponent!: BusinessLoanRateCardComponent;
  @ViewChild('businessOverdraftRateCardComponent') businessOverdraftRateCardComponent!: BusinessOverdraftRateCardComponent;
  @ViewChild('corporateLoanRateCardComponent') corporateLoanRateCardComponent!: CorporateLoanRateCardComponent;
  @ViewChild('insurancePremiumRateCardComponent') insurancePremiumRateCardComponent!: InsurancePremiumRateCardComponent;
  @ViewChild('consumerRateCardComponent') consumerRateCardComponent!: ConsumerRateCardComponent;

  retry(){
    this.ngOnInit();
  }
  formGroup: FormGroup<{
    rateCardName: FormControl<string | null>,
    assetFinanceRateCard:  FormControl<AssetRateCardValue>,
    businessLoanRateCard: FormControl<BusinessLoanRateCardValue>;
    businessOverdraftRateCard: FormControl<BusinessOverdraftRateCardValue>,
    corporateLoanRateCard: FormControl<CorporateLoanRateCardValue>,
    insurancePremiumRateCard: FormControl<InsurancePremiumRateCardValue>,
    consumerRateCard: FormControl<ConsumerRateCardValue>,
  }>;
  formControlRateCardName: FormControl<string | null>;
  formControlAssetFinanceRateCard:  FormControl<AssetRateCardValue>;
  formControlBusinessLoanRateCard: FormControl<BusinessLoanRateCardValue>;
  formControlBusinessOverdraftRateCard: FormControl<BusinessOverdraftRateCardValue>;
  formControlCorporateLoanRateCard: FormControl<CorporateLoanRateCardValue>;
  formControlInsurancePremiumRateCard: FormControl<InsurancePremiumRateCardValue>;
  formControlConsumerRateCard: FormControl<ConsumerRateCardValue>;


  constructor(private route: ActivatedRoute,
    private adminService: AdminService,
    private toastService: PortalHotToastService,
    private router: Router,
    private formBuilder: FormBuilder,
    private dialogService: ApplicationDialogService) {
    this.formControlRateCardName = formBuilder.control(null, [Validators.required, nameIncludeDigitsWithoutBlankSpaceValidator()]);
    this.formControlAssetFinanceRateCard = formBuilder.control(null, [Validators.required]);
    this.formControlBusinessLoanRateCard = formBuilder.control(null, [Validators.required]);
    this.formControlBusinessOverdraftRateCard = formBuilder.control(null, [Validators.required])
    this.formControlCorporateLoanRateCard = formBuilder.control(null, [Validators.required]);
    this.formControlInsurancePremiumRateCard = formBuilder.control(null, [Validators.required]);
    this.formControlConsumerRateCard = formBuilder.control(null, [Validators.required]);
    this.formGroup = this.formBuilder.group({
      rateCardName: this.formControlRateCardName,
      assetFinanceRateCard: this.formControlAssetFinanceRateCard,
      businessLoanRateCard: this.formControlBusinessLoanRateCard,
      businessOverdraftRateCard: this.formControlBusinessOverdraftRateCard,
      corporateLoanRateCard: this.formControlCorporateLoanRateCard,
      insurancePremiumRateCard: this.formControlInsurancePremiumRateCard,
      consumerRateCard: this.formControlConsumerRateCard,
    });
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.ratecards = (this.route.snapshot.data as any).ratecards;

    console.log('*** rateCard', this.ratecards);
    if (this.ratecards && this.ratecards.length) {
      const rateCardName = this.ratecards[0].Name;
      this.formControlRateCardName.setValue(rateCardName);
      for (const ratecard of this.ratecards) {
        switch (ratecard.ApplicationType) {
          case 'AssetFinance': {
            const v: AssetRateCardValue = {
              assetType: ratecard.AssetType,
              assetCategory: ratecard.AssetCategory,
              brokerage: ratecard.Brokerage,
              lowEquifax: ratecard.LowEquifaxScore,
              lowDepositUplift: ratecard.LowDepositUplift,
              gstAge: ratecard.ABNorGSTAge,
              adverseOnFile: ratecard.AdversOnFile,
              docFee: ratecard.DocFee,
              capRate: ratecard.CapRate,
              truck12T: ratecard.Truck12T,
              transactionTypeRate: ratecard.TransactionTypeRate,
              noAssetBacked: ratecard.NoAssetBacked,
              // privateSales: ratecard.PrivateSale,
              // privateSalesDocFee: ratecard.PrivateSaleDocFee,
              assetTier1: ratecard.AssetFinanceTier1,
              assetTier2: ratecard.AssetFinanceTier2,
              assetTier3: ratecard.AssetFinanceTier3,
              assetTier4: ratecard.AssetFinanceTier4,
              loanTerms: ratecard.LoanTerms,
              loanAmount: ratecard.LoanAmount,
            };
            this.formControlAssetFinanceRateCard.setValue(v);
            break;
          }
          case 'InsurancePremium': {
            const v: InsurancePremiumRateCardValue = {
              baseInterestRate: ratecard.BaseInterestRate,
              loanAmount: ratecard.LoanAmount,
              loanTerms: ratecard.LoanTerms,
              adverseOnFile: ratecard.AdversOnFile,
              lowEquifaxScore: ratecard.LowEquifaxScore,
              docFee: ratecard.DocFee,
            }
            this.formControlInsurancePremiumRateCard.setValue(v);
            break;
          }
          case 'Consumer': {
            const v: ConsumerRateCardValue = {
              carsAndCaravans: ratecard.CarsAndCaravans,
              motorbikes: ratecard.Motorbikes,
              solarSystems: ratecard.SolarSystems,
              hybrid: ratecard.Hybrid,
              adverseOnFile: ratecard.AdversOnFile,
              docFee: ratecard.DocFee,
              assetType: ratecard.AssetType,
              assetConditionUsed: ratecard.AssetConditionUsed,
              lowEquifaxScore: ratecard.LowEquifaxScore,
              eotAge: ratecard.EOTAge,
              loanTerms: ratecard.LoanTerms,
              privateSale: ratecard.PrivateSale,
              privateSaleDocFee: ratecard.PrivateSaleDocFee,
              noPropertyOwnership: ratecard.NoPropertyOwnership,
              monthlyAccountKeepingFee: ratecard.MonthlyAccountKeepingFee,
              maxBrokerRateDiscount: ratecard.MaxBrokerRateDiscount,
              creditAssitanceFee: ratecard.CreditAssitanceFee,
              ppsrFee: ratecard.PpsrFee,
            }
            this.formControlConsumerRateCard.setValue(v);
            break;
          }
          case 'BusinessLoans': {
            const v: BusinessLoanRateCardValue = {
              adverseOnFile: ratecard.AdversOnFile,
              docFee: ratecard.DocFee,
              brokerage: ratecard.Brokerage,
              loanAmount: ratecard.LoanAmount,
              loanTerms: ratecard.LoanTerms,
              gstAge: ratecard.ABNorGSTAge,
              propertyOwner: ratecard.PropertyOwner,
              noPropertyOwner: ratecard.NoPropertyOwner,
              directorScoreRate:ratecard.DirectorScoreRate,
              nonPropertyOwnerBetween500And600Rate: ratecard.NonPropertyOwnerBetween500And600Rate
            }
            this.formControlBusinessLoanRateCard.setValue(v);
            break;
          }
          case 'BusinessOverdraft': {
            const v: BusinessOverdraftRateCardValue = {
              propertyOwner: ratecard.PropertyOwner,
              noPropertyOwner: ratecard.NoPropertyOwner,
              facilityFee: ratecard.FacilityFee,
              brokerShareFacilityFee: ratecard.BrokerShareFacilityFee,
              docFee: ratecard.DocFee,
              directorScoreRate:ratecard.DirectorScoreRate,
              nonPropertyOwnerBetween500And600Rate: ratecard.NonPropertyOwnerBetween500And600Rate,
              rbaRate: ratecard.rbaRate,
            }
            this.formControlBusinessOverdraftRateCard.setValue(v);
            break;
          }
          case 'CorporateLoans': {
            const v: CorporateLoanRateCardValue = {
              propertyOwner: ratecard.PropertyOwner,
              noPropertyOwner: ratecard.NoPropertyOwner,
              facilityFee: ratecard.FacilityFee,
              brokerShareFacilityFee: ratecard.BrokerShareFacilityFee,
              docFee: ratecard.DocFee,
              directorScoreRate:ratecard.DirectorScoreRate,
              nonPropertyOwnerBetween500And600Rate: ratecard.NonPropertyOwnerBetween500And600Rate,
              rbaRate: ratecard.rbaRate,
              securityType: ratecard.SecurityType ?? null,
            }
            this.formControlCorporateLoanRateCard.setValue(v);
            break;
          }
        }
      }

      // make ratecard name changes across all tabs
      this.subscriptions.push(this.formControlRateCardName.valueChanges.pipe(
        tap(r => {
          this.formControlRateCardName.setValue(r, {onlySelf: true, emitEvent: false, emitModelToViewChange: true});
        })
      ).subscribe());
    }
  }

  private toAssetTypeStorageFormat(assetTypes: AssetTypeRateValue | undefined): AssetTypeRateValue {
    return (assetTypes ?? []).map(assetType => ({
      Cat: assetType.Cat,
      Type: `${assetType.Cat}-${assetType.Type}`,
      Rate: assetType.Rate,
    }));
  }

  private toInputData() {
    // NOTE: formGroup / formControls should be valid when this function is reached
    const assetRateCard: AssetRateCardValue = this.formControlAssetFinanceRateCard.value;
    const insuranceRateCard: InsurancePremiumRateCardValue = this.formControlInsurancePremiumRateCard.value;
    const consumerRateCard: ConsumerRateCardValue = this.formControlConsumerRateCard.value;
    const businessRateCard: BusinessLoanRateCardValue = this.formControlBusinessLoanRateCard.value;
    const businessOverdraftRateCard: BusinessOverdraftRateCardValue = this.formControlBusinessOverdraftRateCard.value;
    const corporateLoanRateCard: CorporateLoanRateCardValue = this.formControlCorporateLoanRateCard.value;

    console.log('****** toInputDAta insurance', insuranceRateCard);
    // NOTE: formControls will not be null, this will be called when formGroup is valid
    const d: UpdateRatecardInput = {
      Name: this.formControlRateCardName.value!,
      AssetFinance: {
        ApplicationType: 'AssetFinance',
        AssetFinanceTier1: assetRateCard?.assetTier1,
        AssetFinanceTier2: assetRateCard?.assetTier2,
        AssetFinanceTier3: assetRateCard?.assetTier3,
        AssetFinanceTier4: assetRateCard?.assetTier4,
        AssetType: this.toAssetTypeStorageFormat(assetRateCard?.assetType),
        AssetCategory: assetRateCard?.assetCategory,
        CapRate: assetRateCard?.capRate,
        Truck12T: assetRateCard?.truck12T,
        NoAssetBacked: assetRateCard?.noAssetBacked,
        TransactionTypeRate: assetRateCard?.transactionTypeRate,
        // PrivateSale: assetRateCard?.privateSales,
        // PrivateSaleDocFee: assetRateCard?.privateSalesDocFee,
        ABNorGSTAge: assetRateCard?.gstAge,
        AdversOnFile: assetRateCard?.adverseOnFile,
        LowEquifaxScore: assetRateCard?.lowEquifax,
        LowDepositUplift: assetRateCard?.lowDepositUplift,

        DocFee: assetRateCard?.docFee,
        Brokerage: assetRateCard?.brokerage,
        LoanTerms: assetRateCard?.loanTerms,
        LoanAmount: assetRateCard?.loanAmount,
      },
      BusinessLoans: {
        ApplicationType: 'BusinessLoans',
        LoanAmount: businessRateCard?.loanAmount,
        LoanTerms: businessRateCard?.loanTerms,
        Brokerage: businessRateCard?.brokerage,
        AdversOnFile: businessRateCard?.adverseOnFile,
        DocFee: businessRateCard?.docFee,
        ABNorGSTAge: businessRateCard?.gstAge,
        PropertyOwner: businessRateCard?.propertyOwner,
        NoPropertyOwner: businessRateCard?.noPropertyOwner,
        DirectorScoreRate:businessRateCard?.directorScoreRate,
        NonPropertyOwnerBetween500And600Rate: businessRateCard?.nonPropertyOwnerBetween500And600Rate
      },
      BusinessOverdraft: {
        ApplicationType: 'BusinessOverdraft',
        PropertyOwner: businessOverdraftRateCard?.propertyOwner,
        NoPropertyOwner: businessOverdraftRateCard?.noPropertyOwner,
        FacilityFee: businessOverdraftRateCard?.facilityFee,
        BrokerShareFacilityFee: businessOverdraftRateCard?.brokerShareFacilityFee,
        DocFee: businessOverdraftRateCard?.docFee,
        DirectorScoreRate:businessOverdraftRateCard?.directorScoreRate,
        NonPropertyOwnerBetween500And600Rate: businessOverdraftRateCard?.nonPropertyOwnerBetween500And600Rate
      },
      CorporateLoans: {
        ApplicationType: 'CorporateLoans',
        PropertyOwner: corporateLoanRateCard?.propertyOwner,
        NoPropertyOwner: corporateLoanRateCard?.noPropertyOwner,
        FacilityFee: corporateLoanRateCard?.facilityFee,
        BrokerShareFacilityFee: corporateLoanRateCard?.brokerShareFacilityFee,
        DocFee: corporateLoanRateCard?.docFee,
        DirectorScoreRate:corporateLoanRateCard?.directorScoreRate,
        NonPropertyOwnerBetween500And600Rate: corporateLoanRateCard?.nonPropertyOwnerBetween500And600Rate,
        SecurityType: corporateLoanRateCard?.securityType,
      },
      InsurancePremium: {
        ApplicationType: 'InsurancePremium',
        BaseInterestRate: insuranceRateCard?.baseInterestRate,
        LoanAmount: insuranceRateCard?.loanAmount,
        LoanTerms: insuranceRateCard?.loanTerms,
        AdversOnFile: insuranceRateCard?.adverseOnFile,
        DocFee: insuranceRateCard?.docFee,
        LowEquifaxScore: insuranceRateCard?.lowEquifaxScore,
      },
      Consumer: {
        ApplicationType: 'Consumer',
        CarsAndCaravans: consumerRateCard?.carsAndCaravans,
        Motorbikes: consumerRateCard?.motorbikes,
        SolarSystems: consumerRateCard?.solarSystems,
        Hybrid: consumerRateCard?.hybrid,
        EOTAge: consumerRateCard?.eotAge,
        LoanTerms: consumerRateCard?.loanTerms,
        AdversOnFile: consumerRateCard?.adverseOnFile,
        PrivateSale: consumerRateCard?.privateSale,
        PrivateSaleDocFee: consumerRateCard?.privateSaleDocFee,
        DocFee: consumerRateCard?.docFee,
        NoPropertyOwnership: consumerRateCard?.noPropertyOwnership,
        LowEquifaxScore: consumerRateCard?.lowEquifaxScore,
        AssetConditionUsed: consumerRateCard?.assetConditionUsed,
        AssetType: this.toAssetTypeStorageFormat(consumerRateCard?.assetType),
        MonthlyAccountKeepingFee: consumerRateCard?.monthlyAccountKeepingFee,
        MaxBrokerRateDiscount: consumerRateCard?.maxBrokerRateDiscount,
        CreditAssitanceFee: consumerRateCard?.creditAssitanceFee,
        PpsrFee: consumerRateCard?.ppsrFee,
      },
    }
    return d;
  }

  save($event: Event) {
    this.triggerMark.next(true);
    this.formGroup.markAllAsTouched();

    if (this.formGroup.invalid) {
      this.dialogService.openAlertDialog({
        message: 'Missing Information',
        subMessage: 'Make sure all fields across tabs are filled in.',
      });
    } else {
      const d = this.toInputData();
      // this.isLoadingSave = true;
      this.adminService.createRatecard(d).pipe(
        this.loader.save.track(),
        this.toastService.retryableMessage({
          successMessage: 'Rate card saved',
          errorMessage: 'Failed to save the Rate Card',
          retryFn: () => {
            console.log('**** retry ', this);
            this.save($event);
          }
        }),
        // this.toastService.snackBarObservable('Rate card saved'),
        tap(async r => {
          await this.router.navigate(navigationUrlForRateCards());
        })
      ).subscribe();
    }
  }

  update($event: Event) {
    this.triggerMark.next(true);
    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid) {
      console.log(`invalid form fields`, findInvalidControlsRecursive(this.formGroup));
      this.dialogService.openAlertDialog({
        message: 'Missing Information',
        subMessage: 'Make sure all fields across tabs are filled in.',
      });
    } else {
    if (this.ratecards && this.ratecards.length) {
      // this.isLoadingUpdate = true;
      const d = this.toInputData();
      const rateCardId = this.ratecards[0].RatecardId;
      console.log("Rate Card Id:::",rateCardId);
      this.adminService.updateRatecard(rateCardId, d).pipe(
        this.loader.update.track(),
        this.toastService.retryableMessage({
          successMessage: 'Rate card saved',
          errorMessage: 'Failed to save the Rate Card',
          retryFn: ()=> {
            console.log('**** retry ', this);
            this.update($event);
          }
        }),
        // this.toastService.snackBarObservable('Rate card saved'),
        tap(async r => {
          await this.router.navigate(navigationUrlForRateCardDetails(rateCardId,{t: String(Date.now())})
          );
        })
      ).subscribe();
    }
  }
  }

  async cancel($event: Event) {
    await this.router.navigate(navigationUrlForRateCards());
  }
  async onClickBack() {
    await this.router.navigate(navigationUrlForRateCards());
  }

}
