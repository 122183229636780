<div class="whitelabel-styles-component">
  <div class="row separator">
    <div class="col-lg-6 col-md-12">
      <message-box title="Design - Color Palettes" type="info">
        <span>
          Use <a href="https://themes.angular-material.dev/">material designer</a>
          as the basis to generate the required primary, accent and warn
          color palette. Make sure to choose Material 2 instead on the right hand
          side tab.
        </span>
      </message-box>
    </div>
  </div>

  <!-- primary -->
  <div class="row separator">
    <div class="col-lg-6 col-md-12">
      <span class="mat-h4">Primary color Palette</span>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Primary 50"
        [formControl]="formControlPrimary50"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Primary 100"
        [formControl]="formControlPrimary100"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Primary 200"
        [formControl]="formControlPrimary200"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Primary 300"
        [formControl]="formControlPrimary300" />
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Primary 400"
        [formControl]="formControlPrimary400"></hex-color>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Primary 500"
        [formControl]="formControlPrimary500"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Primary 600"
        [formControl]="formControlPrimary600"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Primary 700"
        [formControl]="formControlPrimary700"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Primary 800"
        [formControl]="formControlPrimary800"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Primary 900"
        [formControl]="formControlPrimary900"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Primary A100"
        [formControl]="formControlPrimaryA100"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Primary A200"
        [formControl]="formControlPrimaryA200"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Primary A400"
        [formControl]="formControlPrimaryA400"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Primary A700"
        [formControl]="formControlPrimaryA700"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Primary Contrast 50"
        [formControl]="formControlPrimaryContrast50"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Primary Contrast 100"
        [formControl]="formControlPrimaryContrast100"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Primary Contrast 200"
        [formControl]="formControlPrimaryContrast200"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Primary Contrast 300"
        [formControl]="formControlPrimaryContrast300"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Primary Contrast 400"
        [formControl]="formControlPrimaryContrast400"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Primary Contrast 500"
        [formControl]="formControlPrimaryContrast500"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Primary Contrast 600"
        [formControl]="formControlPrimaryContrast600"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Primary Contrast 700"
        [formControl]="formControlPrimaryContrast700"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Primary Contrast 800"
        [formControl]="formControlPrimaryContrast800"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Primary Contrast 900"
        [formControl]="formControlPrimaryContrast900"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Primary Contrast A100"
        [formControl]="formControlPrimaryContrastA100"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Primary Contrast A200"
        [formControl]="formControlPrimaryContrastA200"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Primary Contrast A400"
        [formControl]="formControlPrimaryContrastA400"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Primary Contrast A700"
        [formControl]="formControlPrimaryContrastA700"/>
    </div>
  </div>



  <!-- secondary -->
  <div class="row separator-bottom">
    <div class="col-lg-6 col-md-12">
      <span class="mat-h4">Accent color Palette</span>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Accent 50"
        [formControl]="formControlAccent50"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Accent 100"
        [formControl]="formControlAccent100"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Accent 200"
        [formControl]="formControlAccent200"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Accent 300"
        [formControl]="formControlAccent300"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Accent 400"
        [formControl]="formControlAccent400"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Accent 500"
        [formControl]="formControlAccent500"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Accent 600"
        [formControl]="formControlAccent600"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Accent 700"
        [formControl]="formControlAccent700"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Accent 800"
        [formControl]="formControlAccent800"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Accent 900"
        [formControl]="formControlAccent900"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Accent A100"
        [formControl]="formControlAccentA100"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Accent A200"
        [formControl]="formControlAccentA200"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Accent A400"
        [formControl]="formControlAccentA400"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Accent A700"
        [formControl]="formControlAccentA700"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Accent Contrast 50"
        [formControl]="formControlAccentContrast50"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Accent Contrast 100"
        [formControl]="formControlAccentContrast100"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color

        [formControl]="formControlAccentContrast200"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Accent Contrast 300"
        [formControl]="formControlAccentContrast300"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Accent Contrast 400"
        [formControl]="formControlAccentContrast400"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Accent Contrast 500"
        [formControl]="formControlAccentContrast500"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Accent Contrast 600"
        [formControl]="formControlAccentContrast600"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Accent Contrast 700"
        [formControl]="formControlAccentContrast700"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Accent Contrast 800"
        [formControl]="formControlAccentContrast800"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Accent Contrast 900"
        [formControl]="formControlAccentContrast900"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Accent Contrast A100"
        [formControl]="formControlAccentContrastA100"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Accent Contrast A200"
        [formControl]="formControlAccentContrastA200"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Accent Contrast A400"
        [formControl]="formControlAccentContrastA400"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Accent Contrast A700"
        [formControl]="formControlAccentContrastA700"/>
    </div>
  </div>


  <!-- warn -->
  <div class="row separator-bottom">
    <div class="col-lg-6 col-md-12">
      <span class="mat-h4">Warn color Palette</span>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Warn 50"
        [formControl]="formControlWarn50"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Warn 100"
        [formControl]="formControlWarn100"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Warn 200"
        [formControl]="formControlWarn200"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Warn 300"
        [formControl]="formControlWarn300"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Warn 400"
        [formControl]="formControlWarn400"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Warn 500"
        [formControl]="formControlWarn500"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Warn 600"
        [formControl]="formControlWarn600"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Warn 700"
        [formControl]="formControlWarn700"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Warn 800"
        [formControl]="formControlWarn800"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Warn 900"
        [formControl]="formControlWarn900"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Warn A100"
        [formControl]="formControlWarnA100"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Warn A200"
        [formControl]="formControlWarnA200"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Warn A400"
        [formControl]="formControlWarnA400"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Warn A700"
        [formControl]="formControlWarnA700"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Warn Contrast 50"
        [formControl]="formControlWarnContrast50"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Warn Contrast 100"
        [formControl]="formControlWarnContrast100"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Warn Contrast 200"
        [formControl]="formControlWarnContrast200"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Warn Contrast 300"
        [formControl]="formControlWarnContrast300"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Warn Contrast 400"
        [formControl]="formControlWarnContrast400"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Warn Contrast 500"
        [formControl]="formControlWarnContrast500"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Warn Contrast 600"
        [formControl]="formControlWarnContrast600"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Warn Contrast 700"
        [formControl]="formControlWarnContrast700"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Warn Contrast 800"
        [formControl]="formControlWarnContrast800"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Warn Contrast 900"
        [formControl]="formControlWarnContrast900"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Warn Contrast A100"
        [formControl]="formControlWarnContrastA100"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Warn Contrast A200"
        [formControl]="formControlWarnContrastA200"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Warn Contrast A400"
        [formControl]="formControlWarnContrastA400"/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <hex-color
        title="Warn Contrast A700"
        [formControl]="formControlWarnContrastA700"/>
    </div>
  </div>

</div>
