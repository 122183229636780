import {Component, Input, OnInit} from '@angular/core';
import { Application, BankStatement, BankStatementsAnalysisData, CreateApplicationNoteFn, GetBankStatementsAnalysisFn, GetBsaCalculatorFn, GetBsaExcludedLenderListFn, GetBsaLenderListFn, GetDscrCalculatorHistoryFn, GetDscrCalculatorValueFn, PayloadApiResponse, RefreshBankStatementFn, RemoveApplicationNoteByNoteIdFn, SaveBsaCalculatorFn, UpdateDscrCalculatorValueFn, User, isAdmin, isAnalyst, isInternalUser } from '@portal-workspace/grow-shared-library';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { tap } from 'rxjs/operators';
import {Observable, Subscription} from 'rxjs';
import {ApplicationDialogService, GetNotesByApplicationIdFn, getAccessToken, getUser} from '@portal-workspace/grow-ui-library';
import {PortalHotToastService} from '../../portal-hot-toast-component/hot-toast.service';
import { getAbn } from '@portal-workspace/grow-shared-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import { CustomerAnalysisComponent } from '../../bank-statement-component/customer-analysis.component';
import { AccountSummaryComponent } from '../../bank-statement-component/account-summary.component';
import { MatButtonModule } from '@angular/material/button';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { BankStatementDecisioningComponent } from '../../bank-statement-component/bank-statement-decisioning.component';

import { MatCardModule } from '@angular/material/card';
import { Response } from 'request';
import { DscrCalculatorComponent } from '../dscr-calculator.component';
import {FlexModule} from "@angular/flex-layout";


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'business-bank-statement',
    templateUrl: './business-bank-statement.component.html',
    styleUrls: ['./business-bank-statement.component.scss'],
    standalone: true,
    imports: [
    MatCardModule,
    MessageBoxComponent,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
    MatButtonModule,
    AccountSummaryComponent,
    CustomerAnalysisComponent,
    DscrCalculatorComponent,
    FlexModule,
    BankStatementDecisioningComponent,
]
})
export class BusinessBankStatementComponent implements OnInit {

  subscriptions: Subscription[] = [];

  @Input({required: true}) apiUrl!: string;
  @Input({required: true}) bankStatementsUrl!: string;
  @Input({required: true}) application!: Application;
  @Input({required: true}) getBankStatementsAnalysisFn!: GetBankStatementsAnalysisFn;
  @Input({required: true}) refreshBankStatementFn!: RefreshBankStatementFn;
  @Input({required: true}) getDscrCalculatorValueFn!: GetDscrCalculatorValueFn;
  @Input({required: true}) updateDscrCalculatorValueFn!: UpdateDscrCalculatorValueFn;
  @Input({required: true}) getBsaLenderListFn!: GetBsaLenderListFn;
  @Input({required: true}) getBsaExcludedLenderListFn!: GetBsaExcludedLenderListFn;
  @Input({required: true}) saveBsaCalculatorFn!: SaveBsaCalculatorFn;
  @Input({required: true}) getBsaCalculatorFn!: GetBsaCalculatorFn;
  @Input({required: true}) getDscrCalculatorHistoryFn!: GetDscrCalculatorHistoryFn;
  @Input() getNotesByApplicationIdFn!: GetNotesByApplicationIdFn;
  @Input() createApplicationNoteFn!: CreateApplicationNoteFn;
  @Input() removeApplicationNoteByNoteIdFn!: RemoveApplicationNoteByNoteIdFn;

  salesforceId?: string | null;
  formControlBankStatementLink: FormControl<string | null>;
  getAbn = getAbn
  bankStatementAnalysisData!: BankStatementsAnalysisData
  hasBankStatement: boolean = false;
  isAdmin = isAdmin;
  isAnalyst = isAnalyst;
  isInternalUser = isInternalUser;
  loggedInUser: User | null = getUser();
  showCalculator: boolean = false;
  bankStatementMessage!: string;

  toggleCalculator() {
    this.showCalculator = !this.showCalculator;
  }

  // TODO: refactoring move all services out, replace with functions passed in as 
  constructor(private formBuilder: FormBuilder,
              private applicationDialogService: ApplicationDialogService,
              private portalHotToastService: PortalHotToastService,) {
    this.formControlBankStatementLink = formBuilder.control(null);
  }

  ngOnInit(): void {
    console.log('bank-statements application',this.application)
    // if (this.application.ApplicationType == 'Consumer') {
    //   this.hasBankStatement == false
    // }
    // else {
      this.subscriptions.push(this.getBankStatementsAnalysisFn({ reference: getAbn(this.application) })
        .pipe(
          this.portalHotToastService.spinnerObservable(),
          tap((r: PayloadApiResponse<BankStatementsAnalysisData | null>) => {
            console.log('result', r)
            if (r.status && r.payload) {
              this.bankStatementAnalysisData = r.payload
              if (!Object.keys(this.bankStatementAnalysisData).length) {
                this.hasBankStatement = false;
              } else {
                this.hasBankStatement = true;
              }
            }
            else {
              this.hasBankStatement = false;
              this.bankStatementMessage = r.message;
            }
          })
        )
        .subscribe())
    // }
    this.salesforceId = this.application.AppInfo.SalesforceId;
    if (this.salesforceId) {
      this.formControlBankStatementLink.setValue(`${this.bankStatementsUrl}/bank-statements?reference=${this.getAbn(this.application)}`);
    }
  }

  refreshBankStatement() {
    this.subscriptions.push(
      this.refreshBankStatementFn(getAbn(this.application)).pipe(
        this.portalHotToastService.spinnerObservable()
      ).subscribe(
        (result: PayloadApiResponse<Response | {Error: string}>) => {
          console.log('refresh result: ', result);
          if (result.status) {
            this.applicationDialogService.successDialog({
              message: 'Success',
              subMessage: 'The bank statement has been refreshed'
            }).afterClosed().subscribe(() => {
              this.getBankStatementsAnalysisFn({ reference: getAbn(this.application) })
              .pipe(this.portalHotToastService.spinnerObservable())
              .subscribe(
                (r: PayloadApiResponse<BankStatementsAnalysisData | null>) => {
                  console.log('result', r)
                  if (r.status && r.payload) {
                    this.bankStatementAnalysisData = r.payload
                    if (!Object.keys(this.bankStatementAnalysisData).length) {
                      this.hasBankStatement = false;
                    } else {
                      this.hasBankStatement = true;
                    }
                  }
                  else {
                    this.hasBankStatement = false;
                    this.bankStatementMessage = r.message;
                  }
                })
            })
          } else {
            this.applicationDialogService.openAlertDialog({
              message: 'Error',
              subMessage: 'Unable to refresh this bank statement'
            })
          }
        }
      )
    )
  }
}
