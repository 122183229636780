import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { InputMaskModule } from '@ngneat/input-mask';
import { AngularMaterialLibraryModule } from '../../angular-material/angular-material-library.module';
import { DirectiveLibraryModule } from '../../directives/directive-library.module';
import { AccountSummaryComponent } from './account-summary.component';
import { CustomerAnalysisComponent } from './customer-analysis.component';
import { TransactionSummaryComponent } from './transaction-summary.component';
import { DecisionMetricsComponent } from './decision-metrics.component';
import { DayEndBalanceChartComponent } from './day-end-balance-chart.component';
import { MonthlyRevenueChartComponent } from './monthly-revenue-chart.component';
import { DishonoursTableComponent } from './dishonours-table.component';
import { OverdrawnTableComponent } from './overdrawn-table.component';
import { NonPreferredLenderSummaryComponent } from './non-preferred-lender-summary.component';
import { MonthlyDebitsCreditsTableComponent } from './monthly-debits-credits-table.component';
import { CategoriesTableComponent } from './categories-table.component';
import { FullTransactionListComponent } from './full-transaction-list.component';
import { MonthlyRevenueTableComponent } from './monthly-revenue-table.component';
import { CategoryDetailsTableComponent } from './category-details-table.component';
import { PipeLibraryModule } from '../../pipes/pipe-library.module';
import { CustomPaginatorModule } from '../custom-paginator-component/custom-paginator.module';
import { MonthlyDebitsCreditsChartComponent } from './monthly-debits-credits-chart.component';
import { TransactionTableComponent } from './transaction-table.component';
import { BankStatementDecisioningComponent } from './bank-statement-decisioning.component';
import { BankStatementOverviewComponent } from './bank-statement-overview.component'; 
import { BankStatementIncomeComponent } from './bank-statement-income.component';
import { BankStatementDscrCalculatorComponent } from './bank-statement-dscr-calculator.component';
import { BankStatementAtoPaymentComponent } from './bank-statement-ato-payment.component';
import { LenderTableComponent } from './lender-table.component';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        InputMaskModule,
        AngularMaterialLibraryModule,
        DirectiveLibraryModule,
        PipeLibraryModule,
        CustomPaginatorModule,
         
        AccountSummaryComponent,
        CustomerAnalysisComponent,
        TransactionSummaryComponent,
        DecisionMetricsComponent,
        DayEndBalanceChartComponent,
        MonthlyRevenueChartComponent,
        DishonoursTableComponent,
        OverdrawnTableComponent,
        NonPreferredLenderSummaryComponent,
        MonthlyDebitsCreditsTableComponent,
        CategoriesTableComponent,
        FullTransactionListComponent,
        MonthlyRevenueTableComponent,
        CategoryDetailsTableComponent,
        MonthlyDebitsCreditsChartComponent,
        TransactionTableComponent,
        BankStatementDecisioningComponent,
        BankStatementOverviewComponent,
        BankStatementIncomeComponent,
        BankStatementDscrCalculatorComponent,
        BankStatementAtoPaymentComponent,
        LenderTableComponent,
    ],
    exports: [AccountSummaryComponent, CustomerAnalysisComponent, CategoriesTableComponent, CategoryDetailsTableComponent, DayEndBalanceChartComponent, DecisionMetricsComponent, DishonoursTableComponent, FullTransactionListComponent, MonthlyRevenueChartComponent, BankStatementDecisioningComponent, BankStatementOverviewComponent, BankStatementIncomeComponent, BankStatementDscrCalculatorComponent, BankStatementAtoPaymentComponent, LenderTableComponent]
})
export class BankStatementModule {}
