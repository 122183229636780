<div class="application-type-amount-component">
  <div>
    @switch (applicationType) {
      @case ('AssetFinance') {
        <span>
          Up to {{ASSET_SLIDER_BORROWING_MAX | abbreviateNumber}}
        </span>
      }
      @case ('TradeFinance') {
        <span>
          Up to 3mln
        </span>
      }
      @case ('InsurancePremium') {
        <span>
          Up to {{INSURANCE_SLIDER_BORROWING_MAX | abbreviateNumber }}
        </span>
      }
      @case ('BusinessLoans') {
        <span>
          Up to {{BUSINESS_SLIDER_BORROWING_MAX | abbreviateNumber}}
        </span>
      }
      @case ('BusinessOverdraft') {
        <span>
          Up to  {{OVERDRAFT_SLIDER_BORROWING_MAX | abbreviateNumber}}
        </span>
      }
      @case ('Consumer') {
        <span>
          Up to {{CONSUMER_SLIDER_BORROWING_MAX | abbreviateNumber}}
        </span>
      }
      @case ('CorporateLoans') {
        <span>
          {{CORPORATE_SLIDER_BORROWING_MIN | abbreviateNumber}} to {{CORPORATE_SLIDER_BORROWING_MAX | abbreviateNumber}}
        </span>
      }
      <!-- @case ('FloorplanFinance') {
        <span>
          Up to 3mln
        </span>
      } -->
    }
  </div>
</div>
