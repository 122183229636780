import {Component, forwardRef, inject, OnInit} from "@angular/core";
import {
  FormBuilder,
  FormControl, FormGroup,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import {HexColorComponent, MessageBoxComponent, setupUntilDestroy} from "@portal-workspace/grow-ui-library";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatButtonModule} from "@angular/material/button";
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {AbstractControlValueAccessor} from "../abstract-control-value-accessor";
import {HexColorValue, WhitelabelStyles, WhitelabelStylesValue,} from "@portal-workspace/grow-shared-library";
import {UntilDestroy} from "@ngneat/until-destroy";
import {Subscription} from "rxjs";
import {delay, tap} from "rxjs/operators";


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  selector: 'whitelabel-styles',
  templateUrl: './whitelabel-styles.component.html',
  styleUrls: ['./whitelabel-styles.component.scss'],
  standalone: true,
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(()=>WhitelabelStylesComponent), multi: true},
    {provide: MARK, useExisting: forwardRef(() => WhitelabelStylesComponent)},
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    HexColorComponent,
    FlexLayoutModule,
    MatButtonModule,
    MessageBoxComponent,
  ]
})
export class WhitelabelStylesComponent extends AbstractControlValueAccessor<WhitelabelStylesValue> implements OnInit {

  subscriptions: Subscription[] = [];

  formBuilder = inject(FormBuilder);

  // primary
  formControlPrimary50: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlPrimary100: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlPrimary200: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlPrimary300: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlPrimary400: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlPrimary500: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlPrimary600: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlPrimary700: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlPrimary800: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlPrimary900: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlPrimaryA100: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlPrimaryA200: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlPrimaryA400: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlPrimaryA700: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlPrimaryContrast50: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlPrimaryContrast100: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlPrimaryContrast200: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlPrimaryContrast300: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlPrimaryContrast400: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlPrimaryContrast500: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlPrimaryContrast600: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlPrimaryContrast700: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlPrimaryContrast800: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlPrimaryContrast900: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlPrimaryContrastA100: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlPrimaryContrastA200: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlPrimaryContrastA400: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlPrimaryContrastA700: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);


  // accent
  formControlAccent50: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlAccent100: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlAccent200: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlAccent300: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlAccent400: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlAccent500: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlAccent600: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlAccent700: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlAccent800: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlAccent900: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlAccentA100: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlAccentA200: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlAccentA400: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlAccentA700: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlAccentContrast50: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlAccentContrast100: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlAccentContrast200: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlAccentContrast300: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlAccentContrast400: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlAccentContrast500: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlAccentContrast600: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlAccentContrast700: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlAccentContrast800: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlAccentContrast900: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlAccentContrastA100: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlAccentContrastA200: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlAccentContrastA400: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlAccentContrastA700: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);

  // warn
  formControlWarn50: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlWarn100: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlWarn200: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlWarn300: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlWarn400: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlWarn500: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlWarn600: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlWarn700: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlWarn800: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlWarn900: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlWarnA100: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlWarnA200: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlWarnA400: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlWarnA700: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlWarnContrast50: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlWarnContrast100: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlWarnContrast200: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlWarnContrast300: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlWarnContrast400: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlWarnContrast500: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlWarnContrast600: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlWarnContrast700: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlWarnContrast800: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlWarnContrast900: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlWarnContrastA100: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlWarnContrastA200: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlWarnContrastA400: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);
  formControlWarnContrastA700: FormControl<HexColorValue> = this.formBuilder.control(null, [Validators.required]);

  formGroup = this.formBuilder.group({
    'grow-primary-palette-50': this.formControlPrimary50,
    'grow-primary-palette-100': this.formControlPrimary100,
    'grow-primary-palette-200': this.formControlPrimary200,
    'grow-primary-palette-300': this.formControlPrimary300,
    'grow-primary-palette-400': this.formControlPrimary400,
    'grow-primary-palette-500': this.formControlPrimary500,
    'grow-primary-palette-600': this.formControlPrimary600,
    'grow-primary-palette-700': this.formControlPrimary700,
    'grow-primary-palette-800': this.formControlPrimary800,
    'grow-primary-palette-900': this.formControlPrimary900,
    'grow-primary-palette-A100': this.formControlPrimaryA100,
    'grow-primary-palette-A200': this.formControlPrimaryA200,
    'grow-primary-palette-A400': this.formControlPrimaryA400,
    'grow-primary-palette-A700': this.formControlPrimaryA700,
    'grow-primary-palette-contrast-50': this.formControlPrimaryContrast50,
    'grow-primary-palette-contrast-100': this.formControlPrimaryContrast100,
    'grow-primary-palette-contrast-200': this.formControlPrimaryContrast200,
    'grow-primary-palette-contrast-300': this.formControlPrimaryContrast300,
    'grow-primary-palette-contrast-400': this.formControlPrimaryContrast400,
    'grow-primary-palette-contrast-500': this.formControlPrimaryContrast500,
    'grow-primary-palette-contrast-600': this.formControlPrimaryContrast600,
    'grow-primary-palette-contrast-700': this.formControlPrimaryContrast700,
    'grow-primary-palette-contrast-800': this.formControlPrimaryContrast800,
    'grow-primary-palette-contrast-900': this.formControlPrimaryContrast900,
    'grow-primary-palette-contrast-A100': this.formControlPrimaryContrastA100,
    'grow-primary-palette-contrast-A200': this.formControlPrimaryContrastA200,
    'grow-primary-palette-contrast-A400': this.formControlPrimaryContrastA400,
    'grow-primary-palette-contrast-A700': this.formControlPrimaryContrastA700,

    'grow-accent-palette-50': this.formControlAccent50,
    'grow-accent-palette-100': this.formControlAccent100,
    'grow-accent-palette-200': this.formControlAccent200,
    'grow-accent-palette-300': this.formControlAccent300,
    'grow-accent-palette-400': this.formControlAccent400,
    'grow-accent-palette-500': this.formControlAccent500,
    'grow-accent-palette-600': this.formControlAccent600,
    'grow-accent-palette-700': this.formControlAccent700,
    'grow-accent-palette-800': this.formControlAccent800,
    'grow-accent-palette-900': this.formControlAccent900,
    'grow-accent-palette-A100': this.formControlAccentA100,
    'grow-accent-palette-A200': this.formControlAccentA200,
    'grow-accent-palette-A400': this.formControlAccentA400,
    'grow-accent-palette-A700': this.formControlAccentA700,
    'grow-accent-palette-contrast-50': this.formControlAccentContrast50,
    'grow-accent-palette-contrast-100': this.formControlAccentContrast100,
    'grow-accent-palette-contrast-200': this.formControlAccentContrast200,
    'grow-accent-palette-contrast-300': this.formControlAccentContrast300,
    'grow-accent-palette-contrast-400': this.formControlAccentContrast400,
    'grow-accent-palette-contrast-500': this.formControlAccentContrast500,
    'grow-accent-palette-contrast-600': this.formControlAccentContrast600,
    'grow-accent-palette-contrast-700': this.formControlAccentContrast700,
    'grow-accent-palette-contrast-800': this.formControlAccentContrast800,
    'grow-accent-palette-contrast-900': this.formControlAccentContrast900,
    'grow-accent-palette-contrast-A100': this.formControlAccentContrastA100,
    'grow-accent-palette-contrast-A200': this.formControlAccentContrastA200,
    'grow-accent-palette-contrast-A400': this.formControlAccentContrastA400,
    'grow-accent-palette-contrast-A700': this.formControlAccentContrastA700,

    'grow-warn-palette-50': this.formControlWarn50,
    'grow-warn-palette-100': this.formControlWarn100,
    'grow-warn-palette-200': this.formControlWarn200,
    'grow-warn-palette-300': this.formControlWarn300,
    'grow-warn-palette-400': this.formControlWarn400,
    'grow-warn-palette-500': this.formControlWarn500,
    'grow-warn-palette-600': this.formControlWarn600,
    'grow-warn-palette-700': this.formControlWarn700,
    'grow-warn-palette-800': this.formControlWarn800,
    'grow-warn-palette-900': this.formControlWarn900,
    'grow-warn-palette-A100': this.formControlWarnA100,
    'grow-warn-palette-A200': this.formControlWarnA200,
    'grow-warn-palette-A400': this.formControlWarnA400,
    'grow-warn-palette-A700': this.formControlWarnA700,
    'grow-warn-palette-contrast-50': this.formControlWarnContrast50,
    'grow-warn-palette-contrast-100': this.formControlWarnContrast100,
    'grow-warn-palette-contrast-200': this.formControlWarnContrast200,
    'grow-warn-palette-contrast-300': this.formControlWarnContrast300,
    'grow-warn-palette-contrast-400': this.formControlWarnContrast400,
    'grow-warn-palette-contrast-500': this.formControlWarnContrast500,
    'grow-warn-palette-contrast-600': this.formControlWarnContrast600,
    'grow-warn-palette-contrast-700': this.formControlWarnContrast700,
    'grow-warn-palette-contrast-800': this.formControlWarnContrast800,
    'grow-warn-palette-contrast-900': this.formControlWarnContrast900,
    'grow-warn-palette-contrast-A100': this.formControlWarnContrastA100,
    'grow-warn-palette-contrast-A200': this.formControlWarnContrastA200,
    'grow-warn-palette-contrast-A400': this.formControlWarnContrastA400,
    'grow-warn-palette-contrast-A700': this.formControlWarnContrastA700,
  });

  ngOnInit() {
    setupUntilDestroy(this);
    this.subscriptions.push(this.formGroup.valueChanges.pipe(
      delay(0),
      tap(_ => {
        if (this.formGroup.valid) {
          const v = {
            'grow-primary-palette-50': this.formControlPrimary50.value!,
            'grow-primary-palette-100': this.formControlPrimary100.value!,
            'grow-primary-palette-200': this.formControlPrimary200.value!,
            'grow-primary-palette-300': this.formControlPrimary300.value!,
            'grow-primary-palette-400': this.formControlPrimary400.value!,
            'grow-primary-palette-500': this.formControlPrimary500.value!,
            'grow-primary-palette-600': this.formControlPrimary600.value!,
            'grow-primary-palette-700': this.formControlPrimary700.value!,
            'grow-primary-palette-800': this.formControlPrimary800.value!,
            'grow-primary-palette-900': this.formControlPrimary900.value!,
            'grow-primary-palette-A100': this.formControlPrimaryA100.value!,
            'grow-primary-palette-A200': this.formControlPrimaryA200.value!,
            'grow-primary-palette-A400': this.formControlPrimaryA400.value!,
            'grow-primary-palette-A700': this.formControlPrimaryA700.value!,
            'grow-primary-palette-contrast-50': this.formControlPrimaryContrast50.value!,
            'grow-primary-palette-contrast-100': this.formControlPrimaryContrast100.value!,
            'grow-primary-palette-contrast-200': this.formControlPrimaryContrast200.value!,
            'grow-primary-palette-contrast-300': this.formControlPrimaryContrast300.value!,
            'grow-primary-palette-contrast-400': this.formControlPrimaryContrast400.value!,
            'grow-primary-palette-contrast-500': this.formControlPrimaryContrast500.value!,
            'grow-primary-palette-contrast-600': this.formControlPrimaryContrast600.value!,
            'grow-primary-palette-contrast-700': this.formControlPrimaryContrast700.value!,
            'grow-primary-palette-contrast-800': this.formControlPrimaryContrast800.value!,
            'grow-primary-palette-contrast-900': this.formControlPrimaryContrast900.value!,
            'grow-primary-palette-contrast-A100': this.formControlPrimaryContrastA100.value!,
            'grow-primary-palette-contrast-A200': this.formControlPrimaryContrastA200.value!,
            'grow-primary-palette-contrast-A400': this.formControlPrimaryContrastA400.value!,
            'grow-primary-palette-contrast-A700': this.formControlPrimaryContrastA700.value!,

            'grow-accent-palette-50': this.formControlAccent50.value!,
            'grow-accent-palette-100': this.formControlAccent100.value!,
            'grow-accent-palette-200': this.formControlAccent200.value!,
            'grow-accent-palette-300': this.formControlAccent300.value!,
            'grow-accent-palette-400': this.formControlAccent400.value!,
            'grow-accent-palette-500': this.formControlAccent500.value!,
            'grow-accent-palette-600': this.formControlAccent600.value!,
            'grow-accent-palette-700': this.formControlAccent700.value!,
            'grow-accent-palette-800': this.formControlAccent800.value!,
            'grow-accent-palette-900': this.formControlAccent900.value!,
            'grow-accent-palette-A100': this.formControlAccentA100.value!,
            'grow-accent-palette-A200': this.formControlAccentA200.value!,
            'grow-accent-palette-A400': this.formControlAccentA400.value!,
            'grow-accent-palette-A700': this.formControlAccentA700.value!,
            'grow-accent-palette-contrast-50': this.formControlAccentContrast50.value!,
            'grow-accent-palette-contrast-100': this.formControlAccentContrast100.value!,
            'grow-accent-palette-contrast-200': this.formControlAccentContrast200.value!,
            'grow-accent-palette-contrast-300': this.formControlAccentContrast300.value!,
            'grow-accent-palette-contrast-400': this.formControlAccentContrast400.value!,
            'grow-accent-palette-contrast-500': this.formControlAccentContrast500.value!,
            'grow-accent-palette-contrast-600': this.formControlAccentContrast600.value!,
            'grow-accent-palette-contrast-700': this.formControlAccentContrast700.value!,
            'grow-accent-palette-contrast-800': this.formControlAccentContrast800.value!,
            'grow-accent-palette-contrast-900': this.formControlAccentContrast900.value!,
            'grow-accent-palette-contrast-A100': this.formControlAccentContrastA100.value!,
            'grow-accent-palette-contrast-A200': this.formControlAccentContrastA200.value!,
            'grow-accent-palette-contrast-A400': this.formControlAccentContrastA400.value!,
            'grow-accent-palette-contrast-A700': this.formControlAccentContrastA700.value!,

            'grow-warn-palette-50': this.formControlWarn50.value!,
            'grow-warn-palette-100': this.formControlWarn100.value!,
            'grow-warn-palette-200': this.formControlWarn200.value!,
            'grow-warn-palette-300': this.formControlWarn300.value!,
            'grow-warn-palette-400': this.formControlWarn400.value!,
            'grow-warn-palette-500': this.formControlWarn500.value!,
            'grow-warn-palette-600': this.formControlWarn600.value!,
            'grow-warn-palette-700': this.formControlWarn700.value!,
            'grow-warn-palette-800': this.formControlWarn800.value!,
            'grow-warn-palette-900': this.formControlWarn900.value!,
            'grow-warn-palette-A100': this.formControlWarnA100.value!,
            'grow-warn-palette-A200': this.formControlWarnA200.value!,
            'grow-warn-palette-A400': this.formControlWarnA400.value!,
            'grow-warn-palette-A700': this.formControlWarnA700.value!,
            'grow-warn-palette-contrast-50': this.formControlWarnContrast50.value!,
            'grow-warn-palette-contrast-100': this.formControlWarnContrast100.value!,
            'grow-warn-palette-contrast-200': this.formControlWarnContrast200.value!,
            'grow-warn-palette-contrast-300': this.formControlWarnContrast300.value!,
            'grow-warn-palette-contrast-400': this.formControlWarnContrast400.value!,
            'grow-warn-palette-contrast-500': this.formControlWarnContrast500.value!,
            'grow-warn-palette-contrast-600': this.formControlWarnContrast600.value!,
            'grow-warn-palette-contrast-700': this.formControlWarnContrast700.value!,
            'grow-warn-palette-contrast-800': this.formControlWarnContrast800.value!,
            'grow-warn-palette-contrast-900': this.formControlWarnContrast900.value!,
            'grow-warn-palette-contrast-A100': this.formControlWarnContrastA100.value!,
            'grow-warn-palette-contrast-A200': this.formControlWarnContrastA200.value!,
            'grow-warn-palette-contrast-A400': this.formControlWarnContrastA400.value!,
            'grow-warn-palette-contrast-A700': this.formControlWarnContrastA700.value!,
          };
          this.propagateChange(v);
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe());
  }


  override doWriteValue(v?: WhitelabelStyles | null | undefined): void | WhitelabelStyles {
     const x = v?.['grow-primary-palette-50'] ?? null;
    this.formControlPrimary50.setValue(v?.["grow-primary-palette-50"] ?? null);
    this.formControlPrimary100.setValue(v?.['grow-primary-palette-100'] ?? null);
    this.formControlPrimary200.setValue(v?.['grow-primary-palette-200'] ?? null);
    this.formControlPrimary300.setValue(v?.['grow-primary-palette-300'] ?? null);
    this.formControlPrimary400.setValue(v?.['grow-primary-palette-400'] ?? null);
    this.formControlPrimary500.setValue(v?.['grow-primary-palette-500'] ?? null);
    this.formControlPrimary600.setValue(v?.['grow-primary-palette-600'] ?? null);
    this.formControlPrimary700.setValue(v?.['grow-primary-palette-700'] ?? null);
    this.formControlPrimary800.setValue(v?.['grow-primary-palette-800'] ?? null);
    this.formControlPrimary900.setValue(v?.['grow-primary-palette-900'] ?? null);
    this.formControlPrimaryA100.setValue(v?.['grow-primary-palette-A100'] ?? null);
    this.formControlPrimaryA200.setValue(v?.['grow-primary-palette-A200'] ?? null);
    this.formControlPrimaryA400.setValue(v?.['grow-primary-palette-A400'] ?? null);
    this.formControlPrimaryA700.setValue(v?.['grow-primary-palette-A700'] ?? null);
    this.formControlPrimaryContrast50.setValue(v?.['grow-primary-palette-contrast-50'] ?? null);
    this.formControlPrimaryContrast100.setValue(v?.['grow-primary-palette-contrast-100'] ?? null);
    this.formControlPrimaryContrast200.setValue(v?.['grow-primary-palette-contrast-200'] ?? null);
    this.formControlPrimaryContrast300.setValue(v?.['grow-primary-palette-contrast-300'] ?? null);
    this.formControlPrimaryContrast400.setValue(v?.['grow-primary-palette-contrast-400'] ?? null);
    this.formControlPrimaryContrast500.setValue(v?.['grow-primary-palette-contrast-500'] ?? null);
    this.formControlPrimaryContrast600.setValue(v?.['grow-primary-palette-contrast-600'] ?? null);
    this.formControlPrimaryContrast700.setValue(v?.['grow-primary-palette-contrast-700'] ?? null);
    this.formControlPrimaryContrast800.setValue(v?.['grow-primary-palette-contrast-800'] ?? null);
    this.formControlPrimaryContrast900.setValue(v?.['grow-primary-palette-contrast-900'] ?? null);
    this.formControlPrimaryContrastA100.setValue(v?.['grow-primary-palette-contrast-A100'] ?? null);
    this.formControlPrimaryContrastA200.setValue(v?.['grow-primary-palette-contrast-A200'] ?? null);
    this.formControlPrimaryContrastA400.setValue(v?.['grow-primary-palette-contrast-A400'] ?? null);
    this.formControlPrimaryContrastA700.setValue(v?.['grow-primary-palette-contrast-A700'] ?? null);

    this.formControlAccent50.setValue(v?.["grow-accent-palette-50"] ?? null);
    this.formControlAccent100.setValue(v?.['grow-accent-palette-100'] ?? null);
    this.formControlAccent200.setValue(v?.['grow-accent-palette-200'] ?? null);
    this.formControlAccent300.setValue(v?.['grow-accent-palette-300'] ?? null);
    this.formControlAccent400.setValue(v?.['grow-accent-palette-400'] ?? null);
    this.formControlAccent500.setValue(v?.['grow-accent-palette-500'] ?? null);
    this.formControlAccent600.setValue(v?.['grow-accent-palette-600'] ?? null);
    this.formControlAccent700.setValue(v?.['grow-accent-palette-700'] ?? null);
    this.formControlAccent800.setValue(v?.['grow-accent-palette-800'] ?? null);
    this.formControlAccent900.setValue(v?.['grow-accent-palette-900'] ?? null);
    this.formControlAccentA100.setValue(v?.['grow-accent-palette-A100'] ?? null);
    this.formControlAccentA200.setValue(v?.['grow-accent-palette-A200'] ?? null);
    this.formControlAccentA400.setValue(v?.['grow-accent-palette-A400'] ?? null);
    this.formControlAccentA700.setValue(v?.['grow-accent-palette-A700'] ?? null);
    this.formControlAccentContrast50.setValue(v?.['grow-accent-palette-contrast-50'] ?? null);
    this.formControlAccentContrast100.setValue(v?.['grow-accent-palette-contrast-100'] ?? null);
    this.formControlAccentContrast200.setValue(v?.['grow-accent-palette-contrast-200'] ?? null);
    this.formControlAccentContrast300.setValue(v?.['grow-accent-palette-contrast-300'] ?? null);
    this.formControlAccentContrast400.setValue(v?.['grow-accent-palette-contrast-400'] ?? null);
    this.formControlAccentContrast500.setValue(v?.['grow-accent-palette-contrast-500'] ?? null);
    this.formControlAccentContrast600.setValue(v?.['grow-accent-palette-contrast-600'] ?? null);
    this.formControlAccentContrast700.setValue(v?.['grow-accent-palette-contrast-700'] ?? null);
    this.formControlAccentContrast800.setValue(v?.['grow-accent-palette-contrast-800'] ?? null);
    this.formControlAccentContrast900.setValue(v?.['grow-accent-palette-contrast-900'] ?? null);
    this.formControlAccentContrastA100.setValue(v?.['grow-accent-palette-contrast-A100'] ?? null);
    this.formControlAccentContrastA200.setValue(v?.['grow-accent-palette-contrast-A200'] ?? null);
    this.formControlAccentContrastA400.setValue(v?.['grow-accent-palette-contrast-A400'] ?? null);
    this.formControlAccentContrastA700.setValue(v?.['grow-accent-palette-contrast-A700'] ?? null);

    this.formControlWarn50.setValue(v?.["grow-warn-palette-50"] ?? null);
    this.formControlWarn100.setValue(v?.['grow-warn-palette-100'] ?? null);
    this.formControlWarn200.setValue(v?.['grow-warn-palette-200'] ?? null);
    this.formControlWarn300.setValue(v?.['grow-warn-palette-300'] ?? null);
    this.formControlWarn400.setValue(v?.['grow-warn-palette-400'] ?? null);
    this.formControlWarn500.setValue(v?.['grow-warn-palette-500'] ?? null);
    this.formControlWarn600.setValue(v?.['grow-warn-palette-600'] ?? null);
    this.formControlWarn700.setValue(v?.['grow-warn-palette-700'] ?? null);
    this.formControlWarn800.setValue(v?.['grow-warn-palette-800'] ?? null);
    this.formControlWarn900.setValue(v?.['grow-warn-palette-900'] ?? null);
    this.formControlWarnA100.setValue(v?.['grow-warn-palette-A100'] ?? null);
    this.formControlWarnA200.setValue(v?.['grow-warn-palette-A200'] ?? null);
    this.formControlWarnA400.setValue(v?.['grow-warn-palette-A400'] ?? null);
    this.formControlWarnA700.setValue(v?.['grow-warn-palette-A700'] ?? null);
    this.formControlWarnContrast50.setValue(v?.['grow-warn-palette-contrast-50'] ?? null);
    this.formControlWarnContrast100.setValue(v?.['grow-warn-palette-contrast-100'] ?? null);
    this.formControlWarnContrast200.setValue(v?.['grow-warn-palette-contrast-200'] ?? null);
    this.formControlWarnContrast300.setValue(v?.['grow-warn-palette-contrast-300'] ?? null);
    this.formControlWarnContrast400.setValue(v?.['grow-warn-palette-contrast-400'] ?? null);
    this.formControlWarnContrast500.setValue(v?.['grow-warn-palette-contrast-500'] ?? null);
    this.formControlWarnContrast600.setValue(v?.['grow-warn-palette-contrast-600'] ?? null);
    this.formControlWarnContrast700.setValue(v?.['grow-warn-palette-contrast-700'] ?? null);
    this.formControlWarnContrast800.setValue(v?.['grow-warn-palette-contrast-800'] ?? null);
    this.formControlWarnContrast900.setValue(v?.['grow-warn-palette-contrast-900'] ?? null);
    this.formControlWarnContrastA100.setValue(v?.['grow-warn-palette-contrast-A100'] ?? null);
    this.formControlWarnContrastA200.setValue(v?.['grow-warn-palette-contrast-A200'] ?? null);
    this.formControlWarnContrastA400.setValue(v?.['grow-warn-palette-contrast-A400'] ?? null);
    this.formControlWarnContrastA700.setValue(v?.['grow-warn-palette-contrast-A700'] ?? null);
  }
}
