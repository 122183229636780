<div class="currency-with-period-component">
  <div fxLayout="column"
       fxLayoutAlign="center start"
       [fxLayoutGap]="pxToRem(10)">
    <mat-label class="mat-caption">{{title}}</mat-label>
    <div fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="center center"
         [fxLayoutGap]="pxToRem(10)">
      <currency-input [formControl]="formControlAmount"></currency-input>
      <mat-form-field>
        <mat-label>Period</mat-label>
        <mat-select [formControl]="formControlPeriod">
          @for (period of periods; track period) {
            <mat-option [value]="period">
              {{period}}
            </mat-option>
          }
        </mat-select>
        @for (errorKey of errorKeys(formControlPeriod); track errorKey) {
          <mat-error>
            @if (formControlPeriod.touched && formControlPeriod.hasError(errorKey)) {
              {{errorMessage(formControlPeriod, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
  </div>
</div>
