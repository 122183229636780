<div class="asset-tier2-component">
  <div class="row">
    <div class="col-12">
      <div class="mat-h3">{{title}}</div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-4 col-md-12">
      <mat-form-field>
        <mat-label>Year 1</mat-label>
        <input [inputMask]="createNoDecimalInputMask" matInput type="text" [formControl]="formControlYear1" required>
        @for (errorKey of errorKeys(formControlYear1); track errorKey) {
          <mat-error>
            @if (formControlYear1.touched && formControlYear1.hasError(errorKey)) {
              {{errorMessage(formControlYear1, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
    <div class="col-lg-4 col-md-12">
      <mat-form-field>
        <mat-label>Year 2</mat-label>
        <input [inputMask]="createNoDecimalInputMask" matInput type="text" [formControl]="formControlYear2" required>
        @for (errorKey of errorKeys(formControlYear2); track errorKey) {
          <mat-error>
            @if (formControlYear2.touched && formControlYear2.hasError(errorKey)) {
              {{errorMessage(formControlYear2, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
    <div class="col-lg-4 col-md-12">
      <mat-form-field>
        <mat-label>Year 3</mat-label>
        <input [inputMask]="createNoDecimalInputMask" matInput type="text" [formControl]="formControlYear3" required>
        @for (errorKey of errorKeys(formControlYear3); track errorKey) {
          <mat-error>
            @if (formControlYear3.touched && formControlYear3.hasError(errorKey)) {
              {{errorMessage(formControlYear3, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
  </div>


  <div class="separator-bottom row">
    <!-- <div class="mat-caption font-weight-500 separator-bottom">ASSET BACKED</div> -->
    <div class="col-12">
      <mat-form-field>
        <mat-label>0 - {{formControlYear1.value}} Years</mat-label>
        <input [inputMask]="createTwoDecimalInputMask" [formControl]="formControlAssetBackedLowYear" matInput type="text"required >
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlAssetBackedLowYear); track errorKey) {
          <mat-error>
            @if (formControlAssetBackedLowYear.touched && formControlAssetBackedLowYear.hasError(errorKey)) {
              {{errorMessage(formControlAssetBackedLowYear, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
    <div class="col-12">
      <mat-form-field>
        <mat-label>{{parseToInt(formControlYear1.value) + 1}} - {{formControlYear2.value}} years</mat-label>
        <input [inputMask]="createTwoDecimalInputMask" [formControl]="formControlAssetBackedMediumYear" matInput required
          type="text">
          <span class="mdi mdi-percent" matSuffix></span>
          @for (errorKey of errorKeys(formControlAssetBackedMediumYear); track errorKey) {
            <mat-error>
              @if (formControlAssetBackedMediumYear.touched && formControlAssetBackedMediumYear.hasError(errorKey)) {
                {{errorMessage(formControlAssetBackedMediumYear, errorKey)}}
              }
            </mat-error>
          }
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field>
          <mat-label>{{parseToInt(formControlYear2.value) + 1}} - {{formControlYear3.value}} years</mat-label>
          <input [inputMask]="createTwoDecimalInputMask" [formControl]="formControlAssetBackedHighYear" matInput required
            type="text">
            <span class="mdi mdi-percent" matSuffix></span>
            @for (errorKey of errorKeys(formControlAssetBackedHighYear); track errorKey) {
              <mat-error>
                @if (formControlAssetBackedHighYear.touched && formControlAssetBackedHighYear.hasError(errorKey)) {
                  {{errorMessage(formControlAssetBackedHighYear, errorKey)}}
                }
              </mat-error>
            }
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field>
            <mat-label>{{parseToInt(formControlYear3.value) + 1}} - {{maxYear}} years</mat-label>
            <input [inputMask]="createTwoDecimalInputMask" [formControl]="formControlAssetBackedVeryHighYear" matInput required
              type="text">
              <span class="mdi mdi-percent" matSuffix></span>
              @for (errorKey of errorKeys(formControlAssetBackedVeryHighYear); track errorKey) {
                <mat-error>
                  @if (formControlAssetBackedVeryHighYear.touched && formControlAssetBackedVeryHighYear.hasError(errorKey)) {
                    {{errorMessage(formControlAssetBackedVeryHighYear, errorKey)}}
                  }
                </mat-error>
              }
            </mat-form-field>
          </div>

        <!-- </div> -->
        <!-- <div class="col-6">
        <div fxLayout="column">
          <div class="mat-caption font-weight-500 separator-bottom">NOT ASSET BACKED</div>
          <mat-form-field>
            <mat-label>0 - {{formControlYear1.value}} Years</mat-label>
            <input [inputMask]="createTwoDecimalInputMask" [formControl]="formControlNoAssetBackedLowYear" matInput type="text">
            <span class="mdi mdi-percent" matSuffix></span>
            <mat-error *ngFor="let errorKey of errorKeys(formControlNoAssetBackedLowYear)">
              <ng-container *ngIf="formControlNoAssetBackedLowYear.touched && formControlNoAssetBackedLowYear.hasError(errorKey)">
                {{errorMessage(formControlNoAssetBackedLowYear, errorKey)}}
              </ng-container>
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{parseToInt(formControlYear1.value) + 1}} - {{formControlYear2.value}} years</mat-label>
            <input [inputMask]="createTwoDecimalInputMask" [formControl]="formControlNoAssetBackedMediumYear" matInput type="text">
            <span class="mdi mdi-percent" matSuffix></span>
            <mat-error *ngFor="let errorKey of errorKeys(formControlNoAssetBackedMediumYear)">
              <ng-container *ngIf="formControlNoAssetBackedMediumYear.touched && formControlNoAssetBackedMediumYear.hasError(errorKey)">
                {{errorMessage(formControlNoAssetBackedMediumYear, errorKey)}}
              </ng-container>
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{parseToInt(formControlYear2.value) + 1}} - {{formControlYear3.value}} years</mat-label>
            <input [inputMask]="createTwoDecimalInputMask" [formControl]="formControlNoAssetBackedHighYear" matInput type="text">
            <span class="mdi mdi-percent" matSuffix></span>
            <mat-error *ngFor="let errorKey of errorKeys(formControlNoAssetBackedHighYear)">
              <ng-container *ngIf="formControlNoAssetBackedHighYear.touched && formControlNoAssetBackedHighYear.hasError(errorKey)">
                {{errorMessage(formControlNoAssetBackedHighYear, errorKey)}}
              </ng-container>
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{parseToInt(formControlYear3.value) + 1}} - {{maxYear}} years</mat-label>
            <input [inputMask]="createTwoDecimalInputMask" [formControl]="formControlNoAssetBackedVeryHighYear" matInput type="text">
            <span class="mdi mdi-percent" matSuffix></span>
            <mat-error *ngFor="let errorKey of errorKeys(formControlNoAssetBackedVeryHighYear)">
              <ng-container *ngIf="formControlNoAssetBackedVeryHighYear.touched && formControlNoAssetBackedVeryHighYear.hasError(errorKey)">
                {{errorMessage(formControlNoAssetBackedVeryHighYear, errorKey)}}
              </ng-container>
            </mat-error>
          </mat-form-field>
        </div>
      </div> -->
      <mat-divider [inset]="true"></mat-divider>
    </div>
  </div>
