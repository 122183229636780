<div class="years-and-months-component" fxLayout="column" [formGroup]="formGroup">
  <div class="col-7 pl-0">
    <div fxLayout="row">
      <mat-form-field>
        <mat-label>{{title}}</mat-label>
        <input matInput type="number" min="0" [formControl]="formControlYears" placeholder="Select year">
      </mat-form-field>
      <mat-form-field>
        <mat-select [formControl]="formControlMonths" placeholder="Select month">
          @for (monthOption of monthsOptions; track monthOption) {
            <mat-option [value]="monthOption">{{monthOption}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  @if (requirePrevAddress) {
    <div>
      <custom-address title="Previous address" [mark]="markObservable"  [formControl]="formControlPreviousAddress"></custom-address>
    </div>
  }
</div>
