<div class="business-finance-page container">
  <div class="header">
    <div class="mat-h2">New Business Term Loan Application</div>
    <!-- <button class="arrow-left" (click)="onClickBack()">
      <span class="mdi mdi-arrow-left"></span>
    </button> -->
  </div>
  <application-stepper2 [linear]="false" #applicationStepper="applicationStepper2">
    <cdk-step [stepControl]="formGroupStep1" ><ng-content *ngTemplateOutlet="step1" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep2" ><ng-content *ngTemplateOutlet="step2" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep3" ><ng-content *ngTemplateOutlet="step3" ></ng-content></cdk-step>
    @if (step3OnwardsOrganisationType === 'trust') {
      <cdk-step [stepControl]="formGroupStep4"><ng-content *ngTemplateOutlet="step4_1" ></ng-content></cdk-step>
    }
    @if (step3OnwardsOrganisationType === 'sole-trader') {
      <cdk-step [stepControl]="formGroupStep4"><ng-content *ngTemplateOutlet="step4_2" ></ng-content></cdk-step>
    }
    @if (step3OnwardsOrganisationType === 'partnership') {
      <cdk-step [stepControl]="formGroupStep4"><ng-content *ngTemplateOutlet="step4_3" ></ng-content></cdk-step>
    }
    @if (step3OnwardsOrganisationType === 'company') {
      <cdk-step [stepControl]="formGroupStep4"><ng-content *ngTemplateOutlet="step4_4" ></ng-content></cdk-step>
    }
    @if (step3OnwardsOrganisationType === 'other') {
      <cdk-step [stepControl]="formGroupStep4"><ng-content *ngTemplateOutlet="step4_5" ></ng-content></cdk-step>
    }
    <cdk-step [stepControl]="formGroupStep5" ><ng-content *ngTemplateOutlet="step5" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep6" ><ng-content *ngTemplateOutlet="step6" ></ng-content></cdk-step>
    <!-- <cdk-step [stepControl]="formGroupStep7" ><ng-content *ngTemplateOutlet="step7" ></ng-content></cdk-step> -->
    <cdk-step [stepControl]="formGroupStep8" ><ng-content *ngTemplateOutlet="step8" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep9" ><ng-content *ngTemplateOutlet="step9" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep10" ><ng-content *ngTemplateOutlet="step10" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep11" ><ng-content *ngTemplateOutlet="step11" ></ng-content></cdk-step>
  </application-stepper2>
</div>


<!--
##########################################
#  STEP 1:
##########################################
-->
<ng-template #step1>
  <div class="step1">
    <form [formGroup]="formGroupStep1" >
      <div class="row">
        <div class="col-12">
          <div class="mat-subtitle-1">Applicant's business name</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <business-search [placeholder]="businessSearchPlaceholder" [mark]="applicationStepper.markTriggerSubject(formGroupStep1)" [formControl]="formControlStep1Business" [searchFn]="step1BusinessSearchFn"></business-search>
        </div>
      </div>
      @if (formControlStep1Business.value && (formControlStep1Business.value.type === 'search-result') && ($any(formControlStep1Business.value).result.ABNAgeMonths ?? 0) < 12) {
        <div class="row mb-4">
          <div class="col-lg-6 col-md-12">
            <div class="mb-2">The ABN age for the business is less than 12 months old. Does the business owner(s) have a previous ABN with over 12 months of history?</div>
            <business-number-search
              [mark]="applicationStepper.markTriggerSubject(formGroupStep1)"
              [searchFn]="step4BusinessNumberSearchFn"
              [formControl]="formControlStep1PreviousBusiness"
            ></business-number-search>
          </div>
        </div>
      }
      @if (!existingApplication) {
        <div class="row mb-4">
          <div class="col-12">
            <div class="mat-subtitle-1 mb-2">Security Questions</div>
            <div class="mb-2">Enter your details below that you can use to recover the application when it is in draft</div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <name title="First Name" [mark]="applicationStepper.markTriggerSubject(formGroupStep1)" [formControl]="formControlStep1FirstName"></name>
          </div>
          <div class="col-lg-3 col-md-12">
            <name title="Last Name" [mark]="applicationStepper.markTriggerSubject(formGroupStep1)" [formControl]="formControlStep1LastName"></name>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <email [mark]="applicationStepper.markTriggerSubject(formGroupStep1)" [formControl]="formControlStep1Email"></email>
          </div>
        </div>
      }
    </form>
  </div>
</ng-template>


<!--
##########################################
#  STEP 2:
##########################################
-->
<ng-template #step2>
  <div class="step2">
    <form [formGroup]="formGroupStep2" >
      <div class="row">
        <div class="col-12">
          <div class="mat-h3">Business Term Loan application</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <loan-purpose-selection
            [formControl]="formControlStep2LoanPurpose"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
          </loan-purpose-selection>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <business-loan-terms-selection
            [formControl]="formControlStep2LoanTerms"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
          </business-loan-terms-selection>
        </div>
        <div class="col-lg-3 col-md-12">
          <payment-frequency-selection
            [filter]="['Monthly', 'Fortnightly']"
            [formControl]="formControlStep2PaymentFrequency"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
          </payment-frequency-selection>
        </div>
      </div>
      <!-- <div class="row">
      <div class="col-lg-3 col-md-12">
        <brokerage-selection
          [formControl]="formControlStep2Brokerage"
          [max]="4"
          [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
        </brokerage-selection>
      </div>
      <div class="col-lg-3 col-md-12">
        <payment-frequency-selection
          [filter]="['Monthly', 'Fortnightly']"
          [formControl]="formControlStep2PaymentFrequency"
          [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
        </payment-frequency-selection>
      </div>
    </div> -->
    <div class="row mt-3">
      <div class="col-lg-3 col-md-12">
        <yes-no
          title="Property owner"
          [formControl]="formControlStep2PropertyOwner"
          [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
        </yes-no>
      </div>
      <div class="col-lg-3 col-md-12">
        <yes-no
          title="Adverse on file"
          [formControl]="formControlStep2AdverseOnFile"
          [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
        </yes-no>
      </div>
    </div>
    <!-- <div class="row">
    <div class="col-lg-3 col-md-12">
      <yes-no
        title="Equifax Score above 600"
        [formControl]="formControlStep2EquifaxScoreAboveThreshold"
        [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
      </yes-no>
    </div>
    <div class="col-lg-3 col-md-12" *ngIf="!formControlStep2EquifaxScoreAboveThreshold.value">
      <yes-no
        title="Director Score 500 - 600"
        [formControl]="formControlStep2DirectorScore"
        [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
      </yes-no>
    </div>
  </div> -->
  <div class="row">
    <div class="col-lg-3 col-md-12">
      <yes-no
        title="Doc Fee financed"
        [formControl]="formControlStep2DocFeeFinanced"
        [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
      </yes-no>
    </div>
    @if (!formControlStep2PropertyOwner.value) {
      <div class="col-lg-3 col-md-12">
        <yes-no title="Has your business taken another commercial loan in the past 3 years?"
          [formControl]="formControlStep2PreviousLoan"
          [notselected]="true"
          [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
        </yes-no>
      </div>
    }
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <currency-input title="Requested limit" [formControl]="formControlStep2LoanAmount" [min]="1" [max]="step2MaxLimit"
      [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"></currency-input>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <span class="mat-caption">Disclaimer: Your application is under the assumption of your direct Equifax score > 600.</span>
    </div>
  </div>
</form>
</div>
</ng-template>

<!--
##########################################
#  STEP 3:
##########################################
-->
<ng-template #step3>
  <div class="step3">
    <form [formGroup]="formGroupStep3" >
      <div class="row separator">
        <div class="col-12">
          <div class="mat-h3">Company details</div>
          <div class="mat-body">Please provide us with further details so we can provide you with a more accurate quote</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <entity-type
            title="Organisation type"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
            [formControl]="formControlStep3OrganisationType">
          </entity-type>
        </div>
        <div class="col-lg-3 col-md-12">
          <abn [formControl]="formControlStep3Abn" [mark]="applicationStepper.markTriggerSubject(formGroupStep3)">
          </abn>
        </div>
        @if (step3OnwardsOrganisationType === 'company') {
          <div class="col-lg-3 col-md-12">
            <acn [formControl]="formControlStep3Acn"
              [mark]="applicationStepper.markTriggerSubject(formGroupStep3)">
            </acn>
          </div>
        }
        @if (step3OnwardsOrganisationType === 'trust') {
          <div class="col-lg-3 col-md-12">
            <acn [formControl]="formControlStep3Acn"
              [isMandatory]="false"
              [mark]="applicationStepper.markTriggerSubject(formGroupStep3)">
            </acn>
          </div>
        }
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12 mt-2">
          <annual-revenue
            title="Annual revenue"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
            [formControl]="formControlStep3AnnualRevenue" >
          </annual-revenue>
        </div>
        <div class="col-lg-3 col-md-12 mt-2">
          <yes-no
            title="Operates in a commercial premise?"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
            [formControl]="formControlStep3OperatesInCommercialPremises">
          </yes-no>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <primary-industry-selection
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
            [formControl]="formControlStep3PrimaryIndustry">
          </primary-industry-selection>
        </div>
        <div class="col-lg-6 col-md-12">
          <secondary-industry-selection
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
            [formControl]="formControlStep3IndustrySector">
          </secondary-industry-selection>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <custom-address
            title="Primary business address"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
            [formControl]="formControlStep3PrimaryBusinessAddress">
          </custom-address>
        </div>
        <div class="col-lg-3 col-md-12">
          <mobile [formControl]="formControlStep3BusinessLandline"
            [mandatory]="false"
            [onlyMobile]="false"
          title="Business Landline"></mobile>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 4:
##########################################
-->
<ng-template #step4_1> <!-- trust -->
  <div class="step4">
    <form [formGroup]="formGroupStep4">
      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <trustee
            [showAssetsAndLiabilities]="false"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"
            [formControl]="formControlStep4Applicant"
            [showAddressForm]="showAddressFormFlag"
            [businessNumberSearchFn]="step4BusinessNumberSearchFn">
          </trustee>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #step4_2> <!-- sole trader -->
  <div class="step4">
    <form [formGroup]="formGroupStep4">
      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <sole-trader
            [formControl]="formControlStep4Applicant"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"
            [showAddressForm]="showAddressFormFlag">
          </sole-trader>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #step4_3> <!-- partnership -->
  <div class="step4">
    <form [formGroup]="formGroupStep4">
      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName" >
          </application-header-segment>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <partner
            [showAssetsAndLiabilities]="false"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"
            [formControl]="formControlStep4Applicant"
            [showAddressForm]="showAddressFormFlag"
            [businessNumberSearchFn]="step4BusinessNumberSearchFn">
          </partner>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #step4_4> <!-- company -->
  <div class="step4">
    <form [formGroup]="formGroupStep4">
      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <director
            [showAssetsAndLiabilities]="false"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"
            [formControl]="formControlStep4Applicant"
            [showAddressForm]="showAddressFormFlag">
          </director>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #step4_5> <!-- other (member) -->
  <div class="step4">
    <form [formGroup]="formGroupStep4">
      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <member
            [showAssetsAndLiabilities]="false"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"
            [formControl]="formControlStep4Applicant"
            [businessNumberSearchFn]="step4BusinessNumberSearchFn"
            [showAddressForm]="showAddressFormFlag">
          </member>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 5:
##########################################
-->
<ng-template #step5>
  <div class="step5">
    <div class="row separator-bottom">
      <div class="col-12">
        <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
        </application-header-segment>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <guarantor [businessNumberSearchFn]="step5BusinessNumberSearchFn"
          [formControl]="formControlStep5Guarantors"
          (events)="step5GuarantorEvent($event)"
          [showAssetsAndLiabilities]="false"
          [mandatoryGuarantors]="step5MandatoryGuarantors"
          [mark]="applicationStepper.markTriggerSubject(formGroupStep5)"
        [showAddressForm]="showAddressFormFlag"></guarantor>
      </div>
    </div>
  </div>
</ng-template>

<!--
##########################################
#  STEP 6:
##########################################
-->
<ng-template #step6>
  <div class="step6">
    <div class="row separator-bottom">
      <div class="col-12">
        <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
        </application-header-segment>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <form [formGroup]="formGroupStep6">
          <select-contact
            [predefinedContactSelection]="step6PossiblePrimaryContacts"
            [formControl]="formControlStep6PrimaryContact"
          [mark]="applicationStepper.markTriggerSubject(formGroupStep6)"></select-contact>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<!--
##########################################
#  STEP 7:
##########################################
-->
<!-- <ng-template #step7>
<div class="step7">
  <div class="row separator-bottom">
    <div class="col-12">
      <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
      </application-header-segment>
    </div>
  </div>
  <form [formGroup]="formGroupStep7">
    <div class="row">
      <div class="col-12">
        <reference [formControl]="formControlStep7References"
          [mark]="applicationStepper.markTriggerSubject(formGroupStep7)">
        </reference>
      </div>
    </div>
  </form>
</div>
</ng-template> -->

<!--
##########################################
#  STEP 8:
##########################################
-->
<ng-template #step8>
  <div class="step8">
    <div class="row separator-bottom">
      <div class="col-12">
        <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
        </application-header-segment>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="mat-h3">Upload Driver Licence</div>
      </div>
    </div>

    <div class="row separator-bottom">
      <div class="col-lg-6 col-md-12">
        <message-box type="info" title="Before uploading">
          <ul>
            <li>Ensure both the front and back of the licence are supplied</li>
            <li>Ensure the text is clear and readable</li>
            <li>If using PDF, ensure the front of the driver licence is on the first page</li>
            <li>We don’t accept pictures of digital driver licences</li>
          </ul>
        </message-box>
      </div>
    </div>

    <div class="row separator-bottom">
      <div class="col-lg-6 col-md-12">
        <uploaded-files-list [uploadedFiles]="step8UploadedDocs" (events)="onStep8DeleteUploadedDoc($event)"></uploaded-files-list>
      </div>
    </div>

    @for (dr of step8DriverLicenses; track dr) {
      <div class="row separator-bottom">
        <div class="col-lg-6 col-md-12">
          <upload-file [maxFilesAllowed]="2" [mandatory]="!skipFileUploadValidation()"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep8)"
          title="{{dr.name}}'s Driver Licence" [formControl]="dr.formControl" [allowMultiple]="true"></upload-file>
        </div>
      </div>
    }
  </div>
</ng-template>

<!--
##########################################
#  STEP 9:
##########################################
-->
<ng-template #step9>
  <div class="step9">
    <form [formGroup]="formGroupStep9">
      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="mat-h3">Other supporting documents</div>
        </div>
      </div>

      <div class="row separator-bottom">
        <div class="col-lg-6 col-md-12">
          <uploaded-files-list   [uploadedFiles]="step9UploadedDocs" (events)="onStep9DeleteUploadedDoc($event)"></uploaded-files-list>
        </div>
      </div>

      <div class="row separator-bottom">
        <div class="col-lg-6 col-md-12">
          <upload-files-multi-tag-dialog
          #uploadFilesMultiTagDialogComponent="uploadFilesMultiTagDialogComponent"
            [docs]="formControlStep9OtherSupportingDocuments.value ?? []"
            [metadata]="step9OtherDocMetadata"
            [defaultTags]="applicationDefaultDocuments(true)"
            (uploadDocs)="onStep9UploadOtherDoc($event)"
            (deleteDoc)="onStep9DeleteSupportingDoc($event)"
          ></upload-files-multi-tag-dialog>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 10:
##########################################
-->
<ng-template #step10>
  <div class="step10">
    <form [formGroup]="formGroupStep10">
      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mat-subtitle-1">Overview</div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-12">
          <mat-form-field>
            <mat-label>How are you planning to use the funds?</mat-label>
            <textarea matInput rows="10" wrap="hard" maxlength="7000" [formControl]="formControlStep10ApplicationNotes"></textarea>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 11:
##########################################
-->
<ng-template #step11>
  <div class="step11">
    <div class="row separator-bottom">
      <div class="col-12">
        <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
        </application-header-segment>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <business-finance-summary [summary]="step11Summary"></business-finance-summary>
      </div>
    </div>
  </div>
</ng-template>
