<div class="asset-finance-calcualtor-page container-fluid">
<div class="calculator-content mt-4" [formGroup]="formGroupCalculator">
  <div class="section-format mt-1 p-4">
    <div class="row">
      <div class="col-md-10">
        <h3>How much would you like to borrow?</h3>
      </div>
      <div class="col-md-2" fxLayoutAlign="end center"><span class="text-muted">1/7</span></div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <currency-input title="Invoice amount"  [formControl]="formControlInvoiceAmount" [mark]="markTriggerSubject(formGroupCalculator)"></currency-input>
      </div>
    </div>
  </div>

  <div class="section-format mt-1 p-4">
    <div class="row">
      <div class="col-md-10">
        <h3>Asset details</h3>
      </div>
      <div class="col-md-2" fxLayoutAlign="end center"><span class="text-muted">2/7</span></div>
    </div>
    <div class="mat-body separator-bottom">Please provide details on the asset you would like to finance.</div>
    <div class="row separator-bottom">
      <div class="col">
        <asset-selection  [searchFn]="AssetSearchFn" [formControl]="formControlAsset"  [categoryIndexFilter]="assetCategoryIndexFilter"  [mark]="markTriggerSubject(formGroupCalculator)">
        </asset-selection>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3 col-md-12">
        <asset-condition  [formControl]="formControlAssetCondition" [mark]="markTriggerSubject(formGroupCalculator)"></asset-condition>
      </div>
      <div class="col-lg-3 col-md-12">
        <transaction-type-selection  [formControl]="formControlTransactionType"  [mark]="markTriggerSubject(formGroupCalculator)"></transaction-type-selection>
      </div>
    </div>
  </div>

  <div class="section-format mt-1 p-4">
    <div class="row main">
      <div class="col-md-10">
        <h3>Finance details</h3>
      </div>
      <div class="col-md-2" fxLayoutAlign="end center"><span class="text-muted">3/7</span></div>
    </div>
    <div class="mat-body separator-bottom">Let us know your preferred repayment terms</div>
    <div class="row main">
      <div class="col-lg-3 col-md-12">
        <loan-terms-selection  [formControl]="formControlLoanTerms" [mark]="markTriggerSubject(formGroupCalculator)"></loan-terms-selection>
      </div>
      <div class="col-lg-3 col-md-12">
        <payment-frequency-selection  [formControl]="formControlPaymentFrequency" [mark]="markTriggerSubject(formGroupCalculator)">
        </payment-frequency-selection>
      </div>
    </div>
    <div class="row main mt-3">
      <div class="col-lg-3 col-md-12">
        <finance-type-selection  [formControl]="formControlFinanceType"
          [mark]="markTriggerSubject(formGroupCalculator)"
        [optionsFilter]="ASSET_FINANCE_OPTIONS_FILTER"></finance-type-selection>
      </div>
      <div class="col-lg-3 col-md-12">
        <yes-no  title="Doc Fee financed" [formControl]="formControlDocFeeFinanced"
        [mark]="markTriggerSubject(formGroupCalculator)"></yes-no>
      </div>
    </div>

    <div class="row ml-1">
      <div class="col-lg-6 col-md-12" class="balloonWidth">
        <balloon-payment #balloonPaymentComponent="balloonPaymentComponent"  [formControl]="formControlBalloonPayment" [mark]="markTriggerSubject(formGroupCalculator)"></balloon-payment>
      </div>
    </div>
  </div>

  <div class="section-format mt-1 p-4">
    <div class="row">
      <div class="col-md-10">
        <h3>Brokerage details</h3>
      </div>
      <div class="col-md-2" fxLayoutAlign="end center"><span class="text-muted">4/7</span></div>
    </div>
    <div class="mat-body separator-bottom row col-md-6">Enter your origination fee (dollar amount up to $900 ex GST) and brokerage of amount financed (up to 8%)</div>
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <currency-input title="Origination Fee(ex GST)" [min]="0" [formControl]="formControlBrokerOriginationFee" [mark]="markTriggerSubject(formGroupCalculator)"></currency-input>
        @if ((formControlBrokerOriginationFee.value ?? 0) > 900 ) {
          <div class="Warning">Warning:Your origination fee exceeds the limit. </div>
        }
      </div>
      <div class="col-lg-3 col-md-12">
        <brokerage-selection  [formControl]="formControlBrokerage" [mark]="markTriggerSubject(formGroupCalculator)"></brokerage-selection>
      </div>
    </div>
  </div>

  <div class="section-format mt-1 p-4">
    <div class="row main">
      <div class="col-md-10">
        <h3>Risk profile</h3>
      </div>
      <div class="col-md-2" fxLayoutAlign="end center"><span class="text-muted">5/7</span></div>
    </div>
    <div class="mat-body separator-bottom row col-md-6">
    Please provide us with further details so we can provide you with a more accurate quote</div>

    <div class="row main">
      <div class="col-lg-3 col-md-12">
        <yes-no title="Property owner" [formControl]="formControlPropertyOwner" [mark]="markTriggerSubject(formGroupCalculator)"></yes-no>
      </div>
      <div class="col-lg-3 col-md-12">
        <yes-no title="Adverse on file" [formControl]="formControlAdverseOnFile" [mark]="markTriggerSubject(formGroupCalculator)"></yes-no>
      </div>
    </div>
    <div class="row main">
      <div class="col-lg-3 col-md-12">
        <yes-no title="Director Equifax score > 600" [formControl]="formControlEquifaxScoreAboveThreshold" [mark]="markTriggerSubject(formGroupCalculator)"></yes-no>
      </div>
      @if (!formControlPropertyOwner.value) {
        <div class="col-lg-3 col-md-12">
          <yes-no title="Has the business taken another commercial loan in the past 3 years?" [formControl]="formControlPreviousLoan"[mark]="markTriggerSubject(formGroupCalculator)"></yes-no>
        </div>
      }
    </div>
  </div>

  <div class="section-format mt-1 p-4">
    <div class="row main">
      <div class="col-md-10">
        <h3>ABN/GST details</h3>
      </div>
      <div class="col-md-2" fxLayoutAlign="end center"><span class="text-muted">6/7</span></div>
    </div>
    <div class="mat-body separator-bottom row col-md-6">
    Please provide us with your ABN/GST age</div>
    <div class="row main">
      <div class="col-lg-3 col-md-12">
        <yes-no title="ABN/GST age > 24 months" [formControl]="formControlAbnGstAgeAboveThreshold" [mark]="markTriggerSubject(formGroupCalculator)"></yes-no>
      </div>
    </div>
  </div>

  <div class="section-format mt-1 p-4">
    <div class="row main">
      <div class="col-md-10">
        <h3>Asset finance amount</h3>
      </div>
      <div class="col-md-2" fxLayoutAlign="end center"><span class="text-muted">7/7</span></div>
    </div>
    <div class="row main">
      <div class="col-lg-3 col-md-12">
        <currency-input title="Deposit amount" [min]="depositMin" [max]="depositMax"  [formControl]="formControlDepositAmount" [mark]="markTriggerSubject(formGroupCalculator)"></currency-input>
      </div>
      <div class="col-lg-3 col-md-12">
        <currency-input title="Loan amount" [required]="false" [readonly]="true"  [formControl]="formControlLoanAmount" [mark]="markTriggerSubject(formGroupCalculator)"></currency-input>
      </div>
    </div>
  </div>



  <div class="row mt-4">
    <div class="col-12" fxLayout="row" fxLayoutAlign="end center">
      <button mat-flat-button color="primary" class="w-lt-md-100" (click)="onCalculateEstimation($event)">Calculate repayment</button>
    </div>
  </div>
</div>
</div>
