import {Component, OnInit} from '@angular/core';
import {
  ApplicationDialogService,
  BusinessSearchFn, PortalHotToastService,
  setCurrentStepFn,
  setNextButtonText,
  setSubmitStepFn,
  setupUntilDestroy,
  setPrevNextButtonVisibilityFn,
  getUser,
} from '@portal-workspace/grow-ui-library';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ApplicationService} from '../../service/application.service';
import {Subscription} from 'rxjs';
import {
  BankStatementsAnalysisData,
  BusinessSearchValue,
  PayloadApiResponse,
  User,
  isAdmin,
  isAnalyst,
  isInternalUser
} from '@portal-workspace/grow-shared-library';
import { CdkStepper, CdkStepperModule } from '@angular/cdk/stepper';
import { CustomerAnalysisComponent } from '@portal-workspace/grow-ui-library';
import { AccountSummaryComponent } from '@portal-workspace/grow-ui-library';
import { MarkDirective } from '@portal-workspace/grow-ui-library';
import { BusinessSearchComponent } from '@portal-workspace/grow-ui-library';
import { NgTemplateOutlet } from '@angular/common';
import { ApplicationStepper2Component,setStepper2StepConfig } from '@portal-workspace/grow-ui-library';
import {Response} from 'request';
import { MatButtonModule } from '@angular/material/button';
import {WhitelabelService} from "../../service/whitelabel.service";
import {tap} from "rxjs/operators";

const DEFAULT_LOGO = 'https://growpublicaccess.blob.core.windows.net/logos/SVG/DYNA_Logo_103023_Master-Pos.svg'

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './bank-statements.page.html',
    styleUrls: ['./bank-statements.page.scss'],
    standalone: true,
    imports: [ApplicationStepper2Component, CdkStepperModule, MatButtonModule, NgTemplateOutlet, FormsModule, ReactiveFormsModule, BusinessSearchComponent, MarkDirective, AccountSummaryComponent, CustomerAnalysisComponent]
})
export class BankStatementsPage implements OnInit {
  businessSearchPlaceholder: string = "Enter name or ABN…";

  logo = DEFAULT_LOGO;

  isAdmin = isAdmin;
  isAnalyst = isAnalyst;
  isInternalUser = isInternalUser;
  loggedInUser: User | null = getUser();

  subscriptions: Subscription[] = [];

  // step 1:
  step1SearchFn!: BusinessSearchFn;
  formGroupStep1!: FormGroup<{
    business: FormControl<BusinessSearchValue>;
  }>;
  formControlStep1Business!: FormControl<BusinessSearchValue>;

  // step 2
  formGroupStep2!: FormGroup<{}>;
  data!: BankStatementsAnalysisData;

  constructor(
    private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private applicationDialogService: ApplicationDialogService,
    private toastService: PortalHotToastService,
    private whitelabelService: WhitelabelService,
  ) {}

  ngOnInit() {
    setupUntilDestroy(this);

    this.subscriptions.push(this.whitelabelService.subject.asObservable().pipe(
      tap(r => {
        if (r && r.payload.logoLink) {
          this.logo = r.payload.logoLink;
        } else {
          this.logo = DEFAULT_LOGO;
        }
      }),
    ).subscribe());

    this.initStep1();
    this.initStep2();
  }

  get abn() {
    return this.formControlStep1Business.value?.abn;
  }

  get businessName() {
    const businessSearchValue  = this.formControlStep1Business.value;
    if (businessSearchValue && businessSearchValue.type === 'search-result') {
        return businessSearchValue.result?.organisationName ?? businessSearchValue.organisationName;
    }
    return businessSearchValue?.organisationName;
  }

  initStep1() {

    this.formControlStep1Business = this.formBuilder.control(null, [Validators.required]);
    this.formGroupStep1 = this.formBuilder.group({
      business: this.formControlStep1Business,
    });
    this.step1SearchFn = this.applicationService.businessSearchFn;

    setStepper2StepConfig(this.formGroupStep1, {
      showPreviousStepButton:false,
      nextStepClickedFn: async stepper => {
        if (this.formControlStep1Business.valid) {
          this.subscriptions.push(
            this.applicationService.getBankStatementsAnalysis({ reference: this.abn as string})
              .pipe(this.toastService.spinnerObservable())
              .subscribe(
                (result: PayloadApiResponse<BankStatementsAnalysisData | null>) => {
                  if (result.status && result.payload) {
                    this.data = result.payload;
                    console.log(result);
                    if (!Object.keys(this.data).length) {
                      this.applicationDialogService.openAlertDialog({
                        message: `Alert`,
                        subMessage: `We don't have data for this business.`
                      });
                      }
                      else {
                        stepper.next();
                      }
                    }
                    else {
                      this.applicationDialogService.openAlertDialog({
                        message: `Error`,
                        subMessage: result.message
                      })
                    }
                })

          );
        }
      },
    });



  }

  initStep2() {
    this.formGroupStep2 = this.formBuilder.group({});

    setStepper2StepConfig(this.formGroupStep2, {
      nextStepClickedFn: async stepper => {
        stepper.previous();
      },
      nextStepButtonText:"Finish"
    });
  }

  onClickBack(stepper: CdkStepper) {
    if (stepper.selectedIndex) {
      this.formControlStep1Business.reset();
      stepper.previous();
    }
  }

  refreshBankStatement() {
    this.subscriptions.push(
      this.applicationService.refreshBankStatement(this.abn as string).pipe(
        this.toastService.spinnerObservable()
      ).subscribe(
        (result: PayloadApiResponse<Response | {Error: string}>) => {
          console.log('refresh result: ', result)
          if (result.status) {
            this.applicationDialogService.successDialog({
              message: 'Success',
              subMessage: 'The bank statement has been refreshed'
            }).afterClosed().subscribe(() => {
              this.applicationService.getBankStatementsAnalysis({ reference: this.abn as string})
              .pipe(this.toastService.spinnerObservable())
              .subscribe(
                (result: PayloadApiResponse<BankStatementsAnalysisData | null>) => {
                  if (result.status && result.payload) {
                    this.data = result.payload;
                    console.log(result);
                    if (!Object.keys(this.data).length) {
                      this.applicationDialogService.openAlertDialog({
                        message: `Alert`,
                        subMessage: `We don't have data for this business.`
                      });
                      }
                    }
                    else {
                      this.applicationDialogService.openAlertDialog({
                        message: `Error`,
                        subMessage: result.message
                      })
                    }
                })
            })
          } else {
            this.applicationDialogService.openAlertDialog({
              message: 'Error',
              subMessage: 'Unable to refresh this bank statement'
            })
          }
        }
      )
    )
  }
}
