import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeModule } from '@angular/material/tree';
import {FlatTreeControl} from '@angular/cdk/tree';
import {
  AccessLevel, SideMenuComponentFlatTreeNode, SideMenuComponentMenuType, SideMenuComponentTreeNode,
  SideMenuComponentTreeNodeType, isInternalUser,
  User, PortalLoginUser
} from '@portal-workspace/grow-shared-library';
import {pxToRem} from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {Observable, Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {tap} from 'rxjs/operators';
import {Priviledges} from '@portal-workspace/grow-shared-library';
import {ActivatedRoute, Data, Router} from '@angular/router';
import { environment } from 'apps/portal2/src/environments/environment';
import { MatBadgeModule } from '@angular/material/badge';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';
import { FlexModule } from '@angular/flex-layout/flex';

const ALL_ACCESS_LEVELS: Priviledges = [ 'admin' , 'analyst' , 'operations' , 'credit', 'settlement', 'salesBDM', 'salesAM' , 'companyadmin' , 'customer' , 'broker', 'vendor', 'dealer', 'internalbroker', 'salesBDM', 'salesAM' ,'lg'] ;
const ALL_BROKER_ACCESS_LEVELS: Priviledges = [ 'admin' , 'analyst'  , 'operations' , 'credit', 'settlement', 'salesBDM', 'salesAM' , 'companyadmin' , 'customer' , 'broker', 'vendor' , 'internalbroker' ,'salesBDM', 'salesAM'] ;
const ADMIN_AND_ANALYST_ACCESS_LEVELS: Priviledges = [ 'admin' , 'analyst' , 'operations' , 'credit', 'settlement', 'salesBDM', 'salesAM' ];
const NOT_SALESAM_ACCESS_LEVELS: Priviledges = [ 'admin' , 'analyst' , 'operations' , 'credit', 'settlement' ];
// const NOT_SALES_ACCESS_LEVELS: Priviledges = [ 'admin', 'companyadmin', 'analyst', 'operations' , 'credit', 'settlement']
const ADMIN_ACCESS_LEVELS: Priviledges = ['admin'];
const ADMIN_TAB_ACCESS_LEVELS: Priviledges = [ 'admin', 'companyadmin', 'analyst', 'operations' , 'credit', 'settlement', 'salesBDM', 'salesAM' ];
const LG_ACCESS_LEVELS: Priviledges = ['lg'];
const INTERNAL_LEVELS: Priviledges = [ 'admin' , 'internalbroker','salesBDM', 'salesAM', 'analyst', 'operations' , 'credit', 'settlement' ];
const DEALER_ACCESS_LEVELS: Priviledges = ['dealer'];
const PISMO_ACCOUNTS_ACCESS_LEVELS: Priviledges = ['admin', 'operations', 'analyst'];
const OPERATIONS_TAB_ACCESS_LEVELS: Priviledges = ['operations'];
const ADMIN_CREDIT_ACCESS_LEVELS: Priviledges = ['admin', 'credit'];
const ADMIN_SALES_ACCESS_LEVELS: Priviledges = ['admin' , 'analyst' , 'operations' ,'settlement', 'salesBDM', 'salesAM']
const TREE_NODES: SideMenuComponentTreeNode[] = [
  { name: 'Dashboard', icon: 'mdi-chart-box-outline', type: 'dashboard', accessLevels: ALL_ACCESS_LEVELS, children: []},
  { name: 'Applications', icon: 'mdi-view-grid-outline', type: 'applications', accessLevels: ALL_ACCESS_LEVELS, children: []},
  { name: 'New Application', type: 'new-application', icon: 'mdi-view-grid-plus-outline', accessLevels: ALL_ACCESS_LEVELS, children: []},
  { name: 'Calculators', type: 'calculator', icon: 'mdi-calculator-variant-outline', accessLevels: ALL_ACCESS_LEVELS, children: [
      { name: 'Asset Finance', icon: '', type: 'asset-finance-calculator', accessLevels:  [...DEALER_ACCESS_LEVELS, ...ALL_BROKER_ACCESS_LEVELS], children: []},
      { name: 'Business Term Loan', icon: '', type: 'business-loans-calculator', accessLevels:  [...DEALER_ACCESS_LEVELS, ...ALL_BROKER_ACCESS_LEVELS], children: []},
      { name: 'Business Overdraft', icon: '', type: 'business-overdraft-calculator', accessLevels:  [...DEALER_ACCESS_LEVELS, ...ALL_BROKER_ACCESS_LEVELS], children: []},
      { name: 'OD/BL Borrowing Estimator', icon: '', type: 'business-and-overdraft-borrowing-estimator', accessLevels:  [...DEALER_ACCESS_LEVELS, ...ALL_BROKER_ACCESS_LEVELS], children: []},
    ]},
  { name: 'Pismo Accounts', type: 'pismo-accounts', icon: 'mdi-car-pickup', accessLevels: INTERNAL_LEVELS, children: [] },
  { name: 'Bank Statements', type: 'bank-statements', icon: 'mdi-bank-outline', accessLevels: INTERNAL_LEVELS, children: [
    { name: 'Analysis', icon: '', type: 'bank-statements-analysis', accessLevels:  INTERNAL_LEVELS, children: []},
    { name: 'Status', icon: '', type: 'bank-statements-status', accessLevels:  INTERNAL_LEVELS, children: []},
  ]},
  { name: 'Documents', type: 'documents', icon: 'mdi-file-document-multiple-outline', accessLevels: INTERNAL_LEVELS, children: [] },
  { name: 'Document Worklist', type: 'document-worklist', icon: 'mdi-format-list-bulleted', accessLevels: INTERNAL_LEVELS, children: []},
  { name: 'Redbook', type: 'redbook', icon: 'mdi-car-back', accessLevels: INTERNAL_LEVELS, children: []},
  { name: 'Accreditation', type: 'accreditation', icon: 'mdi-file-settings-outline', accessLevels: INTERNAL_LEVELS, children: [] },

];

const ADMIN_TREE_NODES: SideMenuComponentTreeNode[] = [
  { name: 'Admin', type: 'admin', icon: 'mdi-security', accessLevels: ADMIN_TAB_ACCESS_LEVELS, children: [
      { name: 'Users', type: 'users', accessLevels: ADMIN_TAB_ACCESS_LEVELS, children: []},
      { name: 'Pending Approvals', type: 'pending-approvals', accessLevels: ADMIN_SALES_ACCESS_LEVELS, children: []},
      { name: 'Unverified Users', type: 'unverified-users', accessLevels: ADMIN_SALES_ACCESS_LEVELS, children: []},
      { name: 'Rate Card', type: 'rate-cards', accessLevels: NOT_SALESAM_ACCESS_LEVELS, children: []},
      { name: 'Customers', type: 'customers', accessLevels: ADMIN_AND_ANALYST_ACCESS_LEVELS, children: []},
      { name: 'Companies', type: 'companies', accessLevels: ADMIN_ACCESS_LEVELS, children: []},
      { name: 'Reporting', type: 'reporting', accessLevels: NOT_SALESAM_ACCESS_LEVELS, children: []},
      { name: 'Transactions', type: 'transactions', accessLevels: [...ADMIN_ACCESS_LEVELS, ...OPERATIONS_TAB_ACCESS_LEVELS], children: []},
      { name: 'Approval Transactions', type: 'pending-approval-transactions', accessLevels: PISMO_ACCOUNTS_ACCESS_LEVELS, children: []},
      { name: 'Audit Logs', type: 'audit-logs', accessLevels: NOT_SALESAM_ACCESS_LEVELS, children: [] },
      { name: 'Notifications', type: 'notifications-management', accessLevels: ADMIN_ACCESS_LEVELS, children: []},
      { name: 'Set Maintenance', type: 'set-maintenance', accessLevels: ADMIN_ACCESS_LEVELS, children: []},
      { name: 'Oauth', type: 'oauth', accessLevels: ADMIN_ACCESS_LEVELS, children: []},
      { name: 'Promotion', type: 'promotion', accessLevels: ADMIN_ACCESS_LEVELS, children: []},
      { name: 'Bank Statement Configuration', type: 'bsa-settings', accessLevels: ADMIN_CREDIT_ACCESS_LEVELS, children: []},
      { name: 'Whitelabel', type: 'whitelabel-settings', accessLevels: ADMIN_ACCESS_LEVELS, children: []},
    ]},
];


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'sidemenu',
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.scss'],
    standalone: true,
    imports: [FlexModule, NgClass, ExtendedModule, MatBadgeModule, MatTreeModule]
})
export class SideMenuComponent implements OnInit {
  ADMIN_TAB_ACCESS_LEVELS = ADMIN_TAB_ACCESS_LEVELS;

  @Input({required: true}) unreadNotificationsCountObservable?: Observable<number>;
  @Input({required: false}) user: PortalLoginUser | null = null;
  @Output() events: EventEmitter<SideMenuComponentFlatTreeNode>;
  @Output() menuTypeEvent: EventEmitter<SideMenuComponentMenuType>;

  subscriptions: Subscription[] = [];

  unreadNotificationsCount = 0;

  menuItemId: SideMenuComponentTreeNodeType | SideMenuComponentMenuType | '' = '';

  pxToRem = pxToRem;
  isInternalUser = isInternalUser;

  // product tree
  treeDataSource: MatTreeFlatDataSource<SideMenuComponentTreeNode, SideMenuComponentFlatTreeNode>;
  treeControl: FlatTreeControl<SideMenuComponentFlatTreeNode>;
  treeFlattener: MatTreeFlattener<SideMenuComponentTreeNode, SideMenuComponentFlatTreeNode>;

  // admin tree
  treeDataSource2: MatTreeFlatDataSource<SideMenuComponentTreeNode, SideMenuComponentFlatTreeNode>;
  treeControl2: FlatTreeControl<SideMenuComponentFlatTreeNode>;
  treeFlattener2: MatTreeFlattener<SideMenuComponentTreeNode, SideMenuComponentFlatTreeNode>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.events = new EventEmitter();
    this.menuTypeEvent = new EventEmitter<SideMenuComponentMenuType>();

    // product tree
    this.treeFlattener = new MatTreeFlattener<SideMenuComponentTreeNode, SideMenuComponentFlatTreeNode>(
      (treeNode: SideMenuComponentTreeNode, level: number) => ({
        expandable: !!treeNode.children && treeNode.children.length > 0,
        level,
        treeNode
      }),
      (flatTreeNode: SideMenuComponentFlatTreeNode) => flatTreeNode.level,
      (flatTreeNode: SideMenuComponentFlatTreeNode) => flatTreeNode.expandable,
      (treeNode: SideMenuComponentTreeNode) => treeNode.children);
    this.treeControl = new FlatTreeControl(node => node?.level ?? 0, node => node?.expandable ?? true);
    this.treeDataSource = new MatTreeFlatDataSource<SideMenuComponentTreeNode, SideMenuComponentFlatTreeNode>(this.treeControl, this.treeFlattener, TREE_NODES);

    // admin tree
    this.treeFlattener2 = new MatTreeFlattener<SideMenuComponentTreeNode, SideMenuComponentFlatTreeNode>(
      (treeNode: SideMenuComponentTreeNode, level: number) => ({
        expandable: !!treeNode.children && treeNode.children.length > 0,
        level,
        treeNode
      }),
      (flatTreeNode: SideMenuComponentFlatTreeNode) => flatTreeNode.level,
      (flatTreeNode: SideMenuComponentFlatTreeNode) => flatTreeNode.expandable,
      (treeNode: SideMenuComponentTreeNode) => treeNode.children);
    this.treeControl2 = new FlatTreeControl(node => node.level, node => node.expandable);
    this.treeDataSource2 = new MatTreeFlatDataSource<SideMenuComponentTreeNode, SideMenuComponentFlatTreeNode>(this.treeControl2, this.treeFlattener2, ADMIN_TREE_NODES);
  }

  hasAccessLevels(requiredAccessLevels: Priviledges): boolean {
    if (this.user && this.user.priviledges) {
      if (this.user.priviledges.includes('lg')) {
        // lg user
        return requiredAccessLevels.some((r ) => r.includes('lg'));
      } else {
        // broker
        return requiredAccessLevels.some((r ) => this.user?.priviledges.includes(r))
      }
    }
    return false;
  }

  readonly flatTreeNodeHasNoChild = (level: number, flatTreeNode: SideMenuComponentFlatTreeNode) => {
    return (!flatTreeNode.expandable);
  }

  readonly flatTreeNodeHasChild = (level: number, flatTreeNode: SideMenuComponentFlatTreeNode) => {
    return (flatTreeNode.expandable);
  }

  ngOnInit(): void {
    console.log('environment', environment.production)
    setupUntilDestroy(this);

    // get current menuItems
    const routeSplits = this.router.url.split('/');
    if (routeSplits && routeSplits.length >= 3) {
      this.menuItemId = routeSplits[2] == 'application' ? 'applications' : routeSplits[2] as any;
      const index1 = this.getExpandableTreeNodeIndexFromTree1();
      if (index1 !== -1) {
        this.treeControl.expand(this.treeControl.dataNodes[index1]);
      }
      const index2 = this.getExpandableTreeNodeIndexFromTree2();
      if (index2 !== -1) {
        this.treeControl2.expand(this.treeControl2.dataNodes[index2]);
      }
    }

    if (this.unreadNotificationsCountObservable) {
      const sub = this.unreadNotificationsCountObservable.pipe(
        tap(r => {
          if (r) {
            this.unreadNotificationsCount = r;
          }
        })
      ).subscribe();
      this.subscriptions.push(sub);
    }
  }

  onTreeMenuClick($event: MouseEvent, flatTreeNode: SideMenuComponentFlatTreeNode) {
    if (flatTreeNode.treeNode.type) {
      this.menuItemId = flatTreeNode.treeNode.type;
      this.events.emit(flatTreeNode);
    }
  }

  onMenuClick($event: Event, type: SideMenuComponentMenuType) {
    this.menuItemId = type;
    this.menuTypeEvent.emit(type)
  }

  isExpandableNodeActivated(type: SideMenuComponentTreeNode['type']) {
    switch(type) {
      case 'calculator':
        return [
          'asset-finance-calculator',
          'business-loans-calculator',
          'business-overdraft-calculator',
          'business-and-overdraft-borrowing-estimator',
        ].includes(this.menuItemId);
      case 'bank-statements':
        return [
          'bank-statements-analysis',
          'bank-statements-status',
        ].includes(this.menuItemId);
      case 'document-worklist':
        return [
          'document-worklist',
          'credit-worklist',
          'client-services-worklist',
          'settlements-worklist',
        ].includes(this.menuItemId);
      case 'admin':
        return [
          'users',
          'pending-approvals',
          'unverified-users',
          'rate-cards',
          'customers',
          'companies',
          'reporting',
          'transactions',
          'pending-approval-transactions',
          'audit-logs',
          'notifications-management',
          'set-maintenance',
          'oauth',
          'promotion',
          'bsa-settings',
          'whitelabel-settings',
        ].includes(this.menuItemId);
    }
    return false;
  }

  getExpandableTreeNodeIndexFromTree1() {
    if ([
        'asset-finance-calculator',
        'business-loans-calculator',
        'business-overdraft-calculator',
        'business-and-overdraft-borrowing-estimator',
      ].includes(this.menuItemId)) {
      return 3;
    } else if ([
        'bank-statement-analyst',
        'bank-statement-status',
      ].includes(this.menuItemId)) {
      return 5;
    }
    return -1;
  }

  getExpandableTreeNodeIndexFromTree2() {
    if ([
      'users',
      'pending-approvals',
      'unverified-users',
      'rate-cards',
      'customers',
      'companies',
      'reporting',
      'transations',
      'audit-logs',
      'notifications-management',
      'set-maintenance',
      'oauth',
      'promotion',
      'bsa-settings',
      'whitelabel-settings',
    ].includes(this.menuItemId)) {
      return 0;
    }
    return -1;
  }

}
