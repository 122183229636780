<div class="edit-invoice-amount-dialog">
  <mat-dialog-content>
    <div class="row">
      <div class="col-12">
        <div class="mat-subtitle-1 header">{{title}}</div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <currency-input 
          title="Invoice Amount" 
          [formControl]="formControlInvoiceAmount"
          [min]="min">
        </currency-input>
      </div>
      <div class="col-lg-6 col-md-12">
        <currency-input
          title="Deposit amount (incl GST)"
          [formControl]="formControlDepositAmount"
          [min]="minDeposit ?? null"
          [max]="maxDeposit ?? null"
          >
        </currency-input>
      </div>
    </div>
    <br>
      <div class="row">
        <div class="col" fxLayout="row" fxLayoutAlign="space-between center">
          <button mat-flat-button color="accent" class="ml-3" (click)="cancel()">Cancel</button>
          <button mat-flat-button color="primary" class="mr-3" [disabled]="formGroup.invalid" (click)="submit()">Save</button>
        </div>
      </div>
    </mat-dialog-content>
  </div>
