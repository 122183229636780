<div class="customer-asset-finance-page container-fluid">
  <div class="mat-h2">New Consumer Asset Finance Application</div>
  <application-stepper2 [linear]="false" #applicationStepper="applicationStepper2">
    <cdk-step [stepControl]="formGroupStep1" ><ng-content *ngTemplateOutlet="step1" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep1_b" ><ng-content *ngTemplateOutlet="step1_b" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep2" ><ng-content *ngTemplateOutlet="step2" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep3" ><ng-content *ngTemplateOutlet="step3" ></ng-content></cdk-step>
    @if (step3OnWardsHasSpouse()) {
      <cdk-step [stepControl]="formGroupStep4" ><ng-content *ngTemplateOutlet="step4" ></ng-content></cdk-step>
    }
    <cdk-step [stepControl]="formGroupStep5" ><ng-content *ngTemplateOutlet="step5" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep6" ><ng-content *ngTemplateOutlet="step6" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep7" ><ng-content *ngTemplateOutlet="step7" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep8" ><ng-content *ngTemplateOutlet="step8" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep9" ><ng-content *ngTemplateOutlet="step9" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep10" ><ng-content *ngTemplateOutlet="step10" ></ng-content></cdk-step>
  </application-stepper2>
</div>


<!--
##########################################
#  STEP 1:
##########################################
-->
<ng-template #step1>
  <div class="step1">
    <form [formGroup]="formGroupStep1">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <aggregator-search [mandatory]="true" [searchFn]="step1BrokerSearchFn" [title]="'Broker / Vendor'"
            [formControl]="formControlStep1Broker" [disabled]="isExternalUser"
          [mark]="applicationStepper.markTriggerSubject(formGroupStep1)"></aggregator-search>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <salesforce-contact-same-account-selection
            title="Broker Contact"
            [getFunc]="step1GetSalesforceContactSameAccountFn"
            [salesforceAccountId]="this.step1OrgSalesforceId"
            [formControl]="formControlStep1BrokerContact"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep1)"
            [isMandatory]="true">
          </salesforce-contact-same-account-selection>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="mat-h3">Customer name</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <name title="First Name" [formControl]="formControlStep1FirstName"></name>
        </div>
        <div class="col-lg-3 col-md-12">
          <name title="Last Name" [formControl]="formControlStep1LastName"></name>
        </div>
      </div>
      @if (!step1NoOtherUsersInCompany) {
        <div class="brokerCorrespondent">
          <div class="row mb-4">
            <div class="col-lg-6 col-md-12 showCorrespondent">
              <mat-checkbox color="primary" [formControl]="formControlStep1ShowCorrespondent">Add additional broker correspondent</mat-checkbox>
              <span
                matTooltip="Add in additional broker correspondent"
                matTooltipPosition="after"
              class="tooltip-icon mdi mdi-help-circle-outline ml-1"></span>
            </div>
          </div>
          @if (formControlStep1ShowCorrespondent.value) {
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <user-same-company-selection
                  title="Additional Broker Correspondent"
                  [getFunc]="step1GetUserSameCompanyFn"
                  [salesforceId]="this.step1OrgSalesforceId"
                  [formControl]="formControlStep1Correspondent"
                  [isMandatory]="false">
                </user-same-company-selection>
              </div>
            </div>
          }
        </div>
      }
    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 1_b:
##########################################
-->
<ng-template #step1_b>
  <div class="step1_b">
    <form [formGroup]="formGroupStep1_b">
      <div class="row separator-bottom">
        <div class="col-12">
          <div class="mat-h3">Know your customer</div>
          <div class="mat-body">Have you met the applicant for {{primaryApplicant}}?</div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <customer-applicant
            [formControl]="formControlStep1_bCustomerApplicantType"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep1_b)">
          </customer-applicant>
        </div>
      </div>
      <div class="row separator-bottom">
        <div class="col-12">
          <mat-checkbox color="primary" [formControl]="formControlStep1_bPrivacyStatementCheck">
            Do you have a signed privacy statement from the applicant?
          </mat-checkbox>
          @for (errorKey of errorKeys(formControlStep1_bPrivacyStatementCheck); track errorKey) {
            <mat-error>
              @if (formControlStep1_bPrivacyStatementCheck.touched && formControlStep1_bPrivacyStatementCheck.hasError(errorKey)) {
                {{errorMessage(formControlStep1_bPrivacyStatementCheck, errorKey)}}
              }
            </mat-error>
          }
        </div>
      </div>
    </form>
  </div>
</ng-template>


<!--
##########################################
#  STEP 2:
##########################################
-->
<ng-template #step2>
  <div class="step2">
    <form [formGroup]="formGroupStep2">

      <div class="row">
        <div class="col-12">
          <div class="mat-h3">How much would you like to borrow?</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <currency-input
            [min]="MIN_LOAN_AMOUNT"
            [formControl]="formControlStep2InvoiceAmount"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"
            title="Invoice amount (incl. GST)">
          </currency-input>
        </div>
      </div>

      <div class="row separator-bottom">
        <div class="col-12">
          <div class="mat-h3">Asset details</div>
          <div class="mat-body">Please provide details on the asset you would like to finance</div>
        </div>
      </div>
      <div class="row separator-bottom">
        <div class="col-12">
          <asset-selection
            [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"
            [categoryIndexFilter]="step2AssetCategoryIndexFilter"
            [searchFn]="step2AssetSearchFn"
            [formControl]="formControlStep2Asset">
          </asset-selection>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <transaction-type-selection
            [productType]="'Consumer'"
            [formControl]="formControlStep2TransactionType"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
          </transaction-type-selection>
        </div>
      </div>
      @if (step2ShowHybridField) {
        <div class="row mt-1">
          <div class="col-lg-3 col-md-12">
            <yes-no
              title="Hybrid / EV"
              [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"
              [formControl]="formControlStep2Hybrid">
            </yes-no>
          </div>
        </div>
      }


      <div class="row separator-bottom">
        <div class="col-12">
          <div class="mat-h3">Finance details</div>
          <div class="mat-body">Let us know your preferred repayment terms</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <loan-terms-selection-with-input
            title="Loan term (in months)"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"
            [min]="1"
            [max]="84"
            [formControl]="formControlStep2LoanTerms"
          />
        </div>
        <div class="col-lg-3 col-md-12">
          <payment-frequency-selection
            [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"
            [formControl]="formControlStep2PaymentFrequency">
          </payment-frequency-selection>
        </div>
      </div>


      <div class="row mt-3">
        <div class="col-lg-3 col-md-12">
          <yes-no
            title="Doc Fee financed"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"
            [formControl]="formControlStep2DocFeeFinanced">
          </yes-no>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-12 balloonWidth">
          <balloon-payment #balloonPaymentComponent="balloonPaymentComponent"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"
            [formControl]="formControlStep2BalloonPayment">
          </balloon-payment>
        </div>
      </div>


      <div class="row separator-bottom">
        <div class="col-12">
          <div class="mat-h3">Introducer details</div>
          <div class="mat-body separator-bottom">Enter the credit assistance fee</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <currency-input
            [min]="step2MinOriginationFee"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"
            [max]="step2MaxOriginationFee"
            [defaultValue]="1200"
            [disabled]="true"
            title="Credit assistance fee"
            [formControl]="formControlStep2BrokerOriginationFee">
          </currency-input>
        </div>
        <div class="col-lg-3 col-md-12">
          <currency-input
            [disableControl]="true" 
            [readonly]="true"
            title="PPSR fee"
            [formControl]="formControlStep2PpsrFee">
          </currency-input>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-lg-6 col-md-12">
          <slider
            [title]="'Rate Discount'"
            [min]="0"
            [max]="maxRateDiscount"
            [step]="0.1"
            [titlePostfix]="'%'"
            [formControl]="formControlStep2RateDiscount"
          ></slider>
        </div>
      </div> -->


      <div class="row separator-bottom">
        <div class="col-12">
          <div class="mat-h3">Applicant details</div>
          <div class="mat-body separator-bottom">Please provide us with further details so we can provide you with a more accurate quote</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <yes-no
            title="Property owner"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"
            [formControl]="formControlStep2PropertyOwner">
          </yes-no>
        </div>
        <div class="col-lg-3 col-md-12">
          <yes-no
            title="Adverse on file"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"
            [formControl]="formControlStep2AdverseOnFile">
          </yes-no>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-12">
          <yes-no
            title="Good Equifax Score"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"
            [formControl]="formControlStep2EquifaxScoreAboveThreshold">
          </yes-no>
        </div>
      </div>

      @if (!formControlStep2PropertyOwner.value) {
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <yes-no title="Has the customer taken another commercial loan in the past 3 years?"
              [formControl]="formControlStep2PreviousLoan"
              [notselected]="true"
              [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
            </yes-no>
          </div>
        </div>
      }

      <div class="row notes separator-bottom">
        <div class="col-lg-6 col-md-12">
          <span class="date-text">A good Equifax score is generally 700 or higher, indicating a robust credit profile and a consistent repayment history</span>
        </div>
      </div>


      <div class="row separator-bottom">
        <div class="col-12">
          <div class="mat-h3">Asset finance amount</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <currency-input
            [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"
            [min]="step2DepositMin"
            [max]="step2DepositMax"
            title="Deposit amount"
            [formControl]="formControlStep2DepositAmount">
          </currency-input>
        </div>
        <div class="col-lg-3 col-md-12">
          <currency-input
            [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"
            title="Loan amount"
            [required]="false"
            [readonly]="true"
            [formControl]="formControlStep2LoanAmount">
          </currency-input>
        </div>
      </div>
    </form>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="mat-h3">Rate Discount</div>
    </div>
  </div>
  <div class="row separator-bottom">
    <div class="col-lg-6 col-md-12">
      <slider
        [title]="'Rate Discount'"
        [min]="0"
        [max]="maxRateDiscount"
        [step]="0.1"
        [titlePostfix]="'%'"
        [formControl]="formControlStep2RateDiscount"
      ></slider>
    </div>
  </div>

  @if (calculator) {
    <div class="row">
      <div class="col-12">
        <div class="mat-h3">Calculations</div>
      </div>
    </div>
    <div class="row separator-bottom">
      <div class="col-lg-6 col-md-12">
        <commission-chart
          [amountFinanced]="calculator.totalAmt"
          [interestAmt]="calculator.interestAmt"
          [commission]="calculator.commission"
          [repayment]="calculator.emiAmt"
          [creditAssitanceFee]="calculator.brokerOriginationFee"
          [totalEarned]="calculator.totalEarned"
          [totalEarnedInclGst]="calculator.totalEarnedInclGst"
          [comparisonRate]="calculator.comparisonRate"
          [paymentFrequency]="calculator.repaymentFrequency"
          [interestRate]="calculator.displayedInterest"
        ></commission-chart>
      </div>
    </div>
  }
</ng-template>


<!--
##########################################
#  STEP 3:
##########################################
-->
<ng-template #step3>
  <div class="step3">
    <form [formGroup]="formGroupStep3">
      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [title]="'Primary Applicant'" [titleValue]="primaryApplicant">
          </application-header-segment>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mat-h3">Applicant details</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <title-selection
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
            [formControl]="formControlStep3Title">
          </title-selection>
        </div>
        <div class="col-lg-3 col-md-12">
          <name title="First Name" [formControl]="formControlStep3FirstName"></name>
        </div>
        <div class="col-lg-3 col-md-12">
          <name title="Last Name" [formControl]="formControlStep3LastName"></name>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-12">
          <datepicker
            title="Date of birth"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
            [formControl]="formControlStep3Dob"
            [allowOnly18YrsPlus]="true">
          </datepicker>
        </div>
        <div class="col-3">
          <gender
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
            [formControl]="formControlStep3Gender">
          </gender>
        </div>
      </div>


      <div class="row">
        <div class="col-lg-6 col-md-12">
          <custom-address
            title="Residential address"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
            [formControl]="formControlStep3ResidentialAddress">
          </custom-address>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-12">
          <time-in-address
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
            [formControl]="formControlStep3TimeInAddress">
          </time-in-address>
        </div>
      </div>


      <div class="row">
        <div class="col-lg-3 col-md-12">
          <yes-no
            title="Privacy consent obtained"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
            [notselected]="true"
            [formControl]="formControlStep3PrivacyConsent">
          </yes-no>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <property-owner-with-address
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
            [formControl]="formControlStep3PropertyOwnerWithAddress"
            [copyAddressFn]="getFormControlValueFn(formControlStep3ResidentialAddress)">
          </property-owner-with-address>
        </div>
      </div>


      <mat-divider></mat-divider>
      <div class="row separator-top">
        <div class="col-lg-6 col-md-12">
          <email [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
          [formControl]="formControlStep3Email"></email>
        </div>
        <div class="col-lg-3 col-md-12">
          <mobile [formControl]="formControlStep3Mobile" [onlyMobile]="true"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
          ></mobile>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-12">
          <maritial-status-selection
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
            [formControl]="formControlStep3MaritalStatus">
          </maritial-status-selection>
        </div>
        <div class="col-lg-3 col-md-12">
          <mat-form-field>
            <mat-label>Number of dependants</mat-label>
            <input  type="number" matInput [formControl]="formControlStep3NumberOfDependants" min="0">
            @for (errorKey of errorKeys(formControlStep3NumberOfDependants); track errorKey) {
              <mat-error>
                @if (formControlStep3NumberOfDependants.touched && formControlStep3NumberOfDependants.hasError(errorKey)) {
                  {{errorMessage(formControlStep3NumberOfDependants, errorKey)}}
                }
              </mat-error>
            }
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-12">
          <employment-status-selection
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
            [formControl]="formControlStep3EmploymentStatus">
          </employment-status-selection>
        </div>
        <div class="col-lg-6 col-md-12">
          <income
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
            [formControl]="formControlStep3RegularIncome">
          </income>
        </div>
      </div>


      <div class="row">
        <div class="col-12">
          <employer
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
            [formControl]="formControlStep3Employer">
          </employer>
        </div>
      </div>


    </form>
  </div>
</ng-template>


<!--
##########################################
#  STEP 4:
##########################################
-->
<ng-template #step4>
  <div class="step4">
    <form [formGroup]="formGroupStep4">

      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [title]="'Primary Applicant'" [titleValue]="primaryApplicant">
          </application-header-segment>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mat-h3">Partner details</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <title-selection
            [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"
            [formControl]="formControlStep4Title">
          </title-selection>
        </div>
        <div class="col-lg-3 col-md-12">
          <name title="First Name" [formControl]="formControlStep4FirstName"
          [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"></name>
        </div>
        <div class="col-lg-3 col-md-12">
          <name title="Last Name" [formControl]="formControlStep4LastName"
          [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"></name>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-12">
          <datepicker
            title="Date of birth"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"
            [formControl]="formControlStep4Dob"
            [allowOnly18YrsPlus]="true">
          </datepicker>
        </div>
        <div class="col-lg-3 col-md-12">
          <gender
            [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"
            [formControl]="formControlStep4Gender">
          </gender>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-12">
          <email [mark]="applicationStepper.markTriggerSubject(formGroupStep4)" [formControl]="formControlStep4Email"></email>
        </div>
        <div class="col-lg-3 col-md-12">
          <mobile [formControl]="formControlStep4Mobile" [onlyMobile]="true"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"
          ></mobile>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-12">
          <employment-status-selection
            [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"
            [formControl]="formControlStep4EmploymentStatus">
          </employment-status-selection>
        </div>
        <div class="col-lg-6 col-md-12">
          <income
            [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"
            [formControl]="formControlStep4RegularIncome">
          </income>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-12">
          <mat-form-field>
            <mat-label>Employer</mat-label>
            <input matInput type="text" [formControl]="formControlStep4EmployerName">
          </mat-form-field>
        </div>
        <div class="col-lg-3 col-md-12">
          <mobile [formControl]="formControlStep4EmployerContact" [onlyMobile]="false"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"
          ></mobile>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mat-h3">Is the partner willing to act as a guarantor?</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <yes-no
            title="Guarantor"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"
            [notselected]="true"
            [formControl]="formControlStep4Guarantor">
          </yes-no>
        </div>
        @if (step4ShowPrivacyConsent) {
          <div class="col-lg-3 col-md-12">
            <yes-no
              title="Privacy consent obtained (must be YES)"
              [notselected]="true"
              [mustBeTrue]="true"
              [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"
              [formControl]="formControlStep4PrivacyConsent">
            </yes-no>
          </div>
        }
      </div>

    </form>
  </div>
</ng-template>


<!--
##########################################
#  STEP 5:
##########################################
-->
<ng-template #step5>
  <div class="step5">
    <form [formGroup]="formGroupStep5">

      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [title]="'Primary Applicant'" [titleValue]="primaryApplicant">
          </application-header-segment>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mat-h3">Monthly living expenses</div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-12">
          <currency-input
            title="Home loans"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep5)"
            [formControl]="formControlStep5HomeLoans">
          </currency-input>
        </div>
        <div class="col-lg-3 col-md-12">
          <currency-input
            title="Electricity"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep5)"
            [formControl]="formControlStep5Electricity">
          </currency-input>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-12">
          <currency-input
            title="Car loans"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep5)"
            [formControl]="formControlStep5CarLoans">
          </currency-input>
        </div>
        <div class="col-lg-3 col-md-12">
          <currency-input
            title="Other utilities"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep5)"
            [formControl]="formControlStep5OtherUtilities">
          </currency-input>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-12">
          <currency-input
            title="Personal loans"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep5)"
            [formControl]="formControlStep5PersonalLoans">
          </currency-input>
        </div>
        <div class="col-lg-3 col-md-12">
          <currency-input
            title="Education"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep5)"
            [formControl]="formControlStep5Education">
          </currency-input>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-12">
          <currency-input
            title="Total credit card limits"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep5)"
            [formControl]="formControlStep5TotalCreditCardLimits">
          </currency-input>
        </div>
        <div class="col-lg-3 col-md-12">
          <currency-input
            title="Groceries"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep5)"
            [formControl]="formControlStep5Groceries">
          </currency-input>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-12">
          <currency-input
            title="Other loans"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep5)"
            [formControl]="formControlStep5OtherLoans">
          </currency-input>
        </div>
        <div class="col-lg-3 col-md-12">
          <currency-input
            title="Insurance"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep5)"
            [formControl]="formControlStep5Insurance">
          </currency-input>
        </div>
      </div>
      @if (step3OnWardsHasSpouse()) {
        <div >
          <div class="row">
            <div class="col-12">
              <div class="mat-h3">Your share of monthly living expenses</div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 col-md-12">
              <mat-form-field>
                <mat-label>Share</mat-label>
                <input  matInput type="number" min="0" [formControl]="formControlStep5ShareOfMonthlyLivingExpanses">
                <span class="mdi mdi-percent" matSuffix></span>
                @for (errorKey of errorKeys(formControlStep5ShareOfMonthlyLivingExpanses); track errorKey) {
                  <mat-error>
                    @if (formControlStep5ShareOfMonthlyLivingExpanses.touched && formControlStep5ShareOfMonthlyLivingExpanses.hasError(errorKey)) {
                      {{errorMessage(formControlStep5ShareOfMonthlyLivingExpanses, errorKey)}}
                    }
                  </mat-error>
                }
              </mat-form-field>
            </div>
          </div>
        </div>
      }

      <div class="row">
        <div class="col-12">
          <div class="mat-h3">Serviceability ratio</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-9 col-md-12">
          <serviceability-ratio
            #step5ServiceabilityRatioComponent="serviceabilityRatioComponent"
            [serviceabilityCalculationFn]="step5ServiceabilityCalculationFn">
          </serviceability-ratio>
        </div>
      </div>

    </form>
  </div>
</ng-template>


<!--
##########################################
#  STEP 6:
##########################################
-->
<ng-template #step6>
  <div class="step6">
    <form [formGroup]="formGroupStep6">

      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [title]="'Primary Applicant'" [titleValue]="primaryApplicant">
          </application-header-segment>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <reference
            [mark]="applicationStepper.markTriggerSubject(formGroupStep6)"
            [formControl]="formControlStep6References">
          </reference>
        </div>
      </div>

    </form>
  </div>
</ng-template>


<!--
##########################################
#  STEP 7:
##########################################
-->
<ng-template #step7>
  <div class="step7">
    <form [formGroup]="formGroupStep7">

      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [title]="'Primary Applicant'" [titleValue]="primaryApplicant">
          </application-header-segment>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mat-h3">Upload Driver licence</div>
        </div>
      </div>

      <div class="row separator-bottom">
        <div class="col-lg-6 col-md-12">
          <message-box type="info" title="Before uploading">
            <ul>
              <li>Ensure both the front and back of the licence are supplied</li>
              <li>Ensure the text is clear and readable</li>
              <li>If using PDF, ensure the front of the driver licence is on the first page</li>
              <li>We don’t accept pictures of digital driver licences</li>
            </ul>
          </message-box>
        </div>
      </div>


      <div class="row separator-bottom">
        <div class="col-lg-6 col-md-12">
          <uploaded-files-list [uploadedFiles]="step7UploadedDocs" (events)="onStep7DeleteUploadedDoc($event)"></uploaded-files-list>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="ellipsis mat-h3" matTooltip="{{formControlStep3FirstName.value}} {{formControlStep3LastName.value}}">Upload {{formControlStep3FirstName.value}} {{formControlStep3LastName.value}} Driver licence</div>
        </div>
      </div>
      <div class="row separator-bottom">
        <div class="col-lg-6 col-md-12">
          <upload-file
            title="{{formControlStep3FirstName.value}} {{formControlStep3LastName.value}} Driver licence"
            [mandatory]="!skipFileUploadValidation()"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep7)"
            [formControl]="formControlStep7ApplicantDriverLicenceFront">
          </upload-file>
        </div>

      </div>

      @if (step3OnWardsHasSpouse()) {
        <div class="row separator-bottom">
          <div class="col-lg-6 col-md-12">
            <upload-file
              title="{{formControlStep4FirstName.value}} {{formControlStep4LastName.value}} Driver licence"
              [mandatory]="!skipFileUploadValidation()"
              [mark]="applicationStepper.markTriggerSubject(formGroupStep7)"
              [formControl]="formControlStep7ApplicantSpouseDriverLicenceFront">
            </upload-file>
          </div>
        </div>
      }
    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 8:
##########################################
-->
<ng-template #step8>
  <div class="step8">
    <form [formGroup]="formGroupStep8">

      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [title]="'Primary Applicant'" [titleValue]="primaryApplicant">
          </application-header-segment>
        </div>
      </div>

      <div class="row separator-bottom">
        <div class="col-12">
          <div class="mat-h3">Other supporting documents</div>
          <div class="mat-body">Attach supporting documents, such as system quotation, signed privacy consent(s) and pay slips.</div>
        </div>
      </div>

      <div class="row separator-bottom">
        <div class="col-lg-6 col-md-12">
          <uploaded-files-list   [uploadedFiles]="step8UploadedDocs" (events)="onStep8DeleteUploadedDoc($event)"></uploaded-files-list>
        </div>
      </div>

      <div class="row separator-bottom">
        <div class="col-lg-6 col-md-12">
          <upload-files-multi-tag-dialog
            #uploadFilesMultiTagDialogComponent="uploadFilesMultiTagDialogComponent"
            [docs]="formControlStep8OtherSupportingDocuments.value ?? []"
            [metadata]="step8OtherDocMetadata"
            [defaultTags]="applicationDefaultDocuments(true)"
            (uploadDocs)="onStep8UploadOtherDoc($event)"
            (deleteDoc)="onStep8DeleteSupportingDoc($event)"
          ></upload-files-multi-tag-dialog>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 9:
##########################################
-->
<ng-template #step9>
  <div class="step9">
    <form [formGroup]="formGroupStep9">

      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [title]="'Primary Applicant'" [titleValue]="primaryApplicant">
          </application-header-segment>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mat-subtitle-1">Overview</div>
        </div>
      </div>

      <div class="row separator-bottom">
        <div class="col-lg-6 col-md-12">
          <mat-form-field>
            <mat-label>Application notes</mat-label>
            <textarea matInput rows="10" wrap="hard" maxlength="7000"  [formControl]="formControlStep9ApplicationNotes"></textarea>
          </mat-form-field>
        </div>
      </div>

    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 10:
##########################################
-->
<ng-template #step10>
  <div class="step10">
    <form [formGroup]="formGroupStep10">

      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [title]="'Primary Applicant'" [titleValue]="primaryApplicant">
          </application-header-segment>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <consumer-finance-summary [summary]="step10Summary"></consumer-finance-summary>
        </div>
      </div>

    </form>
  </div>
</ng-template>
