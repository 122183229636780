<div class="reset-2fa-code-page container-fluid">

  @if (loader.loading.inProgress$ | async) {
    <custom-content-loader type="list"></custom-content-loader>
  }
  @if (user) {
    <div fxLayout="column"
      fxLayoutAlign="center center"
      >
      <!--mat-card appearance="outlined" style="max-width: 22%"-->
      <mat-card appearance="outlined">
        <mat-card-content>
          <div class="text-center">
            <div class="mat-h3 text-center"
              fxLayout="row"
              fxLayoutAlign="center center">
              To reset your 2FA, input the code that is send to you via SMS
            </div>
            <div class="mat-h4 text-center">
            We’ll text the verification code to your +61 **** {{this.user.MobileNumber!.slice(-3)}} phone</div>
            <div>
              <div>
                <mat-form-field>
                  <mat-label>SMS reset code</mat-label>
                  <input matInput [formControl]="formControlSmsResetCode">
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-12 p-0">
                <div class="separator-bottom"
                  fxFlex="1 1 auto"
                  fxLayout="row"
                  fxLayout.lt-md="column"
                  fxLayoutAlign="center center"
                  fxLayoutAlign.lt-md="center center">
                  <div class="mat-body bottom-text icon-hover mr-1" style="font-weight: 500;">Haven’t got a code?</div>
                  <a class="cursor-pointer" (click)="sendSmsVerification($event)" >Resend SMS<span class="mdi mdi-arrow-right"></span></a>
                </div>
              </div>
            </div>
            <div fxLayout="row"
              fxLayoutGap="10px"
              fxLayout.lt-md="column"
              fxLayoutAlign="center center">
              <button mat-flat-button class="w-100" color="primary"
              (click)="verifySms($event)" [disabled]="formControlSmsResetCode.invalid">Reset 2FA</button>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12"
              fxLayout="column"
              fxLayoutAlign="center center">
              <a (click)="onTopMenuEvent('logout')" class="cursor-pointer">
                <span class="mdi mdi-arrow-left mr-2"></span>Go back to Login
              </a>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  }
</div>
