export type TransactionType = 'Dealer Sale' | 'Private Sale' | 'Sales and Lease Back' | 'Capital Raise' | 'Refinance' | 'Balloon Refinance';
export type TransactionValue = { type: TransactionType , name: string} | null;
export const TransactionValueOptions: Exclude<TransactionValue, null>[] = [
  {type: 'Dealer Sale', name: 'Dealer Sale' },
  {type: 'Private Sale', name: 'Private Sale'},
  {type: 'Sales and Lease Back', name: 'Sales and Lease Back'},
  {type: 'Capital Raise', name: 'Capital Raise'},
  {type: 'Refinance', name: 'Refinance'},
  {type: 'Balloon Refinance', name: 'Balloon Refinance'},
];

export type PrivateSaleType = 'Yes' | 'No' | 'Sales and Lease Back' | 'Capital Raise' | 'Refinance' | 'Balloon Refinance';

export const TransactionValueOptionsForConsumer: Exclude<TransactionValue, null>[] = [
  {type: 'Dealer Sale', name: 'Dealer Sale' },
  {type: 'Private Sale', name: 'Private Sale'},
  {type: 'Capital Raise', name: 'Capital Raise'},
  {type: 'Refinance', name: 'Refinance'},
  {type: 'Balloon Refinance', name: 'Balloon Refinance'},
];
