import {Component, forwardRef, Input, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormGroup, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {distinctUntilChanged, tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {formControlErrorKeys, formControlErrorMessage, setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {
  compareMatch,
  TransactionValue,
  TransactionValueOptions,
  TransactionValueOptionsForConsumer,
} from '@portal-workspace/grow-shared-library';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DisableControlDirective } from '../../directives/disable-control.directive';

@UntilDestroy()
@Component({
    selector: 'transaction-type-selection',
    templateUrl: './transaction-type-selection.component.html',
    styleUrls: ['./transaction-type-selection.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TransactionTypeSelectionComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => TransactionTypeSelectionComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatOptionModule, DisableControlDirective]
})
export class TransactionTypeSelectionComponent extends AbstractControlValueAccessor<TransactionValue> implements OnInit, Mark{

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  compareWithFn: (option: TransactionValue, selected: TransactionValue) => boolean = (option, selected) => {
    return (!!option && !!selected && option.type === selected.type);
  };

  formGroup: FormGroup<{
    selection: FormControl<TransactionValue>;
  }>;
  formControl: FormControl<TransactionValue>;
  subscription: Subscription;

  options = TransactionValueOptions;

  @Input({required: false}) productType: 'AssetFinance' | 'Consumer' = 'AssetFinance';

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControl = formBuilder.control(null, [Validators.required]);
    this.formGroup = formBuilder.group({
      selection: this.formControl
    });
    this.subscription = this.formControl.valueChanges.pipe(
      distinctUntilChanged(compareMatch),
      tap((r: TransactionValue) => {
        this.propagateChange(r);
      })
    ).subscribe();
  }

  ngOnInit(): void {
    setupUntilDestroy(this);

    if (this.productType === 'Consumer') {
      this.options = TransactionValueOptionsForConsumer;
    }
  }


  doWriteValue(v: TransactionValue): void | TransactionValue {
    if (v) {
      const op = this.options.find(o => o.type === v.type);
      if (op) {
        this.formControl.setValue(op);
        return op;
      }
    }
    return undefined;
  }
  mark() {
    this.formGroup.markAllAsTouched();
  }

  reset(): void {
    this.formControl.reset();
  }
}
