import {Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {AccountSummary, Application, BankStatementDecisioningSelection, BankStatementsAnalysisData, BankStatementsAnalysisTransactionData, CreateApplicationNoteFn, GetBsaCalculatorFn, GetBsaExcludedLenderListFn, GetBsaLenderListFn, GetDscrCalculatorHistoryFn, GetDscrCalculatorValueFn, RemoveApplicationNoteByNoteIdFn, SaveBsaCalculatorFn, UpdateDscrCalculatorValueFn} from '@portal-workspace/grow-shared-library';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { DayEndBalanceChartComponent } from './day-end-balance-chart.component';
import { DecisionMetricsComponent } from './decision-metrics.component';
import { TransactionSummaryComponent } from './transaction-summary.component';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { BankStatementOverviewComponent } from './bank-statement-overview.component';
import { BankStatementLoanPaymentComponent } from './bank-statement-loan-payment.component';
import { BankStatementIncomeComponent } from './bank-statement-income.component';
import { BankStatementDscrCalculatorComponent } from './bank-statement-dscr-calculator.component';
import { BankStatementAtoPaymentComponent } from './bank-statement-ato-payment.component';
import { Subscription } from 'rxjs';
import { ApplicationDialogService } from '../application-dialog-component/application-dialog.service';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { GetNotesByApplicationIdFn } from '../asset-listing-component/notes.component';

export type BankStatementTab = {selectionType: BankStatementDecisioningSelection, name: string, index: number};

@Component({
    selector: 'bank-statement-decisioning',
    templateUrl: './bank-statement-decisioning.component.html',
    styleUrls: ['./bank-statement-decisioning.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    standalone: true,
    imports: [MatTableModule, MatTabsModule, NgClass, BankStatementLoanPaymentComponent, BankStatementAtoPaymentComponent, BankStatementDscrCalculatorComponent, ExtendedModule, TransactionSummaryComponent, DecisionMetricsComponent, DayEndBalanceChartComponent, LooseCurrencyPipe, BankStatementOverviewComponent, BankStatementIncomeComponent]
})
export class BankStatementDecisioningComponent implements OnInit, OnChanges {
  @Input({required: true}) data!: BankStatementsAnalysisData;
  @Input({required: true}) getBsaLenderListFn!: GetBsaLenderListFn;
  @Input({required: true}) getBsaExcludedLenderListFn!: GetBsaExcludedLenderListFn;
  @Input({required: true}) getDscrCalculatorValueFn!: GetDscrCalculatorValueFn;
  @Input({required: true}) updateDscrCalculatorValueFn!: UpdateDscrCalculatorValueFn;
  @Input({required: true}) application!: Application;
  @Input({required: true}) saveBsaCalculatorFn!: SaveBsaCalculatorFn;
  @Input({required: true}) getBsaCalculatorFn!: GetBsaCalculatorFn;
  @Input({required: true}) getDscrCalculatorHistoryFn!: GetDscrCalculatorHistoryFn;
  @Input() getNotesByApplicationIdFn!: GetNotesByApplicationIdFn;
  @Input() createApplicationNoteFn!: CreateApplicationNoteFn;
  @Input() removeApplicationNoteByNoteIdFn!: RemoveApplicationNoteByNoteIdFn;
  @ViewChild('bsTabs') tabGroup!: MatTabGroup;

  subscriptions: Subscription[] = [];
  currentSection: BankStatementDecisioningSelection = 'overview';
  tabs: BankStatementTab[] = [];
  selectedAccounts: number[] = [];

  dataSource: AccountSummary[] = [];
  columnsToDisplay = ['icon', 'BankName', 'BSBAccNumber', 'AccHolder', 'AccName', 'currentBalance', 'TotalCredit', 'LoanCreditFlag'];
  expandedElement!: AccountSummary | null;

  confirmLoanPayment: boolean = false;
  confirmIncome: boolean = false;
  confirmAtoPayment: boolean = false;
  loanPaymentTransactions: BankStatementsAnalysisTransactionData[] = [];
  incomeTransactions: BankStatementsAnalysisTransactionData[] = [];
  atoPaymentTransactions: BankStatementsAnalysisTransactionData[] = [];

  constructor(
    private applicationDialogService: ApplicationDialogService,
    private toastService: PortalHotToastService,
  ) {}

  ngOnInit(): void {
    this.dataSource = this.data?.AccountSummary;
    console.log('data source: ', this.dataSource);

    this.tabs = [
      { selectionType: 'overview', name: 'Overview', index: 0 },
      { selectionType: 'loan payment', name: 'Loan Payments', index: 1 },
      { selectionType: 'income', name: 'Income', index: 2 },
      { selectionType: 'ato payment', name: 'ATO Payments', index: 3 },
      { selectionType: 'dscr calculator', name: 'DSCR Calculator', index: 4 },
    ]

    this.subscriptions.push(
      this.getBsaCalculatorFn(this.application.ApplicationId).pipe(
        this.toastService.spinnerObservable()
      ).subscribe(result => {
        if (result) {
          this.selectedAccounts = result.selectedAccounts;
          this.loanPaymentTransactions = result.loanPaymentTransactions;
          this.incomeTransactions = result.incomeTransactions;
          this.atoPaymentTransactions = result.atoPaymentTransactions;

          this.confirmLoanPayment = true;
          this.confirmIncome = true;
          this.confirmAtoPayment = true;
        }
      })
    )
  }

  async setCurrentSelection(tabIndex: number) {
    const tabFound = this.tabs.find(t => t.index == tabIndex);
    const selection = tabFound ? tabFound.selectionType : 'overview' /* default selection if not found */ ;
    this.currentSection = selection as BankStatementDecisioningSelection;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dataSource = this.data?.AccountSummary;
  }

  onSelectAccounts(selectedAccounts: number[]) {
    console.log(selectedAccounts);
    this.selectedAccounts = selectedAccounts;
    this.confirmLoanPayment = false;
    this.confirmIncome = false;
    this.confirmAtoPayment = false;

    this.subscriptions.push(
      this.saveBsaCalculatorFn({
        applicationId: this.application.ApplicationId,
        data: {
          selectedAccounts: this.selectedAccounts
        },
        saveDscrCalculator: false,
      }).pipe(
        this.toastService.spinnerObservable()
      ).subscribe(() => {
        this.tabGroup.selectedIndex = 0;
      })
    )
  }

  updataLoanPaymentTransactions(data: BankStatementsAnalysisTransactionData[]) {
    this.loanPaymentTransactions = data;
    this.confirmLoanPayment = true;

    this.subscriptions.push(
      this.saveBsaCalculatorFn({
        applicationId: this.application.ApplicationId,
        data: {
          loanPaymentTransactions: this.loanPaymentTransactions
        },
        saveDscrCalculator: false,
      }).pipe(
        this.toastService.spinnerObservable()
      ).subscribe(() => {
        this.tabGroup.selectedIndex = 2;
      })
    )
    
    // this.subscriptions.push(
    //   this.applicationDialogService.successDialog({
    //     message: 'Success',
    //     subMessage: 'Loan Payments Confirmed',
    //   }).afterClosed().subscribe(() => {
    //     this.tabGroup.selectedIndex = 2;
    //   })
    // )
  }

  updateIncomeTransactions(data: BankStatementsAnalysisTransactionData[]) {
    this.incomeTransactions = data;
    this.confirmIncome = true;
    
    this.subscriptions.push(
      this.saveBsaCalculatorFn({
        applicationId: this.application.ApplicationId,
        data: {
          incomeTransactions: this.incomeTransactions
        },
        saveDscrCalculator: false,
      }).pipe(
        this.toastService.spinnerObservable()
      ).subscribe(() => {
        this.tabGroup.selectedIndex = 3;
      })
    )

    // this.subscriptions.push(
    //   this.applicationDialogService.successDialog({
    //     message: 'Success',
    //     subMessage: 'Income Confirmed',
    //   }).afterClosed().subscribe(() => {
    //     this.tabGroup.selectedIndex = 3;
    //   })
    // )
  }

  updateAtoPaymentTransactions(data: BankStatementsAnalysisTransactionData[]) {
    this.atoPaymentTransactions = data;
    this.confirmAtoPayment = true;

    this.subscriptions.push(
      this.saveBsaCalculatorFn({
        applicationId: this.application.ApplicationId,
        data: {
          atoPaymentTransactions: this.atoPaymentTransactions
        },
        saveDscrCalculator: false,
      }).pipe(
        this.toastService.spinnerObservable()
      ).subscribe(() => {
        this.tabGroup.selectedIndex = 4;
      })
    )

    // this.subscriptions.push(
    //   this.applicationDialogService.successDialog({
    //     message: 'Success',
    //     subMessage: 'ATO Payments Confirmed',
    //   }).afterClosed().subscribe(() => {
    //     this.tabGroup.selectedIndex = 4;
    //   })
    // )
  }
}
