import {Component, inject, Input, OnInit} from "@angular/core";
import {
  GetDscrCalculatorValueFn,
  UpdateDscrCalculatorValueFn,
  CurrencyInputValue,
  DscrIndustry,
  DscrIndustryEbitda,
  calcApplicationDscrCalculatorValue,
  Application,
  getPaymentPeriod,
  BankStatementsAnalysisTransactionData,
  BankStatementsAnalysisCategory,
  BankStatementsAnalysisTransactionDetails,
  TransactionTag,
  SaveBsaCalculatorFn,
  GetBsaCalculatorFn,
  CreateApplicationNoteFn,
  RemoveApplicationNoteByNoteIdFn,
  GetDscrCalculatorHistoryFn,
  DscrCalculatorHistory,
} from "@portal-workspace/grow-shared-library";
import {UntilDestroy} from "@ngneat/until-destroy";
import {combineLatest, debounceTime, skip, Subscription, tap} from "rxjs";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {CurrencyInputComponent} from "../currency-selection-component/currency-input.component";
import {MatCardModule} from "@angular/material/card";
import {FlexModule} from "@angular/flex-layout/flex";
import {LooseCurrencyPipe} from "../../pipes/loose-currency.pipe";
import {
  createThreeDecimalInputMask,
  formControlErrorKeys,
  formControlErrorMessage,
  setupUntilDestroy
} from "../component-utils";
import { PortalHotToastService } from "../portal-hot-toast-component/hot-toast.service";
import { DscrIndustrySelectionComponent } from "../dscr-industry-selection-component/dscr-industry-selection.component";
import { DscrIndustryEbitdaSelectionComponent } from "../dscr-industry-selection-component/dscr-industry-ebitda-selection.component";
import {InputMaskModule} from "@ngneat/input-mask";
import _ from 'lodash';
import { MatButtonModule } from '@angular/material/button';
import { AssetNotesComponent } from '../asset-listing-component/asset-notes.component';
import { GetNotesByApplicationIdFn } from "../asset-listing-component/notes.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatDividerModule } from "@angular/material/divider";
import { MatTableModule } from '@angular/material/table';
import moment from 'moment';
import { NgClass } from "@angular/common";

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'bank-statement-dscr-calculator',
    templateUrl: './bank-statement-dscr-calculator.component.html',
    styleUrls: ['./bank-statement-dscr-calculator.component.scss'],
    standalone: true,
  imports: [
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    CurrencyInputComponent,
    MatCardModule,
    FlexModule,
    LooseCurrencyPipe,
    DscrIndustrySelectionComponent,
    DscrIndustryEbitdaSelectionComponent,
    InputMaskModule,
    MatButtonModule,
    AssetNotesComponent,
    MatCheckboxModule,
    MatExpansionModule,
    MatDividerModule,
    MatTableModule,
    NgClass,
],
})
export class BankStatementDscrCalculatorComponent implements OnInit {

    createThreeDecimalInputMask = createThreeDecimalInputMask();
    errorKeys = formControlErrorKeys;
    errorMessage = formControlErrorMessage;
    moment = moment;

    @Input({required: true}) getDscrCalculatorValueFn!: GetDscrCalculatorValueFn;
    @Input({required: true}) updateDscrCalculatorValueFn!: UpdateDscrCalculatorValueFn;
    @Input({required: true}) application!: Application;
    @Input({required: true}) loanPaymentTransactions: BankStatementsAnalysisTransactionData[] = [];
    @Input({required: true}) incomeTransactions: BankStatementsAnalysisTransactionData[] = [];
    @Input({required: true}) atoPaymentTransactions: BankStatementsAnalysisTransactionData[] = [];
    @Input({required: true}) data: BankStatementsAnalysisCategory[] = [];
    @Input({required: true}) selectedAccounts: number[] =[];
    @Input({required: true}) saveBsaCalculatorFn!: SaveBsaCalculatorFn;
    @Input({required: true}) getBsaCalculatorFn!: GetBsaCalculatorFn;
    @Input({required: true}) getDscrCalculatorHistoryFn!: GetDscrCalculatorHistoryFn;
    @Input() getNotesByApplicationIdFn!: GetNotesByApplicationIdFn;
    @Input() createApplicationNoteFn!: CreateApplicationNoteFn;
    @Input() removeApplicationNoteByNoteIdFn!: RemoveApplicationNoteByNoteIdFn;

    columnsToDisplay: string[] = ['lastUpdatedTime', 'updatedBy', 'data'];
    dataSource: DscrCalculatorHistory[] = [];
    subscriptions: Subscription[] = [];
    hotToastService!: PortalHotToastService;
    allTransactions: BankStatementsAnalysisTransactionData[] = [];
    maxDays: number = 0;
    // paymentPeriod!: string

    formBuilder!: FormBuilder;
    formGroup!: FormGroup<{
      industry: FormControl<DscrIndustry | null>,
      industryEbitda: FormControl<DscrIndustryEbitda | null>,
      adjustmentPercent: FormControl<CurrencyInputValue>,
      monthlySalesAverage3Months: FormControl<CurrencyInputValue>,
      monthlySalesAverage6Months: FormControl<CurrencyInputValue>,
      monthlySalesAverage12Months: FormControl<CurrencyInputValue>,
      averageMonthlySalesAdopted: FormControl<CurrencyInputValue>,
      salesAdjustment: FormControl<CurrencyInputValue>,
      averageMonthlySalesAdoptedAfterAdjustment: FormControl<CurrencyInputValue>,
      existingDebtCommitmentsMonthly: FormControl<CurrencyInputValue>,
      atoPaymentPlanMonthly: FormControl<CurrencyInputValue>,
      additionalCommitments: FormControl<CurrencyInputValue>,
  }>;
    formControlCustomerName!: FormControl<string | null>; //application
    formControlLoanAmount!: FormControl<CurrencyInputValue>; //application
    formControlIndustry!: FormControl<DscrIndustry | null>;
    formControlIndustryEbitda!: FormControl<DscrIndustryEbitda | null>;
    formControlAdjustmentPercent!: FormControl<CurrencyInputValue>;
    formControlMarginAdoptedPrecent!: FormControl<CurrencyInputValue>; //calculated
    formControlMonthlySalesAverage3Months!: FormControl<CurrencyInputValue>;
    formControlMonthlySalesAverage6Months!: FormControl<CurrencyInputValue>;
    formControlMonthlySalesAverage12Months!: FormControl<CurrencyInputValue>;
    formControlAverageMonthlySalesAdopted!: FormControl<CurrencyInputValue>;
    formControlSalesAdjustment!: FormControl<CurrencyInputValue>;
    formControlAverageMonthlySalesAdoptedAfterAdjustment!: FormControl<CurrencyInputValue>;
    formControlCashAvailableForDebtServiceMonthly!: FormControl<CurrencyInputValue>; //calculated
    formControlExistingDebtCommitmentsMonthly!: FormControl<CurrencyInputValue>;
    formControlAtoPaymentPlanMonthly!: FormControl<CurrencyInputValue>;
    formControlAdditionalCommitments!: FormControl<CurrencyInputValue>;
    formControlProposedCommitmentsMonthly!: FormControl<CurrencyInputValue>; //application
    formControlTotalFinancingCommitmentsMonthly!: FormControl<CurrencyInputValue>; //calculated
    formControlDebtServiceCoverRatio!: FormControl<CurrencyInputValue>; //calculated
    formControlSelectMonthlySalesAverage3Months!: FormControl<boolean | null>;
    formControlSelectMonthlySalesAverage6Months!: FormControl<boolean | null>;
    formControlSelectMonthlySalesAverage12Months!: FormControl<boolean | null>;

    constructor() {
      this.formBuilder = inject(FormBuilder);
      this.hotToastService = inject(PortalHotToastService);
      this.formControlCustomerName = this.formBuilder.control(null);
      this.formControlCustomerName.disable();
      this.formControlLoanAmount = this.formBuilder.control(null);
      this.formControlLoanAmount.disable();
      this.formControlIndustry = this.formBuilder.control(null);
      this.formControlIndustryEbitda = this.formBuilder.control(null);
      this.formControlAdjustmentPercent = this.formBuilder.control(null);
      this.formControlMarginAdoptedPrecent = this.formBuilder.control(null);
      this.formControlMarginAdoptedPrecent.disable();
      this.formControlMonthlySalesAverage3Months = this.formBuilder.control(null);
      this.formControlMonthlySalesAverage6Months = this.formBuilder.control(null);
      this.formControlMonthlySalesAverage12Months = this.formBuilder.control(null);
      this.formControlAverageMonthlySalesAdopted = this.formBuilder.control(null);
      this.formControlSalesAdjustment = this.formBuilder.control(null);
      this.formControlAverageMonthlySalesAdoptedAfterAdjustment = this.formBuilder.control(null);
      this.formControlCashAvailableForDebtServiceMonthly = this.formBuilder.control(null);
      this.formControlCashAvailableForDebtServiceMonthly.disable();
      this.formControlExistingDebtCommitmentsMonthly = this.formBuilder.control(null);
      this.formControlAtoPaymentPlanMonthly = this.formBuilder.control(null);
      this.formControlAdditionalCommitments = this.formBuilder.control(null);
      this.formControlProposedCommitmentsMonthly = this.formBuilder.control(null);
      this.formControlTotalFinancingCommitmentsMonthly = this.formBuilder.control(null);
      this.formControlTotalFinancingCommitmentsMonthly.disable();
      this.formControlDebtServiceCoverRatio = this.formBuilder.control(null);
      this.formControlDebtServiceCoverRatio.disable();
      this.formControlSelectMonthlySalesAverage3Months = this.formBuilder.control(null);
      this.formControlSelectMonthlySalesAverage6Months = this.formBuilder.control(null);
      this.formControlSelectMonthlySalesAverage12Months = this.formBuilder.control(null);


      this.formGroup = this.formBuilder.group({
        industry: this.formControlIndustry,
        industryEbitda: this.formControlIndustryEbitda,
        adjustmentPercent: this.formControlAdjustmentPercent,
        monthlySalesAverage3Months: this.formControlMonthlySalesAverage3Months,
        monthlySalesAverage6Months: this.formControlMonthlySalesAverage6Months,
        monthlySalesAverage12Months: this.formControlMonthlySalesAverage12Months,
        averageMonthlySalesAdopted: this.formControlAverageMonthlySalesAdopted,
        salesAdjustment: this.formControlSalesAdjustment,
        averageMonthlySalesAdoptedAfterAdjustment: this.formControlAverageMonthlySalesAdoptedAfterAdjustment,
        existingDebtCommitmentsMonthly: this.formControlExistingDebtCommitmentsMonthly,
        atoPaymentPlanMonthly: this.formControlAtoPaymentPlanMonthly,
        additionalCommitments: this.formControlAdditionalCommitments,
    });
    }

    ngOnInit(): void {
      setupUntilDestroy(this);

      this.initAllTransactions();
      this.populate();
      this.calc();
      
      this.subscriptions.push(this.getBsaCalculatorFn(this.application.ApplicationId).pipe(
        this.hotToastService.spinnerObservable(),
          tap(r => {
            if(r && r?.averageMonthlySalesAdopted !== null){
              this.formControlIndustry.setValue(r.industry);
              this.formControlIndustryEbitda.setValue(r.industryEbitda);
              this.formControlAdjustmentPercent.setValue(r.adjustmentPercent);
              this.formControlAverageMonthlySalesAdopted.setValue(r.averageMonthlySalesAdopted);
              // this.formControlMarginAdoptedPrecent.setValue(r.marginAdoptedPercent);
              // this.formControlMonthlySalesAverage3Months.setValue(r.monthlySalesAverage3Months);
              // this.formControlMonthlySalesAverage6Months.setValue(r.monthlySalesAverage6Months);
              // this.formControlMonthlySalesAverage12Months.setValue(r.monthlySalesAverage12Months);
              // this.formControlAverageMonthlySalesAdopted.setValue(r.averageMonthlySalesAdopted);
              this.formControlSalesAdjustment.setValue(r.salesAdjustment);
              // this.formControlAverageMonthlySalesAdoptedAfterAdjustment.setValue(r.averageMonthlySalesAdoptedAfterAdjustment);
              // this.formControlCashAvailableForDebtServiceMonthly.setValue(r.cashAvailableForDebtServiceMonthly);
              // this.formControlExistingDebtCommitmentsMonthly.setValue(r.existingDebtCommitmentsMonthly);
              // this.formControlAtoPaymentPlanMonthly.setValue(r.atoPaymentPlanMonthly);
              this.formControlAdditionalCommitments.setValue(r.additionalCommitments);
              this.formControlProposedCommitmentsMonthly.setValue(r.proposedCommitmentsMonthly);
              this.formControlTotalFinancingCommitmentsMonthly.setValue(r.totalFinancingCommitmentsMonthly);
              // this.formControlDebtServiceCoverRatio.setValue(r.debtServiceCoverRatio);
            } else {
              this.initCal();
              this.reCal();
            }
          }),
      ).subscribe());

      this.subscriptions.push(
        this.getDscrCalculatorHistoryFn(this.application.ApplicationId).pipe(
          this.hotToastService.spinnerObservable(),
        ).subscribe(history => {
          console.log('==history: ', history);
          this.dataSource = history;
        })
      )
      
      this.subscriptions.push(
        this.formControlSelectMonthlySalesAverage3Months.valueChanges.subscribe(value => {
          if (value) {
            this.formControlSelectMonthlySalesAverage6Months.setValue(false);
            this.formControlSelectMonthlySalesAverage12Months.setValue(false);

            this.formControlAverageMonthlySalesAdopted.setValue(this.formControlMonthlySalesAverage3Months.value);
            this.reCal();
          }
        })
      )

      this.subscriptions.push(
        this.formControlSelectMonthlySalesAverage6Months.valueChanges.subscribe(value => {
          if (value) {
            this.formControlSelectMonthlySalesAverage3Months.setValue(false);
            this.formControlSelectMonthlySalesAverage12Months.setValue(false);

            this.formControlAverageMonthlySalesAdopted.setValue(this.formControlMonthlySalesAverage6Months.value);
            this.reCal();
          }
        })
      )

      this.subscriptions.push(
        this.formControlSelectMonthlySalesAverage12Months.valueChanges.subscribe(value => {
          if (value) {
            this.formControlSelectMonthlySalesAverage3Months.setValue(false);
            this.formControlSelectMonthlySalesAverage6Months.setValue(false);

            this.formControlAverageMonthlySalesAdopted.setValue(this.formControlMonthlySalesAverage12Months.value);
            this.reCal();
          }
        })
      )
  }

  initAllTransactions() {
    let id = 0;
    this.data.forEach((d: BankStatementsAnalysisCategory) => {
      d.details.forEach((detail: BankStatementsAnalysisTransactionDetails) => {
        let row: BankStatementsAnalysisTransactionData = {
          date: detail.date,
          transaction: detail.text ?? detail.description,
          debit: 0,
          credit: 0,
          party: '',
          category: '',
          otherInfo: '',
          bankId: detail.BankID
        };
        detail?.tags?.forEach((obj: TransactionTag) => {
          const keyArray: string[] = Object.keys(obj);
          const key: string = keyArray.length ? keyArray[0] : '';
          switch (key) {
            case 'thirdParty':
              row.party = obj['thirdParty'];
              break;
            case 'category':
              if (row.category?.length) {
                row.category += ', ' + obj['category'];
              } else {
                row.category = obj['category'];
              }
              break;
            case 'creditDebit':
              obj['creditDebit'] === 'credit' ? row.credit = detail.amount : row.debit = detail.amount;
              break;
            default:
              if (row.otherInfo?.length) {
                row.otherInfo += ` | ${key}: ${obj[key]}`;
              } else {
                row.otherInfo = `${key}: ${obj[key]}`;
              }
          }
        })

        if (this.selectedAccounts.includes(row.bankId)) {
          const rowWithId = {
            ...row,
            id: id++,
          }
          this.allTransactions.push(rowWithId);
        }
      })
    })
  }

  populate() {
    this.formControlCustomerName.setValue(this.application?.CompanyName);
    const amountFinanced = (this.application?.PricingDetails as any).AmountFinance;
    const loan = (this.application?.PricingDetails as any).LoanAmount;
    const loanAmount = amountFinanced ?? loan ?? 0;
    this.formControlLoanAmount.setValue(loanAmount);
    this.formControlProposedCommitmentsMonthly.setValue((this.application?.PricingDetails as any).Repayment);

    const today = new Date();

    // oldest transaction days
    this.allTransactions = this.allTransactions.sort((a, b) => a.date > b.date && -1 || 1);
    if (this.allTransactions.length) {
      const oldestTranasction = this.allTransactions[this.allTransactions.length - 1];
      this.maxDays = Math.round(((today as any) - (new Date(oldestTranasction.date) as any)) / (1000 * 60 * 60 * 24));
    }
    
    const last30Days = new Date((new Date()).setDate((new Date()).getDate() - 30));
    const last90Days = new Date((new Date()).setDate((new Date()).getDate() - 90));
    const last180Days = new Date((new Date()).setDate((new Date()).getDate() - 180));
    const last360Days = new Date((new Date()).setDate((new Date()).getDate() - 360));

    // income
    const last90DaysIncome = this.maxDays >= 90 ?
      _.round(this.incomeTransactions.filter(obj => (new Date(obj.date)) >= last90Days).reduce((a, b) => a + b.credit + b.debit, 0) / 3, 2) :
      0;
    const last180DaysIncome = this.maxDays >= 180 ?
      _.round(this.incomeTransactions.filter(obj => (new Date(obj.date)) >= last180Days).reduce((a, b) => a + b.credit + b.debit, 0) / 6, 2) :
      0;
    const last360DaysIncome = this.maxDays >= 360 ?
      _.round(this.incomeTransactions.filter(obj => (new Date(obj.date)) >= last360Days).reduce((a, b) => a + b.credit + b.debit, 0) / 12, 2) : 
      0;
    this.formControlMonthlySalesAverage3Months.setValue(last90DaysIncome);
    this.formControlMonthlySalesAverage6Months.setValue(last180DaysIncome);
    this.formControlMonthlySalesAverage12Months.setValue(last360DaysIncome);

    // loan payment
    const last30DaysLoanPayment = Math.abs(_.round(this.loanPaymentTransactions.filter(obj => (new Date(obj.date)) >= last30Days).reduce((a, b) => a + b.credit + b.debit, 0), 2));
    this.formControlExistingDebtCommitmentsMonthly.setValue(last30DaysLoanPayment);

    // ato payment plan
    const last30DaysAtoPayment = Math.abs(_.round(this.atoPaymentTransactions.filter(obj => (new Date(obj.date)) >= last30Days).reduce((a, b) => a + b.credit + b.debit, 0), 2));
    this.formControlAtoPaymentPlanMonthly.setValue(last30DaysAtoPayment);
  }

   // calculate the computed values based on manual inputs
  calc() {
    // calculations
    this.subscriptions.push(
      combineLatest([
        this.formControlIndustryEbitda.valueChanges,
        this.formControlAdjustmentPercent.valueChanges,
        this.formControlMonthlySalesAverage3Months.valueChanges,
        this.formControlMonthlySalesAverage6Months.valueChanges,
        this.formControlMonthlySalesAverage12Months.valueChanges,
        this.formControlSalesAdjustment.valueChanges,
        this.formControlAverageMonthlySalesAdopted.valueChanges,
        //this.formControlMarginAdoptedPrecent.valueChanges,
        this.formControlExistingDebtCommitmentsMonthly.valueChanges,
        this.formControlAtoPaymentPlanMonthly.valueChanges,
        this.formControlAdditionalCommitments.valueChanges,
        this.formControlProposedCommitmentsMonthly.valueChanges
      ]).subscribe(([
        industryEbitdaValue,
        adjustmentPercentValue,
        monthlySalesAverage3MonthsValue,
        monthlySalesAverage6MonthsValue,
        monthlySalesAverage12MonthsValue,
        salesAdjustmentValue,
        averageMonthlySalesAdoptedValue,
        existingDebtCommitmentsMonthlyValue,
        atoPaymentPlanMonthlyValue,
        additionalCommitmentsValue,
        proposedDynaCommitmentsMonthlyValue,
      ]) => {
        const industryEbitda = industryEbitdaValue;
        const adjustmentPercent = Number(adjustmentPercentValue ?? 0);
        const monthlySalesAverage3Months = monthlySalesAverage3MonthsValue ?? 0;
        const monthlySalesAverage6Months = monthlySalesAverage6MonthsValue ?? 0;
        const monthlySalesAverage12Months = monthlySalesAverage12MonthsValue ?? 0;
        const salesAdjustment = salesAdjustmentValue ?? 0;
        const averageMonthlySalesAdopted = averageMonthlySalesAdoptedValue ?? 0;
        const existingDebtCommitmentsMonthly = existingDebtCommitmentsMonthlyValue ?? 0;
        const atoPaymentPlanMonthly = atoPaymentPlanMonthlyValue ?? 0;
        const additionalCommitments = additionalCommitmentsValue ?? 0;
        const proposedDynaCommitmentsMonthly = proposedDynaCommitmentsMonthlyValue ?? 0;

        const marginAdoptedPercent = adjustmentPercent + (industryEbitda?.percentage ?? 0);

        const averageMonthlySalesAdoptedAfterAdjustment = _.round(averageMonthlySalesAdopted + salesAdjustment, 2);
        const cashAvailableForDebtServiceMonthly = _.round(averageMonthlySalesAdoptedAfterAdjustment * marginAdoptedPercent / 100, 2);
        const totalFinancingCommitmentsMonthly = _.round(existingDebtCommitmentsMonthly + atoPaymentPlanMonthly + additionalCommitments + proposedDynaCommitmentsMonthly, 2);
        const debtServiceCoverRatio = _.round(totalFinancingCommitmentsMonthly ? cashAvailableForDebtServiceMonthly / totalFinancingCommitmentsMonthly : 0, 2);
    
        this.formControlMarginAdoptedPrecent.setValue(marginAdoptedPercent);
        //this.formControlAverageMonthlySalesAdopted.setValue(averageMonthlySalesAdopted);
        this.formControlAverageMonthlySalesAdoptedAfterAdjustment.setValue(averageMonthlySalesAdoptedAfterAdjustment);
        this.formControlCashAvailableForDebtServiceMonthly.setValue(cashAvailableForDebtServiceMonthly);
        this.formControlTotalFinancingCommitmentsMonthly.setValue(totalFinancingCommitmentsMonthly);
        this.formControlDebtServiceCoverRatio.setValue(debtServiceCoverRatio);
      })
    )
  }

  reCal() {
    const salesAdjustment = this.formControlSalesAdjustment.value ?? 0;
    const existingDebtCommitmentsMonthly = this.formControlExistingDebtCommitmentsMonthly.value ?? 0;
    const atoPaymentPlanMonthly = this.formControlAtoPaymentPlanMonthly.value ?? 0;
    const additionalCommitments = this.formControlAdditionalCommitments.value ?? 0;
    const proposedDynaCommitmentsMonthly = this.formControlProposedCommitmentsMonthly.value ?? 0;
    const averageMonthlySalesAdopted = this.formControlAverageMonthlySalesAdopted.value ?? 0;
    const industryEbitda = this.formControlIndustryEbitda.value;
    const adjustmentPercent = Number(this.formControlAdjustmentPercent.value ?? 0);
    const marginAdoptedPercent = adjustmentPercent + (industryEbitda?.percentage ?? 0);

    const averageMonthlySalesAdoptedAfterAdjustment = _.round(averageMonthlySalesAdopted + salesAdjustment, 2);
    const cashAvailableForDebtServiceMonthly = _.round(averageMonthlySalesAdoptedAfterAdjustment * marginAdoptedPercent / 100, 2);
    const totalFinancingCommitmentsMonthly = _.round(existingDebtCommitmentsMonthly + atoPaymentPlanMonthly + additionalCommitments + proposedDynaCommitmentsMonthly, 2);
    const debtServiceCoverRatio = _.round(totalFinancingCommitmentsMonthly ? cashAvailableForDebtServiceMonthly / totalFinancingCommitmentsMonthly : 0, 2);

    this.formControlAverageMonthlySalesAdoptedAfterAdjustment.setValue(averageMonthlySalesAdoptedAfterAdjustment);
    this.formControlCashAvailableForDebtServiceMonthly.setValue(cashAvailableForDebtServiceMonthly);
    this.formControlTotalFinancingCommitmentsMonthly.setValue(totalFinancingCommitmentsMonthly);
    this.formControlDebtServiceCoverRatio.setValue(debtServiceCoverRatio);
  }

  initCal() {
    const monthlySalesAverage3Months = this.formControlMonthlySalesAverage3Months.value ?? 0;
    const monthlySalesAverage6Months = this.formControlMonthlySalesAverage6Months.value ?? 0;
    const monthlySalesAverage12Months = this.formControlMonthlySalesAverage12Months.value ?? 0;

    const averageMonthlySalesAdopted = _.round(
      (monthlySalesAverage3Months + monthlySalesAverage6Months + monthlySalesAverage12Months) / (
        this.maxDays >= 360 ? 3 : (this.maxDays >= 180 ? 2 : 1)
      ), 2
    );

    this.formControlAverageMonthlySalesAdopted.setValue(averageMonthlySalesAdopted);
  }

  save() {
    if (this.formGroup.valid) {
      this.saveBsaCalculatorFn({
        applicationId: this.application.ApplicationId, 
        data: {
          industry: this.formControlIndustry.value!,
          industryEbitda: this.formControlIndustryEbitda.value!,
          adjustmentPercent: this.formControlAdjustmentPercent.value ?? 0,
          marginAdoptedPrecent: this.formControlMarginAdoptedPrecent.value ?? 0,
          monthlySalesAverage3Months: this.formControlMonthlySalesAverage3Months.value ?? 0,
          monthlySalesAverage6Months: this.formControlMonthlySalesAverage6Months.value ?? 0,
          monthlySalesAverage12Months: this.formControlMonthlySalesAverage12Months.value ?? 0,
          averageMonthlySalesAdopted: this.formControlAverageMonthlySalesAdopted.value ?? 0,
          salesAdjustment: this.formControlSalesAdjustment.value ?? 0,
          averageMonthlySalesAdoptedAfterAdjustment: this.formControlAverageMonthlySalesAdoptedAfterAdjustment.value ?? 0,
          existingDebtCommitmentsMonthly: this.formControlExistingDebtCommitmentsMonthly.value ?? 0,
          atoPaymentPlanMonthly: this.formControlAtoPaymentPlanMonthly.value ?? 0,
          additionalCommitments: this.formControlAdditionalCommitments.value ?? 0,
          cashAvailableForDebtServiceMonthly: this.formControlCashAvailableForDebtServiceMonthly.value ?? 0,
          proposedCommitmentsMonthly: this.formControlProposedCommitmentsMonthly.value ?? 0,
          totalFinancingCommitmentsMonthly: this.formControlTotalFinancingCommitmentsMonthly.value ?? 0,
          debtServiceCoverRatio: this.formControlDebtServiceCoverRatio.value ?? 0,
        },
        saveDscrCalculator: true,
    }).pipe(
        this.hotToastService.snackBarObservable(`Result saved!`),
      ).subscribe();
    }
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'lastUpdatedTime': return 'Last Updated Time';
      case 'updatedBy': return 'Updated By';
      case 'data': return 'Data';
      default: return column;
    }
  }
}
