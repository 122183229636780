<div class="customer-finance-page container-fluid">

  <application-stepper2 [linear]="false" #applicationStepper="applicationStepper2">
    <cdk-step [stepControl]="formGroupStep1" ><ng-content *ngTemplateOutlet="step1" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep2" ><ng-content *ngTemplateOutlet="step2" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep3" ><ng-content *ngTemplateOutlet="step3" ></ng-content></cdk-step>
    @if (step3OnWardsHasSpouse()) {
      <cdk-step [stepControl]="formGroupStep4" ><ng-content *ngTemplateOutlet="step4" ></ng-content></cdk-step>
    }
    <cdk-step [stepControl]="formGroupStep5" ><ng-content *ngTemplateOutlet="step5" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep6" ><ng-content *ngTemplateOutlet="step6" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep7" ><ng-content *ngTemplateOutlet="step7" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep8" ><ng-content *ngTemplateOutlet="step8" ></ng-content></cdk-step>
  </application-stepper2>
</div>


<!--
##########################################
#  STEP 1:
##########################################
-->
<ng-template #step1>
  <div class="step1">
    <form [formGroup]="formGroupStep1">
      <div class="row">
        <div class="col-12">
          <div class="mat-h3">Your name</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <name title="First Name" [formControl]="formControlStep1FirstName"></name>
        </div>
        <div class="col-lg-3 col-md-12">
          <name title="Last Name" [formControl]="formControlStep1LastName"></name>
        </div>
      </div>
    </form>
  </div>
</ng-template>


<!--
##########################################
#  STEP 2:
##########################################
-->
<ng-template #step2>
  <div class="step2">
    <form [formGroup]="formGroupStep2">
      <div class="row">
        <div class="col-12">
          <div class="mat-h3">How much would you like to borrow?</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <currency-input [formControl]="formControlStep2InvoiceAmount"  [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"  title="Invoice amount (incl. GST)"></currency-input>
        </div>
        <div class="col-lg-3 col-md-12">
          <currency-input [formControl]="formControlStep2DepositAmount"  [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"  title="Deposit amount"></currency-input>
        </div>
        <div class="col-lg-3 col-md-12">
          <currency-input [readonly]="true" [formControl]="formControlStep2LoanAmount"  title="Loan amount"></currency-input>
        </div>
      </div>

      <div class="row separator-bottom">
        <div class="col-12">
          <div class="mat-h3">Asset details</div>
          <div class="mat-body">Please provide details on the asset you would like to finance</div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <asset-selection [mark]="applicationStepper.markTriggerSubject(formGroupStep2)" [categoryIndexFilter]="step2AssetCategoryIndexFilter" [searchFn]="step2AssetSearchFn" [formControl]="formControlStep2Asset"></asset-selection>
        </div>
      </div>


      <div class="row separator">
        <div class="col-12">
          <div class="mat-h3">Finance details</div>
          <div class="mat-body">Let us know your preferred repayment terms</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <consumer-loan-terms-selection [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"  [formControl]="formControlStep2LoanTerms"></consumer-loan-terms-selection>
        </div>
        <div class="col-lg-3 col-md-12">
          <payment-frequency-selection [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"  [formControl]="formControlStep2PaymentFrequency"></payment-frequency-selection>
        </div>
      </div>

      <div class="row separator-bottom">
        <div class="col-12">
          <div class="mat-h3">Company details</div>
          <div class="mat-body separator-bottom">Please provide us with further details so we can provide you with a more accurate quote</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <yes-no title="Property owner" [formControl]="formControlStep2PropertyOwner"></yes-no>
        </div>
      </div>
    </form>
  </div>
</ng-template>


<!--
##########################################
#  STEP 3:
##########################################
-->
<ng-template #step3>
  <div class="step3">
    <form [formGroup]="formGroupStep3">
      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [title]="'Primary Applicant'" [titleValue]="primaryApplicant">
          </application-header-segment>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mat-h3">Applicant details</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <title-selection [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"  [formControl]="formControlStep3Title"></title-selection>
        </div>
        <div class="col-lg-3 col-md-12">
          <name title="First Name" [formControl]="formControlStep3FirstName"></name>
        </div>
        <div class="col-lg-3 col-md-12">
          <name title="Last Name" [formControl]="formControlStep3LastName"></name>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-12">
          <datepicker [mark]="applicationStepper.markTriggerSubject(formGroupStep3)" [allowOnly18YrsPlus]="true" title="Date of birth" [formControl]="formControlStep3Dob"></datepicker>
        </div>
        <div class="col-3">
          <gender  [formControl]="formControlStep3Gender"></gender>
        </div>
      </div>


      <div class="row">
        <div class="col-lg-6 col-md-12">
          <custom-address [mark]="applicationStepper.markTriggerSubject(formGroupStep3)" title="Residential address" [formControl]="formControlStep3ResidentialAddress"></custom-address>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-12">
          <time-in-address [mark]="applicationStepper.markTriggerSubject(formGroupStep3)" [formControl]="formControlStep3TimeInAddress"></time-in-address>
        </div>
      </div>


      <div class="row">
        <div class="col-lg-3 col-md-12">
          <yes-no  title="Privacy consent obtained" [formControl]="formControlStep3PrivacyConsent"></yes-no>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <property-owner-with-address
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
            [formControl]="formControlStep3PropertyOwnerWithAddress"
          [copyAddressFn]="getFormControlValueFn(formControlStep3ResidentialAddress)"></property-owner-with-address>
        </div>
      </div>


      <div class="row">
        <div class="col-lg-6 col-md-12">
          <email [formControl]="formControlStep3Email"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
          ></email>
        </div>
        <div class="col-lg-3 col-md-12">
          <mobile [formControl]="formControlStep3Mobile" [onlyMobile]="true"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
          ></mobile>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-12">
          <maritial-status-selection [mark]="applicationStepper.markTriggerSubject(formGroupStep3)" [formControl]="formControlStep3MaritalStatus"></maritial-status-selection>
        </div>
        <div class="col-lg-3 col-md-12">
          <mat-form-field>
            <mat-label>Number of dependants</mat-label>
            <input type="number"   matInput [formControl]="formControlStep3NumberOfDependants">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-12">
          <employment-status-selection [mark]="applicationStepper.markTriggerSubject(formGroupStep3)" [formControl]="formControlStep3EmploymentStatus"></employment-status-selection>
        </div>
        <div class="col-lg-6 col-md-12">
          <income [formControl]="formControlStep3RegularIncome"></income>
        </div>
      </div>


      <div class="row">
        <div class="col-12">
          <employer [mark]="applicationStepper.markTriggerSubject(formGroupStep3)" [formControl]="formControlStep3Employer"></employer>
        </div>
      </div>


    </form>
  </div>
</ng-template>


<!--
##########################################
#  STEP 4:
##########################################
-->
<ng-template #step4>
  <div class="step4">
    <form [formGroup]="formGroupStep4">

      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [title]="'Primary Applicant'" [titleValue]="primaryApplicant">
          </application-header-segment>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mat-h3">Spouse details</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <title-selection  [formControl]="formControlStep4Title"></title-selection>
        </div>
        <div class="col-lg-3 col-md-12">
          <name title="First Name" [formControl]="formControlStep4FirstName"></name>
        </div>
        <div class="col-lg-3 col-md-12">
          <name title="Last Name" [formControl]="formControlStep4LastName"></name>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-12">
          <datepicker  title="Date of birth" [formControl]="formControlStep4Dob" [allowOnly18YrsPlus]="true"></datepicker>
        </div>
        <div class="col-lg-3 col-md-12">
          <gender  [formControl]="formControlStep4Gender"></gender>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-12">
          <email [formControl]="formControlStep4Email"></email>
        </div>
        <div class="col-lg-3 col-md-12">
          <mobile [formControl]="formControlStep4Mobile" [onlyMobile]="true"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"
          ></mobile>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-12">
          <employment-status-selection  [formControl]="formControlStep4EmploymentStatus"></employment-status-selection>
        </div>
        <div class="col-lg-6 col-md-12">
          <income  [formControl]="formControlStep4RegularIncome"></income>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-12">
          <mat-form-field>
            <mat-label>Employer</mat-label>
            <input matInput type="text" [formControl]="formControlStep4EmployerName">
          </mat-form-field>
        </div>
        <div class="col-lg-3 col-md-12">
          <mobile [formControl]="formControlStep4EmployerContact" [onlyMobile]="false" ></mobile>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mat-h3">Is the spouse willing to act as a guarantor?</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <yes-no title="Guarantor" [formControl]="formControlStep4Guarantor"></yes-no>
        </div>
        @if (step4ShowPrivacyConsent) {
          <div class="col-lg-3 col-md-12">
            <yes-no title="Privacy consent obtained" [formControl]="formControlStep4PrivacyConsent"></yes-no>
          </div>
        }
      </div>

    </form>
  </div>
</ng-template>


<!--
##########################################
#  STEP 5:
##########################################
-->
<ng-template #step5>
  <div class="step5">
    <form [formGroup]="formGroupStep5">

      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [title]="'Primary Applicant'" [titleValue]="primaryApplicant">
          </application-header-segment>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mat-h3">Monthly living expenses</div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-12">
          <currency-input [mark]="applicationStepper.markTriggerSubject(formGroupStep5)" title="Home loans" [formControl]="formControlStep5HomeLoans"></currency-input>
        </div>
        <div class="col-lg-3 col-md-12">
          <currency-input [mark]="applicationStepper.markTriggerSubject(formGroupStep5)"  title="Electricity" [formControl]="formControlStep5Electricity"></currency-input>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-12">
          <currency-input [mark]="applicationStepper.markTriggerSubject(formGroupStep5)" title="Car loans" [formControl]="formControlStep5CarLoans"></currency-input>
        </div>
        <div class="col-lg-3 col-md-12">
          <currency-input [mark]="applicationStepper.markTriggerSubject(formGroupStep5)" title="Other utilities" [formControl]="formControlStep5OtherUtilities"></currency-input>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-12">
          <currency-input [mark]="applicationStepper.markTriggerSubject(formGroupStep5)" title="Personal loans" [formControl]="formControlStep5PersonalLoans"></currency-input>
        </div>
        <div class="col-lg-3 col-md-12">
          <currency-input [mark]="applicationStepper.markTriggerSubject(formGroupStep5)" title="Education" [formControl]="formControlStep5Education"></currency-input>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-12">
          <currency-input [mark]="applicationStepper.markTriggerSubject(formGroupStep5)" title="Total credit card limits" [formControl]="formControlStep5TotalCreditCardLimits"></currency-input>
        </div>
        <div class="col-lg-3 col-md-12">
          <currency-input [mark]="applicationStepper.markTriggerSubject(formGroupStep5)" title="Groceries" [formControl]="formControlStep5Groceries"></currency-input>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-12">
          <currency-input [mark]="applicationStepper.markTriggerSubject(formGroupStep5)" title="Other loans" [formControl]="formControlStep5OtherLoans"></currency-input>
        </div>
        <div class="col-lg-3 col-md-12">
          <currency-input [mark]="applicationStepper.markTriggerSubject(formGroupStep5)" title="Insurance" [formControl]="formControlStep5Insurance"></currency-input>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mat-h3">Your share of monthly living expenses</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <mat-form-field>
            <mat-label>Share</mat-label>
            <input matInput type="number" [formControl]="formControlStep5ShareOfMonthlyLivingExpanses">
            <span class="mdi mdi-percent" matSuffix></span>
          </mat-form-field>
        </div>
      </div>

    </form>
  </div>
</ng-template>


<!--
##########################################
#  STEP 6:
##########################################
-->
<ng-template #step6>
  <div class="step6">
    <form [formGroup]="formGroupStep6">

      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [title]="'Primary Applicant'" [titleValue]="primaryApplicant">
          </application-header-segment>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <reference [mark]="applicationStepper.markTriggerSubject(formGroupStep6)" [formControl]="formControlStep6References"></reference>
        </div>
      </div>

    </form>
  </div>
</ng-template>


<!--
##########################################
#  STEP 7:
##########################################
-->
<ng-template #step7>
  <div class="step7">
    <form [formGroup]="formGroupStep7">

      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [title]="'Primary Applicant'" [titleValue]="primaryApplicant">
          </application-header-segment>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mat-h3">Upload Driver licence</div>
        </div>
      </div>

      <div class="row separator-bottom">
        <div class="col-lg-6 col-md-12">
          <message-box type="info" title="Before uploading">
            <ul>
              <li>Ensure both the front and back of the licence are supplied</li>
              <li>Ensure the text is clear and readable</li>
              <li>If using PDF, ensure the front of the driver licence is on the first page</li>
              <li>We don’t accept pictures of digital driver licences</li>
            </ul>
          </message-box>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mat-h3">Upload {{formControlStep3FirstName.value}} {{formControlStep3LastName.value}} Driver licence</div>
        </div>
      </div>
      <div class="row separator-bottom">
        <div class="col-lg-6 col-md-12">
          <upload-file title="{{formControlStep3FirstName.value}} {{formControlStep3LastName.value}} Driver licence FRONT"
            [formControl]="formControlStep7ApplicantDriverLicenceFront">
          </upload-file>
        </div>
      </div>

      @if (step3OnWardsHasSpouse()) {
        <div class="row separator-bottom">
          <div class="col-lg-6 col-md-12">
            <upload-file title="{{formControlStep4FirstName.value}} {{formControlStep4LastName.value}} Driver licence FRONT"
              [formControl]="formControlStep7ApplicantSpouseDriverLicenceFront">
            </upload-file>
          </div>
        </div>
      }

      <div class="row separator-bottom">
        <div class="col-12">
          <div class="mat-h3">Other supporting documents</div>
          <div class="mat-body">Attach supporting documents, such as system quotation, signed privacy consent(s) and pay slips.</div>
        </div>
      </div>
      <div class="row separator-bottom">
        <div class="col-lg-6 col-md-12">
        </div>
      </div>

      <!--
      <div class="row separator-bottom">
        <div class="col-lg-6 col-md-12">
          <mat-form-field>
            <mat-label>Application notes  <span class="mdi mdi-information-outline"></span></mat-label>
            <textarea matInput [formControl]="formControlStep7ApplicationNotes"></textarea>
          </mat-form-field>
        </div>
      </div>
      -->



    </form>
  </div>
</ng-template>


<!--
##########################################
#  STEP 8:
##########################################
-->
<ng-template #step8>
  <div class="step8">
    <form [formGroup]="formGroupStep8">

      <div class="row separator-bottom">
        <div class="col">
          <application-header-segment [applicationId]="brokerApplicationId" [title]="'Primary Applicant'" [titleValue]="primaryApplicant">
          </application-header-segment>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <!--          <consumer-finance-summary [summary]="step8Summary"></consumer-finance-summary>-->
        </div>
      </div>

    </form>
  </div>
</ng-template>
