import {PortalEnvironmentType, constants} from '@portal-workspace/grow-shared-library';

export const environment: PortalEnvironmentType = {
  production: true,
  enableServiceWorker: false,
  gitTag: "v4.3.4",
  gitHash: "11e7b054e1bb6c5529963fb5d22b759de8262217",
  // apiHost: "https://aapi.growfinance.app",
  api2Host: "https://api.dynamoney.com",
  agmApiKey: 'AIzaSyAGN-Wr7Kf6PU5vkcYbta9gvGg_sRfaoY0',
  submitApplicationTermsAndConditionsUrl: 'https://api.dynamoney.com/api2/site-assets/tandc/T&Cs.pdf',
  helpCenterUrl: 'https://dynamoney.com',
  facebookUrl: 'https://www.facebook.com/growfinancelimited',
  linkedinUrl: 'https://www.linkedin.com/company/dynamoney',
  fxApiKey: '87KJ7Y88739DHEXU',
  invoiceFinanceUrl:' https://invoicefinance.grow.com.au/registration/company',
  contactUsUrl: 'https://www.dynamoney.com/contact-us',
  brokerProductGuideUrl: 'https://www.dynamoney.com/product-guide',
  bankStatements: 'https://bankstatements.dynamoney.com',
  idVerify: 'https://idverify.dynamoney.com',
  GrowFinanceGroupSalesforceId: '0017F00002bIaTKQA0', // NOTE: this is (application AppInfo BrokerSalesforceId) / OriginatorBusiness or Arggregator SalesforceId
  OverdraftCustomerRecordTypeId: '0128t0000004I2jAAE',
  GrowFinanceGroupName: "DYNAMONEY",
  appInsightsKey: '4a41adb9-3924-49df-a640-065b35fcd1a7',
  autoRefreshToken: true,
  applicationSubmission: {
    disabledMessage: 'We have temporarily ceased accepting new applications for this product. Please follow us on the relaunch of this product.',
    internalAccessMessage: `Currently this application is only open for internal users. Please follow us for more information.`,
    AssetFinance: 'enabled',
    BusinessLoans: 'enabled',
    TradeFinance: 'terminated',
    Consumer: 'enabled',
    InsurancePremium: 'enabled',
    Commercial: 'disabled',
    BusinessOverdraft: 'enabled',
    CorporateLoans: 'enabled',
  },
  directSaleSubmission: {
    disabledMessage: 'We have temporarily ceased accepting new applications for this product. Please follow us on the relaunch of this product.',
    internalAccessMessage: `Currently this application is only open for internal users. Please follow us for more information.`,
    AssetFinance: 'terminated',
    BusinessLoans: 'enabled',
    TradeFinance: 'terminated',
    Consumer: 'terminated',
    InsurancePremium: 'terminated',
    Commercial: 'terminated',
    BusinessOverdraft: 'enabled',
    CorporateLoans: 'terminated',
  },
  directSaleCompanyId: 3540,
  directSaleUserId: 1542,
  directSaleUserEmail: "jimmys@dynamoney.com",
  directSaleUserFirstName: "Jimmy",
  directSaleUserLastName: "Shen",
  localStorage: {
    encryptionKey: 'a3YKnFwJ/X+hIoAnnPNoIrViZhWzT95LTK5b2dSMIe6MJEGfiQoCsQulAh9FuoAg',
  },
  verimoto: {
    lender: {
      name: "Dynamoney-Formally Grow Finance",
      guid: "0D3D528B-3B5C-4419-853D-D1F7EDEFA177"
    },
    verimotoInspectionTypes: constants.verimotoInspectionTypesProd
  }
};

