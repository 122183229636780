@if (files() && files().length) {
  <div class="uploaded-files-list-component">
    Following files are uploaded:
    <div class="uploaded-files">
      @for (file of files(); track file) {
        <div class="uploaded-file row">
          <span class="mdi mdi-attachment"></span>
          <div class="file-name">
            <span class="ellipsis" matTooltip="{{file.metadata?.name }}">
              @if (!isAzureDocument) {
                {{file.metadata?.name}}
              }
              @if (isAzureDocument) {
                {{file?.metadata?.name}}
              }
            </span>
            <!--
            <span class="ellipsis">{{file?.metadata?.name ? file?.metadata?.name : file.name}}</span>
            -->
          </div>
          <div fxLayoutAlign="end center">
            <span class="mdi mdi-close cursor-pointer" (click)="onDelete($event, file)"></span>
          </div>
          <div class="uploaded-file" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
            <div fxLayoutAlign="start center" fxLayoutGap="5px" fxLayout="row wrap">
              @for (tag of getFileTags(fileTagList(file)); track tag; let i = $index) {
                <span class="date-text">{{tag}}</span>
                @if (i !== getFileTags(fileTagList(file)).length - 1) {
                  <span class="date-text">&#183;</span>
                }
              }
            </div>
          </div>
        </div>
      }
    </div>
  </div>
}
