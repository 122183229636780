import {AfterContentChecked, ChangeDetectorRef, Component, forwardRef, OnInit} from '@angular/core';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {UntilDestroy} from '@ngneat/until-destroy';
import { NG_VALUE_ACCESSOR, Validators, FormGroup, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {formControlErrorKeys, formControlErrorMessage, setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {Subscription} from 'rxjs';
import {distinctUntilChanged, tap} from 'rxjs/operators';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import { compareMatch, EmploymentStatusSelectionValue, EmploymentStatusSelectionValueOptions } from '@portal-workspace/grow-shared-library';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'employment-status-selection',
    templateUrl: './employment-status-selection.component.html',
    styleUrls: ['./employment-status-selection.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EmploymentStatusSelectionComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => EmploymentStatusSelectionComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatOptionModule]
})
export class EmploymentStatusSelectionComponent extends AbstractControlValueAccessor<EmploymentStatusSelectionValue> implements OnInit, AfterContentChecked, Mark {

  options = EmploymentStatusSelectionValueOptions;
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;
  formGroup: FormGroup<{
    selection: FormControl<EmploymentStatusSelectionValue>
  }>;
  formControl: FormControl<EmploymentStatusSelectionValue>;

  subscriptions: Subscription[] = [];

  constructor(private formBuilder: FormBuilder,
              private cdr: ChangeDetectorRef) {
    super();
    this.formControl = formBuilder.control(null, [Validators.required]);
    this.formGroup = formBuilder.group({
      selection: this.formControl,
    });
    const subscription = this.formControl.valueChanges.pipe(
      distinctUntilChanged(compareMatch),
      tap( (v: EmploymentStatusSelectionValue) => {
        if (this.formControl.valid) {
          this.propagateChange(v);
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe();
    this.subscriptions.push(subscription);
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }


  doWriteValue(v: EmploymentStatusSelectionValue | undefined): void | EmploymentStatusSelectionValue {
    if (v) {
      const index = this.options.findIndex( r => r && r.type === v.type);
      if (index !== -1) {
        this.formControl.setValue(this.options[index]);
      }
    } else {
      this.formControl.setValue(null);
    }
    return undefined;
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
  }
  mark(): void {
    this.formGroup.markAllAsTouched();
  }
}
