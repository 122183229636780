<div class="dscr-calculator-component">
  <mat-card appearance="outlined">
    <mat-card-content>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <mat-form-field>
            <mat-label>Customer Name</mat-label>
            <input matInput type="text" [readonly]="true" [formControl]="formControlCustomerName">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <currency-input title="Loan amount" [readonly]="true" [required]="false" [formControl]="formControlLoanAmount">
          </currency-input>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-12">
          <dscr-industry-selection [formControl]="formControlIndustry">
          </dscr-industry-selection>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <dscr-industry-ebitda-selection [formControl]="formControlIndustryEbitda">
          </dscr-industry-ebitda-selection>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-12">
          <mat-form-field>
            <mat-label>% Adjustment (if required -/+)</mat-label>
            <input matInput
              [inputMask]="createThreeDecimalInputMask"
              [formControl]="formControlAdjustmentPercent">
              <span class="mdi mdi-percent" matSuffix></span>
              <!-- <mat-hint>Adjustment %</mat-hint> -->
              @for (errorKey of errorKeys(formControlAdjustmentPercent); track errorKey) {
                <mat-error>
                  @if (formControlAdjustmentPercent.touched && formControlAdjustmentPercent.hasError(errorKey)) {
                    {{errorMessage(formControlAdjustmentPercent, errorKey)}}
                  }
                </mat-error>
              }
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <mat-form-field>
              <mat-label>Margin Adopted</mat-label>
              <input matInput
                [inputMask]="createThreeDecimalInputMask"
                [formControl]="formControlMarginAdoptedPrecent">
                <span class="mdi mdi-percent" matSuffix></span>
                <!-- <mat-hint>Margin adopted</mat-hint> -->
                @for (errorKey of errorKeys(formControlMarginAdoptedPrecent); track errorKey) {
                  <mat-error>
                    @if (formControlMarginAdoptedPrecent.touched && formControlMarginAdoptedPrecent.hasError(errorKey)) {
                      {{errorMessage(formControlMarginAdoptedPrecent, errorKey)}}
                    }
                  </mat-error>
                }
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 col-md-12">
              <currency-input title="Monthly Sales Average - 3 months" [required]="false" [min]="-99999999" [formControl]="formControlMonthlySalesAverage3Months">
              </currency-input>
            </div>
            <div class="col-lg-6 col-md-12">
              <mat-checkbox color="primary" [formControl]="formControlSelectMonthlySalesAverage3Months">
                Select as Average Monthly Sales Adopted
              </mat-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <currency-input title="Monthly Sales Average - 6 months" [required]="false" [min]="-99999999" [formControl]="formControlMonthlySalesAverage6Months">
              </currency-input>
            </div>
            <div class="col-lg-6 col-md-12">
              <mat-checkbox color="primary" [formControl]="formControlSelectMonthlySalesAverage6Months">
                Select as Average Monthly Sales Adopted
              </mat-checkbox>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 col-md-12">
              <currency-input title="Monthly Sales Average - 12 months" [required]="false" [min]="-99999999" [formControl]="formControlMonthlySalesAverage12Months">
              </currency-input>
            </div>
            <div class="col-lg-6 col-md-12">
              <mat-checkbox color="primary" [formControl]="formControlSelectMonthlySalesAverage12Months">
                Select as Average Monthly Sales Adopted
              </mat-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <currency-input title="Average Monthly Sales Adopted" [required]="false" [min]="-99999999" [formControl]="formControlAverageMonthlySalesAdopted">
              </currency-input>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 col-md-12">
              <currency-input title="Sales Adjustment (if required -/+)" [required]="false" [min]="-99999999" [formControl]="formControlSalesAdjustment">
              </currency-input>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <currency-input title="Average Monthly Sales Adopted after Adjustment" [min]="-99999999" [required]="false" [formControl]="formControlAverageMonthlySalesAdoptedAfterAdjustment">
              </currency-input>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 col-md-12">
              <currency-input title="Cash Available for Debt Service (Monthly)" [readonly]="true" [min]="-99999999" [required]="false" [formControl]="formControlCashAvailableForDebtServiceMonthly">
              </currency-input>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <currency-input title="Existing Debt Commitments (Monthly)" [required]="false" [min]="-99999999" [formControl]="formControlExistingDebtCommitmentsMonthly">
              </currency-input>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 col-md-12">
              <currency-input title="ATO Payment Plan (Monthly)" [required]="false" [min]="-99999999" [formControl]="formControlAtoPaymentPlanMonthly">
              </currency-input>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <currency-input title="Additional Commitments If Required" [required]="false" [min]="-99999999" [formControl]="formControlAdditionalCommitments">
              </currency-input>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 col-md-12">
              <currency-input [title]="'Proposed Dyna Commitment (Monthly)'" [min]="-99999999" [required]="false" [formControl]="formControlProposedCommitmentsMonthly">
              </currency-input>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <currency-input title="Total Financing Commitments (Monthly)" [readonly]="true" [min]="-99999999" [required]="false" [formControl]="formControlTotalFinancingCommitmentsMonthly">
              </currency-input>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-lg-6 col-md-12">
              <currency-input title="Debt Service Cover Ratio" [readonly]="true" [required]="false" [min]="-99999999" [formControl]="formControlDebtServiceCoverRatio" [prefix]="false">
              </currency-input>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-12">
              <div fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="10px"
                fxLayout.lt-md="column"
                fxLayoutAlign.lt-md="center start">
                <button mat-flat-button color="primary"
                  [disabled]="formGroup.invalid"
                  (click)="save()"
                  class="w-lt-md-100">
                  Save
                </button>
              </div>
            </div>
          </div>

          <mat-divider></mat-divider>

          @if (dataSource.length) {
            <div class="row mb-4 mt-4">
              <div class="col-12">
                <mat-accordion>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>History</mat-panel-title>
                      <!-- <mat-panel-description></mat-panel-description> -->
                    </mat-expansion-panel-header>
                    <table mat-table [dataSource]="dataSource" class="width-100 mb-2" multiTemplateDataRows>
                      @for (column of columnsToDisplay; track column) {
                        <ng-container matColumnDef="{{column}}">
                          <th mat-header-cell mat-sort-header *matHeaderCellDef [ngClass]="{
                              'column-width-1': column === 'lastUpdatedTime',
                              'column-width-2': column === 'updatedBy',
                              'column-width-3': column === 'data'
                            }"> <div class="cell">{{getColumnTitles(column)}}</div> 
                          </th>
                          <td mat-cell *matCellDef="let element">
                            <div class="cell">
                              @if (column === 'lastUpdatedTime') {
                                {{moment(element.lastUpdatedTime).format('DD/MM/YYYY HH:mm:ss')}}
                              }
                              @if (column === 'updatedBy') {
                                {{element?.GivenName ?? ''}} {{element?.FamilyName ?? ''}}
                              }
                              @if (column === 'data') {
                                <div class="row">
                                  <div class="col-lg-6 col-md-12 date-text">Industry</div>
                                  <div class="col-lg-6 col-md-12">{{element?.industry?.name ?? ''}}</div>
                                </div>
                                <div class="row">
                                  <div class="col-lg-6 col-md-12 date-text">Industry EBITDA</div>
                                  <div class="col-lg-6 col-md-12">{{element?.industryEbitda?.name ?? ''}} {{element?.industryEbitda?.percentage ?? 0}}%</div>
                                </div>
                                <div class="row">
                                  <div class="col-lg-6 col-md-12 date-text">% Adjustment</div>
                                  <div class="col-lg-6 col-md-12">{{element?.adjustmentPercent ?? 0}}%</div>
                                </div>
                                <div class="row">
                                  <div class="col-lg-6 col-md-12 date-text">Margin Adopted</div>
                                  <div class="col-lg-6 col-md-12">{{element?.marginAdoptedPercent ?? 0}}%</div>
                                </div>
                                <div class="row">
                                  <div class="col-lg-6 col-md-12 date-text">Monthly Sales Average - 3 months</div>
                                  <div class="col-lg-6 col-md-12">{{element?.monthlySalesAverage3Months | looseCurrency}}</div>
                                </div>
                                <div class="row">
                                  <div class="col-lg-6 col-md-12 date-text">Monthly Sales Average - 6 months</div>
                                  <div class="col-lg-6 col-md-12">{{element?.monthlySalesAverage6Months | looseCurrency}}</div>
                                </div>
                                <div class="row">
                                  <div class="col-lg-6 col-md-12 date-text">Monthly Sales Average - 12 months</div>
                                  <div class="col-lg-6 col-md-12">{{element?.monthlySalesAverage12Months | looseCurrency}}</div>
                                </div>
                                <div class="row">
                                  <div class="col-lg-6 col-md-12 date-text">Average Monthly Sales Adopted</div>
                                  <div class="col-lg-6 col-md-12">{{element?.averageMonthlySalesAdopted | looseCurrency}}</div>
                                </div>
                                <div class="row">
                                  <div class="col-lg-6 col-md-12 date-text">Sales Adjustment</div>
                                  <div class="col-lg-6 col-md-12">{{element?.salesAdjustment | looseCurrency}}</div>
                                </div>
                                <div class="row">
                                  <div class="col-lg-6 col-md-12 date-text">Average Monthly Sales Adopted after Adjustment</div>
                                  <div class="col-lg-6 col-md-12">{{element?.averageMonthlySalesAdoptedAfterAdjustment | looseCurrency}}</div>
                                </div>
                                <div class="row">
                                  <div class="col-lg-6 col-md-12 date-text">Cash Available for Debt Service (Monthly)</div>
                                  <div class="col-lg-6 col-md-12">{{element?.cashAvailableForDebtServiceMonthly | looseCurrency}}</div>
                                </div>
                                <div class="row">
                                  <div class="col-lg-6 col-md-12 date-text">Existing Debt Commitments (Monthly)</div>
                                  <div class="col-lg-6 col-md-12">{{element?.existingDebtCommitmentsMonthly | looseCurrency}}</div>
                                </div>
                                <div class="row">
                                  <div class="col-lg-6 col-md-12 date-text">ATO Payment Plan (Monthly)</div>
                                  <div class="col-lg-6 col-md-12">{{element?.atoPaymentPlanMonthly | looseCurrency}}</div>
                                </div>
                                <div class="row">
                                  <div class="col-lg-6 col-md-12 date-text">Additional Commitments If Required</div>
                                  <div class="col-lg-6 col-md-12">{{element?.additionalCommitments | looseCurrency}}</div>
                                </div>
                                <div class="row">
                                  <div class="col-lg-6 col-md-12 date-text">Proposed Dyna Commitment (Monthly)</div>
                                  <div class="col-lg-6 col-md-12">{{element?.proposedCommitmentsMonthly | looseCurrency}}</div>
                                </div>
                                <div class="row">
                                  <div class="col-lg-6 col-md-12 date-text">Total Financing Commitments (Monthly)</div>
                                  <div class="col-lg-6 col-md-12">{{element?.totalFinancingCommitmentsMonthly | looseCurrency}}</div>
                                </div>
                                <div class="row">
                                  <div class="col-lg-6 col-md-12 date-text">Debt Service Cover Ratio</div>
                                  <div class="col-lg-6 col-md-12">{{element?.debtServiceCoverRatio}}</div>
                                </div>
                              }
                            </div>
                          </td>
                        </ng-container>
                      }
                      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                      <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"></tr>
                    </table>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </div>

            <mat-divider></mat-divider>
          }

          <div class="row">
            <div class="col-12">
              <asset-notes
                [onlyShowNotes]="true"
                [showInternalOnlyButton]="false"
                [getNotesByApplicationIdFn]="getNotesByApplicationIdFn"
                [createApplicationNoteFn]="createApplicationNoteFn"
                [removeApplicationNoteByNoteIdFn]="removeApplicationNoteByNoteIdFn"
              ></asset-notes>
            </div>
          </div>

        </mat-card-content>
      </mat-card>
    </div>
