<div class="upload-file-component"
  [ngClass]="{'mat-elevation-z24' : inDropableState,}"
  (click)="markAsDirty()"
  (dragleave)="onDragLeave($event)"
  (dragenter)="onDragEnter($event)"
  (dragover)="onDragOver($event)"
  (drop)="onDrop($event)">
  <mat-card appearance="outlined" class="card" [ngClass]="{invalid: hasErrors}">
    <mat-card-content>
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
        <div fxLayout="column" fxLayoutAlign="center center" class="width-100" (click)="onClick($event)">
          <input type="file" #fileInputRef class="file-input" (change)="fileInputChange($event)"
            [accept]="acceptFileTypes" [multiple]="allowMultiple">
            <span class="mdi mdi-tray-arrow-up"></span>
            @if (title) {
              <span class="mat-h4-custom title">{{title}}</span>
            }
            <span class="mat-body message">{{message}}</span>
            @if (subMessage) {
              <span class="mat-caption subMessage">{{subMessage}}</span>
            }
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <div class="uploaded-files">
      @if (hasErrors) {
        <div class="separator-top">
          @for (errorMessage of errorMessages; track errorMessage) {
            <mat-error>{{errorMessage}}</mat-error>
          }
        </div>
      }
      @if(isShowFlieList){
        @for (file of files; track file) {
          <div class="uploaded-file" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
            <div fxLayoutAlign="start center" class="col-lg-8 col-md-12">
              <span class="mdi mdi-attachment"></span>
              <span class="ellipsis" matTooltip="{{file.name }}">{{file.name }}</span>
            </div>

            <div fxLayoutAlign="end center" fxLayoutGap="50px">
              @if(isShowEditButton) {
                <span class="cursor-pointer" (click)="editFile($event, file)">Add Tag</span>
              }
              <span class="mdi mdi-close cursor-pointer" (click)="deleteFile($event, file)"></span>
            </div>

            <!-- <div class="col selected-chips">
              <mat-chip-listbox #chipList aria-label="Tag selection">
                @for (tag of tags; track tag) {
                  <mat-chip-option
                    (removed)="remove(tag)">
                    {{tag.type}}
                    <button matChipRemove>
                      <span class="mdi mdi-close-circle-outline"></span>
                    </button>
                  </mat-chip-option>
                }
              </mat-chip-listbox>
            </div> -->
          </div>
          <div class="col selected-chips uploaded-file" fxLayoutAlign="start center">
            <mat-chip-listbox #chipList aria-label="Tag selection">
              @for (tag of getTagsByFileName(file); track tag) {
                <mat-chip-option
                (removed)="removeSingleTagFromFile(file,tag)">
                  {{tag.type}}
                  <button matChipRemove>
                    <span class="mdi mdi-close-circle-outline"></span>
                  </button>
                </mat-chip-option>
              }
            </mat-chip-listbox>
          </div>
        }
      }
    </div>
  </div>
