import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {ServiceabilityCalculationResult} from '@portal-workspace/grow-shared-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import {tap} from 'rxjs/operators';
import numeral from 'numeral';
import {loadingFor} from '@ngneat/loadoff';
import {setupUntilDestroy} from '../component-utils';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { FlexModule } from '@angular/flex-layout/flex';
 
import { AsyncPipe } from '@angular/common';
import {CustomContentLoaderComponent} from "../custom-content-loader-component/custom-content-loader.component";

export type ServiceabilityCalculationFn = () => Observable<ServiceabilityCalculationResult>;

interface ServiceabilityRationComponentInternalDataSourceElement {
  serviceabilityCriteria: string,
  serviceabilityValue: string,
}

class ServiceabilityRatioComponentInternalDataSource extends DataSource<ServiceabilityRationComponentInternalDataSourceElement> {

  subject: BehaviorSubject<any> = new BehaviorSubject<ServiceabilityRationComponentInternalDataSourceElement[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<ServiceabilityRationComponentInternalDataSourceElement[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(data: ServiceabilityRationComponentInternalDataSourceElement[]) {
    this.subject.next(data);
  }
}

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'serviceability-ratio',
    templateUrl: './serviceability-ratio.component.html',
    styleUrls: ['./serviceability-ratio.component.scss'],
    exportAs: 'serviceabilityRatioComponent',
    standalone: true,
    imports: [CustomContentLoaderComponent, FlexModule, MatTableModule, MatButtonModule, AsyncPipe]
})
export class ServiceabilityRatioComponent implements OnInit, OnChanges {

  loader = loadingFor('serviceabilityCalculation');
  subscriptions: Subscription[] = [];

  dataSource = new ServiceabilityRatioComponentInternalDataSource();

  @Input({required: false}) serviceabilityCalculationFn: ServiceabilityCalculationFn | null = null;
  serviceabilityCalculationResult: ServiceabilityCalculationResult | null = null;

  ngOnInit(): void {
    setupUntilDestroy(this);
  }

  reloadIfLoadedBefore() {
    if (this.serviceabilityCalculationResult) { // was loaded before
      this.reload();
    }
  }

  reload() {
    if (this.serviceabilityCalculationFn) {
      this.dataSource = new ServiceabilityRatioComponentInternalDataSource();
      this.subscriptions.push(this.serviceabilityCalculationFn().pipe(
        this.loader.serviceabilityCalculation.track(),
        tap(r => {
          this.serviceabilityCalculationResult = r;
          this.dataSource.update([
            {serviceabilityCriteria: 'Repayment', serviceabilityValue: this.serviceabilityCalculationResult?.repayment ? numeral(this.serviceabilityCalculationResult.repayment).format('$0,0.00') : 'n/a'},
            {serviceabilityCriteria: 'Net income', serviceabilityValue: this.serviceabilityCalculationResult?.totalNetIncome ? numeral(this.serviceabilityCalculationResult.totalNetIncome).format('$0,0.00') : 'n/a'},
            {serviceabilityCriteria: 'Total expense', serviceabilityValue: this.serviceabilityCalculationResult?.totalExpense ? numeral(this.serviceabilityCalculationResult.totalExpense).format('$0,0.00') : 'n/a'},
            {serviceabilityCriteria: 'Surplus/Deficit', serviceabilityValue: this.serviceabilityCalculationResult?.netSurplus ? numeral(this.serviceabilityCalculationResult.netSurplus).format('$0,0.00'): 'n/a'},
            {serviceabilityCriteria: 'DSCR', serviceabilityValue: this.serviceabilityCalculationResult?.dscr ? String(this.serviceabilityCalculationResult.dscr) : ''},
          ]);
        })
      ).subscribe());
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  calculateServiceability($event: Event) {
    this.reload();
  }

  serviceabilityCalculationRequirementStage(): 'fail' | 'weak' | 'standard' | 'strong' | 'unknown' {
    if (this.serviceabilityCalculationResult) {
      if (this.serviceabilityCalculationResult.dscr < 1.25) {
        return 'fail';
      }
      if (this.serviceabilityCalculationResult.dscr >=1.25 && this.serviceabilityCalculationResult.dscr < 1.5) {
        return 'weak';
      }
      if (this.serviceabilityCalculationResult.dscr >= 1.5 && this.serviceabilityCalculationResult.dscr < 2) {
        return 'standard';
      }
      if (this.serviceabilityCalculationResult.dscr >= 2) {
        return 'strong';
      }
    }
    return 'unknown';
  }
}
