<div class="asset-details-component">
  @if (loader.tableLoading.inProgress$ | async) {
    <div class="loader-padding">
      <custom-content-loader type="list" ></custom-content-loader>
    </div>
  }

  <div class="asset-details" [ngClass]="{hidden: (loader.tableLoading.inProgress$ | async)}">
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="width-100">
      @for (column of displayedColumns; track column) {
        <ng-container matColumnDef="{{column}}">
          <th mat-header-cell *matHeaderCellDef >{{getColumnTitles(column)}}</th>
        <td mat-cell *matCellDef="let element;let i=dataIndex;" [ngClass]="{
        'cell-padding': column !== 'assetNumber' && column !== 'actions',
        'header-cell-padding': column === 'assetNumber',
        'tail-cell-padding': column === 'actions'
        }">
            @if (column === 'assetNumber') {
              {{i + 1}}
            }
            @if (column === 'icon') {
              @if (element == expandedElement) {
                <span class="mdi mdi-chevron-up" matSuffix></span>
              }
              @if (element !== expandedElement) {
                <span class="mdi mdi-chevron-down" matSuffix></span>
              }
            }
            @if (column === 'assets') {
              <div class="title"><p [matTooltip]="getAssetTypePPSR(element)">{{getAssetTypePPSR(element)}}</p></div>
              <div class="description">
                {{element?.SettlementAssetDetails?.year}} - {{element?.SettlementAssetDetails?.description}}
                @if (element?.SettlementAssetDetails?.serialNumber) {
                  <br>
                  }
                  {{(element?.SettlementAssetDetails?.serialNumberType && element?.SettlementAssetDetails?.serialNumber) ? element?.SettlementAssetDetails?.serialNumberType : (element?.SettlementAssetDetails?.serialNumber ?  'Serial Number:' : '')}} {{element?.SettlementAssetDetails?.serialNumberType ? ':' : ''}} {{element?.SettlementAssetDetails?.serialNumber}}
                  <br>
                    Invoice Number: {{element?.invoiceNumber}}
                  </div>
                }
                @if (column === 'invoiceAmount') {
                  {{element?.SettlementAssetDetails?.InvoicePrice | looseCurrency}}
                }
                @if (column === 'supplier') {
                  <p [matTooltip]="getSupplierName(element)">
                  {{ getSupplierName(element) }}</p>
                  <tag-box [state]="element?.SettlementAssetDetails?.supplier == '' ? 'Private-Seller' : (element?.SettlementAssetDetails?.supplier?.isAccredited ? 'Accredited' : 'New') "></tag-box>
                }
                @if (column === 'invoice') {
                  @if (element?.taxInvoices) {
                    @for (doc of element?.taxInvoices; track doc) {
                      <span class="mdi mdi-file-document-outline cursor-pointer" (click)="onDownloadDocument(doc)" [matTooltip]="doc?.metadata?.name"></span>
                    }
                  }
                }
                @if (column === 'status') {
                  <ppsr-status-chip status="{{element.status}}"></ppsr-status-chip>
                }
                @if (column === 'ppsr') {
                  @if (element.PPSRStatus === 'unchecked') {
                    <span class="mdi mdi-progress-clock" matSuffix></span>
                  }
                  @if (element.PPSRStatus === 'issue') {
                    <span class="mdi mdi-progress-alert" matSuffix></span>
                  }
                  @if (element.PPSRStatus === 'confirmed') {
                    <span class="mdi mdi-progress-check" matSuffix></span>
                  }
                  @if (element.PPSRStatus === 'registered') {
                    <span class="mdi mdi-progress-star" matSuffix></span>
                  }
                }
                @if (column === 'supplierPpsr' && element?.SettlementAssetDetails?.supplier != '') {
                  @if (element.SettlementAssetDetails?.supplierPPSRStatus === 'unchecked' || !element.SettlementAssetDetails.supplierPPSRStatus) {
                    <span class="mdi mdi-progress-clock" matSuffix></span>
                  }
                  @if (element.SettlementAssetDetails?.supplierPPSRStatus === 'issue') {
                    <span class="mdi mdi-progress-alert" matSuffix></span>
                  }
                  @if (element.SettlementAssetDetails?.supplierPPSRStatus === 'confirmed') {
                    <span class="mdi mdi-progress-check" matSuffix></span>
                  }
                  @if (element.SettlementAssetDetails?.supplierPPSRStatus === 'registered') {
                    <span class="mdi mdi-progress-star" matSuffix></span>
                  }
                }
                @if (column === 'actions') {
                  <span class="mdi mdi-dots-vertical cursor-pointer" onclick = event.stopPropagation() [matMenuTriggerFor]="menu"></span>
                  <mat-menu #menu="matMenu">
                    <button
                      [disabled]="!isAdmin && (element.PPSRStatus === 'registered' || isPpsrRegistered || element.status === 'accepted')"
                      class="menu-items" mat-menu-item (click)="editAsset(element,i+1)">
                      <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div>Edit</div>
                        <div><span class="mdi mdi-pencil-outline"></span></div>
                      </div>
                    </button>
                    <mat-divider></mat-divider>
                    @if (isInternalUser(loggedInUser)) {
                      <button  [disabled] = "isPpsrRegistered" class="menu-items" mat-menu-item (click)="AcceptAsset(element,i+1)">
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                          <div>Accept</div>
                          <div><span class="mdi mdi-check"></span></div>
                        </div>
                      </button>
                    }
                    @if (isInternalUser(loggedInUser)) {
                      <mat-divider></mat-divider>
                    }
                    @if (isInternalUser(loggedInUser)) {
                      <button   [disabled] = "isPpsrRegistered" class="menu-items" mat-menu-item (click)="RejectAsset(element,i+1)">
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                          <div>Reject</div>
                          <div><span class="mdi mdi-close"></span></div>
                        </div>
                      </button>
                    }
                    @if (isInternalUser(loggedInUser)) {
                      <mat-divider></mat-divider>
                    }
                    <button [disabled] = "application.InteflowStatus?.StageName == 'Settlement'" class="menu-items" mat-menu-item (click)="removeAsset(element)">
                      <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div>Delete</div>
                        <div><span class="mdi mdi-trash-can-outline"></span></div>
                      </div>
                    </button>
                    <mat-divider></mat-divider>
                    @if (isInternalUser(loggedInUser)) {
                      <button  [disabled] = "(!element?.SettlementAssetDetails?.serialNumberType || element?.PPSRStatus === 'registered' || isPpsrRegistered || getAssetTypePPSR(element) === 'Payout')" class="menu-items" mat-menu-item (click)="getAssetDetail(element)">
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                          <div>PPSR Check</div>
                          <div><span class="mdi mdi-autorenew"></span></div>
                        </div>
                      </button>
                    }
                    @if (isInternalUser(loggedInUser)) {
                      <mat-divider></mat-divider>
                    }
                    @if (isInternalUser(loggedInUser)) {
                      <button [disabled]="disableSupplierPPSR(element)" class="menu-items" mat-menu-item (click)="getSearchGrantors(element)">
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                          <div>Supplier PPSR</div>
                          <div><span class="mdi mdi-magnify"></span></div>
                        </div>
                      </button>
                    }
                    @if (isInternalUser(loggedInUser)) {
                      <mat-divider></mat-divider>
                    }
                    @if (isInternalUser(loggedInUser) && assetRequiresInpection(element)) {
                      <button [disabled]="getAssetInspection(element.AssetId)" class="menu-items" mat-menu-item (click)="createAssetInspection(element)">
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                          <div>New Asset Inspection</div>
                          <div><span class="mdi mdi-briefcase-eye-outline"></span></div>
                        </div>
                      </button>
                    }
                  </mat-menu>
                }
              </td>
            </ng-container>
          }

          <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length" [class]="expandedElement ? 'expanded-row':''">
              <div class="example-element-detail"
                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                @if (element.PPSRStatus === 'confirmed' || element.PPSRStatus === 'registered' ) {
                  <div class="row">
                    <div class="col">
                      @if (element.SettlementAssetDetails.VehicleDetails !== null) {
                        <asset-ppsr-details
                        [vehicleDetails]="element.SettlementAssetDetails.VehicleDetails"></asset-ppsr-details>
                      }
                      @if (element.SettlementAssetDetails.VehicleDetails === null) {
                        <div class="not-found">
                          No Vehicle detail found
                        </div>
                      }
                    </div>
                  </div>
                }

                @if (element.PPSRStatus !== 'confirmed' && element.PPSRStatus !== 'registered' ) {
                  <div class="row" >
                    <div class="col">
                      <div class="not-found-bg">
                        <div class="not-found">
                          No PPSR check was performed for this asset type
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let element; columns: displayedColumns;"
            class="example-element-row"
            [class.example-expanded-row]="expandedElement === element"
            (click)="expandedElement = expandedElement === element ? null : element">
          </tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"
          (click)="expandedElement = null"></tr>
        </table>
        @if (data<1) {
          <table>
            <tr><div class="not-found"> Please add an asset</div></tr>
          </table>
        }
      </div>
    </div>
