<div class="profile-page container-fluid">
  <profile
    (events)="onProfileEvent($event)"
    [allowProfileEdit]="true"
    [isLoadingProfile] = "isLoadingProfile"
    [isLoadingPassword]= "isLoadingPassword"
    [getUserFn]="getUserFn"
    [logoutFn]="logoutFn"
    [matchConfirmPasswordFn]="matchConfirmPasswordFn"
    [enableDisable2FaFn]="enableDisable2FaFn"
    [add2FaSecretKeyFn]="add2faSecretKeyFn"
    [verify2faTokenFn]="verify2faTokenFn"
    [get2faTotpVerificationCodeFn]="get2faTotpVerificationCodeFn"
  >
</profile>
</div>

