import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {
  BankStatementsAnalysisCategory, BankStatementsAnalysisTransactionDetails,
  BankStatementsAnalysisTransactionData, TransactionTag, DEFAULT_LIMIT, DEFAULT_OFFSET, AccountSummary, AccountFilter,
} from '@portal-workspace/grow-shared-library';
import {PageEvent} from '@angular/material/paginator';
import { FormArray, FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {ApplicationDialogService, setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { Sort, MatSortModule } from '@angular/material/sort';
import {compare} from '@portal-workspace/grow-shared-library';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { CustomPaginatorComponent } from '../custom-paginator-component/custom-paginator/custom-paginator.component';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexModule } from '@angular/flex-layout/flex';
import { NgClass } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import _ from 'lodash';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'full-transaction-list',
    templateUrl: './full-transaction-list.component.html',
    styleUrls: ['./full-transaction-list.component.scss'],
    standalone: true,
    imports: [FlexModule, MatFormFieldModule, MatInputModule, FormsModule, MatButtonModule, ReactiveFormsModule, MatSelectModule, MatOptionModule, MatCheckboxModule, MatTableModule, MatSortModule, NgClass, ExtendedModule, CustomPaginatorComponent, LooseCurrencyPipe]
})
export class FullTransactionListComponent implements OnInit, OnChanges {
  @Input({required: false}) data: BankStatementsAnalysisCategory[] = [];
  @Input({required: false}) accountSummary: AccountSummary[] = [];
  @Input({required: false}) selectedAccounts: number[] = [];

  columnsToDisplay: string[] = ['date', 'category', 'party', 'transaction', 'credit', 'debit'];
  dataSource: BankStatementsAnalysisTransactionData[] = [];
  filteredDataSource: BankStatementsAnalysisTransactionData[] = [];
  displayedData: BankStatementsAnalysisTransactionData[] = [];

  subscriptions: Subscription[] = [];
  formControlSearch!: FormControl<string|null>;
  formControlSearchList: FormArray = new FormArray<any>([]);
  formControlSearchLogic!: FormControl<'AND' | 'OR' | null>;
  searchLogicOptions = ['AND', 'OR'];
  accountFilter: AccountFilter = {};
  limit!: number;
  offset!: number;
  total!: number;
  filter: string[] = [];
  showMessage!: string;
  accountSelectionChanges: boolean = true;
  firstTimeLoadData: boolean = true;
  singleAccount = false;

  constructor(
    private formBuilder: FormBuilder,
    private applicationDialogService: ApplicationDialogService,
  ) {
    this.formControlSearch = this.formBuilder.control('');
    this.formControlSearchLogic = this.formBuilder.control('OR');
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.singleAccount = this.accountSummary.length === 1;
    this.addSearchControl();
    this.initAccountFilter();

    if (this.singleAccount) {
      this.initPageData();
    }


    this.subscriptions.push(
      this.formControlSearchList.valueChanges.pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap((r: string[]) => {
          console.log('r:       ', r)
          this.filter = r.filter(i => !!i).map(i => i.toLowerCase().trim())
          this.filterAccounts(true);
          this.onSearch(false);
          this.initPagination();
        })
      ).subscribe()
    )

    this.subscriptions.push(
      this.formControlSearchLogic.valueChanges.pipe(
        tap(() => {
          this.filter = this.formControlSearchList.value.filter(
            (i: string[]) => !!i
          ).map(
            (i: string) => i.toLowerCase().trim()
          );
          this.filterAccounts(true);
          this.onSearch(false);
          this.initPagination();
        })
      ).subscribe()
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initAccountFilter();
    if (this.singleAccount) {
      this.initPageData();
    }
    if (changes['selectedAccounts']) {
      this.reloadTable();
    }
  }

  initAccountFilter() {
    this.accountSummary.forEach((account: AccountSummary) => {
      this.accountFilter[account.BankID] = new FormControl<boolean>(this.singleAccount);
    })

    if (this.selectedAccounts.length) {
      this.accountSummary.forEach((account: AccountSummary) => {
        this.accountFilter[account.BankID].setValue(this.selectedAccounts.includes(account.BankID));
      })
    }
  }

  initPageData(): void {
    this.dataSource = [];
    this.data.forEach((d: BankStatementsAnalysisCategory) => {
      d.details.forEach((detail: BankStatementsAnalysisTransactionDetails) => {
        let row: BankStatementsAnalysisTransactionData = {
          date: detail.date,
          transaction: detail.text ?? detail.description,
          debit: 0,
          credit: 0,
          party: '',
          category: '',
          otherInfo: '',
          bankId: detail.BankID
        };
        detail?.tags?.forEach((obj: TransactionTag) => {
          const keyArray: string[] = Object.keys(obj);
          const key: string = keyArray.length ? keyArray[0] : '';
          switch (key) {
            case 'thirdParty':
              row.party = obj['thirdParty'];
              break;
            case 'category':
              if (row.category?.length) {
                row.category += ', ' + obj['category'];
              } else {
                row.category = obj['category'];
              }
              break;
            case 'creditDebit':
              obj['creditDebit'] === 'credit' ? row.credit = detail.amount : row.debit = detail.amount;
              break;
            default:
              if (row.otherInfo?.length) {
                row.otherInfo += ` | ${key}: ${obj[key]}`;
              } else {
                row.otherInfo = `${key}: ${obj[key]}`;
              }
          }
        })
        this.dataSource.push(row);
      })
    })

    this.dataSource = this.dataSource.sort((a, b) => a.date > b.date && -1 || 1);
    this.filteredDataSource = this.dataSource;
    this.initPagination();
  }

  initPagination() {
    this.total = this.filteredDataSource.length;
    this.limit = DEFAULT_LIMIT;
    this.offset = DEFAULT_OFFSET;
    this.updateDisplayedData();
  }

  onSearch(firstTriggered: boolean) {
    if (this.filter.length) {
      this.filteredDataSource = (firstTriggered ? this.dataSource : this.filteredDataSource).filter(data => {
        const s = (data.date + data.transaction + data.credit.toString() +
          data.debit.toString() + data.party + data.category + data.otherInfo).toLowerCase();
        if (this.formControlSearchLogic.value === 'OR') {
          for (const f of this.filter) {
            if (s.includes(f)) {
              return true;
            }
          }
          return false;
        } else {
          for (const f of this.filter) {
            if (!s.includes(f)) {
              return false;
            }
          }
          return true;
        }
      })
    } else {
      this.filteredDataSource = this.dataSource;
    }
  }

  updateDisplayedData() {
    this.displayedData = this.filteredDataSource.slice(this.offset * this.limit, (this.offset + 1) * this.limit);
  }

  onSort(sort: Sort) {
    const data = this.filteredDataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.filteredDataSource = data;
      return;
    }

    this.filteredDataSource = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'date':
          return compare(a.date, b.date, isAsc);
        case 'category':
          return compare(a.category ?? '', b.category ?? '', isAsc);
        case 'party':
          return compare(a.party ?? '', b.party ?? '', isAsc);
        case 'transaction':
          return compare(a.transaction, b.transaction, isAsc);
        case 'credit':
          return compare(a.credit, b.credit, isAsc);
        case 'debit':
          return compare(a.debit, b.debit, isAsc);
        default:
          return 0;
      }
    });

    this.updateDisplayedData();
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'date': return 'Date';
      case 'transaction': return 'Transaction';
      case 'debit': return '$ Debit';
      case 'credit': return '$ Credit';
      case 'party': return 'Party';
      case 'category': return 'Category';
      case 'otherInfo': return 'Other Info';
      default: return column;
    }
  }

  needCurrencyPipe(column: string) {
    return ['debit', 'credit'].includes(column);
  }

  onPagination($event: PageEvent) {
    this.limit = $event.pageSize;
    this.offset = $event.pageIndex;
    this.updateDisplayedData();
  }

  needAlignRight(column: string) {
    return ['debit', 'credit'].includes(column);
  }

  exportCsv() {
    const rawCsvData = this.filteredDataSource.map((d: any) => {
      let row: any = {};
      [...this.columnsToDisplay, 'otherInfo', 'account'].forEach((col: string) => {
        if (col === 'account') {
          const account = this.accountSummary.find(a => a.BankID === d.bankId);
          row['Account'] = [account?.BankName, account?.BSBAccNumber, account?.AccName].join(' ');
        } else {
          row[this.getColumnTitles(col)] = d[col];
        }
      });
      return row;
    });
    const header = Object.keys(rawCsvData[0]);
    const csv = rawCsvData.map(row => header.map(fieldName => JSON.stringify(row[fieldName])).join(','));
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');

    const blob = new Blob([csvArray], {type: 'text/csv' })
    saveAs(blob, "transactions.csv");
  }

  filterAccounts(firstTriggered: boolean) {
    const accountIds = Object.keys(this.accountFilter);
    const filteredBankIds: number[] = [];
    for (const accountId of accountIds) {
      const checked = this.accountFilter[accountId].value;
      if (checked) {
        filteredBankIds.push(Number(accountId))
      }
    }
    this.filteredDataSource = (firstTriggered ? this.dataSource : this.filteredDataSource).filter(data => {
      return filteredBankIds.includes(data.bankId);
    });

    if(this.filteredDataSource.length == 0){
      this.showMessage = 'No data found'
    }
  }

  filterChangeHandler() {
    this.accountSelectionChanges = true;
    this.firstTimeLoadData = false;
  }

  reloadTable() {
    const ref = this.applicationDialogService.openProgressSpinnerDialog();
    setTimeout(() => {
      ref.close();
    }, 3000);

    this.initPageData();
    this.onSearch(true);
    this.filterAccounts(false);
    this.initPagination();

    this.accountSelectionChanges = false;
  }

  showFilter() {
    return this.accountSummary && this.accountSummary.length && this.accountSummary.length > 1 &&
      (this.accountSummary[0]?.BankID !== undefined || this.accountSummary[0]?.BankID !== null);

// this code works good in production but not on test.
// If anything goes wrong we can uncomment the below code which works good on test as well as production.

    // return this.accountSummary && this.accountSummary.length && this.accountSummary.length > 1 &&
    // (this.accountSummary[0].hasOwnProperty('BankID'));
  }

  addSearchControl() {
    // const form = this.formBuilder.group({
    //   search: ['']
    // });
    // this.lessons.push(form);
    this.formControlSearchList.push(new FormControl<string | null>(''))
  }

  removeSearchControl(index: number) {
    this.formControlSearchList.removeAt(index);
  }

  toClass(category: string) {
    // || categories.includes('')
    const categories = category ? category.split(',').map(c => _.toLower(c.trim().replace(/\s/g, '-'))) : [];
    if (categories.includes('wages') || categories.includes('wages-paid-out') || categories.includes('eftpos-terminals')) {
      return 'type-1';
    } else if (categories.includes('loans') || categories.includes('non-sacc-loans')) {
      return 'type-2';
    } else if (categories.includes('other-debits') || categories.includes('uncategorised-debits') || categories.includes('third-party-payment-providers')) {
      return 'type-3';
    } else if (categories.includes('tax') || categories.includes('atm') || categories.includes('refunds-and-returns') || categories.includes('other-income') || categories.includes('government-and-council-services') || categories.includes('subscription-tv') || categories.includes('travel')) {
      return 'type-4';
    } else if (categories.includes('online-retail-and-subscription-services')) {
      return 'type-5';
    } else if (categories.includes('external-transfers')) {
      return 'type-6';
    } else if (categories.includes('superannuation')) {
      return 'type-7';
    } else if (categories.includes('fees')) {
      return 'type-8';
    } else if (categories.includes('utilities')) {
      return 'type-9';
    } else if (categories.includes('groceries')) {
      return 'type-10';
    } else if (categories.includes('dining-out') || categories.includes('education') || categories.includes('automotive') || categories.includes('health') || categories.includes('department-stores') || categories.includes('retail') || categories.includes('home-improvement') || categories.includes('entertainment') || categories.includes('internal-transfer') || categories.includes('pet-care')) {
      return 'type-11';
    } else if (categories.includes('insurance')) {
      return 'type-12';
    } else if (categories.includes('telecommunications')) {
      return 'type-13';
    } else if (categories.includes('transport')) {
      return 'type-14';
    } else if (categories.includes('all-other-credits')) {
      return 'type-15';
    } else {
      return ''
    }
  }
}
