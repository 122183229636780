import { Component, EventEmitter, forwardRef, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import {
  Application,
  AssetCategorySelectionValue, AssetCategoryType, AssetCategoryTypeSelectionValue,
  AssetComponentEvent, AssetFinanceApplication, compareMatch, ConsumerFinanceApplication, CurrencyInputValue,
  getAssetCategory,
  getAssetCategoryType, getAssetDescription, getAssetMake, getAssetYear, getBalloonPayment, getDeposit, getFinanceType, getInvoiceAmount,
  getVehicleSerialNumberType, isExternalUser, PpsrAssetValue, SaveAssetEvent, User, VehicleSerialNumberType,
  getVerimotoAssetGuid, isInternalUser, VerimotoInspectionTableDataWithInspectionDetails,
} from '@portal-workspace/grow-shared-library';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { delay, distinctUntilChanged, tap } from 'rxjs/operators';
import { AssetCategorySelectionComponent } from 'libs/grow-ui-library/src/lib/components/asset-cat-and-type-component/asset-category-selection.component';
import { FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  AssetCatAndTypeService,
  AssetCategoryTypeSelectionComponent,
  createYearInputMask,
  doMarkAll,
  getUser,
  markTriggerSubject,
  noSpaceValidator,
  setupUntilDestroy, validRegoValidator,
  validVINValidator,
  validYearValidator,
  formControlErrorKeys, formControlErrorMessage,
} from '@portal-workspace/grow-ui-library';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import { AbstractControlValueAccessor } from '../abstract-control-value-accessor';
import { GST, getConsumerAssetFinanceOnlyAssetCategoryTypes } from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { CurrencyInputComponent } from '../currency-selection-component/currency-input.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AssetCategoryTypeSelectionComponent as AssetCategoryTypeSelectionComponent_1 } from '../asset-cat-and-type-component/asset-category-type-selection.component';
import { MarkDirective } from '../../directives/mark-as-dirty.directive';
import { DisableControlDirective } from '../../directives/disable-control.directive';
import { AssetCategorySelectionComponent as AssetCategorySelectionComponent_1 } from '../asset-cat-and-type-component/asset-category-selection.component';
import { FlexModule } from '@angular/flex-layout/flex';
import { ExtendedModule } from '@angular/flex-layout/extended';

import { NgClass } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import _ from 'lodash';
import {CustomContentLoaderComponent} from "../custom-content-loader-component/custom-content-loader.component";

const vehicleSerialNumberTypeOptions: VehicleSerialNumberType[] = getVehicleSerialNumberType();


let KEY = 1;

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    selector: 'add-asset-details',
    templateUrl: './add-asset-details.component.html',
    styleUrls: ['./add-asset-details.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AddAssetDetailsComponent), multi: true },
        // { provide: MARK, useExisting: forwardRef(() => AddAssetDetailsComponent) },
    ],
    standalone: true,
    imports: [CustomContentLoaderComponent, NgClass, ExtendedModule, MatCheckboxModule, FlexModule, AssetCategorySelectionComponent_1, FormsModule, ReactiveFormsModule, MarkDirective, AssetCategoryTypeSelectionComponent_1, MatFormFieldModule, MatInputModule, InputMaskModule, MatSelectModule, MatOptionModule, CurrencyInputComponent, MatButtonModule, DisableControlDirective]
})

export class AddAssetDetailsComponent extends AbstractControlValueAccessor<PpsrAssetValue> implements OnInit, Mark {
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;
  displayedColumns: string[] = ['asset', 'VINCodeOrChassisNumber', 'invoiceAmount', 'actions'];
  dataSource = [];
  displayAsset2 = false;
  key = `KEY_${KEY++}`;
  isVehicleSelected!: boolean;
  isCarsSelected!: boolean;
  markTriggerSubject = markTriggerSubject;
  isInternalUser = isInternalUser;
  loggedInUser: User | null = getUser();
  isExternalUser = isExternalUser;
  maxDespoit: number | null = null;

  @Input({required: false}) disableControl: boolean = false;
  @Input({required: false}) allowDelete = false;
  @Input({required: false}) title: string | null = null;
  @Input({required: false}) editAssetData: PpsrAssetValue | any;
  @Input({required: false}) updateAssetData: PpsrAssetValue | any;
  @Input({required: false}) firstAssetData: AssetFinanceApplication | ConsumerFinanceApplication | null = null;
  @Input({required: true}) application!: Application;
  @Input({required: true}) privateSeller!: boolean;
  @Input({required: false}) assetInspectionTableData: VerimotoInspectionTableDataWithInspectionDetails[] = [];
  @Output() events: EventEmitter<AssetComponentEvent> = new EventEmitter<AssetComponentEvent>();
  // @Output() dataEvent: EventEmitter<object> = new EventEmitter<object>();
  @Output() savedDataEvent: EventEmitter<SaveAssetEvent> = new EventEmitter<SaveAssetEvent>();
  @Output() disableAddButtonEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() disableAssetRowEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() finishLoadingEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChildren(AssetCategorySelectionComponent) childAssetCategorySelection !: QueryList<AssetCategorySelectionComponent>
  @ViewChildren(AssetCategoryTypeSelectionComponent) childAssetTypeSelection !: QueryList<AssetCategoryTypeSelectionComponent>
  @Input({required: false}) isRentalApplication: boolean = false;
  @Input({required: true}) invoiceNumber!: string;

  formGroup: FormGroup<{
    assetCategory: FormControl<AssetCategorySelectionValue | null>,
    assetType: FormControl<AssetCategoryTypeSelectionValue | null>,
    description: FormControl<string | null>,
    year: FormControl<number | null>,
    serialNumberType: FormControl<string | null>,
    serialNumber: FormControl<string | null>,
    invoicePrice: FormControl<CurrencyInputValue>,
    gst: FormControl<CurrencyInputValue>,
    assetId: FormControl<number | null>,
    depositAmount: FormControl<CurrencyInputValue>,
    // WEB-3702
    depositAmountDynamoney: FormControl<CurrencyInputValue>,
    depositAmountSupplier: FormControl<CurrencyInputValue>,
    amountFinanced: FormControl<CurrencyInputValue>,
    residual: FormControl<number | null>,
    rego: FormControl<string | null>,
    autoGenerateInspection: FormControl<boolean | null>,
  }>;
  formControlAssetCategory: FormControl<AssetCategorySelectionValue | null>;
  formControlAssetType: FormControl<AssetCategoryTypeSelectionValue | null>;
  formControlAssetDescription: FormControl<string | null> ;
  formControlAssetYear: FormControl<number | null>;
  formControlSerialNumberType: FormControl<string | null>;
  formControlSerialNumber: FormControl<string | null>;
  formControlRego: FormControl<string | null>;
  formControlInvoicePrice: FormControl<CurrencyInputValue>;
  formControlGst: FormControl<CurrencyInputValue>;
  formControlAssetId: FormControl<number | null>;
  formControlDepositAmount: FormControl<CurrencyInputValue>;
  // WEB-3702
  formControlDepositAmountDynamoney: FormControl<CurrencyInputValue>;
  formControlDepositAmountSupplier: FormControl<CurrencyInputValue>;
  formControlAmountFinanced: FormControl<CurrencyInputValue>;
  formControlResidual: FormControl<number | null>;
  formControlAutoGenerateInspection: FormControl<boolean | null>;
  vehicleSerialNumberTypeOptions = vehicleSerialNumberTypeOptions;
  createYearMask = createYearInputMask();
  editAssetFlag = false;
  showLoader !: boolean
  assetCategoryIndexFilter: string[] = [];

  subscriptions: Subscription[] = [];
  // markObservable: Subject<boolean> = new Subject<boolean>();

  showMake: boolean = false;
  showSerialNumber = false;
  payoutSelected = false;
  showAutoGenerateInspection = false;

  invalidGST = false
  consumerAssetTypes!: AssetCategoryType[];

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControlAssetCategory = formBuilder.control(null, [Validators.required]);
    this.formControlAssetType = formBuilder.control(null, [Validators.required]);
    this.formControlAssetDescription = formBuilder.control(null, [Validators.required,noSpaceValidator()]);
    this.formControlAssetYear = formBuilder.control(null, [Validators.required, validYearValidator()]);
    this.formControlSerialNumberType = formBuilder.control(null);
    this.formControlSerialNumber = formBuilder.control(null);
    this.formControlRego = formBuilder.control(null, [validRegoValidator()]);
    this.formControlInvoicePrice = formBuilder.control(0);
    this.formControlGst = formBuilder.control(0);
    this.formControlAssetId = formBuilder.control(null);
    this.formControlDepositAmount = formBuilder.control(0, [Validators.required]);
    // WEB-3702
    this.formControlDepositAmount.disable();
    this.formControlDepositAmountDynamoney = formBuilder.control(0, [Validators.required]);
    this.formControlDepositAmountSupplier = formBuilder.control(0, [Validators.required]);
    this.formControlAmountFinanced = formBuilder.control(0, [Validators.required]);
    this.formControlResidual = formBuilder.control(0, [Validators.required]);
    this.formControlAutoGenerateInspection = formBuilder.control(false);
    this.formGroup = formBuilder.group({
      assetCategory: this.formControlAssetCategory,
      assetType: this.formControlAssetType,
      description: this.formControlAssetDescription,
      year: this.formControlAssetYear,
      serialNumberType: this?.formControlSerialNumberType,
      serialNumber: this.formControlSerialNumber,
      invoicePrice: this.formControlInvoicePrice,
      gst: this.formControlGst,
      assetId: this.formControlAssetId,
      depositAmount: this.formControlDepositAmount,
      // WEB-3702
      depositAmountDynamoney: this.formControlDepositAmountDynamoney,
      depositAmountSupplier: this.formControlDepositAmountSupplier,
      amountFinanced: this.formControlAmountFinanced,
      residual: this.formControlResidual,
      rego: this.formControlRego,
      autoGenerateInspection: this.formControlAutoGenerateInspection,
    });
  }

  ngOnInit() {
    setupUntilDestroy(this);
    if(this.application.ApplicationType == 'Consumer'){
      this.formControlAssetCategory.setValue(getAssetCategory('139'))
      this.consumerAssetTypes = getConsumerAssetFinanceOnlyAssetCategoryTypes()
    }

    this.showAutoGenerateInspection = this.privateSeller;

    const sub = this.formGroup.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r => {
        const v: PpsrAssetValue = {
          assetCategory: this.formControlAssetCategory.value,
          assetType: this.formControlAssetType.value,
          description: this.formControlAssetDescription.value!,
          year: this.formControlAssetYear.value,
          serialNumberType: this?.formControlSerialNumberType.value!,
          assetNumber: null,
          serialNumber: this.formControlSerialNumber.value!,
          invoicePrice: this.formControlInvoicePrice.value!,
          gst: this.formControlGst.value!,
          AssetId: this.formControlAssetId.value!,
          salesforceId: null,
          supplier: undefined,
          amountFinanced: this.formControlAmountFinanced.value!,
          depositAmount: this.formControlDepositAmount.value!,
          // WEB-3702
          depositAmountDynamoney: this.formControlDepositAmountDynamoney.value!,
          depositAmountSupplier: this.formControlDepositAmountSupplier.value!,
          residual: this.formControlResidual.value!,
          rego: this.formControlRego.value!,
          autoGenerateInspection: this.formControlAutoGenerateInspection.value!,
        }
        this.propagateChange(v);
      })
    ).subscribe();
    const sub2 = this.formControlInvoicePrice.valueChanges.pipe(
      tap((r: number | null) => {
        let GSTAmount = ''
        if (this.isRentalApplication) {
          GSTAmount = ((r ? r : 0) / 10).toFixed(2);
        } else {
          GSTAmount = ((r ? r : 0) / GST).toFixed(2);
        }
        this.formControlGst.setValue(parseFloat(GSTAmount));
        this.maxDespoit = _.round(r!);
        if ((this.maxDespoit ?? 0) < (this.formControlDepositAmount.value ?? 0)) {
          this.formControlDepositAmount.setValue(0)
        }
      })
    ).subscribe();
    const subGST = this.formControlGst.valueChanges.pipe(
      tap((r:number| null) => {
        this.invalidGST = false
        let maxGst = 0
        if (this.isRentalApplication) {
          maxGst = parseFloat(((this.formControlInvoicePrice.value ? this.formControlInvoicePrice.value : 0) / 10).toFixed(2));
        } else {
          maxGst = parseFloat(((this.formControlInvoicePrice.value ? this.formControlInvoicePrice.value : 0) / GST).toFixed(2));
        }
        if(r! > maxGst){
          this.invalidGST = true
          this.formControlGst.setValidators([Validators.max(maxGst)]);
        }
      })
    ).subscribe();

    const sub3 = this.formControlAssetCategory.valueChanges.pipe(
      tap((r: AssetCategorySelectionValue) => {
        if (r) {
       this.childAssetTypeSelection.forEach(c => c.reset());
          switch (r.index) {
            case '130':
            case '132':
            case '137':
            case '140': {
              this.isVehicleSelected = true;
              this.payoutSelected = false;
              this.formControlAssetYear.setValue(null);
              this.formControlAssetDescription.setValue(null);
              this.formControlSerialNumberType.setValue(vehicleSerialNumberTypeOptions[0].key)
              this.formControlSerialNumberType.setValidators([Validators.required]);
              this.formControlSerialNumber.setValidators([Validators.required, noSpaceValidator()]);
              if (this.formControlSerialNumberType.value === 'VIN') {
                this.formControlSerialNumber.setValidators([validVINValidator()]);
                this.formControlSerialNumber.updateValueAndValidity();
              }
              this.formControlSerialNumber.updateValueAndValidity();
              this.formControlSerialNumberType.updateValueAndValidity();
              break;
            }
            case '141': {
              let date = new Date();
              let currentYear = date.getFullYear();
              this.formControlAssetYear.setValue(currentYear);
              // this.formControlAssetDescription.setValue('Payout');
              this.formControlSerialNumberType.setValue(null);
              this.formControlSerialNumberType.clearValidators();
              this.formControlSerialNumberType.updateValueAndValidity();
              this.formControlSerialNumber.setValue(null);
              this.formControlSerialNumber.clearValidators();
              this.formControlSerialNumber.updateValueAndValidity();
              this.payoutSelected = true
              this.isVehicleSelected = false;
              this.isCarsSelected = false
              break;
            }
            default: {
              this.isVehicleSelected = false;
              this.payoutSelected = false;
              this.formControlAssetYear.setValue(null);
              this.formControlAssetDescription.setValue(null);
              this.formControlSerialNumberType.setValue(null);
              this.formControlSerialNumberType.clearValidators();
              this.formControlSerialNumberType.updateValueAndValidity();
              this.formControlSerialNumber.setValue(null);
              this.formControlSerialNumber.clearValidators();
              this.formControlSerialNumber.updateValueAndValidity();
              this.showSerialNumber = false;
              this.showMake = false;
              break;
            }
          }
        }
      })
    ).subscribe();
    const sub4 = this.formControlSerialNumberType.valueChanges.subscribe(() => {
      if (this.formControlSerialNumberType.value === 'VIN') {
        this.formControlSerialNumber.setValidators([Validators.required, validVINValidator()]);
        this.formControlSerialNumber.updateValueAndValidity();
      } else if (['130', '132', '137'].includes(this.formControlAssetCategory.value?.index as string)) {
        this.formControlSerialNumber.clearValidators();
        this.formControlSerialNumber.setValidators([Validators.required,noSpaceValidator()]);
        this.formControlSerialNumber.updateValueAndValidity();
      } else if ((this.formControlAssetCategory.value?.index as string) === '141') {
        this.formControlSerialNumber.clearValidators();
        this.formControlSerialNumber.updateValueAndValidity();
      } else {
        this.formControlSerialNumber.clearValidators();
        this.formControlSerialNumber.setValidators([Validators.required, noSpaceValidator()]);
        this.formControlSerialNumber.updateValueAndValidity();
      }
    })
    const sub5 = combineLatest([
      this.formControlInvoicePrice.valueChanges,
      this.formControlDepositAmount.valueChanges,
    ]).pipe(
      tap(r => {
        const invoiceAmount = r[0] ?? 0;
        const depositAmount = r[1] ?? 0;
        const financeType = getFinanceType(this.application);
        const depositAmountExGST = Math.round(depositAmount * 1000 / 11) / 100;
        const deposit = financeType === 'Rental' ? depositAmountExGST : depositAmount;
        const loanAmount = (invoiceAmount - deposit > 0) ? (invoiceAmount - deposit).toFixed(2) : '0';
        this.formControlAmountFinanced.setValue(parseFloat(loanAmount));
      })
    ).subscribe();
    const sub6 = this.formControlAssetType.valueChanges.pipe(
      tap((r: AssetCategoryTypeSelectionValue) =>{

        if(r?.value === 'Cars'){
          this.isCarsSelected = true;
          if(!this.formControlSerialNumberType.value){
            this.formControlSerialNumberType.setValue(vehicleSerialNumberTypeOptions[0].key)
          }
          this.formControlSerialNumberType.setValidators([Validators.required]);
          this.formControlSerialNumberType.updateValueAndValidity();

        }else{
          this.isCarsSelected = false;
        }
        if ((this.formControlAssetCategory.value?.index as string) !== '141') {
          this.formControlSerialNumber.setValidators([Validators.required, noSpaceValidator()]);
          if (this.formControlSerialNumberType.value === 'VIN') {
            this.formControlSerialNumber.setValidators([validVINValidator()]);
          }
          this.formControlSerialNumber.updateValueAndValidity();
        }
      })
    ).subscribe();
    // WEB-3702
    const sub7 = combineLatest([
      this.formControlDepositAmountDynamoney.valueChanges,
      this.formControlDepositAmountSupplier.valueChanges,
    ]).pipe(
      tap(r => {
        const depositAmountDynamoney = r[0] ?? 0;
        const depositAmountSupplier = r[1] ?? 0;
        const totalDepositAmount = depositAmountDynamoney + depositAmountSupplier;
        this.formControlDepositAmount.setValue(totalDepositAmount);
      })
    ).subscribe();
    this.subscriptions.push(sub);
    this.subscriptions.push(sub2);
    this.subscriptions.push(subGST);
    this.subscriptions.push(sub3);
    this.subscriptions.push(sub6);
    this.subscriptions.push(sub4);
    this.subscriptions.push(sub5);
    // WEB-3702
    this.subscriptions.push(sub7);

    if (!this.editAssetData && !this.updateAssetData) {
      const sub8 = combineLatest([
        this.formControlInvoicePrice.valueChanges,
        this.formControlAssetCategory.valueChanges,
        this.formControlAssetType.valueChanges,
      ]).pipe(
        tap((
          r: [CurrencyInputValue, AssetCategorySelectionValue, AssetCategoryTypeSelectionValue]
        ) => {
          this.formControlAutoGenerateInspection.setValue(this.showAutoGenerateInspection && this.showAutoGenerateInspectionConditions())
        })
      ).subscribe()
      this.subscriptions.push(sub8);
    }
  }

  ngOnChanges(){
    if(this.firstAssetData){
      setTimeout(() => {
        this.formControlAssetCategory.setValue(getAssetCategory(this.firstAssetData!.AppInfo.AssetCategory));
        setTimeout(() => {
          this.formControlAssetType.setValue(getAssetCategoryType(this.firstAssetData!.AppInfo.AssetCategory, this.firstAssetData!.AppInfo.AssetType));
          setTimeout(() => {
            const make = this.firstAssetData?.AssetSpec.make
            const model = this.firstAssetData?.AssetSpec.family ?? this.firstAssetData?.AssetSpec.vehicle
            let description = ''
            if (make) {
              description = make + " "
              if (model) {
                description += model + " "
              }
            }
            if(Number(getAssetYear(this.firstAssetData as AssetFinanceApplication))){
              this.formControlAssetYear.setValue(Number(getAssetYear(this.firstAssetData as AssetFinanceApplication)));
            }
            else{
              const year = String(this.firstAssetData?.AssetSpec.year).split(" ")
              this.formControlAssetYear.setValue(Number(year[0]));
            }
            this.formControlAssetDescription.setValue(description + (getAssetDescription(this.firstAssetData as AssetFinanceApplication) ?? ""));
            this.formControlInvoicePrice.setValue(Number(getInvoiceAmount(this.firstAssetData as AssetFinanceApplication)));
            const deposit = this.firstAssetData ? Number(getDeposit(this.firstAssetData)) : 0;
            this.formControlDepositAmount.setValue(deposit);
            // WEB-3702
            this.formControlDepositAmountDynamoney.setValue(deposit);
            this.formControlDepositAmountSupplier.setValue(0);
            this.formControlResidual.setValue(Number(getBalloonPayment(this.firstAssetData as AssetFinanceApplication)));
          }, 1000)
        }, 1500)
      }, 1000)
    } else if (isExternalUser(this.loggedInUser)) {
      if (this.application.ApplicationType === 'AssetFinance' && this.application?.AppInfo?.AssetCategory) {
        this.assetCategoryIndexFilter = [this.application?.AppInfo?.AssetCategory, '141'];
      } else {
        this.assetCategoryIndexFilter = [];
      }
    } else {
      this.assetCategoryIndexFilter = [];
    }

    if (this.editAssetData) {  // EDIT : UPDATE
      console.log("Edit asset data:", this.editAssetData)
      this.editAssetFlag = true
      console.log('flag changed to::::', this.editAssetFlag)
      this.showLoader = true
      setTimeout(() => {
        this.formControlAssetCategory.setValue({ index: this.editAssetData?.SettlementAssetDetails?.assetCategory!, value: ''});
        setTimeout(() => {
          this.formControlAssetType.setValue({ index: this.editAssetData?.SettlementAssetDetails?.assetType!, value: ''});
        }, 1500)
      }, 1000)
      setTimeout(() => {
        this.formControlSerialNumberType.setValue(this.editAssetData?.SettlementAssetDetails?.serialNumberType!);
        setTimeout(() => {
          this.formControlSerialNumber.setValue(this.editAssetData?.SettlementAssetDetails?.serialNumber!);
          setTimeout(() => {
            this.formControlRego.setValue(this.editAssetData?.SettlementAssetDetails?.rego!);
          }, 1200)
        }, 1200)
        setTimeout(() => {
          this.formControlAssetYear.setValue(this.editAssetData?.SettlementAssetDetails?.year!);
        },3000)
         setTimeout(() => {
        this.formControlAssetDescription.setValue(this.editAssetData?.SettlementAssetDetails?.description!);
      }, 3000)
      setTimeout(() => {
        this.formControlInvoicePrice.setValue(this.editAssetData?.SettlementAssetDetails?.InvoicePrice!);
      }, 3000)
      setTimeout(() => {
        if (this.editAssetData?.SettlementAssetDetails?.depositAmount) {
          this.formControlDepositAmount.setValue(this.editAssetData?.SettlementAssetDetails?.depositAmount!);
        }
        // WEB-3702
        if (this.editAssetData?.SettlementAssetDetails?.depositAmountDynamoney) {
          this.formControlDepositAmountDynamoney.setValue(this.editAssetData?.SettlementAssetDetails?.depositAmountDynamoney ?? 0);
        }
        if (this.editAssetData?.SettlementAssetDetails?.depositAmountSupplier) {
          this.formControlDepositAmountSupplier.setValue(this.editAssetData?.SettlementAssetDetails?.depositAmountSupplier ?? 0);
        }
      }, 3000)
      setTimeout(() => {
        this.formControlResidual.setValue(this.editAssetData?.SettlementAssetDetails?.residual ?? 0);
      }, 3000)
        setTimeout(() => {
        this.formControlGst.setValue(this.editAssetData?.SettlementAssetDetails?.GST!)
        this.formControlAutoGenerateInspection.setValue(this.editAssetData?.SettlementAssetDetails?.autoGenerateInspection);
        this.showLoader = false;
        this.finishLoadingEvent.emit(true);
      }, 3000)
      }, 1200)
    }
    if (this.updateAssetData) {

      setTimeout(() => {
        this.formControlAssetCategory.setValue({ index: this.updateAssetData?.assetCategory.index, value: this.updateAssetData?.assetCategory.value });
      }, 1000)
      setTimeout(() => {
        this.formControlAssetType.setValue({ index: this.updateAssetData?.assetType.index, value: this.updateAssetData?.assetCategory.value });
      }, 1500)
      setTimeout(() => {
        this.formControlAssetDescription.setValue(this.updateAssetData?.description);
      }, 1500)
      setTimeout(() => {
        this.formControlAssetYear.setValue(this.updateAssetData?.year);
      },1500)
      setTimeout(() => {
        this.formControlSerialNumberType.setValue(this.updateAssetData?.serialNumberType);
      }, 1200)
      setTimeout(() => {
        this.formControlSerialNumber.setValue(this.updateAssetData?.serialNumber);
      }, 1200)
      setTimeout(() => {
        this.formControlInvoicePrice.setValue(this.updateAssetData?.invoicePrice);
      }, 1000)
      setTimeout(() => {
        this.formControlRego.setValue(this.updateAssetData?.rego);
      }, 1200)
      setTimeout(() => {
        this.formControlResidual.setValue(this.updateAssetData?.residual);
      }, 1000)
      setTimeout(() => {
        this.formControlDepositAmount.setValue(this.updateAssetData?.depositAmount);
      }, 1000)
      setTimeout(() => {
        this.formControlGst.setValue(this.updateAssetData?.gst)
      }, 3000)
      setTimeout(() => {
        this.formControlAutoGenerateInspection.setValue(this.updateAssetData?.autoGenerateInspection);
      }, 1000)
    }

    this.showAutoGenerateInspection = this.privateSeller;
  }

  ngAfterViewInit(){
    if(this.application.ApplicationType == 'Consumer'){
      this.formControlAssetCategory.setValue(getAssetCategory('139'))
      this.consumerAssetTypes = getConsumerAssetFinanceOnlyAssetCategoryTypes()
    }
  }
  doWriteValue(v: PpsrAssetValue | null | undefined): void | PpsrAssetValue {
    console.log('*************** ppsr-asset write value', v);
    if (v) {
      this.formControlAssetCategory.setValue(v.assetCategory);
      setTimeout(() => {
        this.formControlAssetType.setValue(v.assetType);
      }, 1000);
      this.formControlSerialNumberType.setValue(v.serialNumberType);
      this.formControlSerialNumber.setValue(v.serialNumber);
      this.formControlRego.setValue(v.rego ?? "");
      this.formControlInvoicePrice.setValue(v.invoicePrice);
      this.formControlGst.setValue(v.gst);
      this.formControlAssetId.setValue(v.AssetId);
      this.formControlAutoGenerateInspection.setValue(!!v?.autoGenerateInspection);
    }
    return undefined;
  }

  // onAddAsset($event: Event) {
  //   // let data = this.formGroup.value;
  //   // // console.log('this is the data', data);
  //   // if (this.updateAssetData) {
  //   //   data.assetId = this.updateAssetData.AssetId;
  //   // }
  //   // markTriggerSubject(this.formGroup).next(true);
  //   // doMarkAll(this.formGroup);
  //   // if (this.formGroup.invalid) { }
  //   // // else {
  //   //   this.data.next({
  //   //     asset: data
  //   //   })
  //     this.events.next({
  //       type: 'Add',
  //     });
  //   //   this.formGroup.reset();
  //   //   this.childAssetCategorySelection.forEach(c => c.reset());
  //   //   this.childAssetTypeSelection.forEach(c => c.reset());
  //   // }
  // }

  onSaveAsset() {
    let data = this.formGroup.value;
    markTriggerSubject(this.formGroup).next(true);
    doMarkAll(this.formGroup);
    if (this.formGroup.invalid) {
      console.log('inside the invalid state')
    }
    else {
      this.savedDataEvent.next({
        asset: {
          assetCategory: this.formControlAssetCategory.value!,
          assetType: this.formControlAssetType.value!,
          description: this.formControlAssetDescription.value!,
          year: this.formControlAssetYear.value!,
          serialNumberType: this.formControlSerialNumberType.value!,
          serialNumber: this.formControlSerialNumber.value!,
          rego: this.formControlRego.value!,
          invoicePrice: this.formControlInvoicePrice.value!,
          gst: this.formControlGst.value!,
          assetId: this.formControlAssetId.value!,
          depositAmount: this.formControlDepositAmount.value!,
          // WEB-3702
          depositAmountDynamoney: this.formControlDepositAmountDynamoney.value!,
          depositAmountSupplier: this.formControlDepositAmountSupplier.value!,
          amountFinanced: this.formControlAmountFinanced.value!,
          residual: this.formControlResidual.value!,
          autoGenerateInspection: this.formControlAutoGenerateInspection.value!,
        }
      })
      this.onRemoveAsset()
      this.disableAddButtonEvent.emit(true);
      this.formGroup.reset();
      this.childAssetCategorySelection.forEach(c => c.reset());
      this.childAssetTypeSelection.forEach(c => c.reset());
      this.disableAssetRowEvent.emit(false);
    }
  }

  onRemoveAsset() {
    this.events.next({
      type: 'Remove',
    });
    this.disableAddButtonEvent.emit(true);
    this.formGroup.reset();
    this.childAssetCategorySelection.forEach(c => c.reset());
    this.childAssetTypeSelection.forEach(c => c.reset());
    this.disableAssetRowEvent.emit(false);
  }

  mark() {
    this.formGroup.markAllAsTouched();
    // this.markObservable.next(true);
  }

  assetInspectionAlreadyCreated() {
    const assetId: number | undefined = this.editAssetData?.AssetId ?? undefined;
    return this.assetInspectionTableData.find(obj => {
      const externalRefSplits = obj.ExternalRef.split('-');
      if (externalRefSplits.length > 1) {
        return Number(externalRefSplits[1]) === assetId;
      }
      return false;
    })
  }

  showAutoGenerateInspectionConditions(): boolean {
    return (
      this.formControlInvoicePrice.value ?? 0) <= 150000 &&
      !!getVerimotoAssetGuid(
        this.formControlAssetCategory.value?.index ?? '',
        this.formControlAssetType.value?.index ?? ''
      ) && !this.assetInspectionAlreadyCreated();
  }
}
