// BPAY
import {Observable} from 'rxjs';
import {ApiResponse, PaginablePayloadApiResponse, PayloadApiResponse, SortTypes} from './types.domain';
import { PismoTransactionProcessingCode } from './pismo.domain';

export const FundingBsbNumber = "033-001";
export const FundingAccountNumber = "166054";

export type AddBankingTransactionFn = (body: NewBankingTransactionBody) => Observable<number>;
export type EditBatchPaymentFn = (body: Partial<BatchPayment>) => Observable<ApiResponse>;
export type EditTransactionFn = (body: Partial<DirectPayment | BPayPayment>) => Observable<ApiResponse>;
export type DeleteBatchPaymentFn = (body: BatchPayment) => Observable<ApiResponse>;
export type DeleteTransactionFn = (body: DirectPayment | BPayPayment) => Observable<ApiResponse>;
export type GetBatchPaymentsFn = (userId: number) => Observable<BatchPayment[]>;
export type GetTransactionsFn = (batchId: number) => Observable<DirectPayment[] | BPayPayment[]>;
export type GeneratePaymentFileFn = (batchId: number) => Observable<string>;
export enum BankingPaymentType {
  BPAY = "bpay",
  DIRECT = "direct-payment",
}

export interface BPayPayment {
  id: number;
  date: string; //ddmmyyyy eg: 07102022 or 3082022
  amount: number;
  billerCode: string;
  crn: string;
  reference: string; // payer payment reference
  type: 'bpay';
  batchId: number;
  userId: number;
  customerId: number;
  PismoAccountId: number;
  PismoCustomerId: number;
  isDeleted: boolean;
  createdTime: string;
  lastUpdatedTime: string;
  status: BankingTransactionStatus;
  rowNumber?: number;
  trackingId: string;
  errorMessage?: string;
  authorizationId: string;
  processingCode: PismoTransactionProcessingCode;
  transactionPosted?: boolean;
}

// Direct Payment
export const DirectEntryIDForDirectCredit = 634928;
export const DirectEntryIDForDirectDebit = 483231;
export const TraceBsbNumberForDirectCredit = "032-797";
export const TraceBsbNumberForDirectDebit = "032-797";
export const TraceAccountNumberForDirectCredit = '306096';
export const TraceAccountNumberForDirectDebit = '305595';
export const OverdraftAccountBsbNumber = "032-143";
export const OverdraftAccountNumber = "747976";

export interface NewBatchPaymentBody {
  userId: number;
  type: BankingPaymentType;
  description: string;
  date: string;
  userName?: string; // Your (money sender) company name - only for direct payments
  userNumber?: number; // Your ID in the bank system, often hardcoded to some number. Consult with your bank - only for direct payments
}

export interface BatchPayment {
  id: number;
  userId: number;
  type: BankingPaymentType;
  description: string;
  createdTime: string;
  lastUpdatedTime: string;
  date: string;
  status: string;
  userName?: string; // Your (money sender) company name - only for direct payments
  userNumber?: number; // Your ID in the bank system, often hardcoded to some number. Consult with your bank - only for direct payments
  //payments: DirectPayment[] | BPayPayment[];
  isDeleted: boolean;
  paymentFile?: string;
  transactions?: number[];
}

//****  deprecated ****//
export interface BatchDirectPayment {
  userName: string; // Your (money sender) company name
  userNumber: number; // Your ID in the bank system, often hardcoded to some number. Consult with your bank
  description: string; // Description of the transactions within the file
  date: string; // ddmmyyyy
  payments: DirectPayment[];
}

export interface DirectPayment {
  id: number;
  type: 'direct-debit' | 'direct-credit';
  date: string;
  bsb: string; // 2-8
  accountNumber: string; // 9-17
  amount: number; // 21-30
  accountTitle: string; // 31-62
  reference: string; // 63-80
  batchId: number;
  userId: number;
  customerId: number;
  PismoAccountId: number;
  PismoCustomerId: number;
  isDeleted: boolean;
  createdTime: string;
  lastUpdatedTime: string;
  status: BankingTransactionStatus;
  processingCode: PismoTransactionProcessingCode;
  rowNumber?: number;
  trackingId: string;
  errorMessage?: string;
  authorizationId: string;
  transactionPosted?: boolean;
}

export interface NewDirectPaymentBody {
  type: 'direct-debit' | 'direct-credit';
  id?: number,     // this is the id of OverdraftUserPayee table ID
  date: string;
  bsb: string; // 2-8
  accountNumber: string; // 9-17
  amount: number; // 21-30
  accountTitle: string; // 31-62
  reference: string; // 63-80
  userId: number;
  customerId: number;
  PismoAccountId: number;
  PismoCustomerId: number;
}

export interface NewBPayPaymentBody {
  date: string; //ddmmyyyy eg: 07102022 or 3082022
  id: number,
  amount: number;
  billerCode: string;
  accountTitle: string;
  crn: string;
  reference: string; // payer payment reference
  type: 'bpay';
  userId: number;
  customerId: number;
  PismoAccountId: number;
  PismoCustomerId: number;
}

export type BankingTransactionStatus = 'created' | 'pismo-rejected' | 'pending' | 'DEEDS-failed' | 'DEEDS-success' | 'DERPS-failed' | 'DERPS-success' | 'CSV-failed' | 'CSV-success'; // Can add more according to the resposne files

export interface NewBankingTransactionBody {
  userId: number;
  batchId?: number;
  type: 'direct-debit' | 'direct-credit' | 'bpay';
  amount: number;
  billerCode: string;
  crn: string;
  reference: string;
  date: string;
  bsb: string;
  accountNumber: string;
  accountTitle: string;
  trackingId: string; // for pismo
  processingCode: PismoTransactionProcessingCode;
  id?: number;
  transactionId?: number; // foreign key
  authorizationId: string; // for pismo
  status?: BankingTransactionStatus; // for processed transaction only
  customerId: number;
  PismoAccountId: number;
  PismoCustomerId: number;
  rowNumber?: number;
  errorMessage?: string;
  responseFile?: string;
}

export interface NewTransactionResponse {
  id: number;
  createdTime: string;
}

export interface BankingTransaction {
  userId: number;
  batchId: number;
  type: 'direct-debit' | 'direct-credit' | 'bpay';
  amount: number;
  billerCode: string;
  crn: string;
  reference: string;
  date: string;
  bsb: string;
  accountNumber: string;
  accountTitle: string;
  trackingId: string; // for pismo
  id: number;
  status: BankingTransactionStatus;
  processingCode: PismoTransactionProcessingCode;
  rowNumber: number; // the row number that this transaction is in the batch
  authorizationId: string;
  customerId: number;
  PismoAccountId: number;
  PismoCustomerId: number;
  transactionPosted?: boolean;
  createdTime?: string;
  lastUpdatedTime?: string;
  //autoRetry: boolean;
  //ip? geoLocation?
}

export interface BPayTransactionDialogData {
  bpay?: BPayPayment;
}

export interface BPayTransactionDialogResult {
  readyForSubmission: boolean;
  bpay: BPayPayment;
}

export interface DirectPaymentTransactionDialogData {
  directPayment?: DirectPayment;
}

export interface DirectPaymentTransactionDialogResult {
  readyForSubmission: boolean;
  directPayment: DirectPayment;
}

export interface DirectPaymentBatchDialogData {
  payments: DirectPayment[];
}

export interface DirectPaymentBatchDialogResult {
  readyForSubmission: boolean;
  batchDirectPayment: BatchDirectPayment;
}

export interface NewBatchPaymentDialogData {

}

export interface NewBatchPaymentDialogResult {
  readyForSubmission: boolean;
  batchPayment: {
    type: BankingPaymentType;
    description: string;
    userName?: string; // Your (money sender) company name - only for direct payments
    userNumber?: number;
    date: string;
    id?: number;
  }
}

export interface SftpListFiles {
  type: string;
  name: string;
  size: number;
  modifyTime: number;
  accessTime: number;
  rights: {
    user: string;
    group: string;
    other: String;
  };
  longname: string;
}

export interface NewBrokerageTransaction {
  PismoAccountId: number,
  CustomerId: number,
  TotalBrokerage: number,
  NetBrokerage: number,
  GST: number,
  TransactionId: number,
  BrokerName: string,
  BrokerAccountName: string,
  BrokerBsb: string,
  BrokerAccountNumber: string,
  BrokerFinancialInstitutionName: string,
}

export interface FiscalBankingNofitication {
  paymentFile: string;
  responseFile: string;
  failedTransactions: OskoPaymentResponse[];
  oskoNotReachableTransactions: OskoPaymentResponse[];
}

export interface FiscalBankingErrorNotification {
  time: string;
  message: string;
}

export interface OskoPaymentResponse {
  "Pay From Account": string;
  "Your Bank Reference": string;
  "Payment Type": string;
  "Remittance Type": string;
  "Buyer Reference": string;
  "Bank Ref/Cheq#": string;
  "Success": string;
  "Status": string;
  "Status Description": string;
  "Payment Date": string;
  "Recipient Code": string;
  "Recipient Name": string;
  "Recipient Account": string;
  "Currency": string;
  "Amount": string;
}

export interface GetBankingTransaction extends BankingTransaction {
  pendingTransactionCreatedTime: string | null
  pendingTransactionLastUpdatedTime: string | null
  pendingTransactionStatus: BankingTransactionStatus | null,
  processedTransactionCreatedTime: string | null,
  processedTransactionLastUpdatedTime: string | null,
  processedTransactionErrorMessage: string | null,
  processedTransactionStatus: BankingTransactionStatus | null,
  batchStatus: string | null,
  batchPaymentFile: string | null,
  batchCreatedTime: string | null,
  batchLastUpdatedTime: string | null,
  netBrokerage: number | null,
  brokerageGst: number | null,
  responseFile: string | null,
  sentToBankTime: string | null,
  processedTime: string | null,
}

export type BankingTransactionSortType = SortTypes<'id' | 'batchId' | 'type' | 'amount' | 'date' | 'PismoAccountId' | 'PismoCustomerId'>;

export interface ScheduledTransaction extends DirectPayment {
  processedStatus: BankingTransactionStatus;
  processedErrorMessage: string;
  scheduledTime: string;
  processedTransactionId: number;
}

export interface NewPendingApprovalTransactionBody {
  userId: number;
  type: 'direct-debit' | 'direct-credit' | 'bpay';
  amount: number;
  billerCode: string;
  crn: string;
  reference: string;
  date: string;
  bsb: string;
  accountNumber: string;
  accountTitle: string;
  processingCode: PismoTransactionProcessingCode;
  customerId: number;
  PismoAccountId: number;
  PismoCustomerId: number;
}

export interface PendingApprovalTransaction extends NewPendingApprovalTransactionBody {
  id: number;
  createdTime: string;
  lastUpdatedTime: string;
  lastUpdatedBy: number;
  status: 'Approved' | 'Pending' | 'Rejected';
  transactionId: number;
  GivenName?: string;
  FamilyName?: string;
}

export interface UpdatePendingTransactionsBody {
  transactions: number[];
  status: 'Approved' | 'Rejected';
}

export interface UpdateAllPendingTransactionsBody {
  status: 'Approved' | 'Rejected';
}

export type GetPendingApprovalTransactionsFn = (opt: {
  page: {
    limit: number,
    offset: number,
  },
  filter: string,
  sorts: any,
}) => Observable<PaginablePayloadApiResponse<PendingApprovalTransaction>>;

export type GetProcessedPendingTransactionsFn = (opt: {
  page: {
    limit: number,
    offset: number,
  },
  filter: string,
  sorts: any,
}) => Observable<PaginablePayloadApiResponse<PendingApprovalTransaction>>;

export type UpdatePendingTransactionsFn = (data: UpdatePendingTransactionsBody) => Observable<ApiResponse>;
export type UpdateAllPendingTransactionsFn = (data: UpdateAllPendingTransactionsBody) => Observable<ApiResponse>;

export type PendingTransactionSortType = SortTypes<'id' | 'type' | 'amount' | 'date' | 'PismoAccountId' | 'PismoCustomerId'>;
