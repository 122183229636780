<div class="generate-contract-dialog">
  <mat-dialog-content class= 'max-height-100-vh'>
    @if (depositValidationMessage) {
      <message-box title="Deposit mismatch" type="warn">
        {{depositValidationMessage}}
      </message-box>
    }
    <application-stepper2 [linear]="false"
      [showHeader]="false"
      #applicationStepper="applicationStepper2">
      @if (application.ApplicationType === 'AssetFinance' || application.ApplicationType === 'Consumer') {
        <cdk-step
          [stepControl]="formGroupStep1" ><ng-content *ngTemplateOutlet="step1_asset" ></ng-content></cdk-step>
        }
        @if (application.ApplicationType === 'BusinessLoans') {
          <cdk-step
            [stepControl]="formGroupStep1BusinessLoan" ><ng-content *ngTemplateOutlet="step1_business_loan" ></ng-content></cdk-step>
          }
          @if (application.ApplicationType === 'BusinessOverdraft') {
            <cdk-step
              [stepControl]="formGroupStep1BusinessOverdraft" ><ng-content *ngTemplateOutlet="step1_business_overdraft" ></ng-content></cdk-step>
            }

            @if (application.ApplicationType === 'CorporateLoans' ) {
              <cdk-step
                [stepControl]="formGroupStep1CorporateLoans" ><ng-content *ngTemplateOutlet="step1_corporate_loans" ></ng-content></cdk-step>
              }
            <cdk-step [stepControl]="formGroupStep2" ><ng-content *ngTemplateOutlet="step2" ></ng-content></cdk-step>
            <cdk-step [stepControl]="formGroupStep3" ><ng-content *ngTemplateOutlet="step3" ></ng-content></cdk-step>
            <cdk-step [stepControl]="formGroupStep4" ><ng-content *ngTemplateOutlet="step4" ></ng-content></cdk-step>
          </application-stepper2>
        </mat-dialog-content>
      </div>

      <!--
      ##########################################
      #  STEP 1:
      ##########################################
      -->
      <ng-template #step1_asset>
        <div class="step1">
          <form [formGroup]="formGroupStep1" >
            <div class="row">
              <div class="col-12">
                <div class="mat-subtitle-1 header">Contract Details</div>
              </div>
            </div>
            @if (application.ApplicationType === 'AssetFinance') {
              <div class="row">
                <div class="col-6">
                  <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                    <div>Legal Name</div>
                    @if (!editLegalName) {
                      <div>
                        {{formControlStep1LegalName.value}}
                        <span [ngClass]="{'visibility-hidden': isExternalUser(loggedInUser)}" class="mdi mdi-pencil-outline" matTooltip="Edit" matTooltipPosition="right" (click)="editField('legalName')"></span>
                      </div>
                    }
                    @if (editLegalName) {
                      <div fxLayoutAlign="start center" class="long-input">
                        <mat-form-field>
                          <input matInput type="text" [formControl]="formControlStep1LegalName">
                          @for (errorKey of errorKeys(formControlStep1LegalName); track errorKey) {
                            <mat-error>
                              @if (formControlStep1LegalName.touched && formControlStep1LegalName.hasError(errorKey)) {
                                {{errorMessage(formControlStep1LegalName, errorKey)}}
                              }
                            </mat-error>
                          }
                        </mat-form-field>
                        <span class="mdi mdi-check" matTooltip="Save" matTooltipPosition="right" (click)="editField('legalName')"></span>
                      </div>
                    }
                  </div>
                </div>
                <div class="col-6">
                  <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                    <div>Address</div>
                    <div >
                      {{formControlStep1Address.value?.address ?? ""}}
                      <span class="mdi mdi-pencil-outline" matTooltip="Edit" matTooltipPosition="right" (click)="onEditAddress()"></span>
                    </div>
                  </div>
                </div>
              </div>
            }
            @if (application.ApplicationType === 'AssetFinance') {
              <div class="row">
                <div class="col-6">
                  <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                    <div>ABN</div>
                    @if (!editABN) {
                      <div>
                        {{formControlStep1ABN.value}}
                        <span [ngClass]="{'visibility-hidden': isExternalUser(loggedInUser)}" class="mdi mdi-pencil-outline" matTooltip="Edit" matTooltipPosition="right" (click)="editField('ABN')"></span>
                      </div>
                    }
                    @if (editABN) {
                      <div fxLayoutAlign="start center" class="medium-input">
                        <mat-form-field>
                          <input matInput type="text" [inputMask]="createAbnInputMask" [formControl]="formControlStep1ABN">
                          @for (errorKey of errorKeys(formControlStep1ABN); track errorKey) {
                            <mat-error>
                              @if (formControlStep1ABN.touched && formControlStep1ABN.hasError(errorKey)) {
                                {{errorMessage(formControlStep1ABN, errorKey)}}
                              }
                            </mat-error>
                          }
                        </mat-form-field>
                        <span class="mdi mdi-check" matTooltip="Save" matTooltipPosition="right" (click)="editField('ABN')"></span>
                      </div>
                    }
                  </div>
                </div>
                <div class="col-6">
                  <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                    <div>ACN</div>
                    @if (!editACN) {
                      <div>
                        {{formControlStep1ACN.value}}
                        <span [ngClass]="{'visibility-hidden': isExternalUser(loggedInUser)}" class="mdi mdi-pencil-outline" matTooltip="Edit" matTooltipPosition="right" (click)="editField('ACN')"></span>
                      </div>
                    }
                    @if (editACN) {
                      <div fxLayoutAlign="start center" class="medium-input">
                        <mat-form-field>
                          <input matInput type="text" [inputMask]="createAcnInputMask"[formControl]="formControlStep1ACN">
                        </mat-form-field>
                        <span class="mdi mdi-check" matTooltip="Save" matTooltipPosition="right" (click)="editField('ACN')"></span>
                      </div>
                    }
                  </div>
                </div>
              </div>
            }
            <div class="row">
              <div class="col-6">
                <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                  <div>Company Email</div>
                  @if (!editEmail) {
                    <div>
                      {{formControlStep1Email.value}}
                      <span [ngClass]="{'visibility-hidden': isExternalUser(loggedInUser)}" class="mdi mdi-pencil-outline" matTooltip="Edit" matTooltipPosition="right" (click)="editField('companyEmail')"></span>
                    </div>
                  }
                  @if (editEmail) {
                    <div fxLayoutAlign="start center" class="long-input">
                      <mat-form-field>
                        <input matInput type="text" [formControl]="formControlStep1Email">
                        @for (errorKey of errorKeys(formControlStep1Email); track errorKey) {
                          <mat-error>
                            @if (formControlStep1Email.touched && formControlStep1Email.hasError(errorKey)) {
                              {{errorMessage(formControlStep1Email, errorKey)}}
                            }
                          </mat-error>
                        }
                      </mat-form-field>
                      <span class="mdi mdi-check" matTooltip="Save" matTooltipPosition="right" (click)="editField('companyEmail')"></span>
                    </div>
                  }
                </div>
              </div>
              <div class="col-6">
                <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                  <div>Amount Financed</div>
                  <div class="postfix-icon-padding">
                    {{formControlStep1AmountFinanced.value | looseCurrency}}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                  <div>Repayment Frequency</div>
                  <div class="postfix-icon-padding">
                    {{formControlStep1RepaymentFrequency.value?.name}}
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                  <div>Term</div>
                  <div class="postfix-icon-padding">
                    {{formControlStep1LoanTerm.value?.name}}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                  <div>Invoice Amount</div>
                  <div class="postfix-icon-padding">
                    {{formControlStep1InvoicePrice.value | looseCurrency}}
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                  <div>Transaction Type</div>
                  <div class="postfix-icon-padding">
                    {{formControlStep1TransactionType.value?.name}}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                  <div>Deposit (incl GST)</div>
                  @if (!editDeposit) {
                    <div>
                      {{formControlStep1Deposit.value | looseCurrency}}
                      <span class="mdi mdi-pencil-outline" matTooltip="Edit" matTooltipPosition="right" (click)="editField('deposit')"></span>
                    </div>
                  }
                  @if (editDeposit) {
                    <div fxLayoutAlign="start center">
                      <mat-form-field>
                        <input matInput type="number" [formControl]="formControlStep1Deposit">
                        @for (errorKey of errorKeys(formControlStep1Deposit); track errorKey) {
                          <mat-error>
                            @if (formControlStep1Deposit.touched && formControlStep1Deposit.hasError(errorKey)) {
                              {{errorMessage(formControlStep1Deposit, errorKey)}}
                            }
                          </mat-error>
                        }
                      </mat-form-field>
                      <span class="mdi mdi-check" matTooltip="Save" matTooltipPosition="right" (click)="editField('deposit')"></span>
                    </div>
                  }
                </div>
              </div>
              <div class="col-6">
                <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                  <div>Residual</div>
                  <div class="postfix-icon-padding">
                    {{formControlStep1Residual.value | looseCurrency}}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                  <div>Documentation Fee</div>
                  <div class="postfix-icon-padding">
                    {{formControlStep1DocFee.value | looseCurrency}}
                  </div>
                </div>
              </div>
              <div class="col-6">
                @if (application.ApplicationType === 'AssetFinance') {
                  <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                    <div>Doc Fee Financed</div>
                    @if (!editDocFeeFinanced) {
                      <div>
                        {{formControlStep1DocFeeFinanced.value === true ? 'Yes' : 'No'}}
                        <span class="mdi mdi-pencil-outline" matTooltip="Edit" matTooltipPosition="right" (click)="editField('docFeeFinanced')"></span>
                      </div>
                    }
                    @if (editDocFeeFinanced) {
                      <div>
                        <mat-select [formControl]="formControlStep1DocFeeFinanced" class="doc-fee-financed-selection">
                          @for (option of [true, false]; track option) {
                            <mat-option [value]="option">{{option ? 'Yes' : 'No'}}</mat-option>
                          }
                        </mat-select>
                        <span class="mdi mdi-check" matTooltip="Save" matTooltipPosition="right" (click)="editField('docFeeFinanced')"></span>
                      </div>
                    }
                  </div>
                }
                @else {
                  <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                    <div>Doc Fee Financed</div>
                    <div class="postfix-icon-padding">
                      {{formControlStep1DocFeeFinanced.value === true ? 'Yes' : 'No'}}
                    </div>
                  </div>
                }
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                @if (application.ApplicationType === 'AssetFinance') {
                <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                  <div>Brokerage</div>
                  @if (!editBrokerage) {
                    <div>
                      {{formControlStep1BrokerageAmount.value | looseCurrency}}
                      <span class="mdi mdi-pencil-outline" matTooltip="Edit" matTooltipPosition="right" (click)="editField('brokerage')"></span>
                    </div>
                  }
                  @if (editBrokerage) {
                    <div fxLayoutAlign="start center">
                      <mat-form-field>
                        <input matInput type="number" [formControl]="formControlStep1BrokerageAmount">
                        @for (errorKey of errorKeys(formControlStep1BrokerageAmount); track errorKey) {
                          <mat-error>
                            @if (formControlStep1BrokerageAmount.touched && formControlStep1BrokerageAmount.hasError(errorKey)) {
                              {{errorMessage(formControlStep1BrokerageAmount, errorKey)}}
                            }
                          </mat-error>
                        }
                      </mat-form-field>
                      <span class="mdi mdi-check" matTooltip="Save" matTooltipPosition="right" (click)="editField('brokerage')"></span>
                    </div>
                  }
                </div>
                }
                @else {
                  <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                    <div>Commission</div>
                    <div class="postfix-icon-padding">
                      {{formControlStep1Commission.value | looseCurrency}}
                    </div>
                  </div>
                }
              </div>
              <div class="col-6">
                <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                  <div>Number of Installments</div>
                  <div class="postfix-icon-padding">
                    {{formControlStep1NumberOfInstallment.value}}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                  @if (application && application.ApplicationType !== 'Consumer') {
                    <div>Broker Origination Fee</div>
                  }
                  @if (application && application.ApplicationType === 'Consumer') {
                    <div>Credit Assistance Fee</div>
                  }
                  @if (!editBrokerOriginationFee) {
                    <div>
                      {{formControlStep1BrokerOriginationFee.value | looseCurrency}}
                      <span class="mdi mdi-pencil-outline" matTooltip="Edit" matTooltipPosition="right" (click)="editField('brokerOriginationFee')"></span>
                    </div>
                  }
                  @if (editBrokerOriginationFee) {
                    <div fxLayoutAlign="start center">
                      <mat-form-field>
                        <input matInput type="number" [formControl]="formControlStep1BrokerOriginationFee">
                        @for (errorKey of errorKeys(formControlStep1BrokerOriginationFee); track errorKey) {
                          <mat-error>
                            @if (formControlStep1BrokerOriginationFee.touched && formControlStep1BrokerOriginationFee.hasError(errorKey)) {
                              {{errorMessage(formControlStep1BrokerOriginationFee, errorKey)}}
                            }
                          </mat-error>
                        }
                        <span class="mdi mdi-currency-usd" matPrefix></span>
                      </mat-form-field>
                      <span class="mdi mdi-check" matTooltip="Save" matTooltipPosition="right" (click)="editField('brokerOriginationFee')"></span>
                    </div>
                  }
                </div>
              </div>
              <div class="col-6">
                <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                  <div>Term Charges (Interest)</div>
                  <div class="postfix-icon-padding">
                    {{formControlStep1TotalInterest.value | looseCurrency}}
                  </div>
                </div>
              </div>
            </div> 

            @if (application.ApplicationType === 'Consumer') {
              <div class="row">
                <div class="col-6">
                  <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                    <div>Rate Discount</div>
                    @if (!editRateDiscount) {
                      <div>
                        {{formControlStep1RateDiscount.value | number : '1.2-2' | percentage}}
                        <span class="mdi mdi-pencil-outline" matTooltip="Edit" matTooltipPosition="right" (click)="editField('rateDiscount')"></span>
                      </div>
                    }
                    @if (editRateDiscount) {
                      <div fxLayoutAlign="start center">
                        <mat-form-field>
                          <input matInput type="number" [formControl]="formControlStep1RateDiscount">
                          <span class="mdi mdi-percent" matSuffix></span>
                          @for (errorKey of errorKeys(formControlStep1RateDiscount); track errorKey) {
                            <mat-error>
                              @if (formControlStep1RateDiscount.touched && formControlStep1RateDiscount.hasError(errorKey)) {
                                {{errorMessage(formControlStep1RateDiscount, errorKey)}}
                              }
                            </mat-error>
                          }
                          <span class="mdi mdi-currency-usd" matPrefix></span>
                        </mat-form-field>
                        <span class="mdi mdi-check" matTooltip="Save" matTooltipPosition="right" (click)="editField('rateDiscount')"></span>
                      </div>
                    }
                  </div>
                </div>
                <div class="col-6">
                  <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                    <div>Total Earned (incl GST)</div>
                    <div class="postfix-icon-padding">
                      {{formControlStep1TotalEarnedInclGst.value | looseCurrency}}
                    </div>
                  </div>
                </div>
              </div>
            }

            <div class="row">
              <div class="col-6">
                <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                  <div>
                    @if (application.ApplicationType!=='Consumer') {
                      Rate
                    }
                    @if (application.ApplicationType==='Consumer') {
                      APR (Rate)
                    }
                  </div>
                  <div class="postfix-icon-padding">
                    @if (application.ApplicationType!=='Consumer') {
                      {{(formControlStep1InterestRate.value ?? 0) | number : '1.2-2' | percentage}}
                    }
                    @if (application.ApplicationType==='Consumer') {
                      {{(formControlStep1DisplayedRate.value ?? 0) | number : '1.2-2' | percentage}}
                    }
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                  <div>Total Amount Payable</div>
                  <div class="postfix-icon-padding">
                    {{formControlStep1TotalPayment.value | looseCurrency}}
                  </div>
                </div>
              </div>
            </div>  

            <div class="row">
              <div class="col-6">
                <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                  <div>Repayment</div>
                  <div class="postfix-icon-padding">
                    {{formControlStep1Repayment.value | looseCurrency}}
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                  <div>Payment Type</div>
                  <div class="postfix-icon-padding">
                    {{formControlStep1PaymentInAdvanceOrArrears.value}}
                  </div>
                </div>
              </div>
            </div>
            <!--      Deprecated, but maybe useful-->
            <!--      <div class="row">-->
            <!--        <div class="col-6">-->
            <!--          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">-->
            <!--            <div>Invoice price</div>-->
            <!--            <div *ngIf="!editInvoicePrice">-->
            <!--              {{formControlStep1InvoicePrice.value | looseCurrency}}-->
            <!--              <span class="mdi mdi-pencil-outline" matTooltip="Edit" matTooltipPosition="right" (click)="editField('invoicePrice')"></span>-->
          <!--            </div>-->
          <!--            <div *ngIf="editInvoicePrice" fxLayoutAlign="start center">-->
          <!--              <mat-form-field appearance="standard">-->
          <!--                <input matInput type="number" [formControl]="formControlStep1InvoicePrice">-->
          <!--                <span class="mdi mdi-currency-usd" matPrefix></span>-->
        <!--              </mat-form-field>-->
        <!--              <span class="mdi mdi-check" matTooltip="Save" matTooltipPosition="right" (click)="editField('invoicePrice')"></span>-->
      <!--            </div>-->
    <!--          </div>-->
  <!--        </div>-->
  <!--        <div class="col-6">-->
  <!--          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">-->
  <!--            <div>Term charges (interest)</div>-->
  <!--            <div class="postfix-icon-padding">-->
  <!--              {{formControlStep1TotalInterest.value | looseCurrency}}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row">-->
<!--        <div class="col-6">-->
<!--          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">-->
<!--            <div>Payment in advance / arrears</div>-->
<!--            <div *ngIf="!editPaymentInAdvanceOrArrears">-->
<!--              {{formControlStep1PaymentInAdvanceOrArrears.value}}-->
<!--              <span class="mdi mdi-pencil-outline" matTooltip="Edit" matTooltipPosition="right" (click)="editField('paymentInAdvanceOrArrears')"></span>-->
<!--            </div>-->
<!--            <div *ngIf="editPaymentInAdvanceOrArrears">-->
<!--              <mat-select [formControl]="formControlStep1PaymentInAdvanceOrArrears">-->
<!--                <mat-option *ngFor="let option of ['Advance', 'Arrears']" [value]="option">{{option}}</mat-option>-->
<!--              </mat-select>-->
<!--              <span class="mdi mdi-check" matTooltip="Save" matTooltipPosition="right" (click)="editField('paymentInAdvanceOrArrears')"></span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-6">-->
<!--          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">-->
<!--            <div>Total amount payable</div>-->
<!--            <div class="postfix-icon-padding">-->
<!--              {{formControlStep1TotalPayment.value | looseCurrency}}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row">-->
<!--        <div class="col-6">-->
<!--          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">-->
<!--            <div>Rate</div>-->
<!--            <div class="postfix-icon-padding">-->
<!--              {{formControlStep1InterestRate.value | number : '1.2-2'}}%-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-6">-->
<!--          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">-->
<!--            <div>Documentation fee</div>-->
<!--            <div class="postfix-icon-padding">-->
<!--              {{formControlStep1DocFee.value | looseCurrency}}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row">-->
<!--        <div class="col-6">-->
<!--          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">-->
<!--            <div>Term</div>-->
<!--            <div class="postfix-icon-padding">-->
<!--              {{formControlStep1LoanTerm.value?.name}}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-6">-->
<!--          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">-->
<!--            <div>Deposit (where applicable)</div>-->
<!--            <div class="postfix-icon-padding">-->
<!--              {{formControlStep1Deposit.value | looseCurrency}}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row">-->
<!--        <div class="col-6">-->
<!--          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">-->
<!--            <div>Repayment frequency</div>-->
<!--            <div *ngIf="!editRepaymentFrequency">-->
<!--              {{formControlStep1RepaymentFrequency.value?.name}}-->
<!--              <span class="mdi mdi-pencil-outline" matTooltip="Edit" matTooltipPosition="right" (click)="editField('repaymentFrequency')"></span>-->
<!--            </div>-->
<!--            <div *ngIf="editRepaymentFrequency" fxLayoutAlign="start center">-->
<!--              <mat-select [formControl]="formControlStep1RepaymentFrequency">-->
<!--                <mat-option *ngFor="let option of PaymentFrequencyValueOptions" [value]="option">{{option.name}}</mat-option>-->
<!--              </mat-select>-->
<!--              <span class="mdi mdi-check" matTooltip="Save" matTooltipPosition="right" (click)="editField('repaymentFrequency')"></span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-6">-->
<!--          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">-->
<!--            <div>Residual value</div>-->
<!--            <div *ngIf="!editResidual">-->
<!--              {{formControlStep1Residual.value | looseCurrency}}-->
<!--              <span class="mdi mdi-pencil-outline" matTooltip="Edit" matTooltipPosition="right" (click)="editField('residual')"></span>-->
<!--            </div>-->
<!--            <div *ngIf="editResidual" fxLayoutAlign="start center">-->
<!--              <mat-form-field appearance="standard">-->
<!--                <input matInput type="number" [formControl]="formControlStep1Residual">-->
<!--                <span class="mdi mdi-currency-usd" matPrefix></span>-->
<!--              </mat-form-field>-->
<!--              <span class="mdi mdi-check" matTooltip="Save" matTooltipPosition="right" (click)="editField('residual')"></span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row">-->
<!--        <div class="col-6">-->
<!--          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">-->
<!--            <div>Number of installments</div>-->
<!--            <div class="postfix-icon-padding">-->
<!--              {{formControlStep1NumberOfInstallment.value}}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-6">-->
<!--          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">-->
<!--            <div>Brokerage (incl. GST)</div>-->
<!--            <div *ngIf="!editBrokerage">-->
<!--              {{formControlStep1Brokerage.value?.name}}-->
<!--              <span class="mdi mdi-pencil-outline" matTooltip="Edit" matTooltipPosition="right" (click)="editField('brokerage')"></span>-->
<!--            </div>-->
<!--            <div *ngIf="editBrokerage" fxLayoutAlign="start center">-->
<!--              <mat-select [formControl]="formControlStep1Brokerage">-->
<!--                <mat-option *ngFor="let option of BrokerageSelectionValueOptions" [value]="option">{{option.name}}</mat-option>-->
<!--              </mat-select>-->
<!--              <span class="mdi mdi-check" matTooltip="Save" matTooltipPosition="right" (click)="editField('brokerage')"></span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row">-->
<!--        <div class="col-6">-->
<!--          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">-->
<!--            <div>Private sale</div>-->
<!--            <div class="postfix-icon-padding">-->
<!--              {{formControlStep1PrivateSale.value ? 'Yes' : 'No'}}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-6">-->
<!--          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">-->
<!--            <div>Broker origination fee manual (incl. GST)</div>-->
<!--            <div *ngIf="!editBrokerOriginationFee">-->
<!--              {{formControlStep1BrokerOriginationFee.value | looseCurrency}}-->
<!--              <span class="mdi mdi-pencil-outline" matTooltip="Edit" matTooltipPosition="right" (click)="editField('brokerOriginationFee')"></span>-->
<!--            </div>-->
<!--            <div *ngIf="editBrokerOriginationFee" fxLayoutAlign="start center">-->
<!--              <mat-form-field appearance="standard">-->
<!--                <input matInput type="number" [formControl]="formControlStep1BrokerOriginationFee">-->
<!--                <span class="mdi mdi-currency-usd" matPrefix></span>-->
<!--              </mat-form-field>-->
<!--              <span class="mdi mdi-check" matTooltip="Save" matTooltipPosition="right" (click)="editField('brokerOriginationFee')"></span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row">-->
<!--        <div class="col-6">-->
<!--          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">-->
<!--            <div>Amount financed</div>-->
<!--            <div class="postfix-icon-padding">-->
<!--              {{formControlStep1AmountFinanced.value | looseCurrency}}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-6">-->
<!--          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">-->
<!--            <div>Repayment</div>-->
<!--            <div class="postfix-icon-padding">-->
<!--              {{formControlStep1Repayment.value | looseCurrency}}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
</form>
</div>
</ng-template>

<ng-template #step1_business_loan>
  <div class="step1">
    <form [formGroup]="formGroupStep1BusinessLoan" >
      <div class="row">
        <div class="col-12">
          <div class="mat-subtitle-1 header">Contract Details</div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Legal Name</div>
            @if (!editLegalName) {
              <div>
                {{formControlStep1LegalName.value}}
                <span [ngClass]="{'visibility-hidden': isExternalUser(loggedInUser)}" class="mdi mdi-pencil-outline" matTooltip="Edit" matTooltipPosition="right" (click)="editField('legalName')"></span>
              </div>
            }
            @if (editLegalName) {
              <div fxLayoutAlign="start center" class="long-input">
                <mat-form-field>
                  <input matInput type="text" [formControl]="formControlStep1LegalName">
                </mat-form-field>
                <span class="mdi mdi-check" matTooltip="Save" matTooltipPosition="right" (click)="editField('legalName')"></span>
              </div>
            }
          </div>
        </div>
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Address</div>
            <div >
              {{formControlStep1Address.value?.address ?? ""}}
              <span class="mdi mdi-pencil-outline" matTooltip="Edit" matTooltipPosition="right" (click)="onEditAddress()"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>ABN</div>
            @if (!editABN) {
              <div>
                {{formControlStep1ABN.value}}
                <span [ngClass]="{'visibility-hidden': isExternalUser(loggedInUser)}" class="mdi mdi-pencil-outline" matTooltip="Edit" matTooltipPosition="right" (click)="editField('ABN')"></span>
              </div>
            }
            @if (editABN) {
              <div fxLayoutAlign="start center" class="medium-input">
                <mat-form-field>
                  <input matInput type="text" [inputMask]="createAbnInputMask" [formControl]="formControlStep1ABN">
                </mat-form-field>
                <span class="mdi mdi-check" matTooltip="Save" matTooltipPosition="right" (click)="editField('ABN')"></span>
              </div>
            }
          </div>
        </div>
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>ACN</div>
            @if (!editACN) {
              <div>
                {{formControlStep1ACN.value}}
                <span [ngClass]="{'visibility-hidden': isExternalUser(loggedInUser)}" class="mdi mdi-pencil-outline" matTooltip="Edit" matTooltipPosition="right" (click)="editField('ACN')"></span>
              </div>
            }
            @if (editACN) {
              <div fxLayoutAlign="start center" class="medium-input">
                <mat-form-field>
                  <input matInput type="text" [inputMask]="createAcnInputMask"[formControl]="formControlStep1ACN">
                </mat-form-field>
                <span class="mdi mdi-check" matTooltip="Save" matTooltipPosition="right" (click)="editField('ACN')"></span>
              </div>
            }
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Loan Amount</div>
            <div class="postfix-icon-padding">
              {{formControlStep1LoanAmount.value | looseCurrency}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Term charges (interest)</div>
            <div class="postfix-icon-padding">
              {{formControlStep1TotalInterest.value | looseCurrency}}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Payment in advance / arrears</div>
            <div class="postfix-icon-padding">
              {{formControlStep1PaymentInAdvanceOrArrears.value}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Total amount payable</div>
            <div class="postfix-icon-padding">
              {{formControlStep1TotalPayment.value | looseCurrency}}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Rate</div>
            <div class="postfix-icon-padding">
              {{(formControlStep1InterestRate.value ?? 0) | number : '1.2-2' | percentage}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Doc/Annual fee</div>
            <div class="postfix-icon-padding">
              {{formControlStep1DocFee.value | looseCurrency}}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Term</div>
            <div class="postfix-icon-padding">
              {{formControlStep1LoanTerm.value?.name}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Repayment frequency</div>
            <div class="postfix-icon-padding">
              {{formControlStep1RepaymentFrequency.value?.name}}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Doc Fee Financed</div>
            @if (!editDocFeeFinanced) {
              <div>
                {{formControlStep1DocFeeFinanced.value === true ? 'Yes' : 'No'}}
                <span class="mdi mdi-pencil-outline" matTooltip="Edit" matTooltipPosition="right" (click)="editField('docFeeFinanced')"></span>
              </div>
            }
            @if (editDocFeeFinanced) {
              <div>
                <mat-select [formControl]="formControlStep1DocFeeFinanced" class="doc-fee-financed-selection">
                  @for (option of [true, false]; track option) {
                    <mat-option [value]="option">{{option ? 'Yes' : 'No'}}</mat-option>
                  }
                </mat-select>
                <span class="mdi mdi-check" matTooltip="Save" matTooltipPosition="right" (click)="editField('docFeeFinanced')"></span>
              </div>
            }
          </div>
        </div>
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Adverse on File</div>
            <div class="postfix-icon-padding">
              {{formControlStep1AdverseOnFile.value ? "Yes" : "No"}}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Equifax Score above 600</div>
            <div class="postfix-icon-padding">
              {{formControlStep1EquifaxScoreAboveThreshold.value ? "Yes" : "No"}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Director Score 500 - 600</div>
            <div class="postfix-icon-padding">
              {{formControlStep1DirectorScore.value ? "Yes" : "No"}}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Number of installments</div>
            <div class="postfix-icon-padding">
              {{formControlStep1NumberOfInstallment.value}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Establishment fee (Brokerage)</div>
            @if (!editBrokerage) {
              <div>
                {{formControlStep1BrokerageAmount.value | looseCurrency}}
                <span class="mdi mdi-pencil-outline" matTooltip="Edit" matTooltipPosition="right" (click)="editField('brokerage')"></span>
              </div>
            }
            @if (editBrokerage) {
              <div fxLayoutAlign="start center">
                <mat-form-field>
                  <input matInput type="number" [formControl]="formControlStep1BrokerageAmount">
                </mat-form-field>
                <span class="mdi mdi-check" matTooltip="Save" matTooltipPosition="right" (click)="editField('brokerage')"></span>
              </div>
            }
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Amount financed (incl Brokerage)</div>
            <div class="postfix-icon-padding">
              {{(formControlStep1AmountFinanced.value ?? 0)+(formControlStep1BrokerageAmount.value ?? 0) | looseCurrency}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Repayment</div>
            <div class="postfix-icon-padding">
              {{formControlStep1Repayment.value | looseCurrency}}
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #step1_business_overdraft>
  <div class="step1">
    <form [formGroup]="formGroupStep1BusinessOverdraft" >
      <div class="row">
        <div class="col-12">
          <div class="mat-subtitle-1 header">Contract Details</div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Legal Name</div>
            @if (!editLegalName) {
              <div>
                {{formControlStep1LegalName.value}}
                <span [ngClass]="{'visibility-hidden': isExternalUser(loggedInUser)}" class="mdi mdi-pencil-outline" matTooltip="Edit" matTooltipPosition="right" (click)="editField('legalName')"></span>
              </div>
            }
            @if (editLegalName) {
              <div fxLayoutAlign="start center" class="long-input">
                <mat-form-field>
                  <input matInput type="text" [formControl]="formControlStep1LegalName">
                </mat-form-field>
                <span class="mdi mdi-check" matTooltip="Save" matTooltipPosition="right" (click)="editField('legalName')"></span>
              </div>
            }
          </div>
        </div>
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Address</div>
            <div >
              {{formControlStep1Address.value?.address ?? ""}}
              <span class="mdi mdi-pencil-outline" matTooltip="Edit" matTooltipPosition="right" (click)="onEditAddress()"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>ABN</div>
            @if (!editABN) {
              <div>
                {{formControlStep1ABN.value}}
                <span [ngClass]="{'visibility-hidden': isExternalUser(loggedInUser)}" class="mdi mdi-pencil-outline" matTooltip="Edit" matTooltipPosition="right" (click)="editField('ABN')"></span>
              </div>
            }
            @if (editABN) {
              <div fxLayoutAlign="start center" class="medium-input">
                <mat-form-field>
                  <input matInput type="text" [inputMask]="createAbnInputMask" [formControl]="formControlStep1ABN">
                </mat-form-field>
                <span class="mdi mdi-check" matTooltip="Save" matTooltipPosition="right" (click)="editField('ABN')"></span>
              </div>
            }
          </div>
        </div>
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>ACN</div>
            @if (!editACN) {
              <div>
                {{formControlStep1ACN.value}}
                <span [ngClass]="{'visibility-hidden': isExternalUser(loggedInUser)}" class="mdi mdi-pencil-outline" matTooltip="Edit" matTooltipPosition="right" (click)="editField('ACN')"></span>
              </div>
            }
            @if (editACN) {
              <div fxLayoutAlign="start center" class="medium-input">
                <mat-form-field>
                  <input matInput type="text" [inputMask]="createAcnInputMask"[formControl]="formControlStep1ACN">
                </mat-form-field>
                <span class="mdi mdi-check" matTooltip="Save" matTooltipPosition="right" (click)="editField('ACN')"></span>
              </div>
            }
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Facility limit</div>
            <div  class="postfix-icon-padding">
              {{formControlStep1LoanAmount.value | looseCurrency}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Amount available</div>
            <div class="postfix-icon-padding">
              {{formControlStep1AmountFinanced.value | looseCurrency}}
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Rate</div>
            <div class="postfix-icon-padding">
              {{(formControlStep1InterestRate.value ?? 0) | number : '1.2-2' | percentage}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Documentation fee</div>
            <div class="postfix-icon-padding">
              {{(formControlStep1DocFee.value ?? 0) | looseCurrency}}
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Margin</div>
            <div class="postfix-icon-padding">
              {{(formControlStep1DisplayedMargin.value ?? 0) | number : '1.2-2' | percentage}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Monthly Facility Fee</div>
            <div class="postfix-icon-padding">
              {{formControlStep1Repayment.value | looseCurrency}}
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Term</div>
            <div class="postfix-icon-padding">
              {{formControlStep1LoanTerm.value?.name}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Repayment frequency</div>
            <div class="postfix-icon-padding">
              {{formControlStep1RepaymentFrequency.value?.name}}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Property owner</div>
            <div class="postfix-icon-padding">
              {{formControlStep1PropertyOwner.value ? "Yes" : "No"}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Adverse on file</div>
            <div class="postfix-icon-padding">
              {{formControlStep1AdverseOnFile.value ? "Yes" : "No"}}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Equifax Score above 600</div>
            <div class="postfix-icon-padding">
              {{formControlStep1EquifaxScoreAboveThreshold.value ? "Yes" : "No"}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Director Score 500 - 600</div>
            <div class="postfix-icon-padding">
              {{formControlStep1DirectorScore.value ? "Yes" : "No"}}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Payment Type</div>
            <div class="postfix-icon-padding">
              {{formControlStep1PaymentInAdvanceOrArrears.value}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Brokerage (ex. GST)</div>
            @if (!editBrokerage) {
              <div>
                {{formControlStep1BrokerageDollar.value | looseCurrency}}
                <span class="mdi mdi-pencil-outline" matTooltip="Edit" matTooltipPosition="right" (click)="editField('brokerage')"></span>
              </div>
            }
            @if (editBrokerage) {
              <div fxLayoutAlign="start center">
                <mat-form-field>
                  <input matInput type="number" [formControl]="formControlStep1BrokerageDollar">
                </mat-form-field>
                <span class="mdi mdi-check" matTooltip="Save" matTooltipPosition="right" (click)="editField('brokerage')"></span>
              </div>
            }
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Facility Establishment Fee</div>
            <div class="postfix-icon-padding">
              {{formControlStep1FacilityEstablishmentFee.value | looseCurrency}}
            </div>
          </div>
        </div>
        <!-- <div class="col-6">
        <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
          <div>Monthly Facility Fee</div>
          <div class="postfix-icon-padding">
            {{formControlStep1Repayment.value | looseCurrency}}
          </div>
        </div>
      </div>      -->
    </div>
  </form>
</div>
</ng-template>

<!-- CorporateLoans -->
<ng-template #step1_corporate_loans>
  <div class="step1">
    <form [formGroup]="formGroupStep1CorporateLoans" >
      <div class="row">
        <div class="col-12">
          <div class="mat-subtitle-1 header">Contract Details</div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Legal Name</div>
            @if (!editLegalName) {
              <div>
                {{formControlStep1LegalName.value}}
                <span [ngClass]="{'visibility-hidden': isExternalUser(loggedInUser)}" class="mdi mdi-pencil-outline" matTooltip="Edit" matTooltipPosition="right" (click)="editField('legalName')"></span>
              </div>
            }
            @if (editLegalName) {
              <div fxLayoutAlign="start center" class="long-input">
                <mat-form-field>
                  <input matInput type="text" [formControl]="formControlStep1LegalName">
                </mat-form-field>
                <span class="mdi mdi-check" matTooltip="Save" matTooltipPosition="right" (click)="editField('legalName')"></span>
              </div>
            }
          </div>
        </div>
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Address</div>
            <div >
              {{formControlStep1Address.value?.address ?? ""}}
              <span class="mdi mdi-pencil-outline" matTooltip="Edit" matTooltipPosition="right" (click)="onEditAddress()"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>ABN</div>
            @if (!editABN) {
              <div>
                {{formControlStep1ABN.value}}
                <span [ngClass]="{'visibility-hidden': isExternalUser(loggedInUser)}" class="mdi mdi-pencil-outline" matTooltip="Edit" matTooltipPosition="right" (click)="editField('ABN')"></span>
              </div>
            }
            @if (editABN) {
              <div fxLayoutAlign="start center" class="medium-input">
                <mat-form-field>
                  <input matInput type="text" [inputMask]="createAbnInputMask" [formControl]="formControlStep1ABN">
                </mat-form-field>
                <span class="mdi mdi-check" matTooltip="Save" matTooltipPosition="right" (click)="editField('ABN')"></span>
              </div>
            }
          </div>
        </div>
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>ACN</div>
            @if (!editACN) {
              <div>
                {{formControlStep1ACN.value}}
                <span [ngClass]="{'visibility-hidden': isExternalUser(loggedInUser)}" class="mdi mdi-pencil-outline" matTooltip="Edit" matTooltipPosition="right" (click)="editField('ACN')"></span>
              </div>
            }
            @if (editACN) {
              <div fxLayoutAlign="start center" class="medium-input">
                <mat-form-field>
                  <input matInput type="text" [inputMask]="createAcnInputMask"[formControl]="formControlStep1ACN">
                </mat-form-field>
                <span class="mdi mdi-check" matTooltip="Save" matTooltipPosition="right" (click)="editField('ACN')"></span>
              </div>
            }
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Facility limit</div>
            <div  class="postfix-icon-padding">
              {{formControlStep1LoanAmount.value | looseCurrency}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Amount available</div>
            <div class="postfix-icon-padding">
              {{formControlStep1AmountFinanced.value | looseCurrency}}
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Rate</div>
            <div class="postfix-icon-padding">
              {{(formControlStep1InterestRate.value ?? 0) | number : '1.2-2' | percentage}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Documentation fee</div>
            <div class="postfix-icon-padding">
              {{(formControlStep1DocFee.value ?? 0) | looseCurrency}}
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Margin</div>
            <div class="postfix-icon-padding">
              {{(formControlStep1DisplayedMargin.value ?? 0) | number : '1.2-2' | percentage}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Monthly Facility Fee</div>
            <div class="postfix-icon-padding">
              {{formControlStep1Repayment.value | looseCurrency}}
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Term</div>
            <div class="postfix-icon-padding">
              {{formControlStep1LoanTerm.value?.name}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Repayment frequency</div>
            <div class="postfix-icon-padding">
              {{formControlStep1RepaymentFrequency.value?.name}}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Property owner</div>
            <div class="postfix-icon-padding">
              {{formControlStep1PropertyOwner.value ? "Yes" : "No"}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Adverse on file</div>
            <div class="postfix-icon-padding">
              {{formControlStep1AdverseOnFile.value ? "Yes" : "No"}}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Equifax Score above 600</div>
            <div class="postfix-icon-padding">
              {{formControlStep1EquifaxScoreAboveThreshold.value ? "Yes" : "No"}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Director Score 500 - 600</div>
            <div class="postfix-icon-padding">
              {{formControlStep1DirectorScore.value ? "Yes" : "No"}}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Payment Type</div>
            <div class="postfix-icon-padding">
              {{formControlStep1PaymentInAdvanceOrArrears.value}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Brokerage (ex. GST)</div>
            @if (!editBrokerage) {
              <div>
                {{formControlStep1BrokerageDollar.value | looseCurrency}}
                <span class="mdi mdi-pencil-outline" matTooltip="Edit" matTooltipPosition="right" (click)="editField('brokerage')"></span>
              </div>
            }
            @if (editBrokerage) {
              <div fxLayoutAlign="start center">
                <mat-form-field>
                  <input matInput type="number" [formControl]="formControlStep1BrokerageDollar">
                </mat-form-field>
                <span class="mdi mdi-check" matTooltip="Save" matTooltipPosition="right" (click)="editField('brokerage')"></span>
              </div>
            }
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="contract-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Facility Establishment Fee</div>
            <div class="postfix-icon-padding">
              {{formControlStep1FacilityEstablishmentFee.value | looseCurrency}}
            </div>
          </div>
        </div>
  
    </div>
  </form>
</div>
</ng-template>

<!--
##########################################
#  STEP 2:
##########################################
-->
<ng-template #step2>
  <div class="step2">
    <form [formGroup]="formGroupStep2" >
      <div class="row">
        <div class="col-12">
          <div class="mat-subtitle-1 header">Signers</div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <table mat-table [dataSource]="dataSourceStep2" class="width-100 signer-table">
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>
                <span class="cursor-pointer mdi mdi-checkbox-multiple-blank-outline"
                (click)="toggleAllSelection()"></span>
              </th>
              <td mat-cell *matCellDef="let element">
                <mat-checkbox
                  color="primary"
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selectionStep2.toggle(element) : null"
                  [checked]="selectionStep2.isSelected(element)"
                ></mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="fullName">
              <th mat-header-cell *matHeaderCellDef>Full Name</th>
              <td mat-cell *matCellDef="let element">{{element.GivenName + " " + element.SurName}}</td>
            </ng-container>
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef>Email</th>
              <td mat-cell *matCellDef="let element">{{element.Email}}</td>
            </ng-container>
            <ng-container matColumnDef="mobile">
              <th mat-header-cell *matHeaderCellDef>Mobile</th>
              <td mat-cell *matCellDef="let element">{{element.MobileNumber}}</td>
            </ng-container>
            <ng-container matColumnDef="role">
              <th mat-header-cell *matHeaderCellDef>Role</th>
              <td mat-cell *matCellDef="let element">{{getSignersRole(element)}}</td>
            </ng-container>
            <ng-container matColumnDef="primaryContact">
              <th mat-header-cell *matHeaderCellDef style="width: 150px;">*Primary Signer</th>
              <td mat-cell *matCellDef="let element; let i = index;" style="text-align: center!important;">
                <mat-checkbox
                  (change)="onChangeCheckbox($event, i)"
                  [checked]="primaryContactCheckbox[i]"
                color="primary"></mat-checkbox>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayColumnsStep2"></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumnsStep2"></tr>
          </table>

          <span class="date-text notes">*Primary Signer will need to provide the bank details in the contract.</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="mat-subtitle-1 header">Add Recipients</div>
        </div>
      </div>
      <div class="row">
        <div class="col-8">
          <mat-form-field class="cc-email-chip-list">
            <mat-label>Send copy of the contract to</mat-label>
            <mat-chip-grid #chipList aria-label="email list">
              @for (email of ccEmailListStep2; track email) {
                <mat-chip-row (removed)="remove(email)">
                  {{email}}
                  <button matChipRemove>
                    <span class="mdi mdi-close"></span>
                  </button>
                </mat-chip-row>
              }
              <input [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur"
                [formControl]="fromControlStep2CcEmail"
                (matChipInputTokenEnd)="add($event)">
              </mat-chip-grid>
            </mat-form-field>
            @if (ccEmailListStep2Error) {
              <span class="error">
                Please enter a valid email
              </span>
            }
          </div>
        </div>
      </form>
    </div>
  </ng-template>

  <!--
  ##########################################
  #  STEP 3:
  ##########################################
  -->
  <ng-template #step3>
    <div class="step3">
      <form [formGroup]="formGroupStep3" >
        <div class="row">
          <div class="col-12">
            <div class="mat-subtitle-1">
              Contract
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <pdf-viewer [src]="contractBuffer"
              [original-size]="false"
              [zoom]="0.9"
              style="width: 1050px; height: 430px; display: block;"
            ></pdf-viewer>
          </div>
        </div>
      </form>
    </div>
  </ng-template>

  <!--
  ##########################################
  #  STEP 4:
  ##########################################
  -->
  <ng-template #step4>
    <div class="step4">
      <form [formGroup]="formGroupStep4" >
        <div class="row">
          <div class="col-12" fxLayoutGap="10px" fxLayoutAlign="center center">
            <div class="success-icon">
              <span class="mdi mdi-file-sign"></span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12" fxLayoutGap="10px" fxLayoutAlign="center center">
            <div class="mat-subtitle-1">Your contract has been sent successfully</div>
          </div>
        </div>
      </form>
    </div>
  </ng-template>
