<div class="reset-email-mobile-page container-fluid">
  @if (!(inProgressLoader.InProgressUser.inProgress$ | async)) {
    <div class="row">
      <div class="col-12"
        fxLayout="column"
        fxLayoutAlign="center center">
        <div class="row">
          <div class="col-12">
            <div class="mat-h3 text-align-center">Please scan the QR code to reset 2FA</div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <qrcode [qrdata]="verificationCode"
              [allowEmptyString]="true"
              [ariaLabel]="'QR Code image with the following content...'"
              [cssClass]="'center'"
              [colorDark]="'#000000ff'"
              [colorLight]="'#ffffffff'"
              [elementType]="'canvas'"
              [errorCorrectionLevel]="'L'"
              [margin]="4"
              [scale]="1"
              [title]="'Scan the code to get your six-digit code'"
              [width]="250">
            </qrcode>
          </div>
        </div>
        <div class="row w-lt-md-100 mb-4">
          <div class="col-12">
            <button mat-stroked-button class="width-100" (click)="onDone()">Done</button>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="row w-lt-md-100 mt-4">
          <div class="col-12">
            <!-- <button color="primary" [disabled]="isInternalUser" mat-flat-button class="width-100" (click)="disable2FA()">Disable 2FA</button>
            -->
            <div class="mat-body bottom-text icon-hover mr-1" style="font-weight: 500;">
              You can disable your 2fa after logging in
            </div>
          </div>
        </div>
      </div>
    </div>
  }

  @if ((inProgressLoader.InProgressUser.inProgress$ | async)) {
    <div class="row">
      <div class="col-12">
        <custom-content-loader type="list" ></custom-content-loader>
      </div>
    </div>
  }
</div>
