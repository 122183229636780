import { Component, Inject, OnInit } from "@angular/core";
import { FlexModule } from '@angular/flex-layout/flex';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ApiResponse, ConfirmationDialogResult, CustomerOpportunity, GeneratePayoutDialogData, GeneratePayoutDialogResult, GetPendingPaymentsFn, GetPismoCustomerForAccountFn, GetRateCardDetailsFn, PayoutNotificationDialogResult, PismoGeneratePayoutFn, PismoGetAccountResponse, PismoGetPaidFacilityFeeFn, PismoGetPendingInterestFn, PismoSendPayoutNotificationFn, Ratecard, RatecardDetails, getUnpaidFacilityFee } from '@portal-workspace/grow-shared-library';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';

import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { RadioOptionsComponent } from '../radio-options-component/radio-options.component';
import { CurrencyInputComponent } from "../currency-selection-component/currency-input.component";
import {
  ApplicationDialogService,
  Mark,
  PortalHotToastService,
  doMarkAll,
  formControlErrorKeys,
  formControlErrorMessage,
  markTriggerSubject,
  nameIncludeDigitsWithoutBlankSpaceValidator
} from "@portal-workspace/grow-ui-library";
import { Subject, Subscription } from "rxjs";
import { MatDividerModule } from '@angular/material/divider';
import moment from 'moment';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  templateUrl: './generate-payout.dialog.html',
  styleUrls: ['./generate-payout.dialog.scss'],
  standalone: true,
  imports: [
    FlexModule,
    MatButtonModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatCheckboxModule,
    MatOptionModule,
    MatSelectModule,
    MatDividerModule,
    LooseCurrencyPipe,
    RadioOptionsComponent,
    CurrencyInputComponent
]
})
export class GeneratePayoutDialog implements OnInit, Mark {

  formGroup: FormGroup<{
    adjustment: FormControl<number | null>,
    reason: FormControl<string | null>,
    autoDirectDebit: FormControl<boolean | null>,
  }>;
  formControlAdjustment!: FormControl<number | null>
  formControlReason!: FormControl<string | null>;
  formControlAutoDirectDebit!: FormControl<boolean | null>;
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  markTriggerSubject = markTriggerSubject;
  markObservable: Subject<boolean> = new Subject<boolean>();
  showCard: boolean = false;
  subscriptions: Subscription[] = [];
  pismoGetPaidFacilityFeeFn!: PismoGetPaidFacilityFeeFn;
  pismoGetPendingInterestFn!: PismoGetPendingInterestFn;
  getPismoCustomerForAccountFn!: GetPismoCustomerForAccountFn;
  pismoSendPayoutNotificationFn!: PismoSendPayoutNotificationFn;
  pismoGeneratePayoutFn!: PismoGeneratePayoutFn;
  getPendingPaymentsFn!: GetPendingPaymentsFn;
  getDefaultRatecardDetailsFn!: GetRateCardDetailsFn;

  pismoAccountDetails!: PismoGetAccountResponse;
  opportunityDetails!: CustomerOpportunity | null;

  unpaidFacilityFee: number = 0;
  outstandingBalance: number = 0;
  pendingInterest: number = 0;
  payment: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: GeneratePayoutDialogData,
    private dialogRef: MatDialogRef<GeneratePayoutDialog, GeneratePayoutDialogResult>,
    private formBuilder: FormBuilder,
    private toastService: PortalHotToastService,
    private dialogService: ApplicationDialogService,
  ) {
    this.pismoAccountDetails = data.accountAndOpportunityDetails.pismoAccountDetails;
    this.opportunityDetails = data.accountAndOpportunityDetails.opportunityDetails;
    console.log(data);
    this.pismoGetPaidFacilityFeeFn = data.pismoGetPaidFacilityFeeFn;
    this.pismoGetPendingInterestFn = data.pismoGetPendingInterestFn;
    this.getPismoCustomerForAccountFn = data.getPismoCustomerForAccountFn;
    this.pismoSendPayoutNotificationFn = data.pismoSendPayoutNotificationFn;
    this.pismoGeneratePayoutFn = data.pismoGeneratePayoutFn;
    this.getPendingPaymentsFn = data.getPendingPaymentsFn;
    this.getDefaultRatecardDetailsFn = data.getDefaultRatecardDetailsFn;

    this.formControlAdjustment = formBuilder.control(null);
    this.formControlReason = formBuilder.control(null);
    this.formControlAutoDirectDebit = formBuilder.control(true);
    this.formGroup = formBuilder.group({
      adjustment: this.formControlAdjustment,
      reason: this.formControlReason,
      autoDirectDebit: this.formControlAutoDirectDebit,
    });
  }

  mark() {
    this.formGroup.markAllAsTouched();
    this.markObservable.next(true);
  }

  ngOnInit(): void {
    const term = this.opportunityDetails?.Term ?? 0;

    this.subscriptions.push(
      this.getDefaultRatecardDetailsFn(0, 'BusinessOverdraft')
      .pipe(this.toastService.spinnerObservable()).subscribe((rateCard: RatecardDetails) => {
        const facilityFeePercentage = rateCard.FacilityFee / 100;
        this.unpaidFacilityFee = getUnpaidFacilityFee(this.pismoAccountDetails.creation_date, term, this.pismoAccountDetails.max_credit_limit, facilityFeePercentage);
        // this.totalFacilityFee = Math.round(100 * this.pismoAccountDetails.max_credit_limit * facilityFeePercentage * (term / 12)) / 100;
      })
    )

    // this.subscriptions.push(
    //   this.pismoGetPaidFacilityFeeFn(this.pismoAccountDetails.account_id)
    //     .pipe(this.toastService.spinnerObservable()).subscribe((fee: number) => {
    //     this.paidFacilityFee = fee;
    //   })
    // )

    this.subscriptions.push(
      this.pismoGetPendingInterestFn(this.pismoAccountDetails.account_id)
        .pipe(this.toastService.spinnerObservable()).subscribe((interest: number) => {
        this.pendingInterest = interest;
      })
    )

    this.subscriptions.push(
      this.getPendingPaymentsFn(this.pismoAccountDetails.account_id)
      .pipe(this.toastService.spinnerObservable()).subscribe((pendingPayments: number) => {
        this.outstandingBalance = Math.round(100 * (this.pismoAccountDetails.max_credit_limit - this.pismoAccountDetails.available_limit + pendingPayments)) / 100;
      })
    )

    this.subscriptions.push(
      this.formControlAdjustment.valueChanges.subscribe((value: number | null) => {
        if (value) {
          this.formControlReason.clearValidators();
          this.formControlReason.setValidators([Validators.required, nameIncludeDigitsWithoutBlankSpaceValidator()]);
          this.formControlReason.updateValueAndValidity();
        } else {
          this.formControlReason.clearValidators();
          this.formControlReason.updateValueAndValidity();
        }
      })
    )
  }

  get totalPayment() {
    return Math.round(100 * (this.unpaidFacilityFee + this.outstandingBalance + this.pendingInterest - (this.formControlAdjustment.value ?? 0))) / 100;
  }

  get maxAdjustment() {
    return Math.round(100 * (this.unpaidFacilityFee + this.outstandingBalance + this.pendingInterest)) / 100;
  }

  onCancel(event: Event) {
    this.dialogRef.close();
  }

  onSubmit() {
    markTriggerSubject(this.formGroup).next(true);
    doMarkAll(this.formGroup);

    if (this.totalPayment <= 0) {
      this.dialogService.openAlertDialog({
        message: "Error",
        subMessage: "Unable to generate payout as the total payment is less or equal to zero"
      }).afterClosed().subscribe()
    } else {
      this.subscriptions.push(
        this.dialogService.openYesNoDialog({
          message: "Account Closure",
          subMessage: "Do you want to close this account?"
        }).afterClosed().subscribe((result: ConfirmationDialogResult | undefined) => {
          if (result) {
            const closeAccount = !!result.readyForSubmission
            this.pismoGeneratePayoutFn({
              unpaidFacilityFee: this.unpaidFacilityFee,
              outstandingBalance: this.outstandingBalance,
              pendingInterest: this.pendingInterest,
              discount: this.formControlAdjustment.value ?? 0,
              discountReason: this.formControlReason.value ?? '',
              totalPayment: this.totalPayment,
              pismoAccountId: this.pismoAccountDetails.account_id,
              date: moment(Date.now()).format('DDMMYYYY'),
              closeAccount: closeAccount,
              autoDirectDebit: !!this.formControlAutoDirectDebit.value,
            }).pipe(
              this.toastService.spinnerObservable()
            ).subscribe((resposne: ApiResponse) => {
              if (resposne.status) {
                this.dialogService.successDialog({
                  message: "Success",
                  subMessage: "Payout have been processed successfully."
                }).afterClosed().subscribe(() => {
                  this.dialogRef.close({
                    readyForSubmission: true,
                    payment: this.totalPayment,
                    adjustment: this.formControlAdjustment.value ?? 0,
                    reason: this.formControlReason.value ?? ''
                  })
                })
              } else {
                this.dialogService.openAlertDialog({
                  message: "Error",
                  subMessage: resposne.message
                }).afterClosed().subscribe()
              }
            })
          }
        })
      )
    }
  }

  onSendNotification() {
    this.subscriptions.push(
      this.dialogService.openPayoutNotificationDialog({
        pismoAccountDetails: this.pismoAccountDetails,
        getPismoCustomerForAccountFn: this.getPismoCustomerForAccountFn
      }).afterClosed().subscribe((result: PayoutNotificationDialogResult | undefined) => {
        if (result && result.readyForSubmission) {
          console.log(result)
          this.pismoSendPayoutNotificationFn({
            email: result.email,
            ccEmail: result.ccEmail,
            unpaidFacilityFee: this.unpaidFacilityFee,
            outstandingBalance: this.outstandingBalance,
            pendingInterest: this.pendingInterest,
            discount: this.formControlAdjustment.value ?? 0,
            discountReason: this.formControlReason.value ?? '',
            totalPayment: this.totalPayment,
            pismoAccountId: this.pismoAccountDetails.account_id,
          }).pipe(this.toastService.spinnerObservable()).subscribe((result) => {
            this.dialogService.successDialog({
              message: 'Success',
              subMessage: 'Payout Estimation has been sent to the customers'
            }).afterClosed().subscribe()
          })
        }
      })
    )
  }

}
