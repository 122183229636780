import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DebitCreditSummary} from '@portal-workspace/grow-shared-library';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';
import { MatTableModule } from '@angular/material/table';

@Component({
    selector: 'dishonours-table',
    templateUrl: './dishonours-table.component.html',
    styleUrls: ['./dishonours-table.component.scss'],
    standalone: true,
    imports: [MatTableModule, NgClass, ExtendedModule, LooseCurrencyPipe]
})
export class DishonoursTableComponent implements OnInit, OnChanges {
  @Input({required: true}) data!: DebitCreditSummary;
  dataSource: DebitCreditSummary[] = [];
  columnsToDisplay: string[] = ['totaldebit', 'numdebit'];
  moreWidth: boolean = false;

  constructor() {}

  ngOnInit(): void {
    if (this.data) {
      this.dataSource = [this.data];
    }

    if (this.data?.numcredit && this.data?.numcredit > 0) {
      this.columnsToDisplay = ['totaldebit', 'numdebit', 'totalcredit', 'numcredit'];
      this.moreWidth = true;
   }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.moreWidth = false;
    this.columnsToDisplay = ['totaldebit', 'numdebit'];
    if (this.data) {
      this.dataSource = [this.data];
    }

    if (this.data?.numcredit && this.data?.numcredit > 0) {
      this.columnsToDisplay = ['totaldebit', 'numdebit', 'totalcredit', 'numcredit'];
      this.moreWidth = true;
    }
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'totaldebit': return '$ Dishonours';
      case 'totalcredit': return '$ Dishonours Reversal';
      case 'numdebit': return '# Dishonours';
      case 'numcredit': return '# Dishonours Reversal';
      default: return column;
    }
  }

  needCurrencyPipe(column: string) {
    return ['totaldebit', 'totalcredit'].includes(column);
  }
}
