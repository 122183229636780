import {Component, forwardRef, OnInit} from '@angular/core';
import { FormBuilder, NG_VALUE_ACCESSOR, Validators, FormGroup, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';
import {distinctUntilChanged, tap} from 'rxjs/operators';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import { compareMatch, FacilityEstablishmentValue, FacilityEstablishmentValueOptions } from '@portal-workspace/grow-shared-library';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';


@UntilDestroy()
@Component({
    selector: 'facility-establishment',
    templateUrl: './facility-establishment.component.html',
    styleUrls: ['./facility-establishment.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FacilityEstablishmentComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => FacilityEstablishmentComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatOptionModule]
})
export class FacilityEstablishmentComponent extends AbstractControlValueAccessor<FacilityEstablishmentValue> implements OnInit, Mark {

  options = FacilityEstablishmentValueOptions;

  formGroup: FormGroup<{
    selection: FormControl<FacilityEstablishmentValue>,
  }>;
  formControl: FormControl<FacilityEstablishmentValue>;
  subscription: Subscription;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControl = formBuilder.control(null, [Validators.required]);
    this.formGroup = formBuilder.group({
      selection: this.formControl,
    });
    this.subscription = this.formControl.valueChanges.pipe(
      distinctUntilChanged(compareMatch),
      tap((r: FacilityEstablishmentValue) => {
        this.propagateChange(r);
      })
    ).subscribe();
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
  }

  doWriteValue(v: FacilityEstablishmentValue | undefined): void | FacilityEstablishmentValue {
    if (v) {
      const op = this.options.find(o => o.type === v.type);
      if (op) {
        this.formControl.setValue(op);
        return op;
      }
    }
    // this.formControl.setValue(v ?? null);
    else {
      const toSelect = this.options.find(x => x.name == '2.00%');
      if (toSelect) {
        setTimeout(() => this.propagateChange(toSelect), 1000);
      }
    }
  }
  mark() {
    this.formGroup.markAllAsTouched();
  }
}
