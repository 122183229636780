import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { AccountSummary } from '@portal-workspace/grow-shared-library';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';
import { MatTableModule } from '@angular/material/table';

interface DecisionMetricsData {
  description: string;
  all: number;
  last180days: number;
  last90days: number;
}

@Component({
    selector: 'decision-metrics',
    templateUrl: './decision-metrics.component.html',
    styleUrls: ['./decision-metrics.component.scss'],
    standalone: true,
    imports: [MatTableModule, NgClass, ExtendedModule, LooseCurrencyPipe]
})
export class DecisionMetricsComponent implements OnInit, OnChanges {
  @Input({required: true}) data!:AccountSummary;
  columnsToDisplay = ['description', 'all', 'last180days', 'last90days'];
  dataSource: DecisionMetricsData[] = [];
  constructor() {}

  ngOnInit(): void {
    this.loadData()
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadData();
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'description': return 'Description ';
      case 'all': return 'All';
      case 'last180days': return 'Last 180 days';
      case 'last90days': return 'Last 90 days';
      default: return column;
    }
  }

  loadData(){
    this.dataSource = [];
    this.dataSource.push({description: 'Avg. $ Balance', all: this.data?.AvgBalance, last180days: this.data?.AvgBalance180, last90days: this.data?.AvgBalance90});
    this.dataSource.push({description: '$ Credits', all: this.data?.EXITAmtCredit, last180days: this.data?.EXITAmtCredit180, last90days: this.data?.EXITAmtCredit90});
    this.dataSource.push({description: 'Monthly Avg. $ Credits', all: this.data?.EXITAmtCredit / 12, last180days: this.data?.EXITAmtCredit180 / 6, last90days: this.data?.EXITAmtCredit90 / 3});
    this.dataSource.push({description: '# Credits', all: this.data?.EXITNumCredit, last180days: this.data?.EXITNumCredit180, last90days: this.data?.EXITNumCredit90});
    this.dataSource.push({description: 'Avg. $ Credits', all: this.data?.EXITNumCredit ? this.data?.EXITAmtCredit / this.data?.EXITNumCredit : 0,
      last180days: this.data?.EXITNumCredit180 ? this.data?.EXITAmtCredit180 / this.data?.EXITNumCredit180 : 0,
      last90days: this.data?.EXITNumCredit90 ? this.data?.EXITAmtCredit90 / this.data?.EXITNumCredit90 : 0});
    this.dataSource.push({description: 'Med. $ Credits', all: this.data?.MedianAmtCredit, last180days: this.data?.MedianAmtCredit180, last90days: this.data?.MedianAmtCredit90});
    this.dataSource.push({description: '$ Debits', all: this.data?.EXITAmtDebit, last180days: this.data?.EXITAmtDebit180, last90days: this.data?.EXITAmtDebit90});
    this.dataSource.push({description: '# Debits', all: this.data?.EXITNumDebit, last180days: this.data?.EXITNumDebit180, last90days: this.data?.EXITNumDebit90});
    this.dataSource.push({description: '$ Overdrawn', all: this.data?.Overdrawn.totalamt, last180days: this.data?.Overdrawn180.totalamt, last90days: this.data?.Overdrawn90.totalamt});
    this.dataSource.push({description: '# Overdrawn', all: this.data?.Overdrawn.totalcnt, last180days: this.data?.Overdrawn180.totalcnt, last90days: this.data?.Overdrawn90.totalcnt});
    this.dataSource.push({description: '$ Dishonours', all: this.data?.Dishonours.totalamt, last180days: this.data?.Dishonours180.totalamt, last90days: this.data?.Dishonours90.totalamt});
    this.dataSource.push({description: '# Dishonours', all: this.data?.Dishonours.totalcnt, last180days: this.data?.Dishonours180.totalcnt, last90days: this.data?.Dishonours90.totalcnt});
    this.dataSource.push({description: '$ Non SACC Loans Credits', all: this.data?.['Non SACC LoansCredit'].totalamt, last180days: this.data?.['Non SACC Loans180Credit'].totalamt, last90days: this.data?.['Non SACC Loans90Credit'].totalamt});
    this.dataSource.push({description: 'Monthly Avg. $ Non SACC Loans Credits', all: this.data?.['Non SACC LoansCredit'].avgmonthly, last180days: this.data?.['Non SACC Loans180Credit'].avgmonthly, last90days: this.data?.['Non SACC Loans90Credit'].avgmonthly});
    this.dataSource.push({description: '# Non SACC Loans Credits', all: this.data?.['Non SACC LoansCredit'].totalcnt, last180days: this.data?.['Non SACC Loans180Credit'].totalcnt, last90days: this.data?.['Non SACC Loans90Credit'].totalcnt});
    this.dataSource.push({description: '$ Non SACC Loans Debits', all: this.data?.['Non SACC LoansDebit'].totalamt, last180days: this.data?.['Non SACC Loans180Debit'].totalamt, last90days: this.data?.['Non SACC Loans90Debit'].totalamt});
    this.dataSource.push({description: 'Monthly Avg. $ Non SACC Loans Debits', all: this.data?.['Non SACC LoansDebit'].avgmonthly, last180days: this.data?.['Non SACC Loans180Debit'].avgmonthly, last90days: this.data?.['Non SACC Loans90Debit'].avgmonthly});
    this.dataSource.push({description: '# Non SACC Loans Debits', all: this.data?.['Non SACC LoansDebit'].totalcnt, last180days: this.data?.['Non SACC Loans180Debit'].totalcnt, last90days: this.data?.['Non SACC Loans90Debit'].totalcnt});
  }

  needCurrencyPipe(row: DecisionMetricsData) {
    return row.description.includes('$');
  }

  needAlignRight(column: string) {
    return ['all', 'last180days', 'last90days'].includes(column);
  }
}
