<div class="application-tiles-component">
  <div class="row">
    @if (enableTiles.AssetFinance != 'terminated') {
      <div class="col-md-4 mb-2 mt-4">
        <mat-card (click)="onClickEmitEvent('AssetFinance')" [ngClass]="{'disableClass': enableTiles.AssetFinance === 'disabled'}">
          <mat-card-header>
            <mat-card-title>
              <div class="row ml-1">
                <application-type-icon [applicationType]="'AssetFinance'"></application-type-icon>
                <span> Asset Finance</span>
              </div>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            Fast and simple finance for vehicles, trailers, yellow goods and other equipment.
          </mat-card-content>
          <mat-card-actions>
            <application-type-amount [applicationType]="'AssetFinance'"></application-type-amount>
          </mat-card-actions>
        </mat-card>
      </div>
    }

 
    @if (!isDealer && enableTiles.BusinessLoans != 'terminated') {
      <div class="col-md-4 mb-2 mt-4">
        <mat-card (click)="onClickEmitEvent('BusinessLoans')" [ngClass]="{'disableClass': enableTiles.BusinessLoans === 'disabled'}">
          <mat-card-header>
            <mat-card-title>
              <div class="row ml-1">
                <application-type-icon [applicationType]="'BusinessLoans'"></application-type-icon>
                <span>Business Term Loan </span>
              </div>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            Purchase stock, invest in new technology or purchase additional assets while meeting your cash
            commitments.
          </mat-card-content>
          <mat-card-actions>
            <application-type-amount [applicationType]="'BusinessLoans'"></application-type-amount>
          </mat-card-actions>
        </mat-card>
      </div>
    }
    @if (!isDealer && enableTiles.BusinessOverdraft != 'terminated') {
      <div class="col-md-4 mb-2 mt-4">
        <mat-card (click)="onClickEmitEvent('BusinessOverdraft')" [ngClass]="{'disableClass': enableTiles.BusinessOverdraft === 'disabled'}">
          <mat-card-header>
            <mat-card-title>
              <div class="row ml-1">
                <application-type-icon [applicationType]="'BusinessOverdraft'"></application-type-icon>
                <span>Business Overdraft</span>
              </div>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content class="mat-small pb-4" >
            Cover your expenses and manage your cash flow with Dynamoney's business overdraft. Interest is only charged on the amount you use.
          </mat-card-content>
          <mat-card-actions>
            <application-type-amount [applicationType]="'BusinessOverdraft'"></application-type-amount>
          </mat-card-actions>
        </mat-card>
      </div>
    }
    @if (!isDealer && enableTiles.InsurancePremium != 'terminated') {
      <div class="col-md-4 mb-2 mt-4">
        <mat-card (click)="onClickEmitEvent('InsurancePremium')" [ngClass]="{'disableClass': enableTiles.InsurancePremium === 'disabled'}">
          <mat-card-header>
            <mat-card-title>
              <div class="row ml-1">
                <application-type-icon [applicationType]="'InsurancePremium'"></application-type-icon>
                <span>Insurance Premium</span>
              </div>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            Funding to help you free up money in your business and spread lumpsum payments over time.
          </mat-card-content>
          <mat-card-actions>
            <application-type-amount [applicationType]="'InsurancePremium'"></application-type-amount>
          </mat-card-actions>
        </mat-card>
      </div>
    }
    <!--div class="col-md-4 mb-2 mt-4" *ngIf="(accessLevel ==='admin' || accessLevel ==='internalbroker' || isDealer) && enableTiles.Consumer != 'terminated'"-->
    @if ((accessLevel ==='admin' || accessLevel ==='internalbroker' ||accessLevel === 'salesBDM' || accessLevel === 'salesAM') && enableTiles.Consumer != 'terminated') {
      <div class="col-md-4 mb-2 mt-4">
        <mat-card (click)="onClickEmitEvent('Consumer')" [ngClass]="{'disableClass': enableTiles.Consumer === 'disabled'}">
          <mat-card-header>
            <mat-card-title>
              <div class="row ml-1">
                <application-type-icon [applicationType]="'Consumer'"></application-type-icon>
                <span>Consumer Asset Finance</span>
              </div>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content class="mat-small pb-4" >
            A fast, cost effective solution for consumer car, caravan, and solar finance (with associated inverters and batteries) with a quick credit turnaround
          </mat-card-content>
          <mat-card-actions>
            <application-type-amount [applicationType]="'Consumer'"></application-type-amount>
          </mat-card-actions>
        </mat-card>
      </div>
    }
    @if (enableTiles.CorporateLoans != 'terminated') {
      <div class="col-md-4 mb-2 mt-4">
        <mat-card (click)="onClickEmitEvent('CorporateLoans')" [ngClass]="{'disableClass': enableTiles.CorporateLoans === 'disabled'}">
          <mat-card-header>
            <mat-card-title>
              <div class="row ml-1 icon-hover2">
                <application-type-icon [applicationType]="'CorporateLoans'"></application-type-icon>
                <span>Corporate Loan </span>
              </div>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content class="mat-small pb-4" >
            For loans up to 1mln, secured against property with either a first mortgage or caveat up to 80% LTV and/or other corporate assets.
          </mat-card-content>
          <mat-card-actions>
            <application-type-amount [applicationType]="'CorporateLoans'"></application-type-amount>
          </mat-card-actions>
        </mat-card>
      </div>
    }
  </div>
</div>
