<div class="account-summary">
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="width-100 mb-2">
    @for (column of columnsToDisplay; track column) {
      <ng-container matColumnDef="{{column}}">
      <th mat-header-cell *matHeaderCellDef [ngClass]="{
        'column-width-3': column === 'BankName' || column === 'BSBAccNumber',
        'column-width-2': column === 'AccHolder' || column === 'AccName' || column === 'currentBalance' || column === 'TotalCredit',
        'column-width-1': column === 'LoanCreditFlag',
        'column-width-0': column === 'icon',
        'text-align-right': needAlignRight(column)
      }">
          @if (column !== 'icon') {
            <div class="cell">{{getColumnTitles(column)}}</div>
          }
        </th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'text-align-right': needAlignRight(column)}">
          @if (column === 'icon') {
            <div class="cell">
              @if (expandedElement === element) {
                <span class="mdi mdi-chevron-up"></span>
              }
              @if (expandedElement !== element) {
                <span class="mdi mdi-chevron-down"></span>
              }
            </div>
          }
          @if (column !== 'icon') {
            <div class="cell">
              @if (column !== 'LoanCreditFlag') {
                @if (needCurrencyPipe(column)) {
                  @if (column !== 'TotalCredit') {
                    {{element[column] | looseCurrency}}
                  }
                  @if (column === 'TotalCredit') {
                    {{element?.EXITAmtCredit | looseCurrency}}
                  }
                }
                @if (!needCurrencyPipe(column)) {
                  {{element[column]}}
                }
              }
              @if (column === 'LoanCreditFlag') {
                {{element[column] ? 'Yes' : 'No'}}
              }
            </div>
          }
        </td>
      </ng-container>
    }

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element; let i = dataIndex" class="expanded-row" [attr.colspan]="columnsToDisplay.length">
        <div class="example-element-detail"
          [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <div class="row mt-3">
            <div class="col">
              <div class="mat-h4-custom">Transaction Summary</div>
              <transaction-summary [data]="element"></transaction-summary>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <div class="mat-h4-custom">Decision Metrics</div>
              <decision-metrics [data]="element"></decision-metrics>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <div class="mat-h4-custom">Day End Balance Chart</div>
              <day-end-balance-chart [data]="element.dayEndBalances" [index]="i"></day-end-balance-chart>
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
      class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
</div>
