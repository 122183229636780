<div class="card card-stats bg-white">
  <div class="card-body">
    <div class="header">
      @if (details && details.title) {
        <h4 class="card-title text-uppercase text-muted mb-1"><strong>{{details.title}}</strong></h4>
      }
    </div>
    @if (!loading) {
      <div
        class="body-wrapper  {{(details && details.iconColor) !== undefined ? (details && details.iconColor) + '-shadow' : 'red-shadow'}}"
        >
        <div class="row mb-2">
          <div class="col-6">
            <div class="row">
              <div class="col-4">
                @if (details && details.iconColor) {
                  <div class="icon icon-shape {{details.iconColor !== undefined ? details.iconColor + '-lighten' : 'red-lighten'}} rounded-circle shadow">
                    @if (details && (details.iconColor || details.icon)) {
                      <i  class="mdi mdi-{{details.icon}} {{details.iconColor !== undefined ? details.iconColor : 'red'}}"></i>
                    }
                  </div>
                }
              </div>
              <div class="col-8">
                @if (details && details.col1.title) {
                  <h6 class="card-title title-color mb-0"> {{details.col1.title}} </h6>
                }
                @if (details && !lodash.isNil(details.col1.value)) {
                  <h3 class="font-weight-300 data-font mb-0"> {{details.col1.value}} </h3>
                }
              </div>
            </div>
          </div>
          @if ( details && details.percentage != undefined) {
            <div class="col-6">
              <h6 class="title-color text-nowrap mb-0"> Rolling 30 days</h6>
              @if (details && details.percentage == 0) {
                <h3 class="text-success rolling-data mr-2">
                  <i class=""></i> 0%
                </h3>
              }
              @if (details && details.percentage > 0) {
                <h3 class="text-success rolling-data mr-2">
                  <i class="mdi mdi-arrow-up"> </i> {{details.percentage?.toFixed(2) | percentage}}
                </h3>
              }
              @if (details && details.percentage < 0) {
                <h3 class="text-danger rolling-data mr-2">
                  <i class="mdi mdi-arrow-down"> </i> {{details.percentage?.toFixed(2) | percentage}}
                </h3>
              }
            </div>
          }
        </div>
        <div class="row">
          <div class="col-6">
            <div class="row">
              <div class="col-4">
                @if (details && details.iconColor) {
                  <div class="icon icon-shape {{details.iconColor !== undefined ? details.iconColor + '-lighten' : 'red-lighten'}} rounded-circle shadow">
                    @if (details && (details.iconColor || details.icon)) {
                      <i class="mdi mdi-calendar-outline {{details.icon}} {{details.iconColor !== undefined ? details.iconColor : 'red'}}"></i>
                    }
                  </div>
                }
              </div>
              <div class="col-8">
                @if (details &&  details.col2.title) {
                  <h6 class="card-title title-color mb-0"> {{details.col2.title}} </h6>
                }
                @if (details &&  !lodash.isNil(details.col2.value)) {
                  <h3 class="data-font mb-0"> {{details.col2.value}} </h3>
                }
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row">
              <div class="col">
                @if (details &&  details.col3.title) {
                  <h6 class="card-title title-color mb-0"> {{details.col3.title}} </h6>
                }
                @if (details && !lodash.isNil(details.col3.value)) {
                  <h3 class="data-font mb-0"> {{details.col3.value}} </h3>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    }
    @if (loading) {
      <div class="body-wrapper-loading {{details.iconColor !== undefined ? details.iconColor + '-shadow' : 'red-shadow'}}">
        <custom-content-loader type="list" ></custom-content-loader>
      </div>
    }
  </div>
</div>
