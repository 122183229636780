
export interface BrokerflowBankstatementEstimates {
  estimation?: {
    averageMonthlyTurnover: {
      keywordsFound: string[],
      entries: {
        date: string, // YYYY-MM-DD
        value: number,
        text: string,
      }[],
      total: number,
    },
    atoDebts: {
      keywordsFound: string[],
      entries: {
        date: string, // YYYY-MM-DD
        value: number,
        text: string
      }[],
      total: number
    },
    nonBankLenders: {
      keywordsFound: string[],
      entries: {
        date: string, // YYYY-MM-DD
        value: number,
        text: string
      }[],
      total: number
    },
    dishonourPayments: {
      keywordsFound: string[],
      entries: {
        date: string, // YYYY-MM-DD
        value: number,
        text: string
      }[],
      metricsDishonouredCount: number,
      total: number,
    },
  }
}

export type BrokerflowBankstatement =
  (BrokerflowBankstatement2019 |
    BrokerflowBankstatement2017 |
    BrokerflowBankstatement2016 |
    BrokerflowBankstatementOriginal |
    BrokerflowBankstatementDefault
  ) & BrokerflowBankstatementEstimates;

export interface xxxx {
  xxx: BrokerflowBankstatementDefault[string],
  testing: BrokerflowBankstatementOriginal['accounts'][number],
}

export interface BrokerflowBankstatementDefault {
  computedVersion: 'Default',
  [bankName: string]: BrokerflowBankstatementOriginal['accounts'][string] | 'Default' | string,
  user_token: string,
}

export interface BrokerflowBankstatementOriginal {
  computedVersion: 'Original',
  accounts: {
    [bankName: string]: {
      accounts: {
        accountHolder: string,
        name: string,
        accountNumber: string,
        id: number,
        bsb: string,
        balance: string,
        available: string,
        institution: string,
        accountType: string,
        statementData: {
          details: {
            dateObj: {
              date: string,
              typezone_type: number,
              timezone: string,
            },
            date: string,  // DD-MM-YYYY
            text: string,
            notes: string | null,
            amount: number,
            type: string,
            balance: string,
            tags: string[],
          }[],
          totalCredits: string,
          totalDebits: string,
          openingBalance: string,
          closingBalance: string,
          startDate: string,
          endDate: string,
          minBalance: string,
          maxBalance: string,
          dayEndBalances: {
            date: string,
            balance: string,
          }[],
          minDayEndBalance: string,
          maxDayEndBalance: string,
          daysInNegative: number,
          errorMessage: string,
          analysis: {
            Income: {
              total: {
                transactionCount: number,
                totalValue: number,
                monthAvg: number,
              },
              Wages: {
                transactionCount: number,
                totalValue: number,
                monthAvg: number,
                minValue: number,
                maxValue: number,
                firstTransaction: string,
                period: number,
                periodIsRegular: boolean,
                transactions: {
                  dateObj: {
                    date: string,
                    timezone_type: number,
                    timezone: string,
                  },
                  date: string,
                  text: string,
                  notes: string | null,
                  amount: number,
                  type: string,
                  balance: string,
                  tags: string[],
                }[],
              }
            },
          },
        },
      }[],
    }
  },
  user_token: string,
}


export interface BrokerflowBankstatement2016 {
  computedVersion: '2016',
  reference: string,
  submissionTime: string, // "2017-05-11T15:28:00",
  bankData: {
    bankName: string,
    bankSlug: string,
    userAddress: {
      text: string,
    },
    bankAccounts: {
      id: number,
      accountType: string,
      accountHolder: string,
      accountName: string,
      bsb: string,
      accountNumber: string,
      currentBalance: string,
      availableBalance: string,
      transactions: {
        date: string, // YYYY-MM-DD
        text: string,
        amount: number,
        type: string,
        balance: string,
        tags : ({[key: string]: string | undefined})[]
      }[],
      statementSummary: {
        openingBalance: string,
        totalCredits: string,
        totalDebits: string,
        closingBalance: string,
        minBalance: string,
        minDayEndBalance: string,
        daysInNegative: number,
        searchPeriodStart: string,
        searchPeriodEnd: string,
        transactionsStartDate: string,
        transactionSEndDate: string,
      },
      dayEndBalance: {
        date: string,
        balance: string,
      }[],
      additionalDetails: {
        interestRate: string,
        accountAdress: {
          text: string,
        },
      },
      statementAnalysis: {
        analysisCategory: {
          name: string,
          analysisPoints: {
            name: string,
            value: number,
          }[],
          transactionGroups: {
            name: string,
            analysisPoints: {
              name: string,
              value: string,
            }[],
            transactions: {
              date: string,
              text: string,
              amount: number,
              type: string,
              balance: string,
              tags: {[key: string]: string | undefined}[],
            }[],
          }[],
        }
      }[],
    }[],
  },
  user_token: string,
}

export interface BrokerflowBankstatement2017 {
  computedVersion: '2017',
  dataVersion: 20170401,
  reference: string,
  submissionTime: string, // "2017-05-11T15:28:00",
  bankData: {
    bankName: string,
    bankSlug: string,
    userAddress: {
      text: string,
      streetLine: string,
      streetNumber: string,
      streetName: string,
      streetType: string,
      suburb: string,
      stateCode: string,
      state: string,
      postcode: string,
      countryCode: string,
    },
    bankAccounts: {
      id: number,
      accountType: string,
      accountHolderType: string,
      accountHolder: string,
      accountName: string,
      bsb: string,
      accountNumber: string,
      currentBalance: string,
      availableBalance: string,
      transactions: {
        date: string, // YYYY-MM-DD
        text: string,
        amount: number,
        type: string,
        balance: string,
        tags : ({[key: string]: string | undefined})[]
      }[],
      statementSummary: {
        openingBalance: string,
        totalCredits: string,
        totalDebits: string,
        closingBalance: string,
        minBalance: string,
        minDayEndBalance: string,
        daysInNegative: number,
        searchPeriodStart: string,
        searchPeriodEnd: string,
        transactionsStartDate: string,
        transactionSEndDate: string,
      },
      dayEndBalance: {
        date: string,
        balance: string,
      }[],
      additionalDetails: {
        interestRate: string,
        accountAddress: {
          text: string,
          streetLine: string,
          streetNumber: string,
          streetName: string,
          streetType: string,
          suburb: string,
          stateCode: string,
          state: string,
          postcode: string,
          countryCode: string,
        }
      },
      statementAnalysis: {
        analysisCategory: {
          name: string,
          analysisPoints: {
            name: string,
            value: string | number,
            title: string,
            type: string, // 'date' | 'money' | 'integer' | 'string',
          }[],
          transactionGroups: {
            date: string,
            text: string,
            amount: number,
            type: string,
            balance: string,
            tags: {[key: string]: string | undefined}[],
          }[],
        }
      }[]
    }[],
  },
  decisionMetrics: {
    id: string,
    name: string,
    descriptor: string,
    type: string, // 'date' | 'money' | 'integer' | 'string',
    value: string | number,
  }[],
  user_token: string,
}

export interface BrokerflowBankstatement2019 {
  computedVersion: '2019',
  dataVersion: 20190901,
  reference: string,
  documentId: string,
  submissionTime: string, // "2017-05-11T15:28:00",
  banks: {
    bankName: string,
    bankSlug: string,
    errors: string[],
    bankAccounts: {
      id: number,
      accountType: string,
      accountHolder: string,
      accountHolderType: string,
      accountName: string,
      bsb: string,
      accountNumber: string,
      currentBalance: string,
      availableBalance: string,
      transactions: {
        date: string, // YYYY-MM-DD
        description: string,
        amount: number,
        balance: string,
        type: string,
        // tags: {[key:'pending' | 'thirdParty' | 'category' | 'creditDebit']}[]
        // tags: {[key: string]: string}[],
        // tags: ({'pending': string} | {'thirdParty': string} | {'category': string} | {'creditDebit': string})[],
        tags : ({[key: string]: string | undefined})[]
      }[],
      statementSummary: {
        openingBalance: string,
        totalCredits: string,
        totalDebits: string,
        closingBalance: string,
        minBalance: string,
        minDayEndBalance: string,
        daysInNegative: number,
        searchPeriodStart: string,
        searchPeriodEnd: string,
        transactionsStartDate: string,
        transactionsEndDate: string,
        creditsCategorised: string,
        creditsUncategorised: string,
        debitsCategorised: string,
        debitsUncategorised: string,
        internalTransferDebits: string,
        internalTransferCredits: string,
        accountMovement: string,
        dayEndBalances: {
          date: string,
          balance: string,
        }[]
      },
      statementAnalysis: {
        analysisCategory: {
          name: string,
          analysisPoints: {
            name: string,
            value: string | number,
            title: string,
            type: string, // 'date' | 'money' | 'integer' | 'string',
          }[],
          transactionGroups: {
            name: string,
            analysisPoints: {
              name: string,
              value: string | number,
              title: string,
              type: string, // 'date' | 'money' | 'integer' | 'string',
            }[],
            transactions: {
              date: string,
              description: string,
              amount: number,
              balance: string,
              type: string,
              logo: string,
              suburb: string,
              tags: {[key: string]: string | undefined}[],
            }[]
          }[],
        },
      }[],
    }[],
    address: {
      text: string,
      streetLine: string,
      levelNumber: string,
      levelType: string,
      buildingName: string,
      unitNumber: string,
      unitType: string,
      streetNumber: string,
      streetName: string,
      streetType: string,
      streetSuffix: string,
      suburb: string,
      state: string,
      stateCode: string,
      postcode: string,
      country: string,
      countryCode: string,
    },
  }[],
  decisionMetrics: {
    id: string,
    name: string,
    descriptor: string,
    type: string, // 'date' | 'money' | 'integer' | 'string',
    value: string | number,
  }[],
  user_token: string,
}

export const decisionMatricsMap: Record<string, string> = {
  'DM001': 'Wages - Monthly',
  'DM002': 'Centrelink - Monthly',
  'DM004': 'Number of Dishonours',
  'DM007': 'Gabling Expenditure - Monthly',
  'DM014': 'Collection and Consolidation - Monthly',
  'DM015': 'Credit Card Repayment - Monthly',
  'DM016': 'Other Credits - Monthly',
  'DM023': 'Groceries - Monthly',
  'DM024': 'Insurance - Monthly',
  'DM027': 'Overdraw - Monthly',
  'DM028': 'Rent - Monthly',
  'DM029': 'Subscription TV - Monthly',
  'DM032': 'Telecommunications - Monthly',
  'DM035': 'Utilities - Monthly',
  'DM040': 'Education and Childcare - Monthly',
  'DM048': 'All Loans - Monthly',
  'DM052': 'Personal Care - Monthly',
  'DM053': 'Department Stores - Monthly',
  'DM054': 'Health - Monthly',
  'DM055': 'Travel - Monthly',
  'DM056': 'Home Improvement - Monthly',
  'DM057': 'Entertainment - Monthly',
  'DM058': 'Dining Out - Monthly',
  'DM059': 'Pet Care - Monthly',
  'DM060': 'Gyms and other memberships - Monthly',
  'DM062': 'Retail - Monthly',
  'DM071': 'Uncategorised Debits - Monthly',
  'TF003': 'Vehicles and Transport - Monthly',
};


