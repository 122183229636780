import {Component, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {BusinessNumberSearchFn, AnnualRevenueValue, BusinessSearchFn,} from '@portal-workspace/grow-ui-library';
import {
  AzureStorageDocument,
  CommercialFinanceApplication,
  AbnComponentValue,
  AcnComponentValue,
  AssetCategoryTypeSelectionValue,
  BusinessSearchValue,
  CurrencyInputValue, individualGuarantors,
  isApplicationApplicantIndividual, isApplicationApplicantsSoleTrader,
  MobileValue,
  SelectContactValue,
  YesNoValue,
  OriginatorBusiness, UploadAzureFiles, PaymentFrequencyValueOptions, TermRateForCommercialFinance,
  isExternalUser,
} from '@portal-workspace/grow-shared-library';
import {combineLatest, Subscription} from 'rxjs';
import {
  applicantsThatAreGuarantor,
  applicationToAbn,
  applicationToAcn,
  applicationToApplicants,
  applicationToApplicationNotes,
  applicationToAssetCategoryType,
  applicationToAssetDescription,
  applicationToBrokerSearchValue,
  applicationToBusinessLandline,
  applicationToBusinessSearchValue,
  applicationToDepositAmount,
  applicationToGuarantors,
  applicationToIndustrySector,
  applicationToInvoiceAmount,
  applicationToLegalName,
  applicationToLoanAmount,
  applicationToLoanTerms2,
  applicationToOperatesInCommercialPremise,
  applicationToOrganisationType,
  applicationToPaymentFrequency,
  applicationToPrimaryAddress,
  applicationToPrimaryContact,
  applicationToPrimaryIndustry,
  applicationToPropertyOwner,
  applicationToReferences,
  applicationToRevenue,
  createAbnInputMask,
  createAcnInputMask,
  createCurrencyInputMask,
  createPhoneNumberInputMask,
  fromApplicantToInteflowCommercialEntities,
  fromApplicantToInteflowIndividualsData, fromContactToInteflowContacts,
  fromGuarantorToInteflowCommercialEnttities,
  fromGuarantorToInteflowIndividuals,
  fromReferenceToInteflowReferences,
  getUser,
  setCurrentStepFn,
  toInteflowEntityTypes,
  toInteflowLegalName,
  setupUntilDestroy,
} from '@portal-workspace/grow-ui-library';
import {
  isAddressEquals, patchAcn,
  filesToBase64Files,
  booleanToYesNo,
  minDepositForSolar,
  getFileTags
} from '@portal-workspace/grow-shared-library';

import {AssetCategoryType, getAssetCategory, getCommercialOnlyAssetCategoryTypes} from '@portal-workspace/grow-shared-library';
import {AggregatorSearchFn} from '@portal-workspace/grow-ui-library';
import {AggregatorSearchComponentValue } from '@portal-workspace/grow-shared-library';
import { CdkStepper, CdkStepperModule } from '@angular/cdk/stepper';
import moment from 'moment';
import {map, tap} from 'rxjs/operators';
import {Moment} from 'moment';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ApplicationDialogService} from '@portal-workspace/grow-ui-library';
import {GuarantorValue, IndividualGuarantor} from '@portal-workspace/grow-shared-library';
import {ContactValue} from '@portal-workspace/grow-shared-library';
import {guarantorValidator, requiredAllowEmptyValidator} from '@portal-workspace/grow-ui-library';
import {ReferenceValue} from '@portal-workspace/grow-shared-library';
import _ from 'lodash';
import {CommercialFinanceSummary} from '@portal-workspace/grow-shared-library';
import {LoanTermValue} from '@portal-workspace/grow-shared-library';
import {PaymentFrequencyValue} from '@portal-workspace/grow-shared-library';
import {PrimaryIndustrySelectionValue} from '@portal-workspace/grow-shared-library';
import {SecondaryIndustrySelectionValue} from '@portal-workspace/grow-shared-library';
import {
  FinanceTypeValue,
  FinanceTypeValueOptions as FinanceTypeOptions
} from '@portal-workspace/grow-shared-library';
import {
  AssetConditionValue,
  AssetConditionValueOptions as AssetConditionOptions
} from '@portal-workspace/grow-shared-library';
import {BrokerageSelectionValue, BrokerageSelectionValueOptions as BrokerageOptions} from '@portal-workspace/grow-shared-library';
import {
  ApplicationService,
} from '../../service/application.service';
import {
  Application,
  BrokerOfUser,
  GeoLocation,
  RatecardDetails,
  UpdateApplicationData
} from '@portal-workspace/grow-shared-library'
import {ActivatedRoute, Router} from '@angular/router';
import {PortalHotToastService} from '@portal-workspace/grow-ui-library';
import {ApplicationApplicant, Base64File, User} from '@portal-workspace/grow-shared-library';
import {TotalPaymentBreakupDialogData} from '@portal-workspace/grow-shared-library';
import {AppCalculator, CommercialFinanceFormData, TermRate} from '@portal-workspace/grow-shared-library';
import {BalloonPaymentValueOptions as BalloonPaymentValueOptions, BalloonPaymentValue} from '@portal-workspace/grow-shared-library';
import numeral from 'numeral';
import {AssetCategorySelectionValue} from '@portal-workspace/grow-shared-library';
import {UploadAzureFilesValue} from '@portal-workspace/grow-shared-library';
import {DriverLicenceUpload} from '../asset-finance/asset-finance.page';
import {Address2ComponentValue} from '@portal-workspace/grow-shared-library';
import {navigationUrlForApplications, navigationUrlForNewApplication} from '../../service/navigation-urls';
import {EntityTypeValue, EntityTypes} from '@portal-workspace/grow-shared-library';
import {environment} from '../../../environments/environment';
import { AdminService } from '../../service/admin.service';
import { CommercialFinanceSummaryComponent } from '@portal-workspace/grow-ui-library';
import { UploadFilesWithTagDialogComponent } from '@portal-workspace/grow-ui-library';
import { UploadFileComponent } from '@portal-workspace/grow-ui-library';
import { UploadedFilesListComponent } from '@portal-workspace/grow-ui-library';
import { MessageBoxComponent } from '@portal-workspace/grow-ui-library';
import { ReferenceComponent } from '@portal-workspace/grow-ui-library';
import { SelectContactComponent } from '@portal-workspace/grow-ui-library';
import { GuarantorComponent } from '@portal-workspace/grow-ui-library';
import { MemberComponent } from '@portal-workspace/grow-ui-library';
import { DirectorComponent } from '@portal-workspace/grow-ui-library';
import { PartnerComponent } from '@portal-workspace/grow-ui-library';
import { SoleTraderComponent } from '@portal-workspace/grow-ui-library';
import { TrusteeComponent } from '@portal-workspace/grow-ui-library';
import { MobileComponent } from '@portal-workspace/grow-ui-library';
import { CustomAddressComponent } from '@portal-workspace/grow-ui-library';
import { SecondaryIndustrySelectionComponent } from '@portal-workspace/grow-ui-library';
import { PrimaryIndustrySelectionComponent } from '@portal-workspace/grow-ui-library';
import { AnnualRevenueComponent } from '@portal-workspace/grow-ui-library';
import { AcnComponent } from '@portal-workspace/grow-ui-library';
import { AbnComponent } from '@portal-workspace/grow-ui-library';
import { EntityTypeComponent } from '@portal-workspace/grow-ui-library';
import { ApplicationHeaderSegmentComponent } from '@portal-workspace/grow-ui-library';
import { YesNoComponent } from '@portal-workspace/grow-ui-library';
import { PaymentFrequencySelectionComponent } from '@portal-workspace/grow-ui-library';
import { CommercialLoanTermsSelectionComponent } from '@portal-workspace/grow-ui-library';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AssetCategoryTypeSelectionComponent } from '@portal-workspace/grow-ui-library';
import { CurrencyInputComponent } from '@portal-workspace/grow-ui-library';
import { AggregatorSearchComponent } from '@portal-workspace/grow-ui-library';
import { MarkDirective } from '@portal-workspace/grow-ui-library';
import { BusinessSearchComponent } from '@portal-workspace/grow-ui-library';
import { NgTemplateOutlet } from '@angular/common';
import { ApplicationStepper2Component,setStepper2StepConfig } from '@portal-workspace/grow-ui-library';


// NOTE: Page to land when you are in DRAFT (new unsubmitted) commercial finance application
@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './commercial-finance.page.html',
    styleUrls: ['./commercial-finance.page.scss'],
    standalone: true,
    imports: [ApplicationStepper2Component, CdkStepperModule, NgTemplateOutlet, FormsModule, ReactiveFormsModule, BusinessSearchComponent, MarkDirective, AggregatorSearchComponent, CurrencyInputComponent, AssetCategoryTypeSelectionComponent, MatFormFieldModule, MatInputModule, CommercialLoanTermsSelectionComponent, PaymentFrequencySelectionComponent, YesNoComponent, ApplicationHeaderSegmentComponent, EntityTypeComponent, AbnComponent, AcnComponent, AnnualRevenueComponent, PrimaryIndustrySelectionComponent, SecondaryIndustrySelectionComponent, CustomAddressComponent, MobileComponent, TrusteeComponent, SoleTraderComponent, PartnerComponent, DirectorComponent, MemberComponent, GuarantorComponent, SelectContactComponent, ReferenceComponent, MessageBoxComponent, UploadedFilesListComponent, UploadFileComponent, UploadFilesWithTagDialogComponent, CommercialFinanceSummaryComponent]
})
export class CommercialFinancePage implements OnInit {

  existingApplication: CommercialFinanceApplication | null = null; // editing an existing application
  existingApplicationBureauReport: CommercialFinanceApplication['CompanyDetails'] | null = null;
  rateCard: RatecardDetails | null = null;


  // global:
  ip: string = '';
  geoLocation: GeoLocation = {};
  // user!: User;
  user: User = getUser()!;
  isExternalUser : boolean = isExternalUser(this.user);
  brokerOfUser: BrokerOfUser | null = null;
  acceptedQuotation = false;
  brokerApplicationId: string | null = null;
  applicationId: number | null = null;

  // global: step 3 onwards
  organisationName: string = 'Not available';
  terms: TermRateForCommercialFinance | null = null;          // available after chart popups
  calculator: AppCalculator | null = null;   // available after chart popups

  // after popup confirmation
  businessUseOnly = false;
  selfServiceability = false;
  privacyConfirmation = false;

  subscriptions: Subscription[] = [];


  getFileTags = getFileTags;

  // STEP 1:
  step1BusinessSearchFn!: BusinessSearchFn;
  formControlStep1Business!: FormControl<BusinessSearchValue>;
  formGroupStep1!: FormGroup<{
    business: FormControl<BusinessSearchValue>
  }>;

  // STEP 2:
  step2MonthlyRepayment = 0;
  step2EstimatedDrawdownDate: Moment = moment();
  step2AssetCategory: Exclude<AssetCategorySelectionValue, null> = getAssetCategory('106')!;
  step2FinanceType: Exclude<FinanceTypeValue, null> = FinanceTypeOptions.find(o => o.type === 'chattel-mortgage')!;
  step2AssetCondition: Exclude<AssetConditionValue, null> = AssetConditionOptions.find(o => o.type === 'New')!;
  step2BalloonPaymentPercent: Exclude<BalloonPaymentValue, null> = BalloonPaymentValueOptions.find(o => o.type  === '0')!;
  step2Brokerage: Exclude<BrokerageSelectionValue, null> = BrokerageOptions.find(o => o.type === '0')!;
  step2DocFeeFinanced = false;
  step2PrivateSales = false;
  step2EquifaxScore = true;
  step2AdverseOnFile = false;
  step2BrokerOrigninationFee = 0;
  step2DepositMin: number | null = null;
  step2DepositMax: number | null = null;

  step2AssetTypes!: AssetCategoryType[];
  step2BrokerSearchFn!: AggregatorSearchFn;
  formControlStep2Broker!: FormControl<AggregatorSearchComponentValue>;
  formControlStep2InvoiceAmount!: FormControl<CurrencyInputValue>;
  formControlStep2DepositAmount!: FormControl<CurrencyInputValue>;
  formControlStep2LoanAmount!: FormControl<CurrencyInputValue>;
  formControlStep2AssetType!: FormControl<AssetCategoryTypeSelectionValue>;
  formControlStep2AssetDescription!: FormControl<string | null>;
  formControlStep2LoanTerms!: FormControl<LoanTermValue>;
  formControlStep2PaymentFrequency!: FormControl<PaymentFrequencyValue>;
  formControlStep2PropertyOwner!: FormControl<YesNoValue>;
  formGroupStep2!: FormGroup<{
    broker: FormControl<AggregatorSearchComponentValue>,
    invoiceAmount: FormControl<CurrencyInputValue>,
    depositAmount: FormControl<CurrencyInputValue>,
    loanAmount: FormControl<CurrencyInputValue>,
    assetType: FormControl<AssetCategoryTypeSelectionValue>,
    loanTerms: FormControl<LoanTermValue>,
    paymentFrequency: FormControl<PaymentFrequencyValue>,
    propertyOwner: FormControl<YesNoValue>,
  }>

  // STEP 3:
  step3OnwardsOrganisationType: EntityTypes | null = null;
  formControlStep3OrganisationType!: FormControl<EntityTypeValue>;
  formControlStep3Abn!: FormControl<AbnComponentValue>;
  formControlStep3Acn!: FormControl<AcnComponentValue>;
  formControlStep3AnnualRevenue!: FormControl<AnnualRevenueValue>;
  formControlStep3OperatesInCommercialPremises!: FormControl<YesNoValue>;
  formControlStep3PrimaryIndustry!: FormControl<PrimaryIndustrySelectionValue>;
  formControlStep3IndustrySector!: FormControl<SecondaryIndustrySelectionValue>;
  formControlStep3PrimaryBusinessAddress!: FormControl<Address2ComponentValue>;
  formControlStep3BusinessLandline!: FormControl<MobileValue>;
  formGroupStep3!: FormGroup<{
     organisationType: FormControl<EntityTypeValue>,
     abn: FormControl<AbnComponentValue>,
     acn: FormControl<AcnComponentValue>,
     annualRevenue:  FormControl<AnnualRevenueValue>,
     operatesInCommercialPremises: FormControl<YesNoValue>,
     primaryIndustry: FormControl<PrimaryIndustrySelectionValue>,
     industrySector: FormControl<SecondaryIndustrySelectionValue>,
     primaryBusinessAddress: FormControl<Address2ComponentValue>,
     businessLandline: FormControl<MobileValue>,
  }>;

  // STEP 4:
  formControlStep4Applicants!: FormControl<ApplicationApplicant>;
  step4BusinessSearchFn!: BusinessSearchFn;
  step4BusinessNumberSearchFn!: BusinessNumberSearchFn;
  formGroupStep4!: FormGroup<{
    applicants: FormControl<ApplicationApplicant>
  }>;

  // STEP 5:
  step5CompanySearchFn!: BusinessSearchFn;
  step5BusinessNumberSearchFn!: BusinessNumberSearchFn;
  step5MandatoryGuarantors: IndividualGuarantor[] = [];
  formControlStep5Guarantors!: FormControl<GuarantorValue>;
  formGroupStep5!: FormGroup<{
    guarantors: FormControl<GuarantorValue>
  }>;

  // STEP 6:
  step6PredefinedContactSelection!: ContactValue[];
  formControlStep6PrimaryContact!: FormControl<SelectContactValue>;
  step6PossiblePrimaryContacts: ContactValue[] = [];
  formGroupStep6!: FormGroup<{
    primaryContact: FormControl<SelectContactValue>,
  }>;

  // STEP 7:
  formControlStep7References!: FormControl<ReferenceValue>;
  formGroupStep7!: FormGroup<{
    references: FormControl<ReferenceValue>
  }>;

  // STEP 8:
  formControlStep8OtherSupportingDocuments!: FormControl<UploadAzureFilesValue>;
  formControlStep8ApplicationNotes!: FormControl<string | null>;
  step8DriverLicenses: DriverLicenceUpload[] = [];
  step8UploadedDocs: AzureStorageDocument[] = [];
  step8DeletedUploadedDocs: Exclude<AzureStorageDocument[], null> = [];
  formGroupStep8!: FormGroup<{}>;

  // STEP 9:
  step9Summary!: CommercialFinanceSummary;
  formGroupStep9!: FormGroup<{}>;

  showAddressFormFlag = true;


  constructor(private formBuilder: FormBuilder,
              private toastService: PortalHotToastService,
              private route: ActivatedRoute,
              private applicationService: ApplicationService,
              private router: Router,
              private adminService: AdminService,
              private applicationDialogService: ApplicationDialogService) {
  }

  async ngOnInit() {
    setupUntilDestroy(this);
    this.existingApplication = (this.route.snapshot.data as any).application;
    this.rateCard = (this.route.snapshot.data as any).ratecard;
    console.log('Resolved [CommercialFinance] application', this.existingApplication);
    if (this.existingApplication) {
      if (this.existingApplication.ApplicationType != 'Commercial') {
        this.toastService.error(`This application ${this.existingApplication.ApplicationId} is not an Commercial Finance application`, `Invalid application type`);
      }
    }

    // get user
    // this.user = getUser()!;

    this.initStep1();
    this.initStep2();
    this.initStep3();
    this.initStep4();
    this.initStep5();
    this.initStep6();
    this.initStep7();
    this.initStep8();
    this.initStep9();

    this.populate();

    // get ip
    const sub1 = this.applicationService.getIpAddress().pipe(
      map(r => {
        if (r.status) {
          this.ip = r.payload;
          console.log('**** ip', this.ip);
        }
      })
    ).subscribe();
    this.subscriptions.push(sub1);

    // get geolocation
    try {
      const geoLocation = await this.applicationService.getGeoLocation();
      if (geoLocation) {
        this.geoLocation = geoLocation;
      }
    } catch(err) {
      console.log(err);
    }

    // get broker of user
    const sub2 = this.applicationService.getBrokerOfUser(this.user.UserId).pipe(
      tap(r => {
        if (r.status) {
          this.brokerOfUser = r.payload;
        }
      })
    ).subscribe();
    this.subscriptions.push(sub2);

  }

  step2UpdateDepositValidation() {
      const invoiceAmount = this.formControlStep2InvoiceAmount.value;
      const isPropetyOwner = this.formControlStep2PropertyOwner.value;

      if(invoiceAmount){
        const minDeposit = minDepositForSolar(invoiceAmount, isPropetyOwner ?? false);
        this.step2DepositMin = minDeposit;
        this.step2DepositMax = _.round(invoiceAmount * 0.8, 2); // 80% of invoiceAmount
        // note: as long as it is within min & max range allows it, this way we will still keep the saved value from populate()
        const existingDeposit = this.formControlStep2DepositAmount.value ?? 0;
        if (this.step2DepositMax && existingDeposit > this.step2DepositMax) {
          this.formControlStep2DepositAmount.setValue(this.step2DepositMax);
        } else if (this.step2DepositMin && existingDeposit < this.step2DepositMin) {
          this.formControlStep2DepositAmount.setValue(this.step2DepositMin);
        }
        // if (this.existingApplication) {
        //   this.formControlStep2DepositAmount.setValue(applicationToDepositAmount(this.existingApplication));
        // }
    }
  }

  populate() {
    if (this.existingApplication) {
      // 1
      this.formControlStep1Business.setValue(applicationToBusinessSearchValue(this.existingApplication));
      this.brokerApplicationId = this.existingApplication.BrokerAppId;
      this.applicationId = this.existingApplication.ApplicationId;
      this.acceptedQuotation = this.existingApplication.AcceptQuotation;
      this.organisationName = applicationToLegalName(this.existingApplication) ?? '';

      // 2
      this.formControlStep2Broker.setValue(applicationToBrokerSearchValue(this.existingApplication));
      this.formControlStep2InvoiceAmount.setValue(applicationToInvoiceAmount(this.existingApplication));
      this.formControlStep2DepositAmount.setValue(applicationToDepositAmount(this.existingApplication));
      this.formControlStep2LoanAmount.setValue(applicationToLoanAmount(this.existingApplication));
      this.formControlStep2AssetType.setValue(applicationToAssetCategoryType(this.existingApplication));
      this.formControlStep2AssetDescription.setValue(applicationToAssetDescription(this.existingApplication));
      this.formControlStep2LoanTerms.setValue(applicationToLoanTerms2(this.existingApplication));
      // this.formControlStep2PaymentFrequency.setValue(applicationToPaymentFrequency(this.existingApplication));
      this.formControlStep2PaymentFrequency.setValue(applicationToPaymentFrequency(this.existingApplication) ??
      PaymentFrequencyValueOptions.find(o => o.type === 'Monthly') ?? null);
      this.formControlStep2PropertyOwner.setValue(applicationToPropertyOwner(this.existingApplication));

      // 3
      this.formControlStep3OrganisationType.setValue(applicationToOrganisationType(this.existingApplication));
      this.formControlStep3Abn.setValue(applicationToAbn(this.existingApplication));
      this.formControlStep3Acn.setValue(applicationToAcn(this.existingApplication));
      this.formControlStep3AnnualRevenue.setValue(applicationToRevenue(this.existingApplication));
      this.formControlStep3OperatesInCommercialPremises.setValue(applicationToOperatesInCommercialPremise(this.existingApplication));
      this.formControlStep3PrimaryIndustry.setValue(applicationToPrimaryIndustry(this.existingApplication));
      this.formControlStep3IndustrySector.setValue(applicationToIndustrySector(this.existingApplication))
      this.formControlStep3PrimaryBusinessAddress.setValue(applicationToPrimaryAddress(this.existingApplication))
      this.formControlStep3BusinessLandline.setValue(applicationToBusinessLandline(this.existingApplication))

      // 4
      const v = applicationToApplicants(this.existingApplication);
      this.formControlStep4Applicants.setValue(applicationToApplicants(this.existingApplication));
      if (this.formControlStep4Applicants.value !== null) {
        let propertyOwner: any
        let residentialAddress: any
        // if (this.formControlStep4Applicants.value.kind === 'SoleTrader') {
        if (isApplicationApplicantsSoleTrader(this.formControlStep4Applicants.value)) {
          propertyOwner = this.formControlStep4Applicants.value.propertyOwner;
          residentialAddress = this.formControlStep4Applicants.value.residentialAddress;
        }
        else {
          const applicant = this.formControlStep4Applicants.value;
          for (let i = 0; i <= applicant.length - 1; i++) {
            const currentApplicant = applicant[i];
            if (isApplicationApplicantIndividual(currentApplicant)) {
              propertyOwner = currentApplicant.propertyOwner;
              residentialAddress = currentApplicant.residentialAddress;
            }
          }
        }
        if (propertyOwner) {
          if (propertyOwner.propertyOwner) {
            if (isAddressEquals(residentialAddress, propertyOwner.address)) {
              this.showAddressFormFlag = false;
            }
          }
        }
      }

      // 5
      this.formControlStep5Guarantors.setValue(applicationToGuarantors(this.existingApplication));

      // 6
      this.formControlStep6PrimaryContact.setValue(applicationToPrimaryContact(this.existingApplication));

      // 7
      this.formControlStep7References.setValue(applicationToReferences(this.existingApplication));

      // 8
      this.formControlStep8ApplicationNotes.setValue(applicationToApplicationNotes(this.existingApplication));

      // get existing documents from azure storage
      this.subscriptions.push(this.applicationService.listApplicationDocumentFn(this.existingApplication.ApplicationId).pipe(
          tap(r => {
            if (r && r.length) {
              this.step8UploadedDocs = r;
            }
          })
        ).subscribe()
      );
      // const documents = this.existingApplication.Documents;
      // if (documents && documents.length) {
      //   this.step8UploadedDocs = [...documents];
      // }
    } else {  // not prepopulating ie. creating new application

      // auto populate originator business if we have one
      const orignatorBusinessId = this.user?.OriginatorBusinessId;
      // const aggregatorId = this.user?.AggregatorId;
      if (orignatorBusinessId) {
        this.subscriptions.push(this.applicationService.getOriginatorBusinessById(orignatorBusinessId).pipe(
          tap(r => {
            if (r.payload) {
              const originatorBusiness: OriginatorBusiness = r.payload;
              const v: AggregatorSearchComponentValue = {
                abn: originatorBusiness.ABN,
                entityName: originatorBusiness.EntityName,
                salesforceId: originatorBusiness.SalesforceId,
              }
              this.formControlStep2Broker.setValue(v);
            }
          })
        ).subscribe());
      // } else if (aggregatorId) {
      //   this.subscriptions.push(this.adminService.getAggregatorById(aggregatorId).pipe(
      //     tap(r  => {
      //       if (r.payload) {
      //         const aggregator: Aggregator = r.payload;
      //         const v: AggregatorSearchComponentValue = {
      //           abn: aggregator.ABN,
      //           entityName: aggregator.EntityName,
      //           salesforceId: aggregator.SalesforceId,
      //         }
      //         this.formControlStep2Broker.setValue(v);
      //       }
      //     })
      //   ).subscribe());
      }
    }
  }

  initStep1() {
    this.step1BusinessSearchFn = this.applicationService.businessSearchFn;
    this.formControlStep1Business = this.formBuilder.control(null, [Validators.required]);
    this.formGroupStep1 = this.formBuilder.group({
      business: this.formControlStep1Business,
    });

    this.subscriptions.push(this.formControlStep1Business.valueChanges.pipe(
      tap((r: BusinessSearchValue) => {
        if (r && r.type === 'search-result') { // NOTE: we can deduce only when business is an ASIC/ABN search
          if (r.entityTypeValue) {
            this.formControlStep3OrganisationType.setValue(r.entityTypeValue);
          }
        }
      })
    ).subscribe());

    const sub = this.formControlStep1Business.valueChanges.pipe(
      tap((r: BusinessSearchValue) => {
        if (r) {
          if (r) {
            if (r.abn && this.formControlStep3Abn) {
              this.formControlStep3Abn.setValue(r.abn);
            }
            if (r.acn && this.formControlStep3Acn) {
              const acn = patchAcn(r.acn);
              this.formControlStep3Acn.setValue(acn ?? null);
            }
            this.organisationName = toInteflowLegalName(r) ?? '';
          }
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);

    setStepper2StepConfig(this.formGroupStep1, {
      nextStepClickedFn: (stepper) => {
        stepper.next();
      },
      previousStepClickedFn: async (stepper) => {
        if(this.applicationId){
          await this.router.navigate(navigationUrlForApplications({ reload: true }))
        }else {
          await this.router.navigate(navigationUrlForNewApplication())
        }
      }
    });
  }

  initStep2() {
    this.step2AssetTypes = getCommercialOnlyAssetCategoryTypes();
    this.step2BrokerSearchFn = (term) => {
      return this.applicationService.searchBroker2(term).pipe(map(r => r.payload));
    }
    this.formControlStep2Broker = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2InvoiceAmount = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2DepositAmount = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2LoanAmount = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2AssetType = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2AssetDescription = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2LoanTerms = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2PaymentFrequency = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2PropertyOwner = this.formBuilder.control(false, [Validators.required]);
    this.formGroupStep2 = this.formBuilder.group({
      broker: this.formControlStep2Broker,
      invoiceAmount: this.formControlStep2InvoiceAmount,
      depositAmount: this.formControlStep2DepositAmount,
      loanAmount: this.formControlStep2LoanAmount,
      assetType: this.formControlStep2AssetType,
      loanTerms: this.formControlStep2LoanTerms,
      paymentFrequency: this.formControlStep2PaymentFrequency,
      propertyOwner: this.formControlStep2PropertyOwner,
    });
    const subscription = combineLatest([
      this.formControlStep2InvoiceAmount.valueChanges,
      this.formControlStep2DepositAmount.valueChanges,
    ]).pipe(
      tap(r => {
        const invoiceAmount = r[0];
        const depositAmount = r[1];
        if(invoiceAmount && depositAmount){
          const loanAmount = Number((invoiceAmount - depositAmount > 0) ? (invoiceAmount - depositAmount).toFixed(2) : 0);
          this.formControlStep2LoanAmount.setValue(loanAmount);
        }
      })
    ).subscribe();
    this.subscriptions.push(subscription);

    // deposit calculation trigger
    this.subscriptions.push(this.formControlStep2InvoiceAmount.valueChanges.pipe(
      tap(r => {
        this.step2UpdateDepositValidation();
      })
    ).subscribe());
    this.subscriptions.push(this.formControlStep2PropertyOwner.valueChanges.pipe(
      tap(r => {
        this.step2UpdateDepositValidation();
      })
    ).subscribe());
  }

  initStep3() {
    this.formControlStep3OrganisationType = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3Abn = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3Acn = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3AnnualRevenue = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3OperatesInCommercialPremises = this.formBuilder.control(false, [Validators.required]);
    this.formControlStep3PrimaryIndustry = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3IndustrySector = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3PrimaryBusinessAddress = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3BusinessLandline = this.formBuilder.control(null);
    this.formGroupStep3 = this.formBuilder.group({
      organisationType: this.formControlStep3OrganisationType,
      abn: this.formControlStep3Abn,
      acn: this.formControlStep3Acn,
      annualRevenue: this.formControlStep3AnnualRevenue,
      operatesInCommercialPremises: this.formControlStep3OperatesInCommercialPremises,
      primaryIndustry: this.formControlStep3PrimaryIndustry,
      industrySector: this.formControlStep3IndustrySector,
      primaryBusinessAddress: this.formControlStep3PrimaryBusinessAddress,
      businessLandline: this.formControlStep3BusinessLandline,
    });
    const sub = this.formControlStep3OrganisationType.valueChanges.pipe(
      tap((r: EntityTypeValue) => {
        if (r) {
          if(this.step3OnwardsOrganisationType && this.step3OnwardsOrganisationType !== r.type) {
            // the org type changes and is different from prev value
            this.formControlStep4Applicants.setValue(null)
          }
          this.step3OnwardsOrganisationType = r.type;
          if (r.type === 'company') {
            /* Not required to set it again as validation is already set */
            // this.formControlStep3Acn.setValidators([Validators.required]);
          } else {
            this.formControlStep3Acn.setValidators(null);
            this.formControlStep3Acn.updateValueAndValidity();
          }
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);

    setStepper2StepConfig(this.formGroupStep3, {
      nextStepClickedFn: async stepper => {
         this.applicationDialogService.openCreditInfoExchangeAlertDialog().afterClosed().pipe(
            tap(async r => {
              if (r && r.proceed) {
                await this.saveApplication(stepper);
                // await this.updateDraftInBg();
                // stepper.next();
              }
            })
          ).subscribe()
      },

    });
  }

  initStep4() {
    this.step4BusinessSearchFn = this.applicationService.businessSearchFn;
    this.step4BusinessNumberSearchFn = this.applicationService.businessNumberSearchFn;
    this.formControlStep4Applicants = this.formBuilder.control(null, [Validators.required]);
    this.formGroupStep4 = this.formBuilder.group({
      applicants: this.formControlStep4Applicants
    });

    setStepper2StepConfig(this.formGroupStep4, {
      nextStepClickedFn: async stepper => {
        await this.updateDraftInBg();
            stepper.next();
      },
    });
  }

  initStep5() {
    this.step5CompanySearchFn = this.applicationService.businessSearchFn;
    this.step5BusinessNumberSearchFn = this.applicationService.businessNumberSearchFn;
    this.formControlStep5Guarantors = this.formBuilder.control(null, [requiredAllowEmptyValidator]);
    this.formGroupStep5 = this.formBuilder.group({
      guarantors: this.formControlStep5Guarantors,
    });
    this.formGroupStep5.setValidators([
      guarantorValidator(()=>({
        mandatoryGuarantors: this.step5MandatoryGuarantors,
        guarantors: this.formControlStep5Guarantors.value,
      }))
    ]);
    setCurrentStepFn(this.formGroupStep5, (e) => {
      const guarantors = this.step5OnwardsApplicantsThatAreGuarantor();
      this.step5MandatoryGuarantors = guarantors;
    });

    setStepper2StepConfig(this.formGroupStep5, {
      nextStepClickedFn: async stepper => {
        await this.updateDraftInBg();
        stepper.next();
      },
    });
  }
  initStep6() {
    this.step6PredefinedContactSelection = [];
    this.formControlStep6PrimaryContact = this.formBuilder.control(null, [Validators.required]);
    this.formGroupStep6 = this.formBuilder.group({
      primaryContact: this.formControlStep6PrimaryContact,
    });

    setStepper2StepConfig(this.formGroupStep6, {
      nextStepClickedFn: async stepper => {
        await this.updateDraftInBg();
        stepper.next();
      },
      stepSelectionEventFn: e => {
        const mandatoryGuarantors: IndividualGuarantor[] = this.step5MandatoryGuarantors;
        const guarantors: GuarantorValue = this.formControlStep5Guarantors.value;
        const possiblePrimaryContacts: ContactValue[] = [];

        // all guarantors can be primary contact
        for (const g of mandatoryGuarantors) {
          const c: ContactValue = {
            isManual: false,
            email: g.email,
            mobileNumber: g.mobileNumber,
            areaCode: '',
            lastName: g.lastName,
            firstName: g.firstName,
            title: g.title,
            telephone: g.mobileNumber,
          };
          possiblePrimaryContacts.push(c);
        }
        if (guarantors) {
          for (const g of guarantors) {
            if (g.type === 'Individual') {
              const c: ContactValue = {
                isManual: false,
                email: g.email,
                mobileNumber: g.mobileNumber,
                areaCode: '',
                lastName: g.lastName,
                firstName: g.firstName,
                title: g.title,
                telephone: g.mobileNumber,
              };
              possiblePrimaryContacts.push(c);
            }
          }
        }
        this.step6PossiblePrimaryContacts = possiblePrimaryContacts;
      }
    });


  }

  initStep7() {
    this.step1BusinessSearchFn = this.applicationService.businessSearchFn;
    this.formControlStep7References = this.formBuilder.control(null, [requiredAllowEmptyValidator]);
    this.formGroupStep7 = this.formBuilder.group({
      references: this.formControlStep7References,
    });

    setStepper2StepConfig(this.formGroupStep7, {
      nextStepClickedFn: async stepper => {
        await this.updateDraftInBg();
        stepper.next();
      },
    });



  }

  initStep8() {
    this.formControlStep8OtherSupportingDocuments = this.formBuilder.control(null);
    this.formControlStep8ApplicationNotes = this.formBuilder.control(null);
    this.formGroupStep8 = this.formBuilder.group({});


    setStepper2StepConfig(this.formGroupStep8, {
      nextStepClickedFn: async stepper => {
        await this.updateDraftInBg();
            stepper.next();
      },
      nextStepButtonText: 'Next to Review',
      stepSelectionEventFn: e => {
        const applicantsThatAreGuarantor: IndividualGuarantor[] = this.step5OnwardsApplicantsThatAreGuarantor();
          const guarantors: IndividualGuarantor[] = individualGuarantors(this.formControlStep5Guarantors.value);
          const allThatRequireDriverLicences = [...applicantsThatAreGuarantor, ...guarantors];

          const driverLicences: {id: string, name: string, formControl: FormControl<UploadAzureFilesValue>}[]  = [];
          for (const requireDriverLicence of allThatRequireDriverLicences) {
            const id = `${_.lowerCase(requireDriverLicence.firstName)}${_.lowerCase(requireDriverLicence.middleName)}${_.lowerCase(requireDriverLicence.lastName)}`;
            const existingEntry = this.step8DriverLicenses.find(e => e.id === id);
            if (existingEntry) {
              driverLicences.push(existingEntry);
            } else {
              const name = `${requireDriverLicence.firstName} ${requireDriverLicence.lastName}`;
              const skipValidation = (this.step8UploadedDocs && this.step8UploadedDocs.length);
              const formControl = this.formBuilder.control(null, skipValidation ? [] : [Validators.required]);
              driverLicences.push({id, name, formControl})
              this.formGroupStep8.addControl(name, formControl);
            }
          }
          this.step8DriverLicenses = driverLicences;
      }
    });
  }

  initStep9() {
    this.formGroupStep9 = this.formBuilder.group({});




    setStepper2StepConfig(this.formGroupStep9, {
      nextStepClickedFn: async stepper => {
        this.finalSubmission(stepper, this.step9Summary!);
      },
      nextStepButtonText: 'Submit',
      stepSelectionEventFn: e => {
          this.step9Summary = {
            monthlyRepayment: String(this.step2MonthlyRepayment),
            estimatedDrawDownDate: this.step2EstimatedDrawdownDate,
            applicationId: this.brokerApplicationId ?? '',

            organisation: this.formControlStep1Business.value,
            broker: this.formControlStep2Broker.value,

            invoiceAmount: String(this.formControlStep2InvoiceAmount.value),
            depositAmount: String(this.formControlStep2DepositAmount.value),
            loanAmount: String(this.formControlStep2LoanAmount.value),
            loanTerms: this.formControlStep2LoanTerms.value,
            paymentFrequency: this.formControlStep2PaymentFrequency.value,
            propertyOwner: this.formControlStep2PropertyOwner.value,

            assetCategoryType: this.formControlStep2AssetType.value,
            organisationType: this.formControlStep3OrganisationType.value,
            abn: this.formControlStep3Abn.value,
            acn: this.formControlStep3Acn.value,
            annualRevenue: String(this.formControlStep3AnnualRevenue.value),
            operatesInCommercialPremises: this.formControlStep3OperatesInCommercialPremises.value,
            primaryIndustry: this.formControlStep3PrimaryIndustry.value,
            industrySector: this.formControlStep3IndustrySector.value,
            address: this.formControlStep3PrimaryBusinessAddress.value,
            businessLandline: this.formControlStep3BusinessLandline.value,

            applicants: this.formControlStep4Applicants.value ?? [],

            guarantors: this.formControlStep5Guarantors.value,

            primaryContact: this.formControlStep6PrimaryContact.value,

            references: this.formControlStep7References.value,

            applicationNotes: this.formControlStep8ApplicationNotes.value ?? '',
          }
      }
    });
  }

  finalSubmission(stepper: CdkStepper, summary: CommercialFinanceSummary) {
    const sub = this.applicationDialogService.openCommercialFinanceApplicationConfirmationDialog()
      .afterClosed().pipe(
      tap(async r => {
        if (r && r.readyForSubmission) {
          this.businessUseOnly = true;
          this.selfServiceability = true;
          this.privacyConfirmation = true;
          await this.sendFinalSubmission(stepper);
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);
  }

  async sendFinalSubmission(stepper: CdkStepper) {
    if (this.applicationId) {
      const data = await this.mapToInteflowData(true);
      const sub = this.applicationService.submitApplication(this.applicationId, data).pipe(
        // this.toastService.toastObserver('submit application'),
        this.toastService.spinnerObservable(),
        tap(r => {
          if (r && r.status) {
            const sub = this.applicationDialogService.openApplicationSubmittedDialog().afterClosed().pipe(
              tap(async r => {
                // stepper.reset();
                // stepper.next();
                await this.router.navigate(navigationUrlForApplications({reload: true }), {queryParams: {reload:true}});
              })
            ).subscribe();
            this.subscriptions.push(sub);
          } else {
            this.applicationDialogService.openAlertDialog({
              message: `Error`,
              subMessage: r.message
            });
          }
        })
      ).subscribe();
    }
  }

  private step5OnwardsApplicantsThatAreGuarantor(): IndividualGuarantor[] {
    const applicant  = this.formControlStep4Applicants.value;
    const guarantors = applicantsThatAreGuarantor(applicant);
    return guarantors;
  }

  // NOTE: not used
  // private async step2ShowQuotation(stepper: CdkStepper) {
  //   this.step2EstimatedDrawdownDate = moment().add(1, 'day');
  //   const subscription = this.applicationDialogService.openAssetQuotationDialog({
  //     loadFn: (date) => {
  //       return this.calculateEstimation(date);
  //     }
  //   }).afterClosed()
  //     .pipe(
  //       tap(r => {
  //         if (r && r.acceptedQuotation) {
  //           this.acceptedQuotation = true;
  //           this.saveApplication(stepper);
  //         }
  //       })
  //     ).subscribe();
  //   this.subscriptions.push(subscription);
  // }


  calculateEstimation(date: Moment): TotalPaymentBreakupDialogData {

    const paymentFrequencyValue: PaymentFrequencyValue = this.formControlStep2PaymentFrequency.value;
    const assetConditionValue = this.step2AssetCondition;
    const loanTermValue: LoanTermValue = this.formControlStep2LoanTerms.value;
    const financeType: FinanceTypeValue = this.step2FinanceType;
    const balloonPaymentType: BalloonPaymentValue = this.step2BalloonPaymentPercent;
    const brokerageValue: BrokerageSelectionValue = this.step2Brokerage;
    const calculator = new AppCalculator();
    const loanAmount = numeral(this.formControlStep2LoanAmount.value).value() ?? 0;
    const assetCat = this.step2AssetCategory;
    const assetType: AssetCategoryTypeSelectionValue = this.formControlStep2AssetType.value;
    const adverseOnFile = this.step2AdverseOnFile;
    const equifaxScore = this.step2EquifaxScore;
    const privateSales = this.step2PrivateSales;
    const brokerOriginationFee = this.step2BrokerOrigninationFee;
    const businessSearchValue: BusinessSearchValue = this.formControlStep1Business.value;
    const bureauReport = (businessSearchValue?.type === 'search-result' && businessSearchValue?.result) ? businessSearchValue.result : this.existingApplicationBureauReport;

    const formData: CommercialFinanceFormData = {
      assetCondition: assetConditionValue.type,
      assetCategory: assetCat.index,
      assetType: assetType!.index,
      assetSpec: {
        year: Number(moment().format('YYYY')),
      },
      loanAmount: loanAmount,
      loanTerms: parseFloat(loanTermValue?.type ?? '0'),
      propertyOwner: this.formControlStep2PropertyOwner.value ?? false,
      docFeeFinanced: this.step2DocFeeFinanced,
      brokerOriginationFee,
      brokerage: Number(brokerageValue.type),
      adverseOnFile,
      equifaxScoreAbove600: equifaxScore,
      privateSaleORLeaseback: privateSales,
      balloonPaymentPercent: Number(balloonPaymentType.type),
      financeType: financeType.name ?? '',
    }

    this.terms = calculator.getTotalInterestRateForCommercialFinance(
      this.rateCard!,
      formData,
      bureauReport,
      Number(balloonPaymentType.type ?? 0),
    );
    calculator.setDateValue(date.format('DD/MM/YYYY'));
    calculator.setRepaymentFrequency(paymentFrequencyValue?.type ?? 'Monthly');
    calculator.setPrinicipalValue(loanAmount);
    calculator.setInterestValue(this.terms.totalInterest);
    calculator.setloanValue(Number(loanTermValue?.type ?? '0'));
    calculator.setResidualAmount(this.terms.RV);
    calculator.setBrokeragePercent(Number(brokerageValue?.type ?? '0'));
    calculator.setBrokerageAmount(this.terms.brokerageAmount)
    calculator.refreshUI();
    this.calculator = calculator;

    this.step2MonthlyRepayment = calculator.emiAmt;
    const rst1 = calculator.getDonutChartData();
    const rst2 = calculator.getBarChartYearlyData();
    const rst3 = calculator.getBarChartQuaterlyData();
    const rst4 = calculator.getRepaymentEstimationData2(
      this.terms,
      paymentFrequencyValue?.type ?? 'Monthly',
      'advance',
    );
    const rst5 = calculator.getAmortisationData();
    const x: TotalPaymentBreakupDialogData = {
      repaymentEstimationData: rst4.map((rst ) =>({
        amountFinance: String(rst.amountFinance),
        month24: String(rst.data.find((d ) => d.loanTerm == 24)?.repayment ?? 0),
        month36: String(rst.data.find((d ) => d.loanTerm == 36)?.repayment ?? 0),
        month48: String(rst.data.find((d ) => d.loanTerm == 48)?.repayment ?? 0),
        month60: String(rst.data.find((d ) => d.loanTerm == 60)?.repayment ?? 0),
      })),
      amortizationScheduleData: rst5.map(rst => ({
        year: String(rst.year), payment: String(rst.yearTotal), interest: String(rst.yearInterest), principal: String(rst.yearPrincipal), balance: String(rst.endingBalance),
        details: rst.childRows.map(d =>({
          monthYear: `${d.month} ${d.year}`, payment: String(d.emi), interest: String(d.interest),
          principal: String(d.pricipalPaid), balance: String(d.endingBalance)
        }))
      })),
      paymentChartData: {
        amountFinanced: rst1.pricipalAmt,
        totalInterest: this.terms.totalInterest,
        emiAmt: calculator.emiAmt,
        paymentFrequency: calculator.repaymentFrequency,
        principalAmt: calculator.principalAmt,
        interestAmt: calculator.interestAmt,
        totalAmt: calculator.totalAmt,
        loanTerm: calculator.loanValue, // loanValue from calculator
        lvr: this.terms.LVR,
        rv: this.terms.RV,
        brokerageAmount: calculator.brokerageAmount,
        docFee: this.terms.docFee,
        brokerOriginationFee: this.terms.brokerOriginationFee,
        applicationType:'AssetFinance'
      },
      amortizationChartData: {
        estimatedDrawdownDate: moment(calculator.dateValue),
        annualData: rst2.map(rst => ({
          year: String(rst.year), interest: rst.yearInterest, principal: rst.yearPrincipal, balance: rst.endingBalance
        })),
        quarterlyData: rst3.map(rst => ({
          quarter: `Q${rst.quarter} ${rst.year}`, balance: rst.endingBalance, principal: rst.yearPrincipal, interest: rst.yearInterest
        }))
      },
      calculationLog: this.terms.calculationLog,
    };
    return x;
  }



  async mapToInteflowData(finalSubmission = false) {

    const user  = getUser();
    const financeType: FinanceTypeValue = this.step2FinanceType;
    const applicationNotes = this.formControlStep8ApplicationNotes.value;
    const broker: AggregatorSearchComponentValue = this.formControlStep2Broker.value;
    const assetType: AssetCategoryTypeSelectionValue = this.formControlStep2AssetType.value;
    const contact: SelectContactValue = this.formControlStep6PrimaryContact.value;
    const guarantors: GuarantorValue = this.formControlStep5Guarantors.value;
    const applicants: ApplicationApplicant = this.formControlStep4Applicants.value;
    const refValue: ReferenceValue = this.formControlStep7References.value;


    // workout broker salesforce id
    let brokerSalesforceId = broker!.salesforceId;
    let brokerAbn = broker!.abn;
    let brokerEntityName = broker!.entityName;
    if (user!.BrokerSalesforceId === environment.GrowFinanceGroupSalesforceId) {
      if (broker && broker.salesforceId) {
        brokerSalesforceId = broker.salesforceId;
        brokerAbn = broker.abn;
        brokerEntityName = broker.entityName;
      } else if (this.brokerOfUser && this.brokerOfUser.SalesforceId) {
        brokerSalesforceId = this.brokerOfUser.SalesforceId;
        brokerAbn = this.brokerOfUser.ABN;
        brokerEntityName = this.brokerOfUser.EntityName;
      }
    } else {
      if (this.brokerOfUser && this.brokerOfUser.SalesforceId !== undefined) {
        brokerSalesforceId = this.brokerOfUser.SalesforceId;
        brokerAbn = this.brokerOfUser.ABN;
        brokerEntityName = this.brokerOfUser.EntityName;
      }
    }


    // workout disclosure
    let disclosed = true;
    let introducerGroup = 'ECLIPXCOMMERCIAL';
    if (this.brokerOfUser) {
      if (this.brokerOfUser.Relationship !== 'disclosed') {
        disclosed = false;
        introducerGroup = 'UNDISCLOSED_ECLIPXCOMMERCIAL';
      }
    }


    // ====================
    // work out commercial entities
    // =============
    const b: BusinessSearchValue = this.formControlStep1Business.value;
    const o: EntityTypeValue = this.formControlStep3OrganisationType.value;
    const abn = this.formControlStep3Abn.value;
    const acn = this.formControlStep3Acn.value;

    // work out legalName & entityTYpe
    let legalName = toInteflowLegalName(b) ?? undefined;
    let entityType = o ? toInteflowEntityTypes(o.type) : undefined;

    // work out industries
    const primaryIndustry: PrimaryIndustrySelectionValue = this.formControlStep3PrimaryIndustry.value;
    const secondaryIndustry: SecondaryIndustrySelectionValue = this.formControlStep3IndustrySector.value;
    const revenue = this.formControlStep3AnnualRevenue.value;
    const operateOnCommercialPremises: boolean = this.formControlStep3OperatesInCommercialPremises.value ?? false;
    const landline = this.formControlStep3BusinessLandline.value;
    const phoneNumber = landline ? landline.substring(2).trim() : undefined;
    const areaCode = landline ? landline.substring(0, 2).trim() : undefined;
    const address: Address2ComponentValue = this.formControlStep3PrimaryBusinessAddress.value;

    // entities
    const primaryCommercialEntity = {
      LegalName: legalName ?? undefined,
      EntityType: entityType,
      ABN: abn ?? undefined,
      ACN: _.isEmpty(acn) ? '0' : acn ?? undefined,
      TradingName: '',
      Type: 'Primary',
      PrimaryIndustry: primaryIndustry?.division ?? undefined,
      IndustrySector: secondaryIndustry?.code ?? undefined,
      Revenue: revenue ? Number(revenue) : undefined,
      OperateatCommercialAddressFlag: operateOnCommercialPremises ?? undefined,
      PhoneNumber: phoneNumber,
      AreaCode: areaCode,
      BusinessCategory: 'SME ($1m+ Revenue | $0.5m to $5m Capital)',
      PrinciplePlaceofBusiness: address?.address ? {
        UnformattedAddress: address.address,
        UnitNumber: address?.UnitNumber,
        StreetNumber: address?.StreetNumber,
        StreetName: address?.StreetName,
        StreetType: address?.StreetType,
        Suburb: address?.Suburb,
        State: address?.State,
        Postcode: address?.Postcode
      } : undefined,
    }

    if(entityType!=='PTY'){
      delete primaryCommercialEntity.ACN
    }

    // commercial entities
    const otherCommercialEntities = fromApplicantToInteflowCommercialEntities(applicants);
    const guarantorCommercialEnttities = fromGuarantorToInteflowCommercialEnttities(guarantors);
    const otherCommercialEntity = [
      ...otherCommercialEntities,
      ...guarantorCommercialEnttities,
    ];


    // individuals
    const applicantIndividuals = fromApplicantToInteflowIndividualsData(applicants);
    const guarantorIndividuals = fromGuarantorToInteflowIndividuals(guarantors);
    const otherIndividuals = [
      ...applicantIndividuals,
      ...guarantorIndividuals,
    ];


    // reference
    const references =  fromReferenceToInteflowReferences(refValue);

    // pricing details
    const loanTerm: LoanTermValue = this.formControlStep2LoanTerms.value;
    const invoiceAmount = this.formControlStep2InvoiceAmount.value;
    const deposit = this.formControlStep2DepositAmount.value;
    const brokerage: BrokerageSelectionValue = this.step2Brokerage;
    const loanAmount = _.round(this.formControlStep2LoanAmount.value ?? 0, 2);
    const paymentPeriod: PaymentFrequencyValue = this.formControlStep2PaymentFrequency.value;
    const docFee = this.terms?.docFee ?? undefined;
    const docFeeFinanced = this.step2DocFeeFinanced;
    const propertyOwner = this.formControlStep2PropertyOwner.value;
    const adverseOnFile = this.step2AdverseOnFile;
    const equifaxScore = this.step2EquifaxScore;
    const rate = this.terms?.totalInterest ?? undefined;
    const balloonPercentage: BalloonPaymentValue = this.step2BalloonPaymentPercent;
    const balloonPayment = this.terms?.RV ?? undefined;
    const repayment = this.calculator?.emiAmt ?? undefined;
    const privateSales = this.step2PrivateSales;
    const assetCondition: AssetConditionValue = this.step2AssetCondition;


    const apiBodyPricingDetails = {
      LoanTerm: loanTerm?.type ? Number(loanTerm.type) : undefined,
      InvoiceAmount: (invoiceAmount != null ? (invoiceAmount) : undefined),
      Deposit: (deposit != null ? (deposit) : undefined),
      Brokerage: brokerage ? numeral(brokerage.type).value() ?? undefined : undefined,
      LoanAmount: loanAmount ?? undefined,
      PaymentPeriod: paymentPeriod ? paymentPeriod.name : undefined,
      DocFee: (docFee != null ? Number(docFee): undefined),
      DocFeeFinanced: booleanToYesNo(docFeeFinanced),
      PropertyOwner: booleanToYesNo(propertyOwner),
      AdverseOnFile: booleanToYesNo(adverseOnFile),
      EquifaxScoreAbove600: booleanToYesNo(equifaxScore),
      Rate: rate,
      BalloonPaymentPercentage: balloonPercentage?.type ? Number(balloonPercentage.type) : undefined,
      BalloonPayment: balloonPayment,
      Repayment: repayment,
      PrivateSale: booleanToYesNo(privateSales),
      AssetCondition: assetCondition?.type ?? undefined,
    }


    // file uploads
    const otherSupportingDocs: UploadAzureFilesValue = this.formControlStep8OtherSupportingDocuments.value;
    const driverLicencesUpload: DriverLicenceUpload[] = this.step8DriverLicenses;
    const allToBeDeleted = [...this.step8DeletedUploadedDocs].map(file => file.name);
    let allFiles: any[] = [];
    if (driverLicencesUpload && driverLicencesUpload.length && driverLicencesUpload[0]?.formControl?.value) {
      allFiles = driverLicencesUpload[0].formControl.value!.map((f) => {
        (f as any).tags = ['driverslicense'];
        (f as any).metadata = this.step8OtherDocMetadata;
        return f;
      });
    }
    if (otherSupportingDocs && otherSupportingDocs.length) {
      allFiles = [...allFiles, ...otherSupportingDocs];
    }

    if (this.applicationId && !finalSubmission) {
      this.subscriptions.push(
        (await this.applicationService.uploadApplicationDocumentToAzureStorageFn(
            this.applicationId,
            [...allFiles],
            [...allToBeDeleted])
        ).subscribe()
      );
    }

    const apiBodyReferences = [
      ...references
    ]

    const apiBodyIndividuals  = [
      ...otherIndividuals
    ];

    const apiBodyCommercialEntities = [
      primaryCommercialEntity,
      ...otherCommercialEntity,
    ];

    const apiBodyAppInfo = {
      BrokerAppID: this.brokerApplicationId ?? undefined,
      IntroducerGroup: introducerGroup,
      Disclosed: disclosed,
      FinanceType: financeType?.name ?? undefined,
      UserId: user!.UserId,
      CompanyId: user!.OriginatorBusinessId,
      UserEmail: user!.Email,
      UserFirstName: user!.GivenName,
      UserLastName: user!.FamilyName,
      Notes: applicationNotes ?? undefined,
      BusinessUse: booleanToYesNo(this.businessUseOnly),
      SelfServiceability:  booleanToYesNo(this.selfServiceability),
      PrivacyConfirmation: booleanToYesNo(this.privacyConfirmation),
      BrokerflowDocumentID: undefined,
      BrokerSalesforceID: brokerSalesforceId,
      BrokerAbn: brokerAbn,
      BrokerEntityName: brokerEntityName,
      AssetCategory: this.step2AssetCategory.index,
      AssetType: assetType?.index ?? undefined,
      ContactId: undefined,
      CustomerId: undefined,
      SalesforceId: undefined,
      StageName: 'Draft',
    };

    const apiBodyAssetSpec = {
      year: moment().format('YYYY'),
      description: this.formControlStep2AssetDescription.value ?? undefined,
    };

    const apiBodyContact = fromContactToInteflowContacts(contact);

    const apiBody: UpdateApplicationData = {
      ApplicationType: 'Commercial',
      IP: this.ip,
      ApplicantLocation: this.geoLocation,
      UserId: this.applicationService.getApplicationUserId(this.existingApplication,this.user),
      CompanyId: this.applicationService.getApplicationCompanyId(this.existingApplication,this.user),
      AppInfo: apiBodyAppInfo,
      AssetSpec: apiBodyAssetSpec,
      Contacts: apiBodyContact,
      CommercialEntities: apiBodyCommercialEntities,
      Individuals: apiBodyIndividuals,
      Reference: apiBodyReferences,
      PricingDetails: apiBodyPricingDetails,
      AcceptQuotation: this.acceptedQuotation,
      ApplicationNotes: applicationNotes ?? '',
      CompanyDetails: undefined,
      //Documents: apiBodyDocuments,
      Expense: undefined,
    }
    return apiBody;
  }




  private async saveApplication(stepper: CdkStepper) {
    if (!this.applicationId) { // application was not saved before (new draft)
      const inteflowData = await this.mapToInteflowData();
      this.applicationService.saveApplication(inteflowData).pipe(
        // this.toastService.toastObserver('save draft'),
        tap(r => {
          const applicationId = r.payload.ApplicationId;
          const brokerApplicationId = r.payload.BrokerApplicationId;
          this.brokerApplicationId = brokerApplicationId;
          this.applicationId = applicationId;
          const business: BusinessSearchValue = this.formControlStep1Business.value
          if (business) {
            this.organisationName = business.organisationName;
          }
          stepper.next();
        })
      ).subscribe();
    } else {  // existing draft
      await this.updateDraftInBg();
      stepper.next();
    }
  }


  private async updateDraftInBg(fn?: ()=>void) {
    if (this.applicationId) {
      const data = await this.mapToInteflowData();
      const sub = this.applicationService.updateApplication(this.applicationId, data).pipe(
        fn ? this.toastService.loadingWithMessage(`Saving, please be patient`) : tap(()=>{}),
        tap(r => {
          if (fn) {
            fn();
          }
        })
      ).subscribe();
      this.subscriptions.push(sub);
    }
  }

  onStep8DeleteUploadedDoc($event: any) { // UploadedFilesListComponentEvent
    this.step8DeletedUploadedDocs = [...$event.deleted];
  }

  get step8OtherDocMetadata() {
    const b: BusinessSearchValue = this.formControlStep1Business.value;
    const abn = this.formControlStep3Abn.value ?? '';
    const legalName = toInteflowLegalName(b) ?? '';

    return {
      abn: abn,
      legalname: legalName
    };
  }

  onStep8UploadOtherDoc(files: UploadAzureFiles) {
    const supportingDocs = this.formControlStep8OtherSupportingDocuments.value;
    if (!supportingDocs) {
      this.formControlStep8OtherSupportingDocuments.setValue([...files]);
    } else {
      this.formControlStep8OtherSupportingDocuments.setValue([...supportingDocs, ...files]);
    }
  }

  onStep8DeleteSupportingDoc(index: number) {
    const supportingDocs = this.formControlStep8OtherSupportingDocuments.value;
    if (supportingDocs) {
      supportingDocs.splice(index, 1);
      this.formControlStep8OtherSupportingDocuments.setValue([...supportingDocs]);
    }
  }
}
