import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {
  BankStatementsAnalysisTransactionData,
  BankStatementsAnalysisTransactionDetails, compare,
  DEFAULT_LIMIT,
  DEFAULT_OFFSET, TransactionTag
} from '@portal-workspace/grow-shared-library';
import {PageEvent} from '@angular/material/paginator';
import { Sort, MatSortModule } from '@angular/material/sort';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { CustomPaginatorComponent } from '../custom-paginator-component/custom-paginator/custom-paginator.component';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';
import { MatTableModule } from '@angular/material/table';

@Component({
    selector: 'transaction-table',
    templateUrl: './transaction-table.component.html',
    styleUrls: ['./transaction-table.component.scss'],
    standalone: true,
    imports: [MatTableModule, MatSortModule, NgClass, ExtendedModule, CustomPaginatorComponent, LooseCurrencyPipe]
})
export class TransactionTableComponent implements OnInit, OnChanges {
  @Input({required: false}) data!: BankStatementsAnalysisTransactionDetails[];
  @Input({required: true}) type!: 'overdrawn' | 'dishonour' | 'non-preferred-lender';
  columnsToDisplay: string[] = ['date', 'category', 'party', 'transaction', 'credit', 'debit'];
  dataSource: BankStatementsAnalysisTransactionData[] = [];
  displayedData: BankStatementsAnalysisTransactionData[] = [];

  limit!: number;
  offset!: number;
  total!: number;
  constructor() {}

  ngOnInit(): void {
    this.initColumns();
    this.initPageData();
  }

  ngOnChanges() {
    this.initColumns();
    this.initPageData();
  }

  initColumns() {
    switch(this.type) {
      case 'overdrawn':
        this.columnsToDisplay = ['date', 'category', 'transaction', 'credit', 'debit'];
        break;
      case 'dishonour':
      case 'non-preferred-lender':
        this.columnsToDisplay = ['date', 'party', 'transaction', 'credit', 'debit'];
        break;
      default:
        this.columnsToDisplay = ['date', 'category', 'party', 'transaction', 'credit', 'debit'];
    }
  }

  initPageData(): void {
    this.dataSource = [];
    this.data.forEach((detail: BankStatementsAnalysisTransactionDetails) => {
      let row: BankStatementsAnalysisTransactionData = {
        date: detail.date,
        transaction: detail.text ?? detail.description,
        debit: 0,
        credit: 0,
        party: '',
        category: '',
        otherInfo: '',
        bankId: detail.BankID,
      };
      detail?.tags?.forEach((obj: TransactionTag) => {
        const keyArray: string[] = Object.keys(obj);
        const key: string = keyArray.length ? keyArray[0] : '';
        switch (key) {
          case 'thirdParty':
            row.party = obj['thirdParty'];
            break;
          case 'category':
            if (row.category?.length) {
              row.category += ', ' + obj['category'];
            } else {
              row.category = obj['category'];
            }
            break;
          case 'creditDebit':
            obj['creditDebit'] === 'credit' ? row.credit = detail.amount : row.debit = detail.amount;
            break;
          default:
            if (row.otherInfo?.length) {
              row.otherInfo += ` | ${key}: ${obj[key]}`;
            } else {
              row.otherInfo = `${key}: ${obj[key]}`;
            }
        }
      })
      this.dataSource.push(row);
    })

    this.total = this.dataSource.length;
    this.limit = DEFAULT_LIMIT;
    this.offset = DEFAULT_OFFSET;
    this.updateDisplayedData();
  }

  updateDisplayedData() {
    this.displayedData = this.dataSource.slice(this.offset * this.limit, (this.offset + 1) * this.limit);
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'date': return 'Date';
      case 'transaction': return 'Transaction';
      case 'debit': return '$ Debit';
      case 'credit': return '$ Credit';
      case 'party': return 'Party';
      case 'category': return 'Category';
      case 'otherInfo': return 'Other Info';
      default: return column;
    }
  }

  needCurrencyPipe(column: string) {
    return ['debit', 'credit'].includes(column);
  }

  onSort(sort: Sort) {
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }

    this.dataSource = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'date':
          return compare(a.date, b.date, isAsc);
        case 'category':
          return compare(a.category ?? '', b.category ?? '', isAsc);
        case 'party':
          return compare(a.party ?? '', b.party ?? '', isAsc);
        case 'transaction':
          return compare(a.transaction, b.transaction, isAsc);
        case 'credit':
          return compare(a.credit, b.credit, isAsc);
        case 'debit':
          return compare(a.debit, b.debit, isAsc);
        default:
          return 0;
      }
    });

    this.updateDisplayedData();
  }

  onPagination($event: PageEvent) {
    this.limit = $event.pageSize;
    this.offset = $event.pageIndex;
    this.updateDisplayedData();
  }

  needAlignRight(column: string) {
    return ['debit', 'credit'].includes(column);
  }
}
