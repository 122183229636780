<div class="individual-details-component">
  @if (!(hasEntries)) {
    <div class="no-entries">
      <div fxLayout="column" fxLayoutAlign="center center">
        <img src="/assets/images/faq-empty.png">
        <div class="mat-h3 title">No entries</div>
      </div>
    </div>
  }

  <!-- applicants -->
  <div class="applicant" *ngTemplateOutlet="individual; context: {$implicit: applicants, title: 'Applicant'}">
  </div>

  <!-- directors -->
  <div class="director" *ngTemplateOutlet="individual; context: {$implicit: directors, title: 'Director'}">
  </div>

  <!-- guarantors -->
  <div class="guarantor" *ngTemplateOutlet="individual; context: {$implicit: guarantors, title: 'Guarantor'}">
  </div>

  <!-- partners -->
  <div class="partner" *ngTemplateOutlet="individual; context: {$implicit: partners, title: 'Partner'}">
  </div>

  <!-- shareholders -->
  <div class="shareholder" *ngTemplateOutlet="individual; context: {$implicit: shareHolders, title: 'Shareholder'}">
  </div>

  <!-- sole traders -->
  <div class="soletrader" *ngTemplateOutlet="individual; context: {$implicit: soleTraders, title: 'Sole Trader'}">
  </div>

  <!-- trustees -->
  <div class="trustee" *ngTemplateOutlet="individual; context: {$implicit: trustees, title: 'Trustee'}">
  </div>

  <!-- spouses -->
  <div class="spouse" *ngTemplateOutlet="individual; context: {$implicit: spouses, title: 'Spouse'}">
  </div>

  <!-- Commercial Guarantor -->
  <div class="commercialguarantor" *ngTemplateOutlet="commercialentities; context: {$implicit: commercialGuarantor, title: 'Guarantor'}">
  </div>

  <!-- Commercial Trustee -->
  <div class="commercialtrustee" *ngTemplateOutlet="commercialentities; context: {$implicit: commercialTrustee, title: 'Trustee'}">
  </div>

  <!-- Commercial Partner -->
  <div class="commercialpartner" *ngTemplateOutlet="commercialentities; context: {$implicit: commercialPartner, title: 'Partner'}">
  </div>

  <!-- Commercial Member -->
  <div class="commercialmember" *ngTemplateOutlet="commercialentities; context: {$implicit: commercialMember, title: 'Member'}">
  </div>

</div>




<ng-template #individual let-applicants let-title="title">
  @if (applicants && applicants.length) {
    @for (applicant of applicants; track applicant; let index = $index) {
      <div class="section">
        <div class="row">
          <div class="col-12">
            <div class="mat-h3">{{title}} {{index + 1}}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Title</div>
              <div body>{{applicant.Title}}</div>
            </cell>
          </div>
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>First name</div>
              <div body><p [innerHTML]="applicant.GivenName | ellipsis:10"></p></div>
            </cell>
          </div>
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Last name</div>
              <div body><p [innerHTML]="applicant.SurName | ellipsis:10"></p></div>
            </cell>
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Date of birth</div>
              <div body>{{applicant.DoB|date: 'dd/MM/yyyy'}}</div>
            </cell>
          </div>
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Gender</div>
              <div body>{{applicant.Gender}}</div>
            </cell>
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <cell>
              <div label>Residential address</div>
              <div body>{{applicant.HomeAddress?.UnformattedAddress}}</div>
            </cell>
          </div>
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Privacy consent</div>
              <div body>{{booleanToYesNo(applicant.PrivacyConsent)}}</div>
            </cell>
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Property owner</div>
              <div body>{{booleanToYesNo(applicant.PropertyOwnerFlag)}}</div>
            </cell>
          </div>
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Guarantor</div>
              <div body>{{booleanToYesNo(applicant.GuarantorFlag)}}</div>
            </cell>
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
        @if (applicant.AddressofPropertyOwned) {
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <cell>
                <div label>Property address</div>
                <div body>{{applicant.AddressofPropertyOwned.UnformattedAddress}}</div>
              </cell>
            </div>
          </div>
          <mat-divider [inset]="true"></mat-divider>
          <div class="row">
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>Property value</div>
                <div body><span class="mdi mdi-currency-usd"></span>{{applicant.PropertyValue}}</div>
              </cell>
            </div>
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>Mortgage balance</div>
                <div body><span class="mdi mdi-currency-usd"></span>{{applicant.MortgageBalance}}</div>
              </cell>
            </div>
          </div>
          <mat-divider [inset]="true"></mat-divider>
        }
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <cell>
              <div label>Email address</div>
              <div body>{{applicant.Email}}</div>
            </cell>
          </div>
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Mobile number</div>
              <div body><!--span>+61</span-->{{applicant.MobileNumber}}</div>
            </cell>
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
      </div>
    }
  }
</ng-template>

<ng-template #commercialentities let-entities let-title="title">
  @if (entities && entities.length) {
    @for (entitie of entities; track entitie; let index = $index) {
      <div class="section">
        <div class="row">
          <div class="col-12">
            <!-- <div class="mat-h3" >{{title}} {{ index+ 1}}</div> -->
            <div class="mat-h3" >{{title}}
              @if (entitie.Type === 'Guarantor') {
                {{commonGuarantor + index + 1}}
              } @else {
                {{commonNonGuarantor + index + 1}}
              }
            </div>
          </div>
        </div>
        <div class="row" >
          @if (entitie.ABN) {
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>ABN</div>
                <div body>{{entitie.ABN}}</div>
              </cell>
            </div>
          }
          @if (entitie.ACN) {
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>ACN</div>
                <div body>{{entitie.ACN}}</div>
              </cell>
            </div>
          }
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>EntityType</div>
              <div body>{{entitie.EntityType}}</div>
            </cell>
          </div>
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>LegalName</div>
              <div body>{{entitie.LegalName}}</div>
            </cell>
          </div>
        </div>
      </div>
    }
  }
</ng-template>
