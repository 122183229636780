<div class="portal-dashboard-page header bg-grow pb-6">
  <div class="container-fluid mb-4">
    <div class="header-body">
      @if (loggedInUser.approval && loggedInUser.approval == 1) {
        <div class="row padding_left_right">
          <div class="col-md-12 col-lg-6 mb-2">
            <portal-dashboard-top-widget
              [loading]="(totalApplicationLoader.TotalApplication.inProgress$ | async)"
            [details]="getTopWidgetDetails(1)"></portal-dashboard-top-widget>
          </div>
          <div class="col-md-12 col-lg-6 mb-2">
            <portal-dashboard-top-widget
              [loading]="(inProgressApplicationLoader.InProgressApplication.inProgress$ | async)"
            [details]="getTopWidgetDetails(2)"></portal-dashboard-top-widget>
          </div>
          <div class="col-md-12 col-lg-6 mb-2">
            <portal-dashboard-top-widget
              [loading]="(settlementApplicationLoader.SettlementApplication.inProgress$ | async)"
            [details]="getTopWidgetDetails(3)"></portal-dashboard-top-widget>
          </div>
          <div class="col-md-12 col-lg-6 mb-2">
            <portal-dashboard-top-widget
              [loading]="(settlementApplicationLoader.SettlementApplication.inProgress$ | async)"
            [details]="getTopWidgetDetails(4)"></portal-dashboard-top-widget>
          </div>
        </div>
      }
    </div>
  </div>

  <div class="container-fluid mb-4">
    <!--    charts-->
    @if (loggedInUser.approval && loggedInUser.approval == 1) {
      <div class="row">
        <div class="col-xl-12">
          <div class="card bg-white">
            <mat-button-toggle-group
              #group="matButtonToggleGroup"
              [hideSingleSelectionIndicator]="true"
              [hideMultipleSelectionIndicator]="true"
              value="applicationVolume"
              class="toggle-button-group small-medium-text">
              <mat-button-toggle value="applicationVolume" aria-label="Text align left" (click)="displayApplicationVolumeChart()">
                Application volume
              </mat-button-toggle>
              <mat-button-toggle value="totalSettlements" aria-label="Text align center" (click)="displayTotalSettlementsChart()">
                Total settlements
              </mat-button-toggle>
            </mat-button-toggle-group>
            @if (group.value === 'applicationVolume') {
              <div>
                <div class="card-body">
                  <div>
                    <canvas class="chart-canvas" id="chart-application-volume" height="300"> </canvas>
                  </div>
                </div>
              </div>
            }
            @if (group.value === 'totalSettlements') {
              <div>
                <div class="card-body">
                  <div>
                    <canvas class="chart-canvas" id="chart-total-settlements" height="300"> </canvas>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    }

    <div class="footnote mat-caption" fxLayout="row-reverse">Only applications in the last 12 months are listed</div>

    <!--    tabs-->
    @if (loggedInUser.approval && loggedInUser.approval == 1) {
      <div>
        <mat-tab-group [mat-stretch-tabs]="false" mat-align-tabs="start">
          <mat-tab label="NEWS AND UPDATES">
            <div class="row">
              <div class="col-xl-8">
                @for (news of newsData; track news) {
                  <app-dashboard-news [news]="news"></app-dashboard-news>
                }
              </div>
              <div class="col-xl-4">
                <div class="small-medium-text progress-track-title">Progress track</div>
                @for (application of allApplications | slice:0:9; track application) {
                  <app-dashboard-progress-track [application]="application" (clickApplication)="onClickApplication($event)"></app-dashboard-progress-track>
                }
                @if (allApplications.length == 0) {
                  <div class="my-2">
                    <h5>No Applications yet..!!</h5>
                  </div>
                }
              </div>
            </div>
          </mat-tab>
          <mat-tab label="NEW APPLICATION">
            <div class="new-application-tab">
              <application-tiles [enableTiles]="environment.applicationSubmission" (clickedUrl)="navigationHandler($event)"></application-tiles>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    }

    @if (loggedInUser.approval != 1) {
      <div class="row justify-content-center">
        <div class=" col-lg-12 col-md-7">
          <div class="card bg-secondary border-0 mb-0" style="height: 100vh;">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center approval-msg">
                <h3 class="ng-tns-c2-0">Approval in progress......!!</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    }

  </div>
</div>
