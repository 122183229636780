<div class="broker-user-details-component">
  @if (user && user.isDisabled) {
    <div class="row separator-bottom">
      <div class="col-lg-6 col-md-12">
        <message-box title="Disabled" type="warn">
          <span>This user is disabled</span>
        </message-box>
      </div>
    </div>
  }

  <mat-card appearance="outlined">
    <mat-card-content>
      <div class="separator-bottom">
        <div class="row mb-4 ml-0">
          <span class="mat-body-strong">
            User details
          </span>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <name title="First Name" [formControl]="formControlFirstName"></name>

          </div>
          <div class="col-lg-3 col-md-12">
            <name title="Last Name" [formControl]="formControlLastName"></name>

          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-md-12">
            <aggregator-and-originator-list title="Company name" [getFn]="aggregatorAndOriginatorListValueGetFn"
              [disableControl]="!isInternalUser(loggedInUser)"
            [formControl]="formControlAggregatorOrOriginator"></aggregator-and-originator-list>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <mat-form-field>
              <mat-label>Mobile number</mat-label>
              <input type="text" [inputMask]="createMobilePhoneNumberInputMask" matInput
                [formControl]="formControlMobileNumber">
                @if (user && user.mobile_verified) {
                  <span class="mdi mdi-check-circle-outline" matSuffix></span>
                }
              </mat-form-field>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-lg-6 col-md-12">
              <mat-form-field>
                <mat-label>Email</mat-label>
                <input matInput [inputMask]="createEmailInputMask" type="text" [formControl]="formControlEmail" placeholder="Email">
                @for (errorKey of errorKeys(formControlEmail); track errorKey) {
                  <mat-error>
                    @if (formControlEmail.touched && formControlEmail.hasError(errorKey)) {
                      {{errorMessage(formControlEmail, errorKey)}}
                    }
                  </mat-error>
                }
              </mat-form-field>
            </div>
            @if (canAccess) {  
            <div class="col-lg-3 col-md-12">
              <button mat-flat-button color="primary"
                class="w-lt-md-100"
                [disabled]="formControlEmail.invalid || !isInternalUser(loggedInUser) || ((loader.saving.inProgress$ | async))"
                (click)="updateEmail(user)">
                {{(loader.saving.inProgress$ | async) ? "Saving...":"Save"}}
              </button>
            </div>
          }
          </div>
          <mat-divider [inset]="true"></mat-divider>
        </div>

        <div class="separator-bottom">
          <div class="row mb-4 ml-0">
            <div class="col-12">
              <span class="mat-body-strong">
                Access
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <access-level [user]="loggedInUser"  [formControl]="formControlAccessLevel"></access-level>
            </div>
            <mat-divider [inset]="true"></mat-divider>
          </div>
        </div>

        <div class="separator-bottom">
          <div class="row mb-4 ml-0">
            <div class="col-12">
              <span class="mat-body-strong">
                Status
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-9 col-md-12">
              <mat-card appearance="outlined" class="mb-4 custom-card">
                <mat-card-content>
                  <div class="row">
                    <div class="col-1 mr-2">
                      <span class="mdi mdi-check-circle-outline mt-4"></span>
                    </div>
                    <div class="col-8">
                      <span class="row list-header">User is
                        @if (this.user.isDisabled) {
                          <span class="ml-1"> not active</span>
                        }
                        @else {
                          <span class="ml-1"> active</span>
                        }
                      </span>
                        <span class="row list-description">Switch the toggle
                          @if (this.user.isDisabled) {
                            <span class="ml-1 mr-1"> on to enable</span>
                          }
                          @else {
                            <span class="ml-1 mr-1"> off to disable </span>
                          }
                          this user
                        </span>
                      </div>
                      <div class="col-1">
                        <slide-toggle
                          [showCancelConfirmation]="true"
                          cancelConfirmationMessage="Are you sure you want to disable this user?"
                          [formControl]="formControlEnabled">
                        </slide-toggle>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-9 col-md-12">
                <mat-card appearance="outlined" class="mb-4 custom-card">
                  <mat-card-content>
                    <div class="row">
                      <div class="col-1 mr-2">
                        <span class="mdi mdi-check-circle-outline mt-4"></span>
                      </div>
                      <div class="col-8">
                        <span class="row list-header">User is
                          @if (this.user.approval) {
                            <span class="ml-1"> approved</span>
                          }
                          @else {
                            <span class="ml-1"> not approved</span>
                          }</span>
                          <span class="row list-description">Switch the toggle
                            @if (this.user.approval) {
                              <span class="ml-1 mr-1"> off to reject </span>
                            }
                            @else {
                              <span class="ml-1 mr-1"> on to approve</span>
                            }
                            this user
                          </span>
                        </div>
                        <div class="col-1">
                          <slide-toggle
                            [showCancelConfirmation]="true"
                            cancelConfirmationMessage="Are you sure you want to reject this user?"
                            [formControl]="formControlApproved">
                          </slide-toggle>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-9 col-md-12">
                  <mat-card appearance="outlined" class="mb-4 custom-card">
                    <mat-card-content>
                      <div class="row">
                        <div class="col-1 mr-2">
                          <span class="mdi mdi-check-circle-outline mt-4"></span>
                        </div>
                        <div class="col-8">
                          <span class="row list-header">User is
                            @if (this.user.isLock) {
                              <span class="ml-1"> locked</span>
                            }
                            @else {
                              <span class="ml-1"> unlocked</span>
                            }</span>
                            <span class="row list-description">Switch the toggle
                              @if (this.user.isLock) {
                                <span class="ml-1 mr-1"> off to unlock </span>
                              }
                              @else {
                                <span class="ml-1 mr-1"> on to lock</span>
                              }
                              this user
                            </span>
                          </div>
                          <div class="col-1">
                            <slide-toggle
                              [showCancelConfirmation]="true"
                              cancelConfirmationMessage="Are you sure you want to unlock this user?"
                              [formControl]="formControlLocked">
                            </slide-toggle>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>
                </div>
              </div>
              <mat-divider [inset]="true"></mat-divider>


              <div class="separator-bottom">
                <div class="row mb-4 ml-0 mt-3">
                  <span class="mat-body-strong">
                    Password and Authentication
                  </span>
                </div>
                <div class="row">
                  <div class="col-lg-9 col-md-12">
                    <mat-card appearance="outlined" class="mb-4 custom-card">
                      <mat-card-content>
                        <div class="row pt-2 pb-2">
                          <div class="col-1 mr-2">
                            <span class="mdi mdi-lock-outline mt-4"></span>
                          </div>
                          <div class="col-9">
                            <span class="row list-header">Change password</span>
                          </div>
                          <div class="col-1" (click)="openConfirmPasswordAdmin($event)">
                            <span class="mdi mdi-chevron-right mt-4"></span>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-9 col-md-12">
                    <mat-card appearance="outlined" class="mb-4 custom-card">
                      <mat-card-content>
                        @if (!this.canUser2faBeChanged) {
                          <div class="row">
                            <div class="col-md-12 col-lg-10">
                              <message-box type="warn" title="Info">Unable to disable 2fa for internal users</message-box>
                            </div>
                          </div>
                        }
                        <div class="row pt-2 pb-2">
                          <div class="col-1 mr-2">
                            <span class="mdi mdi-two-factor-authentication mt-4"></span>
                          </div>
                          <div class="col-8">
                            <span class="row list-header">Enable two-factor auth</span>
                            @if (!formControl2FA.value) {
                              <span class="row list-description">Protect the Dynamoney account with an extra layer of security</span>
                            }
                            @if (formControl2FA.value) {
                              <span class="row list-description">2FA is a good way to add an extra layer of security to your Dynamoney account to make sure that only you have the ability to log in</span>
                            }
                          </div>
                          <div class="col-1">
                            <slide-toggle  [showCancelConfirmation]="false"
                              [formControl]="formControl2FA">
                            </slide-toggle>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>
                </div>
                @if (canAccess) {  
                <mat-divider [inset]="true"></mat-divider>
                }
              </div>
              @if (canAccess) {  
              <div class="row">
                <div class="col-lg-3 col-md-12">
                  <button mat-flat-button color="primary"
                    class="w-lt-md-100"
                    [disabled]="formGroup1.invalid || ((loader.saving.inProgress$ | async))"
                    (click)="onSave(user)">
                    {{(loader.saving.inProgress$ | async) ? "Saving...":"Save"}}
                  </button>
                </div>
              </div>
            }
            </mat-card-content>
          </mat-card>
        </div>
