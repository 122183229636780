<div class="direct-debit-dialog">
  <mat-dialog-content>
    <div class="row">
      <div class="col-12">
        <div class="mat-subtitle-1 header">Direct Debit</div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <local-bank-account-detail [formControl]="formControlDirectDebit" [hideInstitutionName]="true"></local-bank-account-detail>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <mat-form-field>
          <mat-label>Amount</mat-label>
          <input type="number" matInput [formControl]="formControlAmount" placeholder="Enter amount...">
          @for (errorKey of errorKeys(formControlAmount); track errorKey) {
            <mat-error>
              @if (formControlAmount.touched && formControlAmount.hasError(errorKey)) {
                {{errorMessage(formControlAmount, errorKey)}}
              }
            </mat-error>
          }
        </mat-form-field>
      </div>

      <div class="col-6">
        <mat-form-field>
          <mat-label>Reference</mat-label>
          <input type="text" matInput [formControl]="formControlReference" placeholder="Enter reference...">
          @for (errorKey of errorKeys(formControlReference); track errorKey) {
            <mat-error>
              @if (formControlReference.touched && formControlReference.hasError(errorKey)) {
                {{errorMessage(formControlReference, errorKey)}}
              }
            </mat-error>
          }
        </mat-form-field>
      </div>
    </div>
    <br>
      <div class="row">
        <div class="col" fxLayout="row" fxLayoutAlign="space-between center">
          <button mat-flat-button color="accent" class="ml-3" (click)="cancel()">Cancel</button>
          <button mat-flat-button color="primary" class="mr-3" [disabled]="formGroup.invalid" (click)="submit()">Submit</button>
        </div>
      </div>
    </mat-dialog-content>
  </div>
