import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Chart} from 'chart.js';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import {Moment} from 'moment';
import moment from 'moment';
import {AmortizationChartData, displaybleDate} from '@portal-workspace/grow-shared-library';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import { DatepickerComponent } from '../datepicker-component/datepicker.component';
import { FlexModule } from '@angular/flex-layout/flex';



@Component({
    selector: 'amortization-chart',
    templateUrl: './amortization-chart.component.html',
    styleUrls: ['./amortization-chart.component.scss'],
    standalone: true,
    imports: [FlexModule, DatepickerComponent, FormsModule, ReactiveFormsModule, MatRadioModule]
})
export class AmortizationChartComponent implements AfterViewInit, OnInit, OnChanges {

  @Output() events: EventEmitter<Moment> = new EventEmitter<Moment>();

  @ViewChild('chartCanvas') chartCanvasElemRef!: ElementRef;
  formControl: FormControl<Moment|null> = new FormControl(null);

  static id = 1;

  @Input({required: false}) data: AmortizationChartData = {estimatedDrawdownDate: moment(), annualData: [], quarterlyData: []};

  chart!: Chart;
  chartId: string;

  selection: 'annual' | 'quarterly' = 'annual';

  constructor() {
    this.chartId = `Armotization-Chart-${AmortizationChartComponent.id++}`;
  }

  annualData = {
    labels: [],
    datasets: [
      {
        label: 'Balance',
        data: [],
        backgroundColor: '#FFFFFF',
        borderColor: '#5D40FF',
        type: 'line',
        stack: 'combined',
        yAxisID: 'y2',
        fill:false
      },
      {
        label: 'Principal paid',
        data: [],
        backgroundColor:'#78909C',
        stack: 'combined',
        barPercentage: 0.2,
      },
      {
        label: 'Interest paid',
        data:  [],
        backgroundColor: '#5D40FF',
        stack: 'combined',
        barPercentage: 0.2,
      }
    ]
  };

  quarterlyData = {
    labels: [],
    datasets: [
      {
        label: 'Balance',
        data: [],
        backgroundColor: '#FFFFFF',
        borderColor: '#5D40FF',
        type: 'line',
        stack: 'combined',
        yAxisID: 'y2',
        fill:false
      },
      {
        label: 'Principal paid',
        data: [],
        backgroundColor: '#78909C',
        stack: 'combined',
        barPercentage: 0.3,
      },
      {
        label: 'Interest paid',
        data:  [],
        backgroundColor: '#5D40FF',
        stack: 'combined',
        barPercentage: 0.3,
      }
    ]
  }

  ngOnInit() {
    this.formControl.setValue((this.data.estimatedDrawdownDate));
    // create annual data
    this.data.annualData.forEach( d => {
      (this.annualData.labels as string[]).push(d.year);
      (this.annualData.datasets[0].data as number[]).push(d.balance);
      (this.annualData.datasets[1].data as number[]).push(d.principal);
      (this.annualData.datasets[2].data as number[]).push(d.interest);
    });

    // create quarterly data
    this.data.quarterlyData.forEach(d => {
      (this.quarterlyData.labels as string[]).push(d.quarter);
      (this.quarterlyData.datasets[0].data as number[]).push(d.balance);
      (this.quarterlyData.datasets[1].data as number[]).push(d.principal);
      (this.quarterlyData.datasets[2].data as number[]).push(d.interest);
    });
  }

  ngAfterViewInit(): void {
    setTimeout(()=> {
        console.log('************** chartId', this.chartId);
        const canvasElement: HTMLCanvasElement = this.chartCanvasElemRef.nativeElement;
        const context = canvasElement.getContext('2d');
        // const context = (document.getElementById(this.chartId) as HTMLCanvasElement).getContext('2d');
        this.chart = new Chart(context!, {
          type: 'bar',
          // @ts-ignore
          data: this.annualData,
          options: {
            responsive: true,
            animation: false,
            maintainAspectRatio: true,
            plugins: {
              legend: {
                display: false,
                position: 'bottom',
              },
              title: {
                display: false,
              },
            },
            scales: {
              x: {
                stacked: false,
              },
              y: {
                stacked: true,
                position: 'right',
                title: {
                  display: true,
                  text: 'Payment',
                },
                ticks: {
                  callback: function(value, index, values) {
                    return `$${Number(value).toFixed(2)}` ;
                  }
                }
              },
              y2: {
                stacked: true,
                position: 'left',
                title: {
                  display: true,
                  text: 'Balance',
                },
                grid: { display: false },
                ticks: {
                  callback: function (value, index, values) {
                    return `$${Number(value).toFixed(2)}`;
                  }
                }
              }
            }
          }
        });
    });
  }

  onPeriodChange($event: MatRadioChange) {
    if ($event.value === 'annual') {
      this.chart.data = this.annualData as any;
      this.selection = 'annual';
    } else {
      this.chart.data = this.quarterlyData as any;
      this.selection = 'quarterly';
    }
    this.chart.update();
  }

  onDateChange($event: MatDatepickerInputEvent<unknown, unknown | null>) {
    console.log('((((((((( date change', $event.value);
    const moment: Moment = $event.value as Moment;
    this.events.emit(moment);

  }

  ngOnChanges(changes: SimpleChanges): void {
    const simpleChange = (changes as any).data;
    if (simpleChange && !simpleChange.firstChange) {
      const data: AmortizationChartData = simpleChange.currentValue;


      this.annualData.labels.length = 0;
      this.annualData.datasets[0].data.length = 0;
      this.annualData.datasets[1].data.length = 0;
      this.annualData.datasets[2].data.length = 0;
      data.annualData.forEach((d ) => {
        (this.annualData.labels as string[]).push(d.year);
        (this.annualData.datasets[0].data as number[]).push(d.balance);
        (this.annualData.datasets[1].data as number[]).push(d.principal);
        (this.annualData.datasets[2].data as number[]).push(d.interest);
      });

      // create quarterly data
      this.quarterlyData.labels.length = 0;
      this.quarterlyData.datasets[0].data.length = 0;
      this.quarterlyData.datasets[1].data.length = 0;
      this.quarterlyData.datasets[2].data.length = 0;
      data.quarterlyData.forEach(d => {
        (this.quarterlyData.labels as string[]).push(d.quarter);
        (this.quarterlyData.datasets[0].data as number[]).push(d.balance);
        (this.quarterlyData.datasets[1].data as number[]).push(d.principal);
        (this.quarterlyData.datasets[2].data as number[]).push(d.interest);
      });

      if (this.selection === 'annual' && this.chart?.data) {
        this.chart.data = this.annualData as any;
      } else if (this.chart?.data) {
        this.chart.data = this.quarterlyData as any;
      }
      if (this.chart) {
        this.chart.update();
      }


      console.log('****** onchanges data', data);
    }
  }
}
