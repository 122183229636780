<div class="categories-table">
  @if (showFilter()) {
    <div class="row">
      <div class="col-12 accounts" fxLayout="row wrap" fxLayoutGap="15px" fxLayoutAlign="start center">
        @for (account of accountSummary; track account) {
          <mat-checkbox color="primary" [formControl]="accountFilter[account.BankID]" (change)="filterChangeHandler()">
            {{account?.AccHolder + "-" + account?.AccName + " (" + account?.BSBAccNumber + ")"}}
          </mat-checkbox>
        }
      </div>
    </div>
  }

  <div>
    @if (accountSelectionChanges && !singleAccount) {
      <div class="row">
        <div class="col-12"
          fxLayoutGap="10px"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="center start">
          @if (!firstTimeLoadData) {
            <span class="text-primary">Account selection has been changed. Click to reload data</span>
          }
          @if (firstTimeLoadData) {
            <span class="text-primary">Click to load data</span>
          }
          <button class="reload-button arrow-left w-lt-md-100" (click)="reloadChartAndTable()">
            <span class="mdi mdi-refresh"></span>
          </button>
        </div>
      </div>
    }

    <div class="row mt-3">
      <div class="col-12">
        <div>Day End Balance Chart for Selected Accounts</div>
        <day-end-balance-chart [data]="dayEndBalanceChartData" [index]="999" [lightTheme]="true"></day-end-balance-chart>
      </div>
    </div>

    <div class="table-section">
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="width-100 mb-2">
        @for (column of columnsToDisplay; track column) {
          <ng-container matColumnDef="{{column}}">
            <th mat-header-cell *matHeaderCellDef [ngClass]="{'text-align-right': needAlignRight(column)}">
              <div class="cell">{{getColumnTitles(column)}}</div>
            </th>
            <td mat-cell *matCellDef="let element" [ngClass]="{'text-align-right': needAlignRight(column)}">
              @if (column === 'icon') {
                <div class="cell">
                  @if (expandedElement === element) {
                    <span class="mdi mdi-chevron-up"></span>
                  }
                  @if (expandedElement !== element) {
                    <span class="mdi mdi-chevron-down"></span>
                  }
                </div>
              }
              @if (column !== 'icon') {
                <div class="cell">
                  @if (element[column] === '') {
                    Uncategorized Transactions
                  }
                  @if (element[column] !== '') {
                    @if (needCurrencyPipe(column)) {
                      {{element[column] | looseCurrency}}
                    }
                    @if (!needCurrencyPipe(column)) {
                      {{element[column]}}
                    }
                  }
                </div>
              }
            </td>
          </ng-container>
        }

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element; let i = dataIndex" class="expanded-row" [attr.colspan]="columnsToDisplay.length">
            <div class="example-element-detail"
              [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <div class="row mt-3">
                <div class="col">
                  @if (element?.details) {
                    <category-details-table [data]="element.details" (checkbox)="handleCheckboxChange($event, i)"></category-details-table>
                  }
                </div>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
          class="example-element-row"
          [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </table>

      @if (isData) {
        <table class="not-found">
          <tr>
            <td> No data found </td>
          </tr>
        </table>
      }
    </div>

    <div class="mt-3">
      <div class="col-12">
        <div>Monthly Debits / Credits for Selected Accounts</div>
        <monthly-debits-credits-chart [data]="selectedDebitCredit" [index]="999" [lightTheme]="true"></monthly-debits-credits-chart>
      </div>
    </div>
  </div>
</div>
