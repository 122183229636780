import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DebitCreditSummary} from '@portal-workspace/grow-shared-library';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';
import { MatTableModule } from '@angular/material/table';

@Component({
    selector: 'monthly-debits-credits-table',
    templateUrl: './monthly-debits-credits-table.component.html',
    styleUrls: ['./monthly-debits-credits-table.component.scss'],
    standalone: true,
    imports: [MatTableModule, NgClass, ExtendedModule, LooseCurrencyPipe]
})
export class MonthlyDebitsCreditsTableComponent implements OnInit, OnChanges {
  @Input({required: true}) data!: DebitCreditSummary;
  dataSource: DebitCreditSummary[] = [];
  columnsToDisplay: string[] = ['totalcredit', 'numcredit', 'totaldebit', 'numdebit'];

  constructor() {}

  ngOnInit(): void {
    if (this.data) {
      this.dataSource = [this.data];
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.data) {
      this.dataSource = [this.data];
    }
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'totaldebit': return 'Total Debit';
      case 'totalcredit': return 'Total Credit';
      case 'numdebit': return '# Debits';
      case 'numcredit': return '# Credits';
      default: return column;
    }
  }

  needCurrencyPipe(column: string) {
    return ['totaldebit', 'totalcredit'].includes(column);
  }
}
